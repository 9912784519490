import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-notifiaction-popup',
  templateUrl: './notifiaction-popup.component.html',
  styleUrls: ['./notifiaction-popup.component.scss']
})
export class NotifiactionPopupComponent implements OnInit {

  @Input() imageSrc: string;
  @Input() errorMsg: string;

  constructor(private modalCtrl:ModalController) {}

  async dismiss() {
    this.modalCtrl.dismiss();
  }

  ngOnInit(): void {
  }

}
