<app-header-left-menu [user]="currentUser"></app-header-left-menu>
<ion-content id="main-content" class="full-width-page">
  <div class="bg-theme-alt">
    <div class="bg-grad-special">
      <div class="bg-grad-special-alt">
        <div class="nk-ovm shape-u shape-contain shape-left-top"></div>
        <app-header [user]="currentUser"></app-header>
        <div class="subContent">
          <div class="main-content container">
            <!-- <ion-row>
              <div class="col-lg-9">
                <h5 class="title-gradient clrB">Hiddin Account</h5>
                <p class="clrL">Manage your communication directory</p>
              </div>
              <div class="col-lg-3 text-lg-right">
                <div class="pdng">
                  <div>
                    <h6 class="clrB mb-2">Profile Type: <span
                        class="greenBold">Individual</span>
                    </h6>
                  </div>
                  <div>
                    <p class="clrL">Subscription expired in <span class="rednBold">29</span>
                      days
                    </p>
                  </div>
                </div>
              </div>
            </ion-row>
            <hr> -->

            <div class="mx-4">
              <ion-row>
                <ion-col size-lg="3" size-md="4" size-sm="12" size-xs="12" style="padding-right: 30px;">
                  <div class="user-img">

                    <!-- <img src="assets/icon/back.png" onclick="goBack();"/>-->
                    <div class="usr-img">

                      <ion-buttons slot="start">
                        <ion-back-button text="" defaultHref="">
                        </ion-back-button>
                      </ion-buttons>
                      <img src="assets/images/team/profile.png" />
                    </div>
                    <h5 class="user-name clrB">{{user?.firstName}} {{user?.lastName}}</h5>
                    <p *ngIf="user?.company" class="clrB mb-2">
                      {{user?.company}}
                    </p>

                    <hr>
                    <div *ngIf="user?.aboutMe" class="field-item mt-2">
                      <div class="field-wrap About">
                        <h6 class="about">About Me</h6>
                        <p class="about f13 clrB">
                          {{user?.aboutMe}}
                        </p>
                      </div>
                    </div>


                  </div>
                </ion-col>
                <ion-col class="pluses" size-lg="9" size-md="8" size-sm="12" size-xs="12">
                  <div class="user-detail">
                    <h5 class="sub-heading">Profile Details</h5>
                    <!-- <p class="text">manage your personal information</p> -->
                    <ion-row>
                      <ion-col *ngIf="user?.firstName" size-lg="4" size-md="7" size-sm="12" size-xs="12">
                        <ion-card>
                          <ion-card-title>
                            <h6 class="title title-xs">Full Name</h6>
                          </ion-card-title>
                          <ion-card-content>
                            <div class="icon">
                              <img src="assets/icon/name.png" />
                            </div>
                            <input type="text" class="input-bordered" value="{{user?.firstName}} {{user?.lastName}}"
                              disabled>
                          </ion-card-content>
                        </ion-card>
                      </ion-col>
                      <ion-col *ngIf="user?.phoneNumber" size-lg="4" size-md="7" size-sm="12" size-xs="12">
                        <ion-card>
                          <ion-card-title>
                            <h6 class="title title-xs">Contact</h6>
                          </ion-card-title>
                          <ion-card-content>
                            <div class="icon">
                              <img src="assets/icon/contact.png" />
                            </div>
                            <input type="text" class="input-bordered"
                              value="{{user?.phoneDialCode}} {{user?.phoneNumber}}" disabled>
                          </ion-card-content>
                        </ion-card>
                      </ion-col>
                      <ion-col *ngIf="user?.email" size-lg="4" size-md="7" size-sm="12" size-xs="12">
                        <ion-card>
                          <ion-card-title>
                            <h6 class="title title-xs">Email</h6>
                          </ion-card-title>
                          <ion-card-content>
                            <div class="icon">
                              <img src="assets/icon/message.png" />
                            </div>
                            <input type="text" class="input-bordered" value="{{user?.email}}" disabled>
                          </ion-card-content>
                        </ion-card>
                      </ion-col>
                      <ion-col *ngIf="user?.birthDate" size-lg="4" size-md="7" size-sm="12" size-xs="12">
                        <ion-card>
                          <ion-card-title>
                            <h6 class="title title-xs">Date of Birth</h6>
                          </ion-card-title>
                          <ion-card-content>
                            <div class="icon">
                              <img src="assets/icon/calender.png" />
                            </div>
                            <input type="text" class="input-bordered" value="{{user?.birthDate}}" disabled>
                          </ion-card-content>
                        </ion-card>
                      </ion-col>
                      <ion-col *ngIf="user?.city" size-lg="4" size-md="7" size-sm="12" size-xs="12">
                        <ion-card>
                          <ion-card-title>
                            <h6 class="title title-xs">City</h6>
                          </ion-card-title>
                          <ion-card-content>
                            <div class="icon">
                              <img src="assets/icon/city.png" />
                            </div>
                            <input type="text" class="input-bordered" value="{{user?.city}}" disabled>
                          </ion-card-content>
                        </ion-card>
                      </ion-col>
                      <ion-col *ngIf="user?.state" size-lg="4" size-md="7" size-sm="12" size-xs="12">
                        <ion-card>
                          <ion-card-title>
                            <h6 class="title title-xs">State</h6>
                          </ion-card-title>
                          <ion-card-content>
                            <div class="icon">
                              <img src="assets/icon/state.png" />

                            </div>
                            <input type="text" class="input-bordered" value="{{user?.state}}" disabled>
                          </ion-card-content>
                        </ion-card>
                      </ion-col>
                      <ion-col *ngIf="user?.country" size-lg="4" size-md="7" size-sm="12" size-xs="12">
                        <ion-card>
                          <ion-card-title>
                            <h6 class="title title-xs">Country</h6>
                          </ion-card-title>
                          <ion-card-content>
                            <div class="icon">
                              <img src="assets/icon/country.png" />
                            </div>
                            <input type="text" class="input-bordered" value="{{user?.country}}" disabled>
                          </ion-card-content>
                        </ion-card>
                      </ion-col>
                      <ion-col *ngIf="user?.language" size-lg="4" size-md="7" size-sm="12" size-xs="12">
                        <ion-card>
                          <ion-card-title>
                            <h6 class="title title-xs">Language</h6>
                          </ion-card-title>
                          <ion-card-content>
                            <div class="icon">
                              <img src="assets/icon/language.png" />
                            </div>
                            <input type="text" class="input-bordered" value="{{user?.language}}" disabled>
                          </ion-card-content>
                        </ion-card>
                      </ion-col>
                    </ion-row>
                  </div>
                </ion-col>

              </ion-row>

            </div>
            <div>
              <!--<button class="btn btn-primary btn-md pull-right mr-2" type="button" (click)="goBack();">Back</button>
                            <button *ngIf="!editEnabled && addEnabled"  class="btn btn-primary btn-md pull-right mr-2" type="button" (click)="showContactCategoryOptions(user.id);">Add Contact</button>
                            --><button *ngIf="editEnabled" class="btn btn-primary btn-md pull-right mr-2" type="button"
                (click)="goTo('/profile-enrich/basic-detail');">Enrich Profile</button>
                <button type="button" *ngIf="viewTimeSlot" (click)="openSettingModal()" class="btn btn-primary">More Info</button>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
      </div>
    </div>
  </div>
</ion-content>