import { Component, Input, OnInit } from '@angular/core';
import { AuthServiceService } from '@services/auth-service.service';
import { RegUser } from '../../pages/basic-reg/RegUser';
import { Globals } from 'src/app/globals';

import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ProfileModalComponent } from '@modal/profile-modal/profile-modal.component';
import { LoaderService } from '@services/loader.service';
import { CommonUtil } from 'src/app/util/CommonUtil';
import { TimeZoneUtils } from 'src/app/util/TimeZoneUtils';

@Component({
  selector: 'app-contact-setting',
  templateUrl: './contact-setting.component.html',
  styleUrls: ['./contact-setting.component.scss', '../../../assets/css/style.scss'],
})
export class ContactSettingComponent {
  requestPath: string;
  errorMessage: string;

  loggedInUser: RegUser;

  selectedTab = 'tab1';
  selectedCategory = 'friends';
  select: boolean = true;
  timeSlotType = 'Custom';

  ownerId;
  preferredTimeSlotToDisplay = [];
  preferredCommChanelToDisplay = [];
  isOlderTime;

  fromTime;
  toTime;
  selectAllTimeSlots = false;

  @Input("user") user;
  @Input("personalizedMsg") personalizedMsg;
  @Input("isSender") isSender;
  @Input("isAddButtonVisible") isAddButtonVisible;

  constructor(private authService: AuthServiceService,
    public global: Globals,
    private modalCtrl: ModalController,
    private router: Router,
    private loader: LoaderService) {
    this.requestPath = this.global.requestPath;
  }

  ngOnInit(): void {
  }

  ionViewDidEnter() {
    this.loggedInUser = this.authService.getLoggedInUser();
    this.ownerId = this.loggedInUser.id;
    this.errorMessage = "";
    this.selectAllCommChanel();
    // console.log(this.user);
    // console.log(this.personalizedMsg);
    // console.log(this.isSender);
    // console.log(this.isAddButtonVisible);
    // For testing only, need to comment this line
    // var arr = "1,4,22".split(",");
    // this.markTimeSlotSelected(arr);
  }

  async dismiss(data) {
    this.modalCtrl.dismiss(data);
  }

  async openProfileModal(userData) {
    this.dismiss('');
    if (this.isSender) {
      const modal = await this.modalCtrl.create({
        component: ProfileModalComponent,
        componentProps: {
          user: userData,
          personalizedMsg: this.personalizedMsg,
          isAddButtonVisible: this.isAddButtonVisible
        }
      });

      modal.onDidDismiss().then((dataReturned) => {
        // this.router.navigateByUrl("dashboard");
      });

      modal.present();
    }
  }

  changeTimeSlot(val: any) {
    this.timeSlotType = val;
    this.selectAllTimeSlots = false;
    this.markAllTimeSlots(false);
  }

  changeTimeSelectionType(event) {
    var val = event.target.checked;
    this.selectAllTimeSlots = val;
    this.markAllTimeSlots(val);
  }

  changeCategory(val: any) {
    this.selectedCategory = val;
  }

  changeTab(tab) {
    this.selectedTab = tab;
  }

  markChecked(id) {
    for (var timeSlot of this.timeSlotsCol1) {
      if (timeSlot.id == id) {
        timeSlot.checked = !timeSlot.checked;
        return;
      }
    }
    for (var timeSlot of this.timeSlotsCol2) {
      if (timeSlot.id == id) {
        timeSlot.checked = !timeSlot.checked;
        return;
      }
    }
  }

  markAllTimeSlots(checked) {
    for (var timeSlot of this.timeSlotsCol1) {
      timeSlot.checked = checked;
    }
    for (var timeSlot of this.timeSlotsCol2) {
      timeSlot.checked = checked;
    }
  }

  getUserPreferredTime() {
    var preferredTimeSlots = "";
    for (var timeSlot of this.timeSlotsCol1) {
      if (timeSlot.checked) {
        preferredTimeSlots += this.convertTimeSlotToUTC(timeSlot.value) + ",";
      }
    }
    for (var timeSlot of this.timeSlotsCol2) {
      if (timeSlot.checked) {
        preferredTimeSlots += this.convertTimeSlotToUTC(timeSlot.value) + ",";
      }
    }
    return preferredTimeSlots.substring(0, preferredTimeSlots.length - 1);
  }

  selectAllCommChanel() {
    for (var commType of this.communicationTypes) {
      commType.checked = true;
    }
  }

  convertTimeSlotToUTC(timeRange) {
    let splittedData = timeRange.split("-");
    if (splittedData.length != 2) {
      console.log("Invalid time range: " + timeRange);
      return timeRange;
    }
    return TimeZoneUtils.convertOnlyTimeToUTC(splittedData[0], this.loggedInUser.timeZone) + "-" + TimeZoneUtils.convertOnlyTimeToUTC(splittedData[1], this.loggedInUser.timeZone);
  }

  getPreferredCommChannel() {
    var preferredcommChanels = "";
    for (var commType of this.communicationTypes) {
      if (commType.checked) {
        preferredcommChanels += commType.id + ",";
      }
    }
    return preferredcommChanels.substring(0, preferredcommChanels.length - 1);
  }

  markTimeSlotSelected(ids) {
    for (var timeSlot of this.timeSlotsCol1) {
      if (ids.indexOf(timeSlot.id + "") > -1) {
        timeSlot.checked = true;
      }
    }
    for (var timeSlot of this.timeSlotsCol2) {
      if (ids.indexOf(timeSlot.id + "") > -1) {
        timeSlot.checked = true;
      }
    }
  }

  changeChanel(id) {
    for (var commType of this.communicationTypes) {
      if (commType.id == id) {
        commType.checked = !commType.checked;
        return;
      }
    }
  }

  addContact() {
    if (this.timeSlotType == "Custom") {
      if (CommonUtil.isEmptyTrim(this.fromTime)) {
        this.errorMessage = "Please select preferred time slots.";
        return;
      }
      if (CommonUtil.isEmptyTrim(this.toTime)) {
        this.errorMessage = "Please select preferred communication channels.";
        return;
      }
    } else {
      var preferredTimeSlots = this.selectAllTimeSlots ? "All" : this.getUserPreferredTime();
      var preferredChanel = this.getPreferredCommChannel();
      if (CommonUtil.isEmptyTrim(preferredTimeSlots)) {
        this.errorMessage = "Please select preferred time slots.";
        return;
      }
      if (CommonUtil.isEmptyTrim(preferredChanel)) {
        this.errorMessage = "Please select preferred communication channels.";
        return;
      }
    }

    if (this.isSender) {
      let userContact = {
        "ownerId": this.ownerId,
        "contactId": this.user.id,
        "senderCategory": this.selectedCategory,
        "status": "Requested",
        "senderStartTime": '',
        "senderEndTime": '',
        "senderPrefTime": '',
        "senderCommChanel": '',
        "isFavorite": false,
        "personalizedMsg": this.personalizedMsg
      };
      userContact.senderCommChanel = this.getPreferredCommChannel();
      if (this.timeSlotType == "Custom") {
        userContact.senderStartTime = TimeZoneUtils.convertOnlyTimeToUTC(this.fromTime, this.loggedInUser.timeZone);
        userContact.senderEndTime = TimeZoneUtils.convertOnlyTimeToUTC(this.toTime, this.loggedInUser.timeZone);
      } else {
        userContact.senderPrefTime = preferredTimeSlots;
      }
      this.createContact(userContact);
    } else {
      let userContact = {
        "ownerId": this.ownerId,
        "contactId": this.user.id,
        "recieverCategory": this.selectedCategory,
        "status": "Requested",
        "recieverStartTime": '',
        "recieverEndTime": '',
        "recieverPrefTime": '',
        "recieverCommChanel": '',
        "isFavorite": false,
        "personalizedMsg": this.personalizedMsg
      };
      userContact.recieverCommChanel = this.getPreferredCommChannel();
      if (this.timeSlotType == "Custom") {
        userContact.recieverStartTime = TimeZoneUtils.convertOnlyTimeToUTC(this.fromTime, this.loggedInUser.timeZone);
        userContact.recieverEndTime = TimeZoneUtils.convertOnlyTimeToUTC(this.toTime, this.loggedInUser.timeZone);
      } else {
        userContact.recieverPrefTime = preferredTimeSlots;
      }
      this.dismiss(userContact);
    }

  }

  approve() {
    var preferredTimeSlots = this.getUserPreferredTime();
    var preferredChanel = this.getPreferredCommChannel();

    if (CommonUtil.isEmptyTrim(preferredTimeSlots)) {
      this.errorMessage = "Please select preferred time slots.";
      return;
    }

    if (CommonUtil.isEmptyTrim(preferredChanel)) {
      this.errorMessage = "Please select preferred communication channels.";
      return;
    }
    this.dismiss('OK');
  }

  createContact(userContact: any) {
    this.loader.show("Please wait...");
    this.authService.postEncrypt('user/directory?' + this.requestPath + '=save', userContact)
      .subscribe((res: any) => {
        this.loader.hide();
        if (res.success) {
          // Close modal and move to pending request page
          this.dismiss('');
          this.router.navigate(['pending-request-new']);
        } else {
          this.errorMessage = res.message;
        }
      }, (error: any) => {
        this.loader.hide();
        this.errorMessage = "Something went wrong, please try again.";
      });
  }

  timeSlotsCol1 = [
    {
      id: 0,
      view: "00:00-01:00 AM",
      value: "0:00 AM-1:00 AM",
      startTime: "00:00",
      endTime: "01:00",
      checked: false
    },
    {
      id: 1,
      view: "01:00-02:00 AM",
      value: "1:00 AM-2:00 AM",
      startTime: "01:00",
      endTime: "02:00",
      checked: false
    },
    {
      id: 2,
      view: "02:00-03:00 AM",
      value: "2:00 AM-3:00 AM",
      startTime: "02:00",
      endTime: "03:00",
      checked: false
    },
    {
      id: 3,
      view: "03:00-04:00 AM",
      value: "3:00 AM-4:00 AM",
      startTime: "03:00",
      endTime: "04:00",
      checked: false
    },
    {
      id: 4,
      view: "04:00-05:00 AM",
      value: "4:00 AM-5:00 AM",
      startTime: "04:00",
      endTime: "05:00",
      checked: false
    },
    {
      id: 5,
      view: "05:00-06:00 AM",
      value: "5:00 AM-6:00 AM",
      startTime: "05:00",
      endTime: "06:00",
      checked: false
    },
    {
      id: 6,
      view: "06:00-07:00 AM",
      value: "6:00 AM-7:00 AM",
      startTime: "06:00",
      endTime: "07:00",
      checked: false
    },
    {
      id: 7,
      view: "07:00-08:00 AM",
      value: "7:00 AM-8:00 AM",
      startTime: "07:00",
      endTime: "08:00",
      checked: false
    },
    {
      id: 8,
      view: "08:00-09:00 AM",
      value: "8:00 AM-9:00 AM",
      startTime: "08:00",
      endTime: "09:00",
      checked: false
    },
    {
      id: 9,
      view: "09:00-10:00 AM",
      value: "9:00 AM-10:00 AM",
      startTime: "09:00",
      endTime: "10:00",
      checked: false
    },
    {
      id: 10,
      view: "10:00-11:00 AM",
      value: "10:00 AM-11:00 AM",
      startTime: "10:00",
      endTime: "11:00",
      checked: false
    },
    {
      id: 11,
      view: "11:00-12:00 AM",
      value: "11:00 AM-12:00 AM",
      startTime: "11:00",
      endTime: "12:00",
      checked: false
    }
  ];
  timeSlotsCol2 = [
    {
      id: 12,
      view: "00:00-01:00 PM",
      value: "12:00 PM-13:00 PM",
      startTime: "12:00",
      endTime: "13:00",
      checked: false
    },
    {
      id: 13,
      view: "01:00-02:00 PM",
      value: "13:00 PM-14:00 PM",
      startTime: "13:00",
      endTime: "14:00",
      checked: false
    },
    {
      id: 14,
      view: "02:00-03:00 PM",
      value: "14:00 PM-15:00 PM",
      startTime: "14:00",
      endTime: "15:00",
      checked: false
    },
    {
      id: 15,
      view: "03:00-04:00 PM",
      value: "15:00 PM-16:00 PM",
      startTime: "15:00",
      endTime: "16:00",
      checked: false
    },
    {
      id: 16,
      view: "04:00-05:00 PM",
      value: "16:00 PM-17:00 PM",
      startTime: "16:00",
      endTime: "17:00",
      checked: false
    },
    {
      id: 17,
      view: "05:00-06:00 PM",
      value: "17:00 PM-18:00 PM",
      startTime: "17:00",
      endTime: "18:00",
      checked: false
    },
    {
      id: 18,
      view: "06:00-07:00 PM",
      value: "18:00 PM-19:00 PM",
      startTime: "18:00",
      endTime: "19:00",
      checked: false
    },
    {
      id: 19,
      view: "07:00-08:00 PM",
      value: "19:00 PM-20:00 PM",
      startTime: "19:00",
      endTime: "20:00",
      checked: false
    },
    {
      id: 20,
      view: "08:00-09:00 PM",
      value: "20:00 PM-21:00 PM",
      startTime: "20:00",
      endTime: "21:00",
      checked: false
    },
    {
      id: 21,
      view: "09:00-10:00 PM",
      value: "21:00 PM-22:00 PM",
      startTime: "21:00",
      endTime: "22:00",
      checked: false
    },
    {
      id: 22,
      view: "10:00-11:00 PM",
      value: "22:00 PM-23:00 PM",
      startTime: "22:00",
      endTime: "23:00",
      checked: false
    },
    {
      id: 23,
      view: "11:00-12:00 PM",
      value: "23:00 PM-24:00 PM",
      startTime: "23:00",
      endTime: "24:00",
      checked: false
    }
  ];

  communicationTypes = [
    {
      id: 1,
      name: "Messenger",
      checked: false
    },
    {
      id: 2,
      name: "Audio Calling",
      checked: false
    },
    {
      id: 3,
      name: "Video Calling",
      checked: false
    },
    {
      id: 4,
      name: "Email",
      checked: false
    }]
}
