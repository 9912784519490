import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ContactSettingComponent } from '@modal/contact-setting/contact-setting.component';
import { RegUser } from 'src/app/pages/basic-reg/RegUser';

@Component({
  selector: 'app-profile-modal',
  templateUrl: './profile-modal.component.html',
  styleUrls: ['./profile-modal.component.scss' , '../../../assets/css/style.scss'],
})
export class ProfileModalComponent implements OnInit {

  @Input("user") user: RegUser;
  profileImageSrc: any;
  @Input("personalizedMsg") personalizedMsg;
  @Input("isAddButtonVisible") isAddButtonVisible;
  
  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {}

  ionViewDidEnter() {
    console.log("isAddButtonVisible: " + this.isAddButtonVisible);
  }
  async dismiss(data) {
    this.modalCtrl.dismiss(data);
  }

  async openSettingModal(userData) {
    this.dismiss('');
    const modal = await this.modalCtrl.create({
      component: ContactSettingComponent,
      componentProps: {
        user: userData,
        personalizedMsg: this.personalizedMsg,
        isSender: true,
        isAddButtonVisible: this.isAddButtonVisible
      }
    });

    modal.onDidDismiss().then((dataReturned) => {
      // this.router.navigateByUrl("dashboard");
    });

    modal.present();
  }

}
