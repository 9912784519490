import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthServiceService } from '@services/auth-service.service';
import { LoaderService } from '@services/loader.service';
import { Globals } from 'src/app/globals';
import { MustMatch } from 'src/app/util/must-match.validator';
import { RegUser } from '../basic-reg/RegUser';

@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.scss'],
})
export class CreatePasswordComponent implements OnInit {

  requestPath;

  heading = "Email Verification";
  subheading = "";

  cpUser = new RegUser();
  cpForm: FormGroup;
  cpSubmitted: boolean;
  errorMessage: string;

  user: RegUser = new RegUser();
  userPwd: RegUser = new RegUser();
  enablePasswordPage: boolean;

  verificationCode: string;
  id: number;

  passwordVisible;
  confirmPasswordVisible;

  constructor(public global: Globals
    , public authService: AuthServiceService
    , public formBuilder: FormBuilder
    , public router: Router
    , private route: ActivatedRoute
    , private loader: LoaderService) {
    this.requestPath = this.global.requestPath;

    this.route.params.subscribe(params => {

      this.verificationCode = params['verificationCode'];
      this.id = params['id'];

      // console.log("verificationCode: " + this.verificationCode);

      if (this.global.isEmpty(this.verificationCode)) {
        this.router.navigate(['/home']);
      }
    });
  }

  ngOnInit() {
    this.cpForm = this.formBuilder.group({
      userPassword: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(6)]]
    }, {
      validator: MustMatch('userPassword', 'confirmPassword')
    });
  }

  ionViewDidEnter() {
    this.onReset();
    this.verifyEmail();
  }

  ionViewWillEnter() {
  }

  onReset = function () {
    this.heading = "Email Verification";
    this.enablePasswordPage = false;

    this.cpForm.reset();
    this.cpUser = new RegUser();
    this.userPwd = new RegUser();
    this.cpSubmitted = false;

    this.errorMessage = null;
  }

  verifyEmail = function () {
    this.cpUser.id = this.id;
    this.cpUser.verificationCode = this.verificationCode;
    this.loader.show("Please wait...");
    this.authService.getNoSecure('ns/common/user?' + this.requestPath + '=verifyVCode&userId=' + this.cpUser.id + "&vCode=" + this.cpUser.verificationCode + "&emailOrPhone=1")
      .subscribe(res => {
        this.loader.hide();
        // console.log(res);
        if (res.success) {
          //Decrypt Data
          this.enablePasswordPage = true;
          this.heading = "Set New Password";
        } else {
          this.errorMessage = res.message;
        }
      }, error => {
        this.loader.hide();
        // console.log(error);
        this.errorMessage = "Something went wrong, please try again.";
      });
  }

  createNewPassword = function () {
    this.cpSubmitted = true;
    this.errorMessage = null;

    if (this.cpForm.invalid) {
      // console.log("Invalid form");
      return;
    }

    this.cpUser.password = window.btoa(this.userPwd.userPassword);
    this.loader.show("Please wait...");
    this.authService.postDefaultEncrypt('ns/common/user?' + this.requestPath + '=createPassword', this.cpUser)
      .subscribe(res => {
        this.loader.hide();
        // console.log(res);
        if (res.success) {
          //Decrypt Data
          res.data = this.global.decryptDefault(res.data);
          this.global.saveLocalData(this.global.HIDDIN_USER_DETAILS, JSON.stringify(res.data));
          this.router.navigate(["/otp"]);
          console.log(res.description);
        } else {
          this.errorMessage = res.message;
        }
      }, error => {
        this.loader.hide();
        // console.log(error);
        this.errorMessage = "Something went wrong, please try again.";
      });
  }

  togglePassword(id: string) {
    const password = document.querySelector('#' + id);
    const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
    password.setAttribute('type', type);

    if (id === 'resetPwd') {
      this.passwordVisible = !this.passwordVisible;
    } else if (id === 'confirmResetPwd') {
      this.confirmPasswordVisible = !this.confirmPasswordVisible;
    }
  }

}
