<app-header-left-menu [user]="user"></app-header-left-menu>
<ion-content id="main-content" class="full-width-page">
  <div class="bg-theme-alt">
    <div class="bg-grad-special">
      <div class="bg-grad-special-alt">
        <div class="nk-ovm shape-u shape-contain shape-left-top"></div>
        <app-header [user]="user"></app-header>
        <div class="subContent">
          <div class="main-content container">
            <h5 class="ath-heading title">Transaction Processing<small class="tc-default">Payment Processing Costs & Security Problems Impact Individuals and Enterprises</small></h5>
            <p>Most transaction processing systems focus on the single event where money changes hands revealing the identities of the parties and relying on encryption to protect the payment details exchanged in each of the resulting transmissions. The systems that rely on encryption to protect the details concerning the manner of payment, the identities of the parties, and the other details of the transaction will fail if the data has been copied in anticipation of future decryption capabilities. Apple pay uses tokenization to protect the manner of payment, but relies on encryption to protect the transaction details and the identities of the parties. Pay Pal also does a good job of protecting the manner of payment between the parties, but relies on encryption to protect the identities of the parties and the transaction details.</p>
            <p>HIDDIN de-identifies all the forms of communication before during and after the traditional focus on the single event where the money changes hands. Current data collection techniques and capabilities make it possible to reveal many of the details of the event where money changes hands from analyzing these other forms of communications and transmissions that occur before and after that event. Studying all the calls, e-mails and correspondence occurring before and after a significant transaction would contain most of the information eventually agreed to and in the transaction event. None of the other payment systems address this potential weakness in their systems. HIDDIN hides all these e-mails, phone calls and any other form of communication that occurs before during and after the transmission event. Like Pay Pal we do not disclose where the money comes from or goes to in any of these transmissions. Payment is handled upon enrollment. Where Apple Pay tokenizes the payment method used in the event where money changes hands HIDDIN tokenizes the identities of all the parties involved in all the communications before during and after so no unauthorized cyber punk can figure out who is involved or how anyone paid or got paid. The transaction details void of identity and payment methods is not valuable even for research purposes and is the only part of these transmissions left for encryption to provide camouflage. Access to <b>HIDDIN'S</b> technology is required to re-identify this data and utilizing <b>HIDDIN'S</b> ability to replace identity with profile information makes this otherwise useless data extremely valuable for research and analytic purposes. (See Research Capabilities)</p>
            <p>The HIDDIN Marketplace enrollment process allows each participant to set up the financial institutions they want to use to pay and be paid as a result of their HIDDIN Marketplace transactions. Every transaction for a particular individual is treated as a separate de-identified event recorded in our de-identified transaction register and in the individual’s daily activity register that can only be access by them upon signing into their account and passing their own designed security protocols.</p>
            <p>To understand the level of detail of the information we are collecting think back to the last register receipt you were given the last time you went to the supermarket or department store and purchased several different items. That receipt contained each item description, product number, quantity purchased, and unit price not to mention the time date and store ID. That is a lot of information but it is not in a form you can do anything with so you discard these receipts like everyone else. We are capturing this level of detail in a machine readable de-identified format for all of your purchases in your daily activity register and for everyone in our transaction register. Once a day all the transaction activity for everyone is summarized from our de-identified transaction register in a single close out entry used to record each participant’s net activity with the financial institution set up upon enrolling. This net figure is compared to the net of the activity in each individuals daily activity register to insure an exact match of the net of the day’s activity.</p>
            <p>The HIDDIN Marketplace acts as the bookkeeper for all the participants, both buyers and sellers, and records only the net of everyone’s activities to their predetermined financial institutions never revealing anything about anyone else’s financial arrangements in the transaction process. Posting only the net of the day’s activity hides each of the transactions that combine to make the amount recorded by your financial institution. Buy six items from seller A four items from seller B and eleven items from seller C and in your net checking account at the end of the day we make a single post representing the total of the day’s activity with no mention of A, B, or C. Payment information is collected at enrollment and only accessed internally by HIDDIN and never exposed directly to any external third parties or transmissions eliminating them as potential points of exposure unlike many of the other third party payment systems.</p>
            <p>The fees associated with third party payment systems range from 1.5% to more than 3% and all the other miscellaneous charges are also eliminated. Utilizing traditional payment processing systems would risk re-identification and typically exposes the date and amounts exchanged between you and A, B, or C, and most systems transmit some parts of the information regarding how things are paid for adding to the security risk even if encryption is utilized. With our payment technology strategy the HIDDIN Marketplace is the transaction data broker for all parties and the bookkeeper for them both as well. Daily we post the net activity for everyone to their net-checking account after all the actual details of the days transactions have been posted to their individual communication portals. The eventual entries into their net checking account reflect the net of all the day’s activity in a single post from the HIDDIN Marketplace.</p>
            <p>Occasionally HIDDIN might need to charge an ACH transfer fee or currency translation fee when an un-enrolled banking institution is required to accommodate unusual transaction types. HIDDIN’S commissions are based on the marketing savings that each industry realizes from using our marketplace. Our objective is to bring down marketing costs and that includes our commissions, marketing research costs, and campaign delivery expenditures to the 50% target for each industry. Traditional transaction processing costs sellers money that they must pass on to their customers. Our marketplace creates savings over the traditional marketing expenditures that cover our costs and still yields an eventual 50% return for our buyers, sellers and their cause marketing endowments to share between them. What we are offering is different and it will take some time for enterprises to transition away from their traditional ineffective marketing practices and enjoy the full benefit of the savings we will be able to provide them. Our five year strategic marketing alliance program should provide all the time they will need to get comfortable and realize most of these savings.</p>
            <p>Pay Pal offers the closest comparable to our technology. Everyone enrolling in the HIDDIN Marketplace will establish a net-checking account at a bank of our mutual choosing. The net checking account starts the day with $100.00 and ends the day with $100.00. The details of what financial accounts that the enrollee ties to this net-checking account is between them and the bank we both are working with and is none of the HIDDIN Marketplaces concern. Pay Pal collects the banking information of the account tied to the balances it maintains for their subscribers and is responsible for providing the security for this information. Pay Pal tries to protect the content of the transactions it processes by relying on encryption, which if it can be copied will eventually offer no protection at all as decryption technology eventual catch up.</p>
            <p>Transaction processing through HIDDIN is more secure because we extend the envelope of protection to include all correspondence and communications between the parties before, during, and then after the transaction event that is only possible with HIDDIN technology. HIDDIN never references how anyone pays or is paid in any of these transmissions handling those details upon enrollment far removed from any actual transactions unlike most of today’s transaction processing systems. HIDDIN eliminates the traditional transaction processing fees, charges, and equipment leases. Our marketing commissions are designed to come in at half of the typical industry expenditure for marketing producing the savings for our buyers and sellers to share between themselves and the endowment initiatives they choose to support.</p>
          </div>
        </div>
        <app-footer></app-footer>
      </div>
    </div>
  </div>
</ion-content>
