import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ConfirmForUpgradeToBusinessComponent } from '@modal/confirm-for-upgrade-to-business/confirm-for-upgrade-to-business.component';
import { ThanksForRegComponent } from '@modal/thanks-for-reg/thanks-for-reg.component';
import { ThanksForUpgradeComponent } from '@modal/thanks-for-upgrade/thanks-for-upgrade.component';
import { AuthServiceService } from '@services/auth-service.service';
import { LoaderService } from '@services/loader.service';
import { Globals } from 'src/app/globals';
import { RegUser } from 'src/app/pages/basic-reg/RegUser';

@Component({
  selector: 'app-upgrade-to-business',
  templateUrl: './upgrade-to-business.component.html',
  styleUrls: ['./upgrade-to-business.component.scss'],
})
export class UpgradeToBusinessComponent implements OnInit {

  errorMessage: string = "";
  regForm: FormGroup;
  submitted = false;
  signupInput: RegUser = new RegUser();

  requestPath = this.global.requestPath;

  constructor(private global: Globals,
    private authService: AuthServiceService,
    public modalController: ModalController,
    private formBuilder: FormBuilder,
    private loader: LoaderService,
    private router: Router) {

  }

  ngOnInit(): void {
    this.initializeFormControl();
  }

  ionViewDidEnter() {
    this.initUser();

    var loggedInUser = this.authService.getLoggedInUser();

    if (this.global.isEmpty(loggedInUser)) {
      // console.log("No loggedin user found");
      this.authService.unauthorized();
    } else {
      this.signupInput.username = loggedInUser.username;
      this.signupInput.firstName = loggedInUser.firstName;
      this.signupInput.middleName = loggedInUser.middleName;
      this.signupInput.lastName = loggedInUser.lastName;
      this.signupInput.email = loggedInUser.email;
      this.signupInput.phoneNumber = loggedInUser.phoneNumber;
    }
  }

  initializeFormControl() {
    // Step First
    this.regForm = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.maxLength(100)]],
      middleName: ['', [Validators.maxLength(100)]],
      lastName: ['', [Validators.required, Validators.maxLength(100)]],
      company: ['', [Validators.required, Validators.maxLength(100)]],
    });
  }

  initUser = function () {
    this.regForm.reset();
    this.signupInput = new RegUser();
    this.type = "Business";
    this.submitted = false;
  }

  onReset = function () {
    this.submitted = false;
    this.regForm.reset();
  }

  // convenience getter for easy access to form fields
  get f() { return this.regForm.controls; }

  upgrade = function () {
    this.loader.show("Please wait....");
    this.authService.postEncrypt('user?' + this.requestPath + '=doUpgradeToCompany', this.signupInput)
      .subscribe(res => {
        this.loader.hide();
        // console.log(res);

        if (res.success) {
          res.data = this.global.decrypt(res.data);
          this.global.saveLocalData(this.global.HIDDIN_USER_DETAILS, JSON.stringify(res.data));

          this.dismiss();
          this.openSuccessPopup();
        } else {
          this.errorMessage = res.message;
        }
      }, error => {
        this.loader.hide();
        // console.log(error);
        this.errorMessage = "Error occured at server";
      });
  }

  checkEquipmentRegistered = function () {
    this.submitted = true;
    this.errorMessage = "";

    if (this.regForm.invalid) {
      return;
    }

    this.signupInput.roleLevel = 0;

    this.loader.show("Verifying data...");
    this.authService.get('user?' + this.requestPath + '=checkEquipRegistered')
      .subscribe((res) => {
        this.loader.hide();
        // console.log(res);
        if (res.success) {
          // Equipment registered with this user
          this.openConfirmPopup();

        } else {
          // console.log(res.message);
          // this.errorMessage = res.message;
          this.upgrade();
        }
      }, error => {
        this.loader.hide();
        // console.log(error);
        this.errorMessage = "Error occured";
      });
  }

  async dismiss() {
    this.modalController.dismiss();
  }

  async openSuccessPopup() {
    const modal = await this.modalController.create({
      component: ThanksForUpgradeComponent
    });
    return await modal.present();
  }

  async openConfirmPopup() {
    const modal = await this.modalController.create({
      component: ConfirmForUpgradeToBusinessComponent
    });
    modal.onDidDismiss().then(dataReturned => {
      if (dataReturned !== null) {
        var needToUograde = dataReturned.data;
        if (!this.global.isEmpty(needToUograde)) {
          this.upgrade();
        }
      }
    });
    return await modal.present();
  }

}
