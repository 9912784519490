import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-messages-popup',
  templateUrl: './messages-popup.component.html',
  styleUrls: ['./messages-popup.component.scss'],
})

export class MessagesPopupComponent implements OnInit {

  @Input("personalizedMsg") personalizedMsg;

  constructor(private modalCtrl: ModalController) { }

  async dismiss() {
    this.modalCtrl.dismiss();
  }

  ngOnInit() { }

  ionViewDidEnter() {
    // console.log(this.personalizedMsg);
  }
}
