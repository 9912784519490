<ion-content overflow-scroll="true" class="content-main">
    <div id="login-popup">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <button class="modal-close" aria-label="Close" (click)="dismiss()"><em class="ti ti-close"></em></button>
                <div class="ath-container m-0">
                    <div class="ath-body mesges-popup">
                        <p class="text-color">{{personalizedMsg}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ion-content>