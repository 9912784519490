<app-header-left-menu [user]="user"></app-header-left-menu>
<ion-content id="main-content" class="full-width-page">
  <div class="bg-theme-alt">
    <div class="bg-grad-special">
      <div class="bg-grad-special-alt">
        <div class="nk-ovm shape-u shape-contain shape-left-top"></div>
        <app-header [user]="user"></app-header>
        <div class="subContent">
          <div class="main-content container">
            <!-- my contact section start -->
            <div>
              <ion-row>
                <ion-col size-lg="6" size-md="6" size-sm="12" size-xs="12">
                  <div (click)="openProfile(null, user, true, false)" class="img-wrapper">
                    <div class="field-item user-img">
                      <div *ngIf="profileImageSrc" class="usr-img">
                        <img [src]="profileImageSrc"
                          onerror="this.onerror=null;this.src='assets/images/team/profile.png';" />
                      </div>
                      <div *ngIf="!profileImageSrc" class="usr-img">
                        <img src="assets/images/team/profile.png" />
                      </div>
                    </div>
                    <div class="field-item user-details">
                      <div>
                        <h4 class="title">
                          {{ user.firstName }} {{ user.lastName }}
                        </h4>
                      </div>
                      <div>
                        <span *ngIf="user">{{ user.email }}</span>
                      </div>
                      <div>
                        <span *ngIf="user">{{ user.phoneDialCode }} {{ user.phoneNumber }}</span>
                      </div>
                    </div>
                  </div>
                </ion-col>
                <ion-col size-lg="6" size-md="6" size-sm="12" size-xs="12">
                  <div class="right">
                    <h2 class="title-gradient margin-bottom-0">Welcome to Hiddin Directory</h2>
                    <p class="Welcome-p">Manage your communication directory</p>
                  </div>
                </ion-col>
              </ion-row>
            </div>
            <!-- my contact start -->
            <div class="pending-container container pending-container2 mb-5">
              <div class="pendingAction mt-3 mb-2">
                <div class="row">
                  <div class="col-md-4 col-sm-6 mycontactcol6">
                    <h3 class="pending-heading">My Contacts</h3>
                  </div>
                  <div class="col-md-4 col-sm-6 mycontactcol6">
                    <div class="width100 txt-right">
                      <div class="rov  basicbtn pendingreqbtn" (click)="pendingTab();">Pending Request &nbsp; <i
                          class="fa fa-exclamation-circle" aria-hidden="true"></i> </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12 mycontactcol62">
                    <form>
                      <div class="search-user">
                        <input type="text" placeholder="Search" (click)="goToSearch()" />
                        <button class="buttonstyl">
                          <ion-icon name="search-outline" (click)="goToSearch()" class="searchico"></ion-icon>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div class=" row  mt-4 margin-bottom-20">
                  <div class="col-lg-12 col-md-12">
                    <div class=" row">
                      <div class=" col-lg-2 col-md-4 col-sm-4 " (click)="changeTab(all);">
                        <span class="list-group-item  active-pill text-capitalize"
                          [ngClass]="selectedCategory == all ? ' active-pill' : 'pill'">{{all}}</span>
                      </div>
                      <div class="col-lg-2 col-md-4 col-sm-4   " (click)="changeTab(friends)">
                        <span class="list-group-item text-capitalize"
                          [ngClass]="selectedCategory == friends ? ' active-pill' : 'pill'">{{friends}}</span>
                      </div>
                      <div class=" col-lg-2 col-md-4 col-sm-4  " (click)="changeTab(work)">
                        <span class="list-group-item text-capitalize"
                          [ngClass]="selectedCategory == work ? ' active-pill' : 'pill'">{{work}}</span>
                      </div>
                      <div class=" col-lg-2 col-md-4 col-sm-4  " (click)="changeTab(family)">
                        <span class="list-group-item text-capitalize"
                          [ngClass]="selectedCategory == family ? ' active-pill' : 'pill'">{{family}}</span>
                      </div>
                      <div class=" col-lg-2 col-md-4 col-sm-4  " (click)="changeTab(others)">
                        <span class="list-group-item text-capitalize"
                          [ngClass]="selectedCategory == others ? ' active-pill' : 'pill'">{{others}}</span>
                      </div>
                      <div class=" col-lg-2 col-md-4 col-sm-4  " (click)="changeTab(favorite)">
                        <span class="list-group-item text-capitalize"
                          [ngClass]="selectedCategory == favorite ? ' active-pill' : 'pill'">{{favorite}}</span>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-lg-2 col-md-3 txt-right">
                    <div class="list-group-item pill pending-pill" (click)="pendingTab();">Pending Request </div>
                  </div> -->
                </div>
              </div>
              <div>
                <div class="search-card"
                  *ngFor="let generalContact of userContacts | matchesCategory: selectedCategory">
                  <div class="row width100">
                    <div class="col-sm-12 col-md-2 col-lg-1 col-xl-1 cardImg">
                      <img class="card-img" src="assets/images/team/profile.png">
                    </div>
                    <div class="col-sm-12 col-md-10 col-lg-11 col-xl-11">
                      <h5 class="team-name title title-sm paddingTop0">{{generalContact.contactUser.firstName}}
                        {{generalContact.contactUser.lastName}}
                        <span class="text-capitalize" [class.frndspn]="generalContact.category == 'friends'"
                          [class.workspn]="generalContact.category == 'work'"
                          [class.familyspn]="generalContact.category == 'family'"
                          [class.otherspn]="generalContact.category == 'others'">{{generalContact.category}}</span>
                        <span *ngIf="generalContact.isFavorite" class="favoritespn">Favorite</span>
                      </h5>
                      <div class="row padding-left-7 mt-1">
                        <div class="col-sm-12 col-md-7">
                          <div class="row">
                            <div class=" col-md-6 col-sm-12">
                              <a class="grey">
                                <i class="fa fa-envelope" aria-hidden="true"></i>{{generalContact.contactUser.email}}
                              </a>
                            </div>
                            <div class="col-md-6 col-sm-12">
                              <a class="grey">
                                <i class="fa fa-phone"
                                  aria-hidden="true"></i>{{generalContact.contactUser.phoneDialCode}}
                                {{generalContact.contactUser.phoneNumber | slice:0:6}}**** </a>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-5">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-6 txt-right mb-2">
                              <button type="button" *ngIf="!generalContact.isFavorite"
                                (click)="markFavorite(generalContact, true)" class="basicbtn">Add Fav &nbsp; <i
                                  class="fa fa-heart"></i>
                              </button>
                              <button type="button" *ngIf="generalContact.isFavorite"
                                (click)="markFavorite(generalContact, false)" class="basicbtn">Remove Fav &nbsp;
                                <i class="fa fa-heart"></i>
                              </button>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-6 txt-right mb-2">
                              <button type="button" (click)="openProfile(generalContact, user, false, false)"
                                class="basicbtn" [class.friendsbtn]="generalContact.category == 'friends'"
                                [class.workbtn]="generalContact.category == 'work'"
                                [class.familybtn]="generalContact.category == 'family'"
                                [class.othersbtn]="generalContact.category == 'others'">View Profile &nbsp; <i
                                  class="fa fa-greater-than"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Defult loader -->
              <div class="zero-result mb-5" *ngIf="loadingData">
                <div class="zero-result-container">
                  <img src="../../../../assets/images/loader.gif" class="no-result-img mb-2">
                  <p class="mb-2">Loading contacts, please wait...</p>
                  <div class="search-user">
                  </div>
                </div>
              </div>
              <div class="zero-result mb-5" *ngIf="!loadingData && noDataFound">
                <div class="zero-result-container">
                  <img src="../../../../assets/images/no-result.png" class="no-result-img mb-2">
                  <h5>Your contact list is empty</h5>
                  <p class="mb-2">Please search contact details from search bar and start adding in your list.</p>
                  <div class="search-user">
                    <input type="text" placeholder="Search Directory" (click)="goToSearch()" />
                    <button class="buttonstyl">
                      <ion-icon name="search-outline" (click)="goToSearch()" class="searchico"></ion-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- my contact ends -->
            <!-- pending UI start -->
            <!-- My contact UI start -->
          </div>
        </div>
        <app-footer></app-footer>
      </div>
    </div>
  </div>
  <div class="my-contact"></div>
</ion-content>