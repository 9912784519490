<app-header-left-menu [user]="user"></app-header-left-menu>
<ion-content id="main-content" class="full-width-page">
  <div class="bg-theme-alt">
    <div class="bg-grad-special">
      <div class="bg-grad-special-alt">
        <div class="nk-ovm shape-u shape-contain shape-left-top"></div>
        <app-header [user]="user"></app-header>
        <div class="subContent">
          <div class="main-content container">
            <div class="nk-block block-contact animated fadeInUp" data-animate="fadeInUp" data-delay="0.4"
            style="visibility: visible; animation-delay: 0.4s;" id="contact">
              <div class="row justify-content-center no-gutters">
                <!-- <div class="col-lg-6">
                  <div class="contact-wrap round split split-left split-lg-left bg-light">
                    <h5 class="title title-md">Contact Us</h5>
                    <form class="nk-form-submit" [formGroup]="getInTouchForm">
                      <div class="field-item">
                        <div class="field-wrap">
                          <h6 class="title title-xs">Name<span class="red">*</span></h6>
                          <input type="text" name="contact-name" formControlName="name" class="input-bordered"
                            placeholder="Enter Name" [(ngModel)]="getInTouch.name"
                            [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                          <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                            <div *ngIf="f.name.errors.required">Name is required</div>
                            <div *ngIf="f.name.errors?.maxlength">Name can be max 100 characters long</div>
                          </div>
                        </div>
                      </div>
                      <div class="field-item">
                        <div class="field-wrap">
                          <h6 class="title title-xs">Email<span class="red">*</span></h6>
                          <input type="text" name="contact-name" formControlName="email" class="input-bordered"
                            placeholder="Enter Email" [(ngModel)]=" getInTouch.email"
                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                          <div *ngIf="(submitted || f.email.touched) && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">Email is required</div>
                            <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                            <div *ngIf="f.email.errors.pattern">Email must be a valid email address</div>
                          </div>
                        </div>
                      </div>
                      <div class="field-item">
                        <div class="field-wrap">
                          <h6 class="title title-xs">Message<span class="red">*</span></h6>
                          <textarea type="text" name="contact-message" class="input-bordered" formControlName="message"
                            [(ngModel)]="getInTouch.message" placeholder="Your Message"
                            [ngClass]="{ 'is-invalid': submitted && f.message.errors }"></textarea>
                          <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                            <div *ngIf="f.message.errors.required">Message is required
                            </div>
                            <div *ngIf="f.message.errors.maxlength">Message length cannot be greater than 500 characters
                            </div>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="errorMessage && errorMessage != ''"
                        class="alert alert-danger title title-xs mt-2 mb-2">
                        {{errorMessage}}
                      </div>
                      <div *ngIf="successMessage && successMessage != ''"
                        class="alert alert-success title title-xs mt-2 mb-2">
                        {{successMessage}}
                      </div>
                      <div class="btn-container">
                        <button type="submit" class="btn btn-md btn-grad" (click)="submitGetInTouch()">Submit</button>
                      </div>
                    </form>
                  </div>
                </div> -->
                <div class="col-lg-6">
                  <div class="contact-wrap round split split-left split-lg-left bg-white">
                      <h5 class="ath-heading title align-l">Contact Us</h5>
                      <form class="nk-form-submit" [formGroup]="getInTouchForm">
                          <div class="field-item">
                              <input
                              type="text"
                              class="input-line required" 
                              formControlName="name"
                              placeholder="Enter Name" [(ngModel)]="getInTouch.name"
                              [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
                              >
                              <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                <div *ngIf="f.name.errors.required">Name is required</div>
                                <div *ngIf="f.name.errors?.maxlength">Name can be max 100 characters long</div>
                              </div>
                          </div>
                          <div class="field-item">
                              <input
                              type="email"
                              class="input-line required email"
                              formControlName="email"
                              placeholder="Enter Email" [(ngModel)]=" getInTouch.email"
                              [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                              >
                              <div *ngIf="(submitted || f.email.touched) && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">Email is required</div>
                                <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                                <div *ngIf="f.email.errors.pattern">Email must be a valid email address</div>
                              </div>
                          </div>
                          <div class="field-item">
                              <textarea
                              class="input-line input-textarea required"
                              formControlName="message"
                              [(ngModel)]="getInTouch.message" placeholder="Your Message"
                              [ngClass]="{ 'is-invalid': submitted && f.message.errors }"
                              ></textarea>
                              <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                                <div *ngIf="f.message.errors.required">Message is required
                                </div>
                                <div *ngIf="f.message.errors.maxlength">Message length cannot be greater than 500 characters
                                </div>
                            </div>
                          </div>
                          <div class="row">
                              <div class="col-12">
                                <div *ngIf="errorMessage && errorMessage != ''"
                                class="alert alert-danger title title-xs mt-2 mb-2">
                                {{errorMessage}}
                              </div>
                              <div *ngIf="successMessage && successMessage != ''"
                                class="alert alert-success title title-xs mt-2 mb-2">
                                {{successMessage}}
                              </div>
                              <div class="btn-containerr">
                                <button type="submit" class="btn btn-md btn-grad" (click)="submitGetInTouch()">Submit</button>
                              </div>
                              </div>
                          </div>
                      </form>
                  </div>
              </div>
                <div class="col-lg-4">
                  <div class="contact-wrap split split-right split-lg-right bg-genitian bg-theme tc-light">
                    <div class="d-flex flex-column justify-content-between h-100">
                      <ul class="contact-list">
                        <li><em class="contact-icon fa fa-building"></em>
                          <div class="contact-text">
                            <h6>HIDDIN INC</h6><span>3201 S Dale Mabry<br>Tampa, Florida - 33629 (US)</span>
                          </div>
                        </li>
                        <li><em class="contact-icon fa fa-phone"></em>
                          <div class="contact-text"><span>+1(813) 831-6002</span></div>
                        </li>
                        <li><em class="contact-icon fa fa-envelope"></em>
                          <div class="contact-text"><span>support&#64;hiddin.com</span></div>
                        </li>
                      </ul>
                      <div class="contact-social">
                        <h6>Be a part of our community</h6>
                        <ul class="social-links">
                          <li><a href="#" target="_blank"><em class="fab fa-twitter"></em></a></li>
                          <li><a href="https://www.linkedin.com/company/hiddin-net" target="_blank"><em
                                class="fab fa-linkedin-in"></em></a></li>
                          <li><a href="https://m.facebook.com/Hiddin-111114470802188/?__tn__=C-R" target="_blank"><em
                                class="fab fa-facebook-f"></em></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div><!-- .col -->
              </div><!-- .row -->
            </div>
          </div>
        </div>
        <app-footer></app-footer>
      </div>
    </div>
  </div>
</ion-content>