import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { LeftMenuService } from '@services/left-menu.service';
import { AuthServiceService } from '@services/auth-service.service';
import { Observable, of } from 'rxjs';
import { IonContent, MenuController, ModalController } from '@ionic/angular';
import { RegUser } from 'src/app/pages/basic-reg/RegUser';
import { Globals } from 'src/app/globals';
import { Router } from '@angular/router';


import { CompleteRegConfirmationComponent } from '@modal/complete-reg-confirmation/complete-reg-confirmation.component';
import { EmailVerificationInfoComponent } from '@modal/email-verification-info/email-verification-info.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() user: RegUser;
  @ViewChild(IonContent) content: IonContent;
  @ViewChild('target') target: any;

  LoggedIn: boolean;

  constructor(private leftMenuSvc: LeftMenuService,
    private authSvc: AuthServiceService,
    private menuCtrl: MenuController,
    public modalController: ModalController,
    private globals: Globals,
    private router: Router,
    private modalCtrl: ModalController,
    private global: Globals
  ) { }

  ngOnInit() {
    this.LoggedIn = this.authSvc.isLoggedIn();
  }

  ionViewDidEnter() {
    this.LoggedIn = this.authSvc.isLoggedIn();
  }

  toggleMenu() {
    this.leftMenuSvc.toggleMenu();
  }

  scrollTo(id: string) {
    const target = document.querySelector('#' + id) as HTMLDivElement;

    this.content.scrollToPoint(0, target.offsetTop + (target.offsetHeight / 1.5), 1000)
    this.menuCtrl.close();
  }

  logout() {
    this.authSvc.logout();
    this.router.navigate(['/home']);
  }

  async openCompleteRegConfirmation() {
    const modal = await this.modalCtrl.create({
      component: CompleteRegConfirmationComponent,
      componentProps: {
      }
    });

    return await modal.present();
  }
  // async verificationMail() {
  //   const modal = await this.modalController.create({
  //     component: EmailVerificationInfoComponent,
  //     componentProps: {
  //       errorMsg: 'Only jpg/jpeg and png files are allowed!',
  //       imageSrc: 'assets/images/email-icon.png'
  //     }
  //   });
  //   return await modal.present();
  // }
}
