<app-header-left-menu [user]="user"></app-header-left-menu>
<ion-content id="main-content">
  <div class="bg-theme-alt">
    <div class="bg-grad-special">
      <div class="bg-grad-special-alt">
        <div class="page-container">
          <div class="nk-ovm shape-u shape-contain shape-left-top"></div>
          <app-header [user]="user"></app-header>
          <div id="login-popup">
            <div class="ath-body">
              <div class="left-image-section">
                <!-- <img src="assets/images/hiddin-logo.png"> -->
              </div>
              <div class="right-section">
                <h5 class="ath-heading title">{{heading}}<small class="tc-default">{{subheading}}</small></h5>

                <!-- Step Third -->
                <div *ngIf="enablePasswordPage">
                  <form [formGroup]="cpForm" (ngSubmit)="createNewPassword()">
                    <div class="field-item">
                      <div class="field-wrap">
                        <!-- <label for="pwd">Password<span class="required">*</span></label> -->
                        <input type="password" formControlName="userPassword" class="input-bordered"
                          [(ngModel)]="userPwd.userPassword"
                          [ngClass]="{ 'is-invalid': cpSubmitted && cpForm.controls.userPassword.errors }"
                          placeholder="Enter Password" id="resetPwd" onPaste="return false"
                          placeholder="Enter password" />
                        <i class="fa" [ngClass]="passwordVisible ? 'fa-eye-slash' : 'fa-eye'"
                          (click)="togglePassword('resetPwd')"></i>
                        <div *ngIf="cpSubmitted && cpForm.controls.userPassword.errors" class="invalid-feedback">
                          <div *ngIf="cpForm.controls.userPassword.errors.required">Password is required</div>
                          <div *ngIf="cpForm.controls.userPassword.errors.minlength">Password must be at least 6
                            characters
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="field-item">
                      <div class="field-wrap">
                        <!-- <label for="con_pwd">Confirm Password<span class="required">*</span></label> -->
                        <input type="password" formControlName="confirmPassword" class="input-bordered"
                          [(ngModel)]="userPwd.confirmPassword"
                          [ngClass]="{ 'is-invalid': cpSubmitted && cpForm.controls.confirmPassword.errors }"
                          placeholder="Confirm Password" onPaste="return false" placeholder="Enter Confirm password"
                          id="confirmResetPwd" />
                        <i class="fa" [ngClass]="confirmPasswordVisible ? 'fa-eye-slash' : 'fa-eye'"
                          (click)="togglePassword('confirmResetPwd')"></i>
                        <div *ngIf="cpSubmitted && cpForm.controls.confirmPassword.errors" class="invalid-feedback">
                          <div *ngIf="cpForm.controls.confirmPassword.errors.required">Confirm Password is required
                          </div>
                          <div *ngIf="cpForm.controls.confirmPassword.errors.mustMatch">Passwords must match</div>
                        </div>
                      </div>
                    </div>
                    <div class="text-center">
                      <button type="submit" class="btn btn-primary btn-block btn-md">Create</button>
                    </div>
                  </form>
                </div>

                <!-- Error section -->
                <div *ngIf="errorMessage && errorMessage != ''" class="mt-2 mb-2">
                  <div class="alert alert-danger title title-xs">
                    {{errorMessage}}
                  </div>
                  <div class="ath-note text-center">
                    Click here to <a [routerLink]="['/forgot-pass']">Recover Account</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <app-footer></app-footer>
        </div>
      </div>
    </div>
  </div>
</ion-content>