import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IonContent, ModalController } from '@ionic/angular';
import { AuthServiceService } from '@services/auth-service.service';
import { FileuploadService } from '@services/fileupload.service';
import { LoaderService } from '@services/loader.service';
import { Globals } from 'src/app/globals';
import { RegUser } from '../basic-reg/RegUser';
import { SecurityQuestions } from '../basic-reg/SecurityQuestions';

import { NotifiactionPopupComponent } from '../../modal/notifiaction-popup/notifiaction-popup.component';
import { ThanksForRegComponent } from '../../modal/thanks-for-reg/thanks-for-reg.component';


@Component({
  selector: 'app-complete-registration',
  templateUrl: './complete-registration.component.html',
  styleUrls: ['./complete-registration.component.scss']
})
export class CompleteRegistrationComponent implements OnInit {

  @ViewChild(IonContent) content: IonContent;

  // UI based data
  type: string = 'Individual'
  utilityType: string = 'upload'
  bankType: string = 'upload'
  passportType: string = 'upload'
  dlType: string = 'upload'
  stepCounter: number = 1;

  utilityFiles: any[] = [];
  bankFiles: any[] = [];
  passportFiles: any[] = [];
  dlFiles: any[] = [];
  picPwd: any[] = [];

  displayUtility: boolean = true;
  displayBank: boolean = false;
  displayPassport: boolean = false;
  displayDl: boolean = false;
  displayPicPwd: boolean = false;

  // First Step : Service based data

  requestPath = this.global.requestPath;

  signupInput: RegUser = new RegUser();
  questionsList = {};
  questions = [];
  questions1 = [];
  questions2 = [];
  questions3 = [];
  questions4 = [];
  oldQuestion1Value = null;
  oldQuestion2Value = null;
  oldQuestion3Value = null;
  oldQuestion4Value = null;
  errorMessage: string = "";
  regForm: FormGroup;
  submitted = false;
  firstStepDone: boolean = false;

  // Upload Images : Service based data
  usaStates = [];
  usaBanks = [];
  oldTokens = [];
  deviceId = "";
  owner: RegUser;
  username: any;
  vCode = '';
  selectedState: any = "";
  selectedBank: any = "";
  genders = ['Male', 'Female', 'Other'];

  // Picture Password
  ppUploaded = false;
  ppErrorMessage;
  ppSuccessMessage;
  ppSubmitted = false;
  ppUploadInProgress = false;

  // Utility Bill
  ubUploaded = false;
  ubErrorMessage;
  ubSuccessMessage;
  ubSubmitted = false;
  ubInProgress = false;
  utilityBill: any = {};

  // Bank Cheque
  bcUploaded = false;
  bcErrorMessage;
  bcSuccessMessage;
  bcSubmitted = false;
  bcInProgress = false;
  bankCheque: any = {};

  // Driving Liscense Bill
  dlUploaded = false;
  dlErrorMessage;
  dlSuccessMessage;
  dlSubmitted = false;
  dlInProgress = false;
  drivingLic: any = {};

  // Passport
  passUploaded = false;
  passErrorMessage;
  passSuccessMessage;
  passSubmitted = false;
  passInProgress = false;
  passport: any = {};

  // Utility Bill Form
  f_ubForm: FormGroup;
  f_ubErrorMessage;
  f_ubSuccessMessage;
  f_ubSubmitted = false;
  f_ubInProgress = false;
  get f_ub() { return this.f_ubForm.controls; }

  // Utility Bill Form
  f_bcForm: FormGroup;
  f_bcErrorMessage;
  f_bcSuccessMessage;
  f_bcSubmitted = false;
  f_bcInProgress = false;
  get f_bc() { return this.f_bcForm.controls; }

  // Driving License Form
  f_dlForm: FormGroup;
  f_dlErrorMessage;
  f_dlSuccessMessage;
  f_dlSubmitted = false;
  f_dlInProgress = false;
  get f_dl() { return this.f_dlForm.controls; }

  // Passport Form
  f_passForm: FormGroup;
  f_passErrorMessage;
  f_passSuccessMessage;
  f_passSubmitted = false;
  f_passInProgress = false;
  get f_pass() { return this.f_passForm.controls; }

  // Completion 
  completionErrorMsg: boolean = false;
  completionInProcess = false;

  constructor(private global: Globals,
    private authService: AuthServiceService,
    public modalController: ModalController,
    private formBuilder: FormBuilder,
    private loader: LoaderService,
    private router: Router,
    private fileUpload: FileuploadService) {

  }

  ngOnInit(): void {
    this.initializeFormControl();
    this.getStatesList();
    this.getBanksList();
  }

  ionViewDidEnter() {
    this.initUser();
    this.onReset();
    this.resetForms();

    var loggedInUser = this.authService.getLoggedInUser();

    if (this.global.isEmpty(loggedInUser)) {
      // console.log("No loggedin user found");
      return;
    } else {
      this.signupInput.username = loggedInUser.username;
      this.signupInput.firstName = loggedInUser.firstName;
      this.signupInput.lastName = loggedInUser.lastName;
      this.signupInput.email = loggedInUser.email;
      this.signupInput.phoneNumber = loggedInUser.phoneNumber;
      this.signupInput.imageUrl = this.authService.getProfilePicture();

      this.regForm.get("userName").disable();
      this.regForm.get("emailAddress").disable();
      this.regForm.get("phoneNumber").disable();
    }

    // Check if first page is already done
    this.getMyProfile();
  }

  onLoadData = function () {
    this.getQuestions();
  }

  getMyProfile = function () {
    this.loader.show("Fetching user details...");
    this.authService.get('user?' + this.requestPath + '=getUserDetail')
      .subscribe((res) => {
        this.loader.hide();
        // console.log(res);
        if (res.success) {
          res.data = this.global.decrypt(res.data);
          if (this.global.isEmpty(res.data.isSeqQueUploaded) || false == res.data.isSeqQueUploaded) {
            this.onLoadData();
          } else {
            this.firstStepDone = true;
            this.stepCounter = this.stepCounter + 1;

            // this._changeRef.detectChanges();

            // Fetch Draft Save Data
            this.getAllData();
          }
        } else {
          // console.log(res.message);
          this.errorMsg = res.message;
        }
      }, error => {
        this.loader.hide();
        // console.log(error);
        this.errorMsg = "Error occured";
      });
  }

  getAllData = function () {
    this.loader.show("Please wait...");
    this.authService.get('image?' + this.requestPath + '=getAllUploadedData')
      .subscribe((res) => {
        this.loader.hide();
        // console.log(res);
        if (res.success) {
          var data = this.global.decrypt(res.data);

          if (!this.global.isEmpty(data)) {

            // Check for Utility Data
            if (!this.global.isEmpty(data.utilityBill)) {
              this.utilityBill = data.utilityBill;
            }

            if (!this.global.isEmpty(data.utilityBillImageUrl)) {
              this.ubUploaded = true;
              var ubImage = this.getImageByType(data.image, 'ub');
              var item = {
                name: ubImage.originalName,
                url: data.utilityBillImageUrl
              }
              this.utilityFiles.push(item);
            }

            // Check for Cheque Data
            if (!this.global.isEmpty(data.cheque)) {
              this.bankCheque = data.cheque;
              this.selectedBank = this.bankCheque.bankName;
            }

            if (!this.global.isEmpty(data.chequeImageUrl)) {
              this.bcUploaded = true;
              var bcImage = this.getImageByType(data.image, 'bc');
              var item = {
                name: bcImage.originalName,
                url: data.chequeImageUrl
              }
              this.bankFiles.push(item);
            }

            // Check for Passport Data
            if (!this.global.isEmpty(data.passport)) {
              this.passport = data.passport;
            }

            if (!this.global.isEmpty(data.passportImageUrl)) {
              this.passUploaded = true;
              var passImage = this.getImageByType(data.image, 'passport');
              var item = {
                name: passImage.originalName,
                url: data.passportImageUrl
              }
              this.passportFiles.push(item);
            }

            // Check for Driving License Data
            if (!this.global.isEmpty(data.drivingLicense)) {
              this.drivingLic = data.drivingLicense;
              this.selectedState = this.drivingLic.state;
            }

            if (!this.global.isEmpty(data.drivingLicenseImageUrl)) {
              this.dlUploaded = true;
              var dlImage = this.getImageByType(data.image, 'dl');
              var item = {
                name: dlImage.originalName,
                url: data.drivingLicenseImageUrl
              }
              this.dlFiles.push(item);
            }

            // Check for Picture password
            if (!this.global.isEmpty(data.picturePasswordImage)) {
              this.ppUploaded = true;
              var ppImage = this.getImageByType(data.image, 'pp');
              var item = {
                name: ppImage.originalName,
                url: data.picturePasswordImage
              }
              this.picPwd.push(item);
            }

          }
        } else {
          // console.log(res.message);
          this.errorMsg = res.message;
        }
      }, error => {
        this.loader.hide();
        // console.log(error);
        this.errorMsg = "Error occured";
      });
  }

  getImageByType(allImages, type) {

    if (this.global.isEmpty(allImages)) {
      return null;
    }

    for (const image of allImages) {
      if (image.type == type) {
        return image;
      }
    }
    return null;
  }

  initializeFormControl() {
    // Step First
    this.regForm = this.formBuilder.group({
      userName: ['', [Validators.required, Validators.maxLength(100)]],
      firstName: ['', [Validators.required, Validators.maxLength(100)]],
      middleName: ['', [Validators.maxLength(100)]],
      lastName: ['', [Validators.required, Validators.maxLength(100)]],
      emailAddress: ['', [Validators.required, Validators.email, Validators.maxLength(100)]],
      phoneNumber: ['', [Validators.required]],
      company: [''],
      zipCode: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(10)]],
      question1: ['', [Validators.required, Validators.maxLength(100)]],
      answer1: ['', [Validators.required, Validators.maxLength(100)]],
      question2: ['', [Validators.required, Validators.maxLength(100)]],
      answer2: ['', [Validators.required, Validators.maxLength(100)]],
      question3: ['', [Validators.required, Validators.maxLength(100)]],
      answer3: ['', [Validators.required, Validators.maxLength(100)]],
      question4: ['', [Validators.required, Validators.maxLength(100)]],
      answer4: ['', [Validators.required, Validators.maxLength(100)]],
    });

    // Upload Image
    this.owner = new RegUser();

    this.f_ubForm = this.formBuilder.group({
      accountNumber: [null, [Validators.required, Validators.minLength(5), Validators.maxLength(50)]],
      name: ['', [Validators.required, Validators.maxLength(100)]],
      address: ['', [Validators.required, Validators.maxLength(250)]]
    });

    this.f_bcForm = this.formBuilder.group({
      accountNumber: [null, [Validators.required, Validators.minLength(5), Validators.maxLength(50)]],
      bankRoutingNumber: [null, [Validators.required, Validators.minLength(5), Validators.maxLength(50)]],
      chequeId: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
      name: ['', [Validators.required, Validators.maxLength(100)]]
    });

    this.f_dlForm = this.formBuilder.group({
      drivingLicenseId: [null, [Validators.required, Validators.minLength(5), Validators.maxLength(50)]],
      name: ['', [Validators.required, Validators.maxLength(100)]],
      address: [null, [Validators.required, Validators.maxLength(250)]],
      dob: ['', Validators.required],
      gender: ['', Validators.required],
      issued: ['', Validators.required],
      expiry: ['', Validators.required],
    });

    this.f_passForm = this.formBuilder.group({
      passportId: [null, [Validators.required, Validators.minLength(5), Validators.maxLength(50)]],
      name: ['', [Validators.required, Validators.maxLength(100)]],
      dob: ['', Validators.required],
      gender: ['', Validators.required],
      issued: ['', Validators.required],
      expiry: ['', Validators.required],
    });
  }

  disableFirstForm = function () {
    this.regForm.get("userName").disable();
    this.regForm.get("firstName").disable();
    this.regForm.get("middleName").disable();
    this.regForm.get("lastName").disable();
    this.regForm.get("emailAddress").disable();
    this.regForm.get("phoneNumber").disable();
    this.regForm.get("company").disable();
    this.regForm.get("zipCode").disable();
    this.regForm.get("question1").disable();
    this.regForm.get("question2").disable();
    this.regForm.get("question3").disable();
    this.regForm.get("question4").disable();
    this.regForm.get("answer1").disable();
    this.regForm.get("answer2").disable();
    this.regForm.get("answer3").disable();
    this.regForm.get("answer4").disable();
  }

  enableFirstForm = function () {
    // this.regForm.get("userName").enable();
    this.regForm.get("firstName").enable();
    this.regForm.get("middleName").enable();
    this.regForm.get("lastName").enable();
    //this.regForm.get("emailAddress").enable();
    //this.regForm.get("phoneNumber").enable();
    this.regForm.get("company").enable();
    this.regForm.get("zipCode").enable();
    this.regForm.get("question1").enable();
    this.regForm.get("question2").enable();
    this.regForm.get("question3").enable();
    this.regForm.get("question4").enable();
    this.regForm.get("answer1").enable();
    this.regForm.get("answer2").enable();
    this.regForm.get("answer3").enable();
    this.regForm.get("answer4").enable();
  }

  resetFirstForm = function () {
    this.submitted = false;
    this.regForm.get("firstName").reset();
    this.regForm.get("middleName").reset();
    this.regForm.get("lastName").reset();
    this.regForm.get("company").reset();
    this.regForm.get("zipCode").reset();
    this.regForm.get("answer1").reset();
    this.regForm.get("answer2").reset();
    this.regForm.get("answer3").reset();
    this.regForm.get("answer4").reset();
  }

  initUser = function () {
    this.regForm.reset();
    this.signupInput = new RegUser();
    this.type = "Individual";
    this.signupInput.securityQuestions = new SecurityQuestions();
    this.submitted = false;
  }

  // convenience getter for easy access to form fields
  get f() { return this.regForm.controls; }

  onReset = function () {
    this.stepCounter = 1;
    this.firstStepDone = false;
    this.submitted = false;
    this.regForm.reset();

    this.ppErrorMessage = null;
    this.ppSuccessMessage = null;
    this.ubErrorMessage = null;
    this.ubSuccessMessage = null;
    this.bcErrorMessage = null;
    this.bcSuccessMessage = null;
    this.dlErrorMessage = null;
    this.dlSuccessMessage = null;
    this.passErrorMessage = null;
    this.passSuccessMessage = null;
    this.f_ubErrorMessage = null;
    this.f_ubSuccessMessage = null;
    this.f_bcErrorMessage = null;
    this.f_bcSuccessMessage = null;
    this.f_dlErrorMessage = null;
    this.f_dlSuccessMessage = null;
    this.f_passErrorMessage = null;
    this.f_passSuccessMessage = null;
  }

  //Get security questions
  getQuestions = function () {
    this.errorMessage = "";

    this.loader.show("Loading data...");

    this.authService.getNoSecure('ns/refData?' + this.requestPath + '=findAllByParentId&parentId=' + 1)
      .subscribe((res) => {
        this.loader.hide();
        // console.log(res);
        if (res.success) {
          this.questionsList = this.global.decryptDefault(res.data);
          for (var i = 0; i < this.questionsList.length; i++) {
            this.questions.push(this.questionsList[i].name);
            this.questions1.push(this.questionsList[i].name);
            this.questions2.push(this.questionsList[i].name);
            this.questions3.push(this.questionsList[i].name);
            this.questions4.push(this.questionsList[i].name);
          }
          this.getExistingSecurityQuestions();
        } else {
          this.errorMessage = res.message;
        }
      }, error => {
        this.loader.hide();
        // console.log(error);
        this.errorMessage = "Please try again.";
      });
  };

  getExistingSecurityQuestions = function () {
    this.authService.get('user?' + this.requestPath + '=getSecurityQuestions')
      .subscribe((res) => {
        // console.log(res);
        if (res.success) {
          this.signupInput.securityQuestions = this.global.decrypt(res.data);
        } else {
          // this.errorMessage = res.message;
        }
      }, error => {
        // console.log(error);
        this.errorMessage = "Please try again.";
      });
  };

  resetQuestions() {
    this.questions1 = this.questions;
    this.questions2 = this.questions;
    this.questions3 = this.questions;
    this.questions4 = this.questions;
  }

  changeQuestion1 = function (newValue) {

    if (!newValue || newValue == 'undefined') {
      return;
    }

    if (this.oldQuestion1Value != newValue) {

      var indexOfQuestions2 = this.questions2.indexOf(newValue);
      var indexOfQuestions3 = this.questions3.indexOf(newValue);
      var indexOfQuestions4 = this.questions4.indexOf(newValue);

      if (indexOfQuestions2 > -1) {
        this.questions2.splice(indexOfQuestions2, 1);
      } if (indexOfQuestions3 > -1) {
        this.questions3.splice(indexOfQuestions3, 1);
      } if (indexOfQuestions4 > -1) {
        this.questions4.splice(indexOfQuestions4, 1);
      } if (this.oldQuestion1Value != null) {
        this.questions2.push(this.oldQuestion1Value);
        this.questions3.push(this.oldQuestion1Value);
        this.questions4.push(this.oldQuestion1Value);
      }
    }
    else if (this.oldQuestion1Value == null || this.oldQuestion1Value == 'undefined') {
      var indexOfQuestions2 = this.questions2.indexOf(newValue);
      var indexOfQuestions3 = this.questions3.indexOf(newValue);
      var indexOfQuestions4 = this.questions4.indexOf(newValue);
      if (indexOfQuestions2 > -1) {
        this.questions2.splice(indexOfQuestions2, 1);
      } if (indexOfQuestions3 > -1) {
        this.questions3.splice(indexOfQuestions3, 1);
      } if (indexOfQuestions4 > -1) {
        this.questions4.splice(indexOfQuestions4, 1);
      }
    }
    this.oldQuestion1Value = newValue;
  }

  changeQuestion2 = function (newValue) {

    if (newValue == undefined) {
      return;
    }

    if (this.oldQuestion2Value != newValue) {

      var indexOfQuestions1 = this.questions1.indexOf(newValue);
      var indexOfQuestions3 = this.questions3.indexOf(newValue);
      var indexOfQuestions4 = this.questions4.indexOf(newValue);
      if (indexOfQuestions1 > -1) {
        this.questions1.splice(indexOfQuestions1, 1);
      } if (indexOfQuestions3 > -1) {
        this.questions3.splice(indexOfQuestions3, 1);
      } if (indexOfQuestions4 > -1) {
        this.questions4.splice(indexOfQuestions4, 1);
      } if (this.oldQuestion2Value != null) {
        this.questions1.push(this.oldQuestion2Value);
        this.questions3.push(this.oldQuestion2Value);
        this.questions4.push(this.oldQuestion2Value);
      }
    }
    else if (this.oldQuestion2Value == null || this.oldQuestion2Value == 'undefined') {
      var indexOfQuestions1 = this.questions1.indexOf(newValue);
      var indexOfQuestions3 = this.questions3.indexOf(newValue);
      var indexOfQuestions4 = this.questions4.indexOf(newValue);
      if (indexOfQuestions1 > -1) {
        this.questions1.splice(indexOfQuestions1, 1);
      } if (indexOfQuestions3 > -1) {
        this.questions3.splice(indexOfQuestions3, 1);
      } if (indexOfQuestions4 > -1) {
        this.questions4.splice(indexOfQuestions4, 1);
      }
    }
    this.oldQuestion2Value = newValue;
  };

  changeQuestion3 = function (newValue) {

    if (newValue == undefined) {
      return;
    }

    if (this.oldQuestion3Value != newValue) {

      var indexOfQuestions1 = this.questions1.indexOf(newValue);
      var indexOfQuestions2 = this.questions2.indexOf(newValue);
      var indexOfQuestions4 = this.questions4.indexOf(newValue);
      if (indexOfQuestions1 > -1) {
        this.questions1.splice(indexOfQuestions1, 1);
      } if (indexOfQuestions2 > -1) {
        this.questions2.splice(indexOfQuestions2, 1);
      } if (indexOfQuestions4 > -1) {
        this.questions4.splice(indexOfQuestions4, 1);
      } if (this.oldQuestion3Value != null) {
        this.questions1.push(this.oldQuestion3Value);
        this.questions2.push(this.oldQuestion3Value);
        this.questions4.push(this.oldQuestion3Value);
      }
    } else if (this.oldQuestion3Value == null || this.oldQuestion3Value == 'undefined') {
      var indexOfQuestions1 = this.questions1.indexOf(newValue);
      var indexOfQuestions2 = this.questions2.indexOf(newValue);
      var indexOfQuestions4 = this.questions4.indexOf(newValue);
      if (indexOfQuestions1 > -1) {
        this.questions1.splice(indexOfQuestions1, 1);
      } if (indexOfQuestions2 > -1) {
        this.questions2.splice(indexOfQuestions2, 1);
      } if (indexOfQuestions4 > -1) {
        this.questions4.splice(indexOfQuestions4, 1);
      }
    }
    this.oldQuestion3Value = newValue;
  };

  changeQuestion4 = function (newValue) {

    if (newValue == undefined) {
      return;
    }

    if (this.oldQuestion4Value != newValue) {

      var indexOfQuestions1 = this.questions1.indexOf(newValue);
      var indexOfQuestions2 = this.questions2.indexOf(newValue);
      var indexOfQuestions3 = this.questions3.indexOf(newValue);
      if (indexOfQuestions1 > -1) {
        this.questions1.splice(indexOfQuestions1, 1);
      } if (indexOfQuestions2 > -1) {
        this.questions2.splice(indexOfQuestions2, 1);
      } if (indexOfQuestions3 > -1) {
        this.questions3.splice(indexOfQuestions3, 1);
      } if (this.oldQuestion4Value != null) {
        this.questions1.push(this.oldQuestion4Value);
        this.questions2.push(this.oldQuestion4Value);
        this.questions3.push(this.oldQuestion4Value);
      }
    } else if (this.oldQuestion4Value == null || this.oldQuestion4Value == 'undefined') {
      var indexOfQuestions1 = this.questions1.indexOf(newValue);
      var indexOfQuestions2 = this.questions2.indexOf(newValue);
      var indexOfQuestions3 = this.questions3.indexOf(newValue);
      if (indexOfQuestions1 > -1) {
        this.questions1.splice(indexOfQuestions1, 1);
      } if (indexOfQuestions2 > -1) {
        this.questions2.splice(indexOfQuestions2, 1);
      } if (indexOfQuestions3 > -1) {
        this.questions3.splice(indexOfQuestions3, 1);
      }
    }
    this.oldQuestion4Value = newValue;
  };

  //Add employee or Individual
  signUp = function () {

    this.submitted = true;

    this.errorPopupMessage = "";
    this.recoveredUser = new RegUser();

    if (this.regForm.invalid) {
      return;
    }

    this.signupInput.type = this.type;
    if (this.signupInput.type == 'Individual') {
      this.signupInput.roleLevel = 1;
    }
    else if (this.signupInput.type == 'Business') {
      this.signupInput.roleLevel = 0;
    }

    this.loader.show("Please wait....");
    this.authService.postEncrypt('user?' + this.requestPath + '=completeReg', this.signupInput)
      .subscribe(res => {
        this.loader.hide();
        // console.log(res);

        if (res.success) {
          res.data = this.global.decrypt(res.data);
          this.global.saveLocalData(this.global.HIDDIN_USER_DETAILS, JSON.stringify(res.data));

          this.disableFirstForm();

          // Move to the next step
          //this.firstStepDone = true;
          this.stepCounter = this.stepCounter + 1;
          this.content.scrollToTop();
        } else {
          this.errorPopupMessage = res.message;
        }
      }, error => {
        this.loader.hide();
        // console.log(error);
        this.errorPopupMessage = "Error occured at server";
      });
  }

  setType(type: string) {
    this.type = type;

    if (this.type === "Individual") {
      this.regForm.controls['company'].setValidators([Validators.required, Validators.maxLength(100)]);
      this.regForm.controls['lastName'].setValidators([Validators.required, Validators.maxLength(100)]);
    } else if (this.type === "Business") {
      this.regForm.controls['company'].setValidators([Validators.required, Validators.maxLength(100)]);
      this.regForm.controls['lastName'].setValidators([Validators.required, Validators.maxLength(100)]);
    } else {
      this.regForm.controls['company'].setValidators([]);
      this.regForm.controls['lastName'].setValidators([]);
    }
    this.regForm.get('company').updateValueAndValidity();
    this.regForm.get('lastName').updateValueAndValidity();
  }

  goBack() {
    this.stepCounter = this.stepCounter - 1;
    this.content.scrollToTop();
  }

  goNext() {
    // Submit first page
    if (this.stepCounter == 1 && !this.firstStepDone) {
      this.signUp();
    } else if (this.firstStepDone) {
      this.stepCounter = this.stepCounter + 1;
      // Fetch Draft Save Data
      this.getAllData();
    }
  }

  // Upload Images
  resetForms = function () {
    this.resetPPForm();
    this.resetUBForm();
    this.resetBCForm();
    this.resetDLForm();
    this.resetPassForm();
  }

  disableF_UBform = function () {
    this.f_ubForm.get("accountNumber").disable();
    this.f_ubForm.get("name").disable();
    this.f_ubForm.get("address").disable();
  }

  enableF_UBform = function () {
    this.f_ubForm.get("accountNumber").enable();
    this.f_ubForm.get("name").enable();
    this.f_ubForm.get("address").enable();
  }

  disableF_BCform = function () {
    this.f_bcForm.get("accountNumber").disable();
    this.f_bcForm.get("bankRoutingNumber").disable();
    this.f_bcForm.get("chequeId").disable();
    this.f_bcForm.get("name").disable();
  }

  enableF_BCform = function () {
    this.f_bcForm.get("accountNumber").enable();
    this.f_bcForm.get("bankRoutingNumber").enable();
    this.f_bcForm.get("chequeId").enable();
    this.f_bcForm.get("name").enable();
  }

  disableF_DLform = function () {
    this.f_dlForm.get("drivingLicenseId").disable();
    this.f_dlForm.get("name").disable();
    this.f_dlForm.get("address").disable();
    this.f_dlForm.get("dob").disable();
    this.f_dlForm.get("gender").disable();
    this.f_dlForm.get("issued").disable();
    this.f_dlForm.get("expiry").disable();
  }

  enableF_DLform = function () {
    this.f_dlForm.get("drivingLicenseId").enable();
    this.f_dlForm.get("name").enable();
    this.f_dlForm.get("address").enable();
    this.f_dlForm.get("dob").enable();
    this.f_dlForm.get("gender").enable();
    this.f_dlForm.get("issued").enable();
    this.f_dlForm.get("expiry").enable();
  }

  disableF_PASSform = function () {
    this.f_passForm.get("passportId").disable();
    this.f_passForm.get("name").disable();
    this.f_passForm.get("dob").disable();
    this.f_passForm.get("gender").disable();
    this.f_passForm.get("issued").disable();
    this.f_passForm.get("expiry").disable();
  }

  enableF_PASSform = function () {
    this.f_passForm.get("passportId").enable();
    this.f_passForm.get("name").enable();
    this.f_passForm.get("dob").enable();
    this.f_passForm.get("gender").enable();
    this.f_passForm.get("issued").enable();
    this.f_passForm.get("expiry").enable();
  }

  editForm(formName) {

    if (formName == 'f_ubForm') {
      this.ubUploaded = false;
      this.enableF_UBform();
      this.setUtilityType('manual')
    } else if (formName == 'f_bcForm') {
      this.bcUploaded = false;
      this.enableF_BCform();
      this.setBankType('manual')
    } else if (formName == 'f_dlForm') {
      this.dlUploaded = false;
      this.enableF_DLform();
      this.setDlType('manual');
    } else if (formName == 'f_passForm') {
      this.passUploaded = false;
      this.enableF_PASSform();
      this.setPassportType('manual');
    }
  }

  resetPPForm() {
    this.ppUploaded = false;
    this.ppErrorMessage = null;
    this.ppSuccessMessage = null;
    this.ppSubmitted = false;
  }

  resetUBForm() {
    this.ubUploaded = false;
    this.ubErrorMessage = null;
    this.ubSuccessMessage = null;
    this.ubSubmitted = false;
    this.utilityBill = {};
    this.disableF_UBform();
  }

  resetBCForm() {
    this.bcUploaded = false;
    this.bcErrorMessage = null;
    this.bcSuccessMessage = null;
    this.bcSubmitted = false;
    this.bankCheque = {};
    this.disableF_BCform();
  }

  resetDLForm() {
    this.dlUploaded = false;
    this.dlErrorMessage = null;
    this.dlSuccessMessage = null;
    this.dlSubmitted = false;
    this.drivingLic = {};
    this.disableF_DLform();
  }

  resetPassForm() {
    this.passUploaded = false;
    this.passErrorMessage = null;
    this.passSuccessMessage = null;
    this.passSubmitted = false;
    this.passport = {};
    this.disableF_PASSform();
  }

  uploadPicturePassword = function () {

    this.ppSubmitted = true;
    this.ppErrorMessage = null;

    if (this.picPwd.length == 0) {
      this.ppErrorMessage = "Please select a image to upload.";
      return;
    }

    if (this.picPwd.length > 1) {
      alert("You are allowed to upload only one file.");
      return;
    }

    var ppFile = this.picPwd[0];

    if (ppFile) {

      this.ppUploadInProgress = true;

      this.fileUpload.uploadSecure('image?' + this.requestPath + '=saveImageFile&type=pp', ppFile)
        .subscribe((res) => {
          this.ppUploadInProgress = false;
          // console.log(res);
          if (res.success) {
            this.ppUploaded = true;
            // console.log('Picture password is uploaded');
            this.ppSuccessMessage = "Picture Password saved successfully";
          } else {
            this.ppUploaded = false;
            this.ppErrorMessage = res.message;
            // console.log(res.message);
          }
        }, error => {
          //this.loader.hide();
          this.ppUploadInProgress = false;
          // console.log(error);
          this.ppErrorMessage = "Please try again!!!";
          // console.log(this.ppErrorMessage);
        });
    } else {
      // this.errorMsg5 = true;
      // return;
      this.ppErrorMessage = "Please select the Picture Password first.";
      // console.log(this.ppErrorMessage);
    }
  }

  uploadUtilityBillImage = function () {

    this.ubSubmitted = true;
    this.ubErrorMessage = null;
    this.ubSuccessMessage = null;

    if (this.utilityFiles.length == 0) {
      this.ubErrorMessage = "Please select a image to upload.";
      return;
    }

    if (this.utilityFiles.length > 1) {
      alert("You are allowed to upload only one file.");
      return;
    }
    var ubFile = this.utilityFiles[0];

    this.ubInProgress = true;
    if (ubFile) {
      this.fileUpload.uploadSecure('image?' + this.requestPath + '=getUtilityBillDataByImage', ubFile)
        .subscribe((res) => {
          // console.log(res);
          if (res.success) {

            if (this.global.isEmpty(res.data)) {
              this.ubUploaded = false;
              this.ubErrorMessage = "Please upload better quality picture.";
              return;
            }
            this.ubUploaded = true;
            this.disableF_UBform();
            this.utilityBill = res.data;
            // console.log(this.utilityBill);
            this.ubSuccessMessage = "Details saved successfully";
          } else {
            this.ubUploaded = false;
            this.ubErrorMessage = res.message;
            // console.log(res.message);
          }
          this.ubInProgress = false;
        }, error => {
          this.loader.hide();
          this.ubInProgress = false;
          // console.log(error);
          this.ubUploaded = false;
          this.ubErrorMessage = "Please try again!!!";
          // console.log(this.ubErrorMessage);
        });
    } else {
      // this.errorMsg5 = true;
      // return;
      this.ubErrorMessage = "Please select the Utility Bill first.";
      // console.log(this.ubErrorMessage);
    }
  }

  uploadBankChequeImage = function () {

    this.bcSubmitted = true;
    this.bcErrorMessage = null;
    this.bcSuccessMessage = null;

    if (this.global.isEmpty(this.selectedBank)) {
      this.bcErrorMessage = "Please select bank name";
      return;
    }

    if (this.bankFiles.length == 0) {
      this.bcErrorMessage = "Please select a image to upload.";
      return;
    }

    if (this.bankFiles.length > 1) {
      alert("You are allowed to upload only one file.");
      return;
    }

    this.bcInProgress = true;
    var bcFile = this.bankFiles[0];

    if (bcFile) {

      this.fileUpload.uploadSecure('image?' + this.requestPath + '=getChequeDataByImage&selectedBank=' + this.selectedBank, bcFile)
        .subscribe((res) => {
          // console.log(res);
          if (res.success) {

            if (this.global.isEmpty(res.data)) {
              this.bcUploaded = false;
              this.bcErrorMessage = "Please upload better quality picture.";
              return;
            }
            this.bankCheque = res.data;

            this.bcUploaded = true;
            // console.log(this.utilityBill);
            this.disableF_BCform();
            this.bcSuccessMessage = "Details saved successfully.";
          } else {
            this.bcUploaded = false;
            this.bcErrorMessage = res.message;
            // console.log(res.message);
          }
          this.bcInProgress = false;
        }, error => {
          this.bcInProgress = false;
          // console.log(error);
          this.bcUploaded = false;
          this.bcErrorMessage = "Please try again!!!";
          // console.log(this.bcErrorMessage);
        });
    } else {
      // this.errorMsg5 = true;
      // return;
      this.bcErrorMessage = "Please select the Bank Cheque first.";
      // console.log(this.bcErrorMessage);
    }
  }

  uploadDrivingLicImage = function () {

    this.dlSubmitted = true;
    this.dlErrorMessage = null;
    this.dlSuccessMessage = null;

    if (this.global.isEmpty(this.selectedState)) {
      this.dlErrorMessage = "Please select state name";
      return;
    }

    if (this.dlFiles.length == 0) {
      this.dlErrorMessage = "Please select a image to upload.";
      return;
    }

    if (this.dlFiles.length > 1) {
      alert("You are allowed to upload only one file.");
      return;
    }

    this.dlInProgress = true;
    var dlFile = this.dlFiles[0];

    if (dlFile) {

      this.fileUpload.uploadSecure('image?' + this.requestPath + '=getDrivingLicenseDataImage&selectedState=' + this.selectedState, dlFile)
        .subscribe((res) => {
          // console.log(res);
          if (res.success) {

            if (this.global.isEmpty(res.data)) {
              this.dlUploaded = false;
              this.dlErrorMessage = "Please upload better quality picture.";
              return;
            }
            // this.true = false;
            this.drivingLic = res.data;

            this.disableF_DLform();
            this.dlSuccessMessage = "Details saved successfully.";
          } else {
            this.dlUploaded = false;
            this.dlErrorMessage = res.message;
            // console.log(res.message);
          }
          this.dlInProgress = false;
        }, error => {
          //this.loader.hide();
          this.dlInProgress = false;
          // console.log(error);
          this.dlUploaded = false;
          this.dlErrorMessage = "Please try again!!!";
          // console.log(this.dlErrorMessage);
        });
    } else {
      // this.errorMsg5 = true;
      // return;
      this.dlErrorMessage = "Please select the Driving Licscense first.";
      // console.log(this.dlErrorMessage);
    }
  }

  uploadPassportImage = function () {

    this.passSubmitted = true;
    this.passErrorMessage = null;
    this.passSuccessMessage = null;

    if (this.passportFiles.length == 0) {
      this.passErrorMessage = "Please select a image to upload.";
      return;
    }

    if (this.passportFiles.length > 1) {
      alert("You are allowed to upload only one file.");
      return;
    }

    this.passInProgress = true;
    var passFile = this.passportFiles[0];

    if (passFile) {

      this.fileUpload.uploadSecure('image?' + this.requestPath + '=getPassportDataByImage', passFile)
        .subscribe((res) => {
          // console.log(res);
          if (res.success) {
            if (this.global.isEmpty(res.data)) {
              this.passUploaded = false;
              this.passErrorMessage = "Please upload better quality picture.";
              return;
            }
            this.passUploaded = true;
            this.passport = res.data;

            this.disableF_PASSform();
            this.passSuccessMessage = "Details saved successfully.";
          } else {
            this.passUploaded = false;
            this.passErrorMessage = res.message;
            // console.log(this.passErrorMessage);
          }
          this.passInProgress = false;
        }, error => {
          this.passInProgress = false;
          // console.log(error);
          this.passUploaded = false;
          this.passErrorMessage = "Please try again!!!";
          // console.log(this.passErrorMessage);
        });
    } else {
      // this.errorMsg5 = true;
      // return;
      this.passErrorMessage = "Please select the Passport first.";
      // console.log(this.passErrorMessage);
    }
  }

  submitUtilityForm = function () {
    this.f_ubErrorMessage = null;
    this.f_ubSuccessMessage = null;
    this.f_ubSubmitted = true;

    if (this.f_ubForm.invalid) {
      // console.log("Invalid: " + this.f_ubForm.value);
      return;
    }

    this.f_ubInProgress = true;

    this.authService.postEncrypt('image?' + this.requestPath + '=saveUpdateUtilityBill', this.utilityBill)
      .subscribe((res) => {
        // console.log(res);
        if (res.success) {
          this.setUtilityType('upload');
          this.ubUploaded = true;
          this.disableF_UBform();
          this.f_ubSuccessMessage = "Details saved successfully.";
        } else {
          this.ubUploaded = false;
          this.f_ubErrorMessage = "Error occured";
        }
        this.f_ubInProgress = false;
      }, error => {
        //this.loader.hide();
        // console.log(error);
        this.f_ubInProgress = false;
        this.f_ubErrorMessage = "Please try again!!!";
        // console.log(this.f_ubErrorMessage);
      });
  }

  submitChequeForm = function () {
    this.f_bcErrorMessage = null;
    this.f_bcSuccessMessage = null;
    this.f_bcSubmitted = true;

    if (this.f_bcForm.invalid) {
      // console.log("Invalid: " + this.f_bcForm.value);
      return;
    }

    if (this.global.isEmpty(this.selectedBank)) {
      this.f_bcErrorMessage = "Please select bank name";
      return;
    }

    this.bankCheque.bankName = this.selectedBank;

    this.f_bcInProgress = true;

    this.authService.postEncrypt('image?' + this.requestPath + '=saveUpdateCheque', this.bankCheque)
      .subscribe((res) => {
        // console.log(res);
        if (res.success) {
          this.bcUploaded = true;
          this.disableF_BCform();
          this.setBankType('upload');
          this.f_bcSuccessMessage = "Details saved successfully.";
        } else {
          this.f_bcErrorMessage = res.message;
        }
        this.f_bcInProgress = false;
      }, error => {
        // console.log(error);
        this.f_bcInProgress = false;
        this.f_bcErrorMessage = "Please try again!!!";
        // console.log(this.f_bcErrorMessage);
      });
  }

  submitDLForm = function () {
    this.f_dlErrorMessage = null;
    this.f_dlSuccessMessage = null;
    this.f_dlSubmitted = true;

    if (this.f_dlForm.get('expiry').hasError('past')) {
      this.f_dlForm.get('expiry').setErrors(null);
    }

    if (this.f_dlForm.invalid) {
      // console.log("Invalid: " + this.f_dlForm.value);
      return;
    }

    // Expiry date cannot be past date
    if (!(new Date(this.drivingLic.expiry) > new Date(new Date().toDateString()))) {
      this.f_dlForm.controls["expiry"].setErrors({ past: true });
      return;
    }

    if (this.global.isEmpty(this.selectedState)) {
      this.f_dlErrorMessage = "Please select state name";
      return;
    }

    this.drivingLic.state = this.selectedState;

    this.f_dlInProgress = true;

    this.authService.postEncrypt('image?' + this.requestPath + '=saveUpdateDrivingLicense', this.drivingLic)
      .subscribe((res) => {
        // console.log(res);
        if (res.success) {
          this.dlUploaded = true;
          this.disableF_DLform();
          this.setDlType('upload');
          this.f_dlSuccessMessage = "Details saved successfully.";
        } else {
          this.f_dlErrorMessage = res.message;
          // console.log(res.message);
        }
        this.f_dlInProgress = false;
      }, error => {
        // this.loader.hide();
        // console.log(error);
        this.f_dlInProgress = false;
        this.f_dlErrorMessage = "Please try again!!!";
        // console.log(this.f_dlErrorMessage);
      });
  }

  submitPassportForm = function () {
    this.f_passErrorMessage = null;
    this.f_passSuccessMessage = null;
    this.f_passSubmitted = true;

    if (this.f_passForm.get('expiry').hasError('past')) {
      this.f_passForm.get('expiry').setErrors(null);
    }

    if (this.f_passForm.invalid) {
      // console.log("Invalid: " + this.f_passForm.value);
      return;
    }
    // console.log(this.passport);

    // Expiry date cannot be past date
    if (!(new Date(this.passport.expiry) > new Date(new Date().toDateString()))) {
      this.f_passForm.controls["expiry"].setErrors({ past: true })
      return;
    }

    this.f_passInProgress = true;

    this.authService.postEncrypt('image?' + this.requestPath + '=saveUpdatePassport', this.passport)
      .subscribe((res) => {
        // console.log(res);
        if (res.success) {
          this.passUploaded = true;
          this.disableF_PASSform();
          this.setPassportType('upload');
          this.f_passSuccessMessage = "Details saved successfully.";
        } else {
          this.f_passErrorMessage = res.message;
        }
        this.f_passInProgress = false;
      }, error => {
        //this.loader.hide();
        // console.log(error);
        this.f_passInProgress = false;
        this.f_passErrorMessage = "Please try again!!!";
        // console.log(this.f_passErrorMessage);
      });
  }

  completeProcess = function () {

    var finalErrorMsg = "";
    this.completionErrorMsg = null;

    // if (!this.ubUploaded) {
    //   finalErrorMsg = finalErrorMsg + "Utility Bill";
    // }

    // if (!this.bcUploaded) {

    //   if (finalErrorMsg.length == 0) {
    //     finalErrorMsg = finalErrorMsg + "Bank Cheque";
    //   } else {
    //     finalErrorMsg = finalErrorMsg + ", Bank Cheque";
    //   }
    // }

    // if (!this.dlUploaded) {

    //   if (finalErrorMsg.length == 0) {
    //     finalErrorMsg = finalErrorMsg + "Driving License";
    //   } else {
    //     finalErrorMsg = finalErrorMsg + ", Driving License";
    //   }
    // }

    // if (!this.passUploaded) {

    //   if (finalErrorMsg.length == 0) {
    //     finalErrorMsg = finalErrorMsg + "Passport";
    //   } else {
    //     finalErrorMsg = finalErrorMsg + ", Passport";
    //   }
    // }

    // if (!this.ppUploaded) {

    //   if (finalErrorMsg.length == 0) {
    //     finalErrorMsg = finalErrorMsg + "Picture Password";
    //   } else {
    //     finalErrorMsg = finalErrorMsg + ", Picture Password";
    //   }
    // }

    // if (finalErrorMsg.length > 0) {
    //   this.completionErrorMsg = finalErrorMsg + " not uploaded";

    //   return;
    // }

    if (!this.ubUploaded && !this.bcUploaded && !this.dlUploaded && !this.passUploaded && !this.passUploaded && !this.ppUploaded) {
      this.completionErrorMsg = "Please complete atleast any one of the section given above.";
      return;
    }

    this.completionInProcess = true;
    this.loader.show("Please wait...");

    this.authService.get('image?' + this.requestPath + '=signupCompletion')
      .subscribe((res) => {
        this.loader.hide();
        // console.log(res);
        if (res.success) {
          res.data = this.global.decrypt(res.data);
          // console.log(res);

          // Save data in local storage
          this.global.saveLocalData(this.global.HIDDIN_USER_DETAILS, JSON.stringify(res.data));
          // this.router.navigateByUrl('dashboard');
          this.openThanksRegModal(res.data);
        } else {
          // console.log(res.message);
          this.completionErrorMsg = res.message;
        }

        this.completionInProcess = false;
      }, error => {
        this.loader.hide();
        this.completionInProcess = false;
        // console.log(error);
        this.completionErrorMsg = "Please try again.";
      });
  }

  getStatesList = function () {

    this.loader.show("Loading data...");
    this.authService.getNoSecure('ns/refData?' + this.requestPath + '=findAllByParentId&parentId=' + 2)
      .subscribe((res) => {
        this.loader.hide();
        if (res.success) {
          // console.log(res);
          this.usaStates = this.global.decryptDefault(res.data);
        }
        if (res) {

        }
      }, error => {
        this.loader.hide();
        // console.log(error);
      });
  }

  getBanksList = function () {

    this.loader.show("Loading data...");
    this.authService.getNoSecure('ns/refData?' + this.requestPath + '=findAllByParentId&parentId=' + 3)
      .subscribe((res) => {
        this.loader.hide();
        if (res.success) {
          // console.log(res);
          this.usaBanks = this.global.decryptDefault(res.data);
        }
      }, error => {
        this.loader.hide();
        // console.log(error);
      });
  }

  dlDobErrMsg: String = null;
  ppDobErrMsg: String = null;
  dlIssuedErrMsg: String = null;
  dlExpiryErrMsg: String = null;
  ppIssuedErrMsg: String = null;
  ppExpiryErrMsg: String = null;

  dateCheck = function (event, str) {

    this.dlDobErrMsg = null;
    this.ppDobErrMsg = null;
    this.dlIssuedErrMsg = null;
    this.dlExpiryErrMsg = null;
    this.ppIssuedErrMsg = null;
    this.ppExpiryErrMsg = null;

    if (str == "dlDob" && this.isFutureDate(event)) {
      this.dlDobErrMsg = "Date of birth couldn't be a future date.";
      this.drivingLic.dob = "";
      return;
    }
    if (str == "dlIssued" && this.isFutureDate(event)) {
      this.dlIssuedErrMsg = "Issued date couldn't be a future date.";
      this.drivingLic.issued = "";
      return;
    }
    if (str == "dlExpiry" && this.isPastDate(event)) {
      this.dlExpiryErrMsg = "Expiry date couldn't be a past date.";
      this.drivingLic.expiry = "";
      return;
    }
    if (str == "ppDob" && this.isFutureDate(event)) {
      this.ppDobErrMsg = "Date of birth couldn't be a future date.";
      this.passport.dob = "";
    }
    if (str == "ppIssued" && this.isFutureDate(event)) {
      this.ppIssuedErrMsg = "Issued date couldn't be a future date.";
      this.passport.issued = "";
      return;
    }
    if (str == "ppExpiry" && this.isPastDate(event)) {
      this.ppExpiryErrMsg = "Expiry date couldn't be a past date.";
      this.passport.expiry = "";
      return;
    }
  }

  isPastDate = function (event): Boolean {
    if (!(new Date(event.target.value) > new Date(new Date().toDateString()))) {
      return true;
    } else {
      return false;
    }
  }

  isFutureDate = function (event): Boolean {
    if (!(new Date(event.target.value) < new Date(new Date().toDateString()))) {
      return true;
    } else {
      return false;
    }
  }

  setUtilityType(utilityType: string) {
    this.utilityType = utilityType;
  }

  setBankType(bankType: string) {
    this.bankType = bankType;
  }

  setPassportType(passportType: string) {
    this.passportType = passportType;
  }

  setDlType(dlType: string) {
    this.dlType = dlType;
  }

  toggleSection(sectionName: string) {
    if (sectionName === 'utility') {
      this.displayUtility = !this.displayUtility;
    }

    if (sectionName === 'bank') {
      this.displayBank = !this.displayBank;
    }

    if (sectionName === 'passport') {
      this.displayPassport = !this.displayPassport;
    }

    if (sectionName === 'dl') {
      this.displayDl = !this.displayDl;
    }

    if (sectionName === 'picPwd') {
      this.displayPicPwd = !this.displayPicPwd;
    }
  }

  onFileDropped($event, fileType: string) {
    if ($event.length == 1) {
      // var files = $event.dataTransfer.files || $event.target.files;
      var files = $event;
      var extFile = files[0].type;
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png" || extFile == "image/jpg" || extFile == "image/jpeg" || extFile == "image/png") {
        this.prepareFilesList($event, fileType);
      }
      else {
        this.fileTypeNotification();
      }
    }
  }

  fileBrowseHandler(files, fileType: string) {
    var idxDot = files[0].name.lastIndexOf(".") + 1;
    var extFile = files[0].name.substr(idxDot, files[0].name.length).toLowerCase();
    if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
      this.prepareFilesList(files, fileType);
    }
    else {
      this.fileTypeNotification();
    }

  }

  async fileTypeNotification() {
    const modal = await this.modalController.create({
      component: NotifiactionPopupComponent,
      componentProps: {
        errorMsg: 'Only jpg/jpeg and png files are allowed!',
        imageSrc: 'assets/images/error.png'
      }
    });
    return await modal.present();
  }

  deleteFile(index: number, fileType: string) {
    if (fileType === 'utility') {
      this.ubUploaded = false;
      this.utilityFiles.splice(index, 1);
    }
    if (fileType === 'bank') {
      this.bcUploaded = false;
      this.bankFiles.splice(index, 1);
    }
    if (fileType === 'passport') {
      this.passUploaded = false;
      this.passportFiles.splice(index, 1);
    }
    if (fileType === 'dl') {
      this.dlUploaded = false;
      this.dlFiles.splice(index, 1);
    }
    if (fileType === 'picPwd') {
      this.ppUploaded = false;
      this.picPwd.splice(index, 1);
    }
  }

  uploadFilesSimulator(index: number, fileType: string) {
    setTimeout(() => {
      if (fileType === 'utility') {
        if (index === this.utilityFiles.length) {
          return;
        } else {
          const progressInterval = setInterval(() => {
            if (this.utilityFiles[index].progress === 100) {
              clearInterval(progressInterval);
              this.uploadFilesSimulator(index + 1, fileType);
            } else {
              this.utilityFiles[index].progress += 5;
            }
          }, 200);
        }
      }

      if (fileType === 'bank') {
        if (index === this.bankFiles.length) {
          return;
        } else {
          const progressInterval = setInterval(() => {
            if (this.bankFiles[index].progress === 100) {
              clearInterval(progressInterval);
              this.uploadFilesSimulator(index + 1, fileType);
            } else {
              this.bankFiles[index].progress += 5;
            }
          }, 200);
        }
      }

      if (fileType === 'passport') {
        if (index === this.passportFiles.length) {
          return;
        } else {
          const progressInterval = setInterval(() => {
            if (this.passportFiles[index].progress === 100) {
              clearInterval(progressInterval);
              this.uploadFilesSimulator(index + 1, fileType);
            } else {
              this.passportFiles[index].progress += 5;
            }
          }, 200);
        }
      }

      if (fileType === 'dl') {
        if (index === this.dlFiles.length) {
          return;
        } else {
          const progressInterval = setInterval(() => {
            if (this.dlFiles[index].progress === 100) {
              clearInterval(progressInterval);
              this.uploadFilesSimulator(index + 1, fileType);
            } else {
              this.dlFiles[index].progress += 5;
            }
          }, 200);
        }
      }

      if (fileType === 'picPwd') {
        if (index === this.picPwd.length) {
          return;
        } else {
          const progressInterval = setInterval(() => {
            if (this.picPwd[index].progress === 100) {
              clearInterval(progressInterval);
              this.uploadFilesSimulator(index + 1, fileType);
            } else {
              this.picPwd[index].progress += 5;
            }
          }, 200);
        }
      }

    }, 1000);
  }

  prepareFilesList(files: Array<any>, fileType: string) {

    // Only 1 file allowed
    if (files.length > 1) {
      alert("Only one file is allowed to Drag and Drop.");
      return;
    }

    if (fileType === 'utility') {
      this.ubUploaded = false;
      for (const item of files) {
        item.progress = 0;
        const reader = new FileReader();
        item.imagePath = files;
        reader.readAsDataURL(files[0]);
        reader.onload = (_event) => {
          item.url = reader.result;
        }
        this.utilityFiles.push(item);
      }
    }

    if (fileType === 'bank') {
      for (const item of files) {
        item.progress = 0;
        const reader = new FileReader();
        item.imagePath = files;
        reader.readAsDataURL(files[0]);
        reader.onload = (_event) => {
          item.url = reader.result;
        }
        this.bankFiles.push(item);
      }
    }

    if (fileType === 'passport') {
      for (const item of files) {
        item.progress = 0;
        const reader = new FileReader();
        item.imagePath = files;
        reader.readAsDataURL(files[0]);
        reader.onload = (_event) => {
          item.url = reader.result;
        }
        this.passportFiles.push(item);
      }
    }

    if (fileType === 'dl') {
      for (const item of files) {
        item.progress = 0;
        const reader = new FileReader();
        item.imagePath = files;
        reader.readAsDataURL(files[0]);
        reader.onload = (_event) => {
          item.url = reader.result;
        }
        this.dlFiles.push(item);
      }
    }

    if (fileType === 'picPwd') {
      for (const item of files) {
        item.progress = 0;
        const reader = new FileReader();
        item.imagePath = files;
        reader.readAsDataURL(files[0]);
        reader.onload = (_event) => {
          item.url = reader.result;
        }
        this.picPwd.push(item);
      }
    }
    this.uploadFilesSimulator(0, fileType);
  }

  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  async openThanksRegModal(data) {
    const modal = await this.modalController.create({
      component: ThanksForRegComponent,
      componentProps: {
        user: data
      }
    });

    modal.onDidDismiss().then((dataReturned) => {
      this.router.navigateByUrl("dashboard");
    });

    return await modal.present();
  }

}
