<app-header-left-menu [user]="user"></app-header-left-menu>
<ion-content id="main-content">
  <div class="bg-theme-alt">
    <div class="bg-grad-special">
      <div class="bg-grad-special-alt">
        <div class="page-container">
          <div class="nk-ovm shape-u shape-contain shape-left-top"></div>
          <app-header [user]="user"></app-header>
          <div id="login-popup">
            <div class="ath-body">
              <div class="left-image-section">
                <!-- <img src="assets/images/hiddin-logo.png"> -->
              </div>
              <div class="right-section">
                <h5 class="ath-heading title text-center">{{heading}}<small class="tc-default">{{subheading}}</small></h5>

                <!-- Step First -->
                <div *ngIf="stepFirst">
                  <form [formGroup]="fpForm" (ngSubmit)="sendMobileOTP()">
                    <div class="field-item">
                      <div class="field-wrap">
                        <h6 class="title title-xs">Phone Number<span class="red">*</span></h6>
                        <ngx-intl-tel-input type="text" formControlName="phoneNumber"
                        [cssClass]="'phoneNumber'"
                        [preferredCountries]="preferredCountries"
                        [enableAutoCountrySelect]="false"
                        [enablePlaceholder]="true"
                        [searchCountryFlag]="true"
                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                        [selectFirstCountry]="false"
                        [selectedCountryISO]="CountryISO.UnitedStates"
                        [maxLength]="15"
                        [phoneValidation]="true"
                        [separateDialCode]="separateDialCode"
                        [numberFormat]="PhoneNumberFormat.International"                  
                        [(ngModel)]="phoneNumberAll" autocomplete="disabled"
                        (ngModelChange)="onCountryChange($event)"
                        [ngClass]="{ 'is-invalid': fpSubmitted && fpForm.controls.phoneNumber.errors }" required />
                        <small>We will send you a verification code to reset your password</small>
                        <div *ngIf="fpSubmitted && fpForm.controls.phoneNumber.errors" class="invalid-feedback">
                          <div *ngIf="fpForm.controls.phoneNumber.errors.required">Phone number is required</div>
                        </div>
                        <div *ngIf="fpSubmitted && fpForm.controls.phoneNumber.errors" class="invalid-feedback">
                          <div *ngIf="!fpForm.controls.phoneNumber.errors.validatePhoneNumber.valid">Invalid phone number</div>
                        </div>
                      </div>
                    </div>
                    <button type="submit" class="btn btn-primary btn-block btn-md">Send verification code</button>
                  </form>
                  <div class="ath-note text-center">
                    Already have a password? <a [routerLink]="['/login']"><br class="display-sm" />
                      Login here</a>
                  </div>
                </div>

                <!-- Step Second -->
                <div *ngIf="stepSecond">
                  <form [formGroup]="otpForm" (ngSubmit)="validateMobileOTP()">
                    <div class="field-item">
                      <div class="field-wrap">
                        <input type="text" formControlName="otp" class="input-bordered" [(ngModel)]="otp"
                          [ngClass]="{ 'is-invalid': otpSubmitted && otpForm.controls.otp.errors }"
                          placeholder="Enter Verification Code" />
                        <div *ngIf="otpSubmitted && otpForm.controls.otp.errors" class="invalid-feedback">
                          <div *ngIf="otpForm.controls.otp.errors.required">Verification Code is required</div>
                        </div>
                        <small *ngIf="formattedPhoneNo && formattedPhoneNo.length > 0">We sent you a verification code on <label class="boldgreen">{{formattedPhoneNo}}</label> number</small>
                      </div>
                      <div class="timer">
                        <a href="javascript:" [class.disabled]="!activeResendLink"
                          (click)="resendMobileOTP()">Resend</a><span *ngIf="!activeResendLink"> {{time}}</span>
                      </div>
                    </div>
                    <div class="text-center">
                      <button type="submit" class="btn btn-primary btn-block btn-md">Verify verification code</button>
                    </div>
                  </form>
                </div>

                <!-- Step Third -->
                <div *ngIf="stepThird">
                  <form [formGroup]="emailOtpForm" (ngSubmit)="validateEmailOTP()">
                    <div class="field-item">
                      <div class="field-wrap">
                        <input type="text" formControlName="emailOtp" class="input-bordered" [(ngModel)]="emailOtp"
                          [ngClass]="{ 'is-invalid': emailOtpSubmitted && emailOtpForm.controls.emailOtp.errors }"
                          placeholder="Enter Verification Code" />
                        <div *ngIf="emailOtpSubmitted && emailOtpForm.controls.emailOtp.errors" class="invalid-feedback">
                          <div *ngIf="emailOtpForm.controls.emailOtp.errors.required">Verification Code is required</div>
                        </div>
                        <small *ngIf="formattedEmail && formattedEmail.length > 0">We sent you a verification code on <label class="boldgreen">{{formattedEmail}}</label> email</small>
                      </div>
                      <div class="timer">
                        <a href="javascript:" [class.disabled]="!activeResendLink"
                          (click)="resendEmailOTP()">Resend</a><span *ngIf="!activeResendLink"> {{time}}</span>
                      </div>
                    </div>
                    <div class="text-center">
                      <button type="submit" class="btn btn-primary btn-block btn-md">Verify verification code</button>
                    </div>
                  </form>
                </div>

                <!-- Step Third -->
                <div *ngIf="stepFour">
                  <form [formGroup]="cpForm" (ngSubmit)="createNewPassword()">
                    <div class="field-item">
                      <div class="field-wrap">
                        <!-- <label for="pwd">Password<span class="required">*</span></label> -->
                        <input type="password" formControlName="userPassword" class="input-bordered"
                          [(ngModel)]="cpUser.userPassword"
                          [ngClass]="{ 'is-invalid': cpSubmitted && cpForm.controls.userPassword.errors }"
                          placeholder="Enter Password" id="resetPwd" onPaste="return false"
                          placeholder="Enter password" />
                        <i class="fa" [ngClass]="passwordVisible ? 'fa-eye-slash' : 'fa-eye'"
                          (click)="togglePassword('resetPwd')"></i>
                        <div *ngIf="cpSubmitted && cpForm.controls.userPassword.errors" class="invalid-feedback">
                          <div *ngIf="cpForm.controls.userPassword.errors.required">Password is required</div>
                          <div *ngIf="cpForm.controls.userPassword.errors.minlength">Password must be at least 6
                            characters
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="field-item">
                      <div class="field-wrap">
                        <!-- <label for="con_pwd">Confirm Password<span class="required">*</span></label> -->
                        <input type="password" formControlName="confirmPassword" class="input-bordered"
                          [(ngModel)]="cpUser.confirmPassword"
                          [ngClass]="{ 'is-invalid': cpSubmitted && cpForm.controls.confirmPassword.errors }"
                          placeholder="Confirm Password" onPaste="return false" placeholder="Enter Confirm password"
                          id="confirmResetPwd" />
                        <i class="fa" [ngClass]="confirmPasswordVisible ? 'fa-eye-slash' : 'fa-eye'"
                          (click)="togglePassword('confirmResetPwd')"></i>
                        <div *ngIf="cpSubmitted && cpForm.controls.confirmPassword.errors" class="invalid-feedback">
                          <div *ngIf="cpForm.controls.confirmPassword.errors.required">Confirm Password is required
                          </div>
                          <div *ngIf="cpForm.controls.confirmPassword.errors.mustMatch">Passwords must match</div>
                        </div>
                      </div>
                    </div>
                    <div class="text-center">
                      <button type="submit" class="btn btn-primary btn-block btn-md">Create</button>
                    </div>
                  </form>
                </div>

                <!-- Fourth Step -->
                <div *ngIf="!stepFirst && !stepSecond && !stepThird && !stepFour" class="text-center mt-2 p-t-30">
                  <i class="fa fa-check-circle tc-success"></i>
                  <div class="ath-note text-center">
                    Click here to <a [routerLink]="['/login']">Login</a>
                  </div>
                </div>

                <!-- Error section -->
                <div *ngIf="errorMessage && errorMessage != ''" class="alert alert-danger title title-xs mt-2 mb-2">
                  {{errorMessage}}
                </div>
              </div>
            </div>
          </div>
          <app-footer></app-footer>
        </div>
      </div>
    </div>
  </div>
</ion-content>