import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthServiceService } from './auth-service.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private router: Router,
    private authservice: AuthServiceService) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    // console.log(route);

    if (!this.authservice.isLoggedIn()) {
      this.router.navigate(["login"], { queryParams: { "redirectUrl": window.location.href } });
      return false;
    }

    if (environment.isSubscriptionRequired) {
      if (this.authservice.getSubscription()) {
        this.router.navigate(["renew-subscription"]);
        return false;
      }
    }
    return true;
  }
}
