import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from 'src/app/globals';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { LoaderService } from 'src/app/services/loader.service';
import * as moment from 'moment';
import { RegUser } from '../basic-reg/RegUser';
import { EncryptionUtil } from 'src/app/util/EncryptionUtil';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login-global',
  templateUrl: './login-global.component.html',
  styleUrls: ['./login-global.component.scss'],
})
export class LoginGlobalComponent implements OnInit {

  userForm: RegUser = new RegUser();

  // emailPattern = "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$";
  emailPattern = "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?";
  requestPath: string;

  /* Phone number form */
  phoneNoSubmitted: boolean;
  mobileNoForm: FormGroup;
  phoneNoFormEnabled: boolean;
  phoneNoErrorMessage: string;
  encDefaultKey: string;

  /* OTP form */
  otpSubmitted: boolean;
  otpForm: FormGroup;
  otpFormEnabled: boolean;
  otpErrorMessage: string;
  defaultKey: string;

  /* OTP form */
  signUpForm: FormGroup;
  submitted: boolean = false;
  errorMessage: string;
  detailFormEnabled: boolean;
  matchPassword: boolean;

  /* Success Message */
  signupSuccess: boolean = false;

  constructor(public global: Globals
    , public authService: AuthServiceService
    , public formBuilder: FormBuilder
    , public router: Router
    , private loader: LoaderService) {
    this.requestPath = this.global.requestPath;
  }

  ngOnInit() {

    this.mobileNoForm = this.formBuilder.group({
      phoneNumber: ['', [Validators.required]]
    });

    this.otpForm = this.formBuilder.group({
      mobileOtp: ['', [Validators.required]]
    });

    this.signUpForm = this.formBuilder.group({
      emailAddress: ['', [Validators.required, Validators.maxLength(50), Validators.pattern(this.emailPattern)]],
      userPassword: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.signUpForm.controls; }
  get phn() { return this.mobileNoForm.controls; }
  get otp() { return this.otpForm.controls; }

  user: RegUser = new RegUser();
  LoggedIn: boolean;

  ionViewDidEnter() {
    this.initUser();
    this.onReset();
    this.user = this.authService.getLoggedInUser();
    // this.user.imageUrl = this.authService.getProfilePicture();
    if (!this.global.isEmpty(this.user)) {
      this.user.imageUrl = this.authService.getProfilePicture();
    }
  }

  ionViewWillEnter() {
    this.user = this.authService.getLoggedInUser();
    this.LoggedIn = this.authService.isLoggedIn();
    if (!this.global.isEmpty(this.user)) {
      this.user.imageUrl = this.authService.getProfilePicture();
    }
  }

  initUser = function () {
    this.signUpForm.reset();
    this.userForm = new RegUser();

    // Show Phone Number form
    this.phoneNoFormEnabled = true;
    this.otpFormEnabled = false;
    this.detailFormEnabled = false;
    this.matchPassword = true;
  }

  mobileNoSubmit = function () {
    this.phoneNoSubmitted = true;
    this.phoneNoErrorMessage = null;

    // stop here if form is invalid
    if (this.mobileNoForm.invalid) {
      // console.log("Invalid: " + this.mobileNoForm.value);
      return;
    }

    // Hardcoded, need to remove
    this.userForm.phoneDialCode = "91";

    this.loader.show("Please wait...");
    this.authService.postDefaultEncrypt('ns/auth?' + this.requestPath + '=login_mob', this.userForm)
      .subscribe(res => {
        this.loader.hide();
        // console.log(res);
        if (res.success) {
          //Decrypt Data
          res.data = this.global.decryptDefault(res.data);
          this.encDefaultKey = res.data.key;
          this.phoneNoFormEnabled = false;
          this.otpFormEnabled = true;
        } else {
          this.phoneNoErrorMessage = res.message;
        }
      }, error => {
        this.loader.hide();
        // console.log(error);
        this.phoneNoErrorMessage = "Something went wrong, please try again.";
      });
  }

  otpSubmit = function () {
    this.otpSubmitted = true;
    this.otpErrorMessage = null;

    // stop here if form is invalid
    if (this.otpForm.invalid) {
      // console.log("Invalid: " + this.otpForm.value);
      return;
    }

    // Decrypt default key
    try {
      this.defaultKey = EncryptionUtil.decrypt(this.userForm.mobileOtp, this.encDefaultKey);
    }
    catch (err) {
      this.otpErrorMessage = "Invalid verification code";
      // console.log("Error in decrypting default key:" + err);
    }
    this.userForm.mobileOtp = EncryptionUtil.encrypt(this.defaultKey, this.userForm.mobileOtp);

    this.loader.show("Please wait...");
    this.authService.postDefaultEncrypt('ns/auth?' + this.requestPath + '=login_otp_verify', this.userForm)
      .subscribe(res => {
        this.loader.hide();
        // console.log(res);
        if (res.success) {
          this.otpFormEnabled = false;
          this.detailFormEnabled = true;
        } else {
          this.otpErrorMessage = res.message;
        }
      }, error => {
        this.loader.hide();
        // console.log(error);
        this.otpErrorMessage = "Something went wrong, please try again.";
      });
  }

  public noWhitespace(control: FormControl) {
    let isWhitespace = (control.value || '').toString().trim().length === 0;
    let isValid = !isWhitespace;
    return isValid ? null : { 'required': true }
  }

  public login() {
    this.submitted = true;
    this.errorMessage = null;

    // stop here if form is invalid
    if (this.signUpForm.invalid) {
      // console.log("Invalid: " + this.signUpForm.value);
      return;
    }

    this.userForm.email = this.userForm.emailAddress;
    this.userForm.password = EncryptionUtil.encrypt(this.defaultKey, window.btoa(this.userForm.userPassword));

    this.loader.show("Please wait...");
    this.authService.postDefaultEncrypt('ns/auth?' + this.requestPath + '=login_details', this.userForm)
      .subscribe(res => {
        this.loader.hide();
        // console.log(res);
        if (res.success) {

          if (environment.isSubscriptionRequired && res.statusCode == 1001) {
            // Subscription expired
            this.global.saveLocalData(this.global.SUBSCRIPTION, JSON.stringify(true));
          } else {
            this.global.saveLocalData(this.global.SUBSCRIPTION, JSON.stringify(false));
          }

          res.data = this.global.decryptDefault(res.data);
          this.global.saveLocalData(this.global.HIDDIN_TOKEN, JSON.stringify(res.data.access_token));
          res.data.key = EncryptionUtil.decrypt(this.defaultKey, res.data.key);
          this.global.saveLocalData(this.global.BOGUS_KEY, JSON.stringify(res.data.key));
          // console.log('Successfully Logged-In');
          this.getMyProfile();
        } else {
          this.errorMessage = res.message;
        }
      }, error => {
        this.loader.hide();
        // console.log(error);
        this.errorMessage = "Something went wrong, please try again.";
      });
  }

  getMyProfile = function () {
    this.loader.show("Fetching user details...");
    this.authService.get('user?' + this.requestPath + '=getLoginUserDetailNew')
      .subscribe((res) => {
        this.loader.hide();
        // console.log(res);
        if (res.success) {
          res.data = this.global.decryptDefault(res.data);
          this.user = EncryptionUtil.decrypt(this.defaultKey, res.data);
          this.global.saveLocalData(this.global.HIDDIN_USER_DETAILS, this.user);
          this.getImageUrl();
        } else {
          // console.log(res.message);
          this.errorMessage = res.message;
        }
      }, error => {
        this.loader.hide();
        // console.log(error);
        this.errorMessage = "Error occured";
      });
  }

  getImageUrl = function () {
    this.loader.show("Please wait...");
    this.authService.get('image?' + this.requestPath + '=getImage&type=profilepic')
      .subscribe((res) => {
        this.loader.hide();
        // console.log(res);
        if (res.success) {
          res.data = this.global.decrypt(res.data);
          if (!this.global.isEmpty(res.data)) {
            this.global.saveLocalData(this.global.USER_IMAGE_URL, JSON.parse(res.data).imageUrl);
          }
        } else {
          this.errorMessage = res.message;
        }
        this.router.navigate(['/dashboard']);
      }, error => {
        this.loader.hide();
        // console.log(error);
        this.errorMessage = "Error occured, please try again.";
      });
  }

  onReset = function () {
    this.submitted = false;
    this.signUpForm.reset();
    this.errorMessage = null;
  }

}


