<app-header-left-menu [user]="user"></app-header-left-menu>
<ion-content id="main-content" class="full-width-page">
  <div class="bg-theme-alt">
    <div class="bg-grad-special">
      <div class="bg-grad-special-alt">
        <div class="nk-ovm shape-u shape-contain shape-left-top"></div>
        <app-header [user]="user"></app-header>
        <div class="subContent">
          <div class="main-content container">
            <!-- Heading section-->
            <div class="row">
              <div class="col-10">
                <h3 class="title title-md title-gradient search-heading-flex mediumfontsize">
                  <ion-buttons slot="start">
                    <ion-back-button text="" defaultHref="directory-dashbord-new"></ion-back-button>
                  </ion-buttons>&nbsp; Search Directory
                </h3>
              </div>
              <div class="col-2 textRight">
                <button *ngIf="normalSection" (click)="advanceTab();"
                  class="btn btn-rg btn-auto btn-outline btn-grad on-bg-theme-dark-alt btn-option btn-outlines"><img
                    src="../../../assets/images/filter2.png"></button>
                <button *ngIf="advanceSection" (click)="normalTab();"
                  class="btn btn-rg btn-auto btn-outline btn-grad on-bg-theme-dark-alt btn-option btn-outlines"><img
                    src="../../../assets/images/filter.png"></button>
              </div>
            </div>
            <span class="red-local">*Only initial or full word will be searched</span>
            <div class="advance-search-container" *ngIf="advanceSection">
              <form class="form-inline search-form2 desktop-flex " (ngSubmit)="searchUserDetails(1)"
                [formGroup]="userSearchForm">
                <div class="form-group widthm100">
                  <input class="form-control" formControlName="fNameControl" placeholder="First Name">
                </div>
                <div class="form-group widthm100">
                  <input class="form-control" placeholder="Last Name" formControlName="lNameControl">
                </div>
                <div class="form-group widthm100">
                  <input class="form-control" formControlName="emailControl" placeholder="Email Address">
                </div>
                <div class="form-group widthm100">
                  <input class="form-control" formControlName="pNumberControl" placeholder="Mobile Number">
                </div>
                <button type="submit" class="btn btn-default search-submit">Search <i class="fa fa-search"
                    aria-hidden="true"></i></button>
              </form>
            </div>
            <div class="normal-search-container" *ngIf="normalSection">
              <form class="form-inline search-form2 desktop-flex " (submit)="searchContacts(1)">
                <div class="form-group row width100">
                  <div class="col-md-10">
                    <input class="form-control" [(ngModel)]="searchTerm" [ngModelOptions]="{standalone: true}"
                      placeholder="Enter text here to search">
                  </div>
                  <div class="col-md-2">
                    <button type="submit" class="btn btn-default search-submit searchere">Search <i class="fa fa-search"
                        aria-hidden="true"></i></button>
                  </div>
                </div>
              </form>
            </div>
            <!-- Success message when request send to searched person -->
            <div>
              <div *ngIf="contactAdded" class="successMessage">
                <span>Request has been sent successfully. Details are available on <a href=""
                    [routerLink]="['/directory-dashbord-new']">Dashborad</a>. </span>
              </div>
            </div>
            <!-- Search results -->
            <div *ngIf="searchPerformed == true && searchResults && searchResults.length > 0">
              <h2 class="mobile-margin-bottom-30 searchr mediumfontsize">Search Results <span
                  class="result-found">{{searchResults.length}} result found</span>
              </h2>
              <div class="search-card"
                *ngFor="let searchResult of searchResults | paginate: { itemsPerPage: 12, currentPage: p };">
                <div class="row width100">
                  <div class="col-sm-12 col-md-2 col-lg-1 col-xl-1 cardImg">
                    <img class="card-img" src="assets/images/team/profile.png">
                  </div>
                  <div class="col-sm-12 col-md-10 col-lg-11 col-xl-11">
                    <h5 class="team-name title title-sm paddingTop0">{{searchResult.user.firstName}}
                      {{searchResult.user.lastName}}
                      <!-- &emsp;<span class="dot bg-green"></span> -->
                    </h5>
                    <div class="row  m-0">
                      <div class="col-sm-12 col-md-8">
                        <div class="row search-no-padding">
                          <div class="col-4">
                            <p class="light-heading">City</p>
                            <p class="grey">{{searchResult.user.city}}</p>
                          </div>
                          <div class="col-4">
                            <p class="light-heading">State</p>
                            <p class="grey">{{searchResult.user.state}}</p>
                          </div>
                          <div class="col-4">
                            <p class="light-heading">Country</p>
                            <p class="grey">{{searchResult.user.country}}</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-4 txt-right">
                        <div class="row">
                          <!-- <div class="col-6  ">
                            <button class="btn customBtn2 btn-md pull-right mr-2" type="button"
                              (click)="showContactCategoryOptions(searchResult.user.id)"
                              *ngIf="!contactIds?.includes(searchResult.user.id)">View Profile</button>
                            <button class="btn customBtn2 btn-md pull-right mr-2" type="button"
                              (click)="openProfile(searchResult.user, false)">View Profile</button>
                          </div> -->
                          <div class="col-12 margintopbtn">
                            <button *ngIf="!contactIds?.includes(searchResult.user.id)"
                              (click)="openModal(searchResult.user, true)" class="basicbtn viewProfilebtn">View Profile
                              &nbsp; <i class="fa fa-greater-than"></i></button>
                            <button *ngIf="contactIds?.includes(searchResult.user.id)"
                              (click)="openModal(searchResult.user, false)" class="basicbtn viewProfilebtn">View Profile
                              &nbsp; <i class="fa fa-greater-than"></i></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-12">
                  <pagination-controls (pageChange)="pagechange($event)" [hidden]="searchResults.length == 0"
                    style=" text-align: center;"></pagination-controls>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-sm-12 col-md-12 textRight">
                  <button class="basicbtn viewProfilebtn " (click)='reset()'>Reset &nbsp; <i class="fa fa-refresh"
                      aria-hidden="true"></i></button>
                </div>
              </div>
            </div>
            <!-- Defult loader -->
            <div class="zero-result mb-5" *ngIf="loadingData">
              <div class="zero-result-container">
                <img src="../../../../assets/images/loader.gif" class="no-result-img mb-2">
                <p class="mb-2">Searching Hiddin users, Please wait...</p>
                <div class="search-user">
                </div>
              </div>
            </div>

            <!-- No result found in search -->
            <div class="zero-result mb-5 mt-4"
              *ngIf="!loadingData && searchPerformed == true && searchResults && searchResults.length == 0">
              <div class="zero-result-container">
                <img src="../../../../assets/images/no-result.png" class="no-result-img mb-2">
                <h5>No result found</h5>
                <p class="mb-2">Please try with another details from search bar and start adding in your list.</p>
              </div>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
      </div>
    </div>
  </div>
</ion-content>