<ion-content overflow-scroll="true" class="content-main">
  <div id="login-popup">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <!-- <button class="modal-close" aria-label="Close" (click)="dismiss()"><em class="ti ti-close"></em></button> -->
        <div class="ath-container m-0">
          <div class="ath-body">
            <h5 class="ath-heading title">Thank You <small class="tc-default" *ngIf="user && user.isDocumentUploaded">Your registration is completed.</small><small class="tc-default" *ngIf="!user.isDocumentUploaded">Your registration is partially completed.</small>
            </h5>
            <div class="text-center">
              <img src="assets/images/check-circle.gif" width="100px" height="100px">
            </div>
            <div class="notification-content">
              <div class="info" *ngIf="user && user.isDocumentUploaded">
                You have successfully completed the registration process. Now you can access our services offered to you on your Dashboard page.
                For using Hiddin Services, you need to visit Hiddin IOT section on the Dashboard where you can add
                Devices (Mobile, iPads, Tabs etc) and these Devices will be used to manage the Hiddin Equipments
                (Television, Thermostat, Refrigerator, other IOT Equipments).
              </div>
              <div class="info" *ngIf="!user.isDocumentUploaded">
                You have partially completed registration process. To complete the registration process, you need to visit the Hiddin IOT section on the Dashboard, where you can add
                Devices (Mobile, iPads, Tabs etc). Those Devices will be used to manage the Hiddin IOT Equipments (Television, Thermostat, Refrigerator, other IOT Equipments) and their transmission data.
              </div>
              <div class="btn btn-primary btn-md mt-2" (click)="dismiss()">Ok</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>