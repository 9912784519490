import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IonContent, MenuController } from '@ionic/angular';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from 'src/app/globals';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { LoaderService } from 'src/app/services/loader.service';
import { environment } from 'src/environments/environment';
import { RegUser } from '../basic-reg/RegUser';
import * as moment from 'moment';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { CommonUtil } from 'src/app/util/CommonUtil';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  userForm: RegUser = new RegUser();

  signUpForm: FormGroup;
  submitted: boolean = false;
  errorMessage: string;

  emailAlreadyExist: boolean;
  emailMessage: string;

  passwordVisible: boolean = false;

  emailPattern = '^[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}';
  requestPath: string;

  dob: NgbDateStruct;
  dateValidation: boolean;

  user: RegUser = new RegUser();
  LoggedIn: boolean;

  redirectUrl: string;

  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.India];

  isMobileLoginEnabled = false;

  constructor(public global: Globals
    , public authService: AuthServiceService
    , public formBuilder: FormBuilder
    , public router: Router
    , private loader: LoaderService
    , private menuCtrl: MenuController
    , private route: ActivatedRoute) {
    this.requestPath = this.global.requestPath;

    this.route.queryParams.subscribe(params => {
      this.redirectUrl = params['redirectUrl'];
    });
  }

  ngOnInit() {
    this.signUpForm = this.formBuilder.group({
      emailAddress: ['', [Validators.required, Validators.maxLength(50), Validators.pattern(this.emailPattern)]],
      userPassword: ['', [Validators.required, Validators.minLength(6)]],
      phoneNumber: ['', [Validators.required, this.noWhitespace]]
    });
  }

  public noWhitespace(control: FormControl) {
    let isWhitespace = (control.value || '').toString().trim().length === 0;
    let isValid = !isWhitespace;
    return isValid ? null : { 'required': true }
  }

  ionViewDidEnter() {
    this.initUser();
    this.onReset();
    this.user = this.authService.getLoggedInUser();
    this.LoggedIn = this.authService.isLoggedIn();
    if (!this.global.isEmpty(this.user)) {
      this.user.imageUrl = this.authService.getProfilePicture();
    }
  }

  initUser = function () {
    this.signUpForm.reset();
    this.userForm = new RegUser();
  }

  // convenience getter for easy access to form fields
  get f() { return this.signUpForm.controls; }

  public login() {
    this.submitted = true;
    this.errorMessage = null;

    // stop here if form is invalid
    // if (this.signUpForm.invalid) {
    //   // console.log("Invalid: " + this.signUpForm.value);
    //   return;
    // }



    if (this.signUpForm.controls["userPassword"].status == "INVALID") {
      return;
    }

    if (this.isMobileLoginEnabled && this.signUpForm.controls["phoneNumber"].status == "INVALID") {
      return;
    }

    if (!this.isMobileLoginEnabled && this.signUpForm.controls["emailAddress"].status == "INVALID") {
      return;
    }

    this.userForm.phoneDialCode = this.userForm.phoneNumberAll?.dialCode;
    this.userForm.phoneCountryShortName = this.userForm.phoneNumberAll?.countryCode;
    this.userForm.phoneNumber = this.userForm.phoneNumberAll?.e164Number.replace(this.userForm.phoneNumberAll?.dialCode, "");

    this.loader.show("Please wait...");
    this.authService.loginOld(this.userForm.emailAddress, this.userForm.phoneNumber, this.userForm.userPassword)
      .subscribe(res => {
        this.loader.hide();
        // console.log(res);
        if (res.success) {

          if (environment.isSubscriptionRequired && res.statusCode == 1001) {
            // Subscription expired
            this.global.saveLocalData(this.global.SUBSCRIPTION, JSON.stringify(true));
          } else {
            this.global.saveLocalData(this.global.SUBSCRIPTION, JSON.stringify(false));
          }

          res.data = this.global.decryptDefault(res.data);
          this.global.saveLocalData(this.global.HIDDIN_TOKEN, JSON.stringify(res.data.access_token));
          this.global.saveLocalData(this.global.BOGUS_KEY, JSON.stringify(res.data.key));
          // console.log('Successfully Logged-In');
          this.getMyProfile();
          this.onReset();
          this.initUser();
        } else {
          this.errorMessage = res.message;
        }
      }, error => {
        this.loader.hide();
        // console.log(error);
        this.errorMessage = "Something went wrong, please try again.";
      });
  }

  getMyProfile = function () {
    this.loader.show("Fetching user details...");
    this.authService.get('user?' + this.requestPath + '=getLoginUserDetail')
      .subscribe((res) => {
        this.loader.hide();
        // console.log(res);
        if (res.success) {
          this.user = this.global.decryptDefault(res.data);
          this.user.birthDate = moment(this.user.birthDate).format("MM/DD/YYYY");
          this.global.saveLocalData(this.global.HIDDIN_USER_DETAILS, JSON.stringify(this.user));
          this.getImageUrl();
        } else {
          // console.log(res.message);
          this.errorMessage = res.message;
        }
      }, error => {
        this.loader.hide();
        // console.log(error);
        this.errorMessage = "Error occured";
      });
  }

  getImageUrl = function () {
    this.loader.show("Please wait...");
    this.authService.get('image?' + this.requestPath + '=getImage&type=profilepic')
      .subscribe((res) => {
        this.loader.hide();
        // console.log(res);
        if (res.success) {
          res.data = this.global.decrypt(res.data);
          if (!this.global.isEmpty(res.data)) {
            this.global.saveLocalData(this.global.USER_IMAGE_URL, JSON.parse(res.data).imageUrl);
          }
        } else {
          this.errorMessage = res.message;
        }
        if (!this.global.isEmpty(this.redirectUrl)) {
          window.location.href = this.redirectUrl;
        } else {
          this.router.navigate(['/dashboard']);
        }
      }, error => {
        this.loader.hide();
        // console.log(error);
        this.errorMessage = "Error occured, please try again.";
      });
  }

  onReset = function () {
    this.submitted = false;
    this.signUpForm.reset();
    this.errorMessage = null;
    this.isMobileLoginEnabled = false;
  }

  togglePassword(id: string) {
    const password = document.querySelector('#' + id);
    const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
    password.setAttribute('type', type);

    this.passwordVisible = !this.passwordVisible;


  }

  toggleLoginMethod() {
    this.isMobileLoginEnabled = !this.isMobileLoginEnabled;
  }

}
