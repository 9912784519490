import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { AuthServiceService } from '@services/auth-service.service';
import { LoaderService } from '@services/loader.service';
import { Globals } from 'src/app/globals';
import { RegUser } from '../../basic-reg/RegUser';
import { AlertController } from '@ionic/angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MathcesCategoryPipe } from 'src/app/pipe/matchesCategory.pipe';
import { ContactCategory } from 'src/app/constants/ContactCategory';

@Component({
  selector: 'app-directory-dashbord-new',
  templateUrl: './directory-dashbord-new.component.html',
  styleUrls: ['./directory-dashbord-new.component.scss', '../../../../assets/css/style.scss'],
  providers: [MathcesCategoryPipe]
})
export class DirectoryDashbordNewComponent implements OnInit {

  errorMessage: string;
  requestPath: string;
  lastLoadedPage: number = 1;
  user: RegUser = new RegUser();
  ownerId: number;
  profileImageSrc: any;
  contactIds: number[] = [];
  noDataFound = true;
  loadingData = false;

  userContacts: [] = [];
  requestedContacts: [] = [];
  pendingRequests: [] = [];

  p: number = 1;

  // Contact Category
  all: string = ContactCategory.ALL;
  friends: string = ContactCategory.FRIENDS;
  work: string = ContactCategory.WORK;
  family: string = ContactCategory.FAMILY;
  others: string = ContactCategory.OTHERS;
  favorite: string = ContactCategory.FAVOURITE;

  selectedCategory: string = this.all;

  constructor(public global: Globals,
    public authService: AuthServiceService,
    public modalController: ModalController,
    public formBuilder: FormBuilder,
    public router: Router,
    private loader: LoaderService,
    public atrCtrl: AlertController,
    private modalService: NgbModal,
    private matchesCategory: MathcesCategoryPipe) {
    this.requestPath = this.global.requestPath;
  }

  ngOnInit() {
    // var refresh = this.router.getCurrentNavigation().extras.state.refresh;
  }

  ionViewDidEnter() {
    this.resetInputs();
    this.ownerId = this.authService.getLoggedInUser().id;

    this.user = this.authService.getLoggedInUser();
    if (this.global.isEmpty(this.user)) {
      this.getMyProfile();
    } else {
      this.profileImageSrc = this.authService.getProfilePicture();
      this.user.imageUrl = this.profileImageSrc;
    }
    this.getUserContacts();
  }

  getMyProfile = function () {
    this.authService.get('user?' + this.requestPath + '=getUserDetail')
      .subscribe((res: any) => {
        this.loader.hide();
        if (res.success) {
          // Update user data
          this.user = this.global.decrypt(res.data);;
          this.user.imageUrl = this.profileImageSrc;
          this.ownerId = this.user.id;

          // Save data in local storage
          this.global.saveLocalData(this.global.HIDDIN_USER_DETAILS, JSON.stringify(this.user));
        } else {
          this.errorMessage = res.message;
        }
      }, (error: any) => {
        this.loader.hide();
        this.errorMessage = "Something went wrong, please try again.";
      });
  }

  getUserContacts = function () {
    this.loadingData = true;
    this.authService.get('user/directory?' + this.requestPath + '=list')
      .subscribe((res: any) => {
        this.loadingData = false;
        if (res.success) {
          this.userContacts = this.global.decrypt(res.data);
          if (this.userContacts != null && this.userContacts.length > 0) {
            this.noDataFound = false;
            this.contactIds = this.userContacts.map(function (contact: any) { return contact.contactUser.id; });
            this.contactIds.push(this.ownerId);
            for (var contact of this.userContacts) {
              if (contact.ownerId == this.ownerId) {
                contact.category = contact.senderCategory;
              } else {
                contact.category = contact.recieverCategory;
              }
            }
          }
        } else {
          this.errorMessage = res.message;
        }
      }, (error: any) => {
        this.loadingData = false;
        this.errorMessage = "Something went wrong, please try again.";
      });
  }

  markFavorite(userContact: any, isFavorite: boolean) {
    userContact.isFavorite = isFavorite;
    this.loader.show("Please wait...");
    this.authService.postEncrypt('user/directory?' + this.requestPath + '=save', userContact)
      .subscribe((res: any) => {
        this.loader.hide();
        if (res.success) {
          // Reinitialized the form state
          this.getUserContacts();
        } else {
          this.errorMessage = res.message;
        }
      }, (error: any) => {
        this.loader.hide();
        this.errorMessage = "Something went wrong, please try again.";
      });
  }

  pendingTab() {
    this.router.navigateByUrl('/pending-request-new');
    return false;

  }
  open(content) {
    this.modalService.open(content);
  }

  close(content) {
    this.modalService.dismissAll(content);
  }

  resetInputs() {
    this.errorMessage = null;
  }

  goTo(link: string) {
    this.router.navigateByUrl(link)
  }

  convertTime(time) {
    if (!time) {
      return '';
    }
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }

  pagechange = function (event: any) {
    // console.log(event);
    this.p = event;
    if ((event >= ((5 * this.lastLoadedPage) - 1))) {
      this.searchContacts(this.lastLoadedPage + 1);
    }
  }

  changeTab(tabName) {
    this.selectedCategory = tabName;
    var filterData = null;
    if (tabName == this.all) {
      filterData = this.userContacts;
    } else {
      if (tabName == this.favorite) {
        filterData = this.userContacts.filter((contact: any) => { return contact.isFavorite === true; });
      } else {
        filterData = this.userContacts.filter((contact: any) => { return contact.category === tabName; });
      }
    }
    if (filterData != null && filterData.length > 0) {
      this.noDataFound = false;
    } else if (filterData.length == 0) {
      this.noDataFound = true;
    }
  }

  goToSearch() {
    this.router.navigate(["search-new"]);
  }

  openProfile(userContact: any, user: any, editEnabled: boolean, isSearchResult: boolean) {
    this.router.navigateByUrl('/profile-details-new', { state: { 'userContact': userContact, 'user': user, 'editEnabled': editEnabled, 'addEnabled': !this.contactIds.includes(user.id), 'isSearchResult': isSearchResult, 'viewTimeSlot': true } });
    return false;
  }

}
