export class SecurityQuestions {

    constructor() {
        this.question1 = null;
        this.question2 = null;
        this.question3 = null;
        this.question4 = null;
    }
    
    question1: string;
    question2: string;
    question3: string;
    question4: string;
    answer1: string;
    answer2: string;
    answer3: string;
    answer4: string;
    verificationCode: string;
    userId: number;
}