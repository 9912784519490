import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { AuthServiceService } from '@services/auth-service.service';
import { LoaderService } from '@services/loader.service';
import { Globals } from 'src/app/globals';
import { AlertController } from '@ionic/angular';
import { RegUser } from '../../basic-reg/RegUser';

import { ModalController } from '@ionic/angular';
import { ContactSettingComponent } from '@modal/contact-setting/contact-setting.component';


@Component({
  selector: 'app-profile-details',
  templateUrl: './profile-details.component.html',
  styleUrls: ['./profile-details.component.scss'],
})
export class ProfileDetailsComponent implements OnInit {
  ownerId: number;
  user: any;
  userForm: RegUser = new RegUser();
  currentUser: RegUser = new RegUser();
  editEnabled: boolean = false;
  contactAdded: boolean = false;
  addEnabled: boolean = false;
  isSearchResult: boolean = false;
  viewTimeSlot: boolean;
  errorMessage: string;
  requestPath: string;

  contactId: number;
  contactCategory: string = 'friends';
  contactTimePreference: any;
  userContact;

  constructor(public global: Globals,
    private location: Location,
    public router: Router,
    public authService: AuthServiceService,
    private loader: LoaderService,
    public modlCtrl: ModalController,
    public atrCtrl: AlertController) {
    this.requestPath = this.global.requestPath;
  }

  ngOnInit() {
    if (this.global.isEmpty(this.router.getCurrentNavigation().extras.state)) {
      this.router.navigate(['dashboard']);
    }
    this.editEnabled = this.router.getCurrentNavigation().extras.state.editEnabled;
    this.addEnabled = this.router.getCurrentNavigation().extras.state.addEnabled;
    this.user = this.router.getCurrentNavigation().extras.state.user;
    this.isSearchResult = this.router.getCurrentNavigation().extras.state.isSearchResult;
    this.userContact = this.router.getCurrentNavigation().extras.state.userContact;
    this.viewTimeSlot = this.router.getCurrentNavigation().extras.state.viewTimeSlot;
  }

  ionViewDidEnter() {
    this.ownerId = this.authService.getLoggedInUser().id;
    this.currentUser = this.authService.getLoggedInUser();
    this.currentUser.imageUrl = this.authService.getProfilePicture();
  }

  goBack() {
    this.location.back();
  }

  goTo(link: string) {
    this.router.navigateByUrl(link)
  }

  async openSettingModal() {
    const modal = await this.modlCtrl.create({
      component: ContactSettingComponent,
      componentProps: {
        user: this.userContact,
        personalizedMsg: this.userContact.personalizedMsg,
        isSender: false,
        isAddButtonVisible: false,
        viewMode: true
      }
    });

    modal.onDidDismiss().then((dataReturned) => {
      // this.router.navigateByUrl("dashboard");
    });

    modal.present();
  }
}
