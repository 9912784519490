import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { RegAlertComponent } from '@modal/reg-alert/reg-alert.component';
import { AuthServiceService } from '@services/auth-service.service';
import { Globals } from 'src/app/globals';
import { environment } from 'src/environments/environment';
import { RegUser } from '../basic-reg/RegUser';
import { appList } from './dashboard';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.page.html',
  styleUrls: ['./dashboard.page.scss'],
})
export class DashboardPage implements OnInit {

  user: RegUser = new RegUser();

  apps: appList[] = [
    {
      name: 'Hiddin Profile',
      imageSrc: 'assets/images/partners/hiddin-covid.png',
      link: 'goToHiddinProfile',
      desc: 'Complete the registration process and Manage your Hiddin Profile',
      info: 'Through this user can manage his/her profile, enrich the details and security questions and upgrade to business type.'
    },
    {
      name: 'Hiddin Platform',
      imageSrc: 'assets/images/partners/hiddin-covid.png',
      link: 'goToHiddinCore',
      desc: 'Hiddin Plateform for managing Hiddin Equipments and IOT Devices',
      info: 'Through this user can add their devices by which user can manage their equipments (Thermostat, Television and other IOT equipments).'
    },
    {
      name: 'Hiddin Directory',
      imageSrc: 'assets/images/partners/hiddin-covid.png',
      link: 'goToDirectory',
      desc: 'To find your friends, colleagues and anyone already registered on the platform',
      info: 'It helps the user to search the data or an information from our server or outside of it exist in whole world.'
    },
    {
      name: 'Hiddin Messenger',
      imageSrc: 'assets/images/messaging.jpg',
      link: 'goToCommunication',
      desc: 'A complete and secure solution for all your communication needs including messaging and calling',
      info: 'Hiddin provide the highly secured communication system for the user. So user confidential information remain safe in this dark world.'
    },
    {
      name: 'Hiddin Email',
      imageSrc: 'assets/images/mail-server.png',
      link: 'goToEmail',
      desc: 'A fully secured solution email communication on Hiddin Platform and still allowing external emails',
      info: 'Hiddin provide the highly secured email system for the user. So user confidential information remain safe in this dark world.'
    },
    {
      name: 'Hiddin File Share',
      imageSrc: 'assets/images/share-drive.jpg',
      link: 'goToShare',
      desc: 'A fully secured solution share files and media with Hiddin Platform users',
      info: 'Hiddin provide the highly secured file and media sharing system for the user. So user confidential information remain safe in this dark world.'
    },
    {
      name: 'Hiddin IOT Simulation',
      imageSrc: 'assets/images/partners/hiddin-covid.png',
      link: 'goToHiddinSimulation',
      desc: 'A Place where we can see the actual IOT Equipment simulation',
      info: 'Hiddin Simulator gives the pictorial overview of the data flow in between user Equipments and Hiddin server in a real-time.'
    },
    {
      name: 'Hiddin Scheduler',
      imageSrc: 'assets/images/scheduler.png',
      link: 'goToSchedulaer',
      desc: 'Hiddin Scheduler for booking services',
      info: 'We provide a service where user can request for booking and Business can provide confirmation on booking like booking in hotel, restaurants etc.'
    },
    {
      name: 'My MD Doctor',
      imageSrc: 'assets/images/partners/mymd-color.png',
      link: 'goToMMD',
      desc: 'To contact Dr. Riscile\'s office',
      info: ' My MD Doctor offers all possible medical solutions and even more like booking appointments with doctor easily and managing your heath record.'
    },
  ]

  constructor(private authService: AuthServiceService,
    private global: Globals,
    private router: Router,
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {

  }

  goTo(link: string) {
    // console.log("link: " + link);
    this[link]();
  }

  ionViewDidEnter() {
    this.user = this.authService.getLoggedInUser();
    this.user.imageUrl = this.authService.getProfilePicture();

    if (!this.authService.isLoggedIn()) {
      this.router.navigate(['home']);
    }
  }

  goToMMD() {
    var url = 'https://www.mymddoctor.com/';
    this.goToSite(url);
  }
  
  goToHiddinProfile(){
    var url = environment.appBaseUrl + 'profile';
    // this.goToSite(url);
    window.open(url, "_blank");
  }

  goToHiddinCore() {
    var url = environment.appBaseUrl + "core/add-device";
    this.goToSite(url);
  }

  goToHiddinSimulation() {
    var url = environment.appBaseUrl + "core/simulator/demo";
    window.open(url, "_blank");
    // this.goToSite(url);
  }

  goToSchedulaer() {
    var url = 'scheduler/';
    this.goToSite(url);
  }

  goToCommunication() {
    var url = 'http://chat.vteam.live?token=' + this.authService.getToken();
    // this.goToSite(url);
    window.open(url, "_blank");
  }

  goToEmail() {
    var url = 'https://mail.vteam.live?token=' + this.authService.getToken();
    // this.goToSite(url);
    window.open(url, "_blank");
  }

  goToShare(){
    var url = 'https://vteam.live/share';
    // this.goToSite(url);
    window.open(url, "_blank");
  }

  goToDirectory() {
    var url = environment.appBaseUrl + 'directory-dashbord-new';
    // this.goToSite(url);
    window.open(url, "_blank");
    //this.router.navigate(['/directory-dashbord']);
  }
  
  dismiss() {
  }

  goToSite(url: string) {
    if (this.user.roleLevel == 2) {
      // Demo User
      this.openCompleteRegModal();
    } else if (this.user.roleLevel == 1 && (this.user.parentId === null || this.user.parentId === 0)) {
      // Individual User
      if (this.global.isEmpty(this.user.isDocumentUploaded) || this.user.isDocumentUploaded == false) {
        this.openCompleteRegModal();
      } else {
        window.open(url, "_blank");
      }
    } else if (this.user.roleLevel == 0) {
      if (this.global.isEmpty(this.user.isDocumentUploaded) || this.user.isDocumentUploaded == false) {
        this.openCompleteRegModal();
      } else {
        window.open(url, "_blank");
      }
    }
    else if (this.user.roleLevel === 3 && (this.user.parentId !== null && this.user.parentId !== 0)) {
      // Employee
      if (this.global.isEmpty(this.user.isSeqQueUploaded) || this.user.isSeqQueUploaded == false) {
        this.openCompleteRegModal();
      } else {
        window.open(url, "_blank");
      }
    } else if ((this.user.roleLevel === 4 || this.user.roleLevel === 5) && (this.user.parentId !== null && this.user.parentId !== 0)) {
      // Relative Admin/User
      if (this.global.isEmpty(this.user.isSeqQueUploaded) || this.user.isSeqQueUploaded == false) {
        this.openCompleteRegModal();
      } else {
        window.open(url, "_blank");
      }
    } else {
      // console.log("Owner type not detected, navigate to error");
    }
  }

  async openCompleteRegModal() {
    const modal = await this.modalCtrl.create({
      component: RegAlertComponent,
      componentProps: {
      }
    });

    return await modal.present();
  }

}
