import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonContent, MenuController } from '@ionic/angular';
import { AuthServiceService } from '@services/auth-service.service';
import { Globals } from 'src/app/globals';
import { RegUser } from 'src/app/pages/basic-reg/RegUser';

@Component({
  selector: 'app-header-left-menu',
  templateUrl: './header-left-menu.component.html',
  styleUrls: ['./header-left-menu.component.scss']
})
export class HeaderLeftMenuComponent implements OnInit {

  @Input() user: RegUser;
  @Input() content;
  // @ViewChild(IonContent) content: IonContent;
  @ViewChild('target') target: any;

  LoggedIn: boolean;
  isHome: boolean = false;
  isRegCompleted: boolean;
  
  constructor(private authSvc:AuthServiceService, 
    private menuCtrl: MenuController,
    private router: Router,
    private global: Globals) { }

  ngOnInit() {
    this.LoggedIn = this.authSvc.isLoggedIn(); 
    if(this.router.url === '/home' || '' ) {
      this.isHome = true;
    }
    this.checkForCompleteReg();
  }

  checkForCompleteReg() {
    if (this.user && ((this.user.type == 'Individual' && !this.global.isEmpty(this.user.parentId)
      && this.user.parentId != 0 && !this.global.isEmpty(this.user.isSeqQueUploaded)
      && this.user.isSeqQueUploaded) || (!this.global.isEmpty(this.user.isDocumentUploaded) && this.user.isDocumentUploaded))) {
      this.isRegCompleted = true;
    } else {
      this.isRegCompleted = false;
    }
  }

  scrollTo(id: string) {
    const target = document.querySelector('#' + id) as HTMLDivElement;

    this.content.scrollToPoint(0, target.offsetTop + (target.offsetHeight/0.9), 1000)
    this.menuCtrl.close();
  }

  logout() {
    this.authSvc.logout();
    this.router.navigate(['/home']);
  }



}
