import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { ContactSettingComponent } from '@modal/contact-setting/contact-setting.component';
import { MessagesPopupComponent } from '@modal/messages-popup/messages-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthServiceService } from '@services/auth-service.service';
import { LoaderService } from '@services/loader.service';
import { Globals } from 'src/app/globals';
import { RegUser } from '../../basic-reg/RegUser';

@Component({
  selector: 'app-pending-request-new',
  templateUrl: './pending-request-new.component.html',
  styleUrls: ['./pending-request-new.component.scss', '../../../../assets/css/style.scss'],
})
export class PendingRequestNewComponent implements OnInit {
  allRequestVar: boolean = true;
  allPendingVar: boolean = false;
  requestVar: boolean = false;
  loadingPendingData = false;
  loadingRequestedData = false;
  errorMessage: string;
  successMessage: string;
  requestPath: string;
  user: RegUser = new RegUser();
  profileImageSrc;
  LoggedIn: boolean;

  requestedContacts: [] = [];
  pendingRequests: [] = [];
  contactIds: number[] = [];

  noDataFound = true;

  constructor(public global: Globals,
    public authService: AuthServiceService,
    public modalController: ModalController,
    public formBuilder: FormBuilder,
    public router: Router,
    private loader: LoaderService,
    public atrCtrl: AlertController,
    private modalService: NgbModal) {
    this.requestPath = this.global.requestPath;
  }

  ngOnInit() { }

  ionViewDidEnter() {
    this.LoggedIn = this.authService.isLoggedIn();
    this.user = this.authService.getLoggedInUser();
    this.profileImageSrc = this.authService.getProfilePicture();
    this.user.imageUrl = this.profileImageSrc;

    this.errorMessage = null;
    // this.noDataFound = false;

    this.getPendingRequestWithMe();
    this.getRequestedContacts();
  }

  getRequestedContacts = function () {
    this.errorMessage = null;
    this.requestedContacts = [];
    this.loadingRequestedData = true;
    this.authService.get('user/directory?' + this.requestPath + '=requestedList')
      .subscribe((res: any) => {
        this.loadingRequestedData = false;
        if (res.success) {
          this.requestedContacts = this.global.decrypt(res.data);
          if (!this.global.isEmpty(this.requestedContacts)) {
            this.noDataFound = false;
            for (var i = 0; i < this.requestedContacts.length; i++) {
              this.contactIds.push(this.requestedContacts[i].contactUser.id);
            }
          }
        } else {
          this.errorMessage = res.message;
          this.noDataFound = true;
        }
      }, (error: any) => {
        this.loadingRequestedData = false;
        this.errorMessage = "Something went wrong, please try again.";
      });
  }

  getPendingRequestWithMe = function () {
    this.errorMessage = null;
    this.pendingRequests = [];
    this.loadingPendingData = true;
    this.authService.get('user/directory?' + this.requestPath + '=pendingWithMeList')
      .subscribe((res: any) => {
        this.loadingPendingData = false;
        if (res.success) {
          this.pendingRequests = this.global.decrypt(res.data);
          if (this.pendingRequests != null && this.pendingRequests.length > 0) {
            this.noDataFound = false;
          }
        } else {
          this.errorMessage = res.message;
          this.noDataFound = true;
        }
      }, (error: any) => {
        this.loadingPendingData = false;
        this.errorMessage = "Something went wrong, please try again.";
      });
  }

  openProfile(userContact: any, user: any, editEnabled: boolean, isSearchResult: boolean) {
    this.router.navigateByUrl('/profile-details', { state: { 'userContact': userContact, 'user': user, 'editEnabled': editEnabled, 'addEnabled': !this.contactIds.includes(user.id), 'isSearchResult': isSearchResult } });
    return false;
  }

  rejectContact(userContact: any) {
    this.errorMessage = null;
    userContact.status = "Rejected";
    this.loader.show("Please wait...");
    this.authService.postEncrypt('user/directory?' + this.requestPath + '=save', userContact)
      .subscribe((res: any) => {
        this.loader.hide();
        if (res.success) {
          // Reinitialized the form state
          this.getPendingRequestWithMe();
        } else {
          this.errorMessage = res.message;
        }
      }, (error: any) => {
        this.loader.hide();
        this.errorMessage = "Something went wrong, please try again.";
      });
  }

  cancelContact(userContact: any) {
    this.errorMessage = null;
    userContact.status = "Canceled";
    this.loader.show("Please wait...");
    this.authService.postEncrypt('user/directory?' + this.requestPath + '=save', userContact)
      .subscribe((res: any) => {
        this.loader.hide();
        if (res.success) {
          // Reinitialized the form state
          this.getRequestedContacts();
        } else {
          this.errorMessage = res.message;
        }
      }, (error: any) => {
        this.loader.hide();
        this.errorMessage = "Something went wrong, please try again.";
      });
  }

  approveContact(userContact: any) {
    this.errorMessage = null;
    userContact.status = "Confirmed";
    // console.log(userContact);
    this.loader.show("Please wait...");
    this.authService.postEncrypt('user/directory?' + this.requestPath + '=save', userContact)
      .subscribe((res: any) => {
        this.loader.hide();
        if (res.success) {
          this.dismiss('OK');
          this.gotoDashboard();
        } else {
          this.errorMessage = res.message;
        }
      }, (error: any) => {
        this.loader.hide();
        this.errorMessage = "Something went wrong, please try again.";
      });
    return false;
  }

  async dismiss(data) {
    this.modalController.dismiss(data);
  }

  async openSettingModal(contactUser, user) {
    const modal = await this.modalController.create({
      component: ContactSettingComponent,
      componentProps: {
        user: user,
        personalizedMsg: '',
        isSender: false
      }
    });

    modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned !== null) {
        var updatedData = dataReturned.data;
        if (!this.global.isEmpty(updatedData)) {
          contactUser.recieverStartTime = updatedData.recieverStartTime;
          contactUser.recieverEndTime = updatedData.recieverEndTime;
          contactUser.recieverPrefTime = updatedData.recieverPrefTime;
          contactUser.recieverCommChanel = updatedData.recieverCommChanel;
          contactUser.recieverCategory = updatedData.recieverCategory;
          this.approveContact(contactUser);
        }
      }
    });

    modal.present();
  }

  async messagespopup(msg) {
    // console.log(msg);
    const modal = await this.modalController.create({
      component: MessagesPopupComponent,
      componentProps: {
        personalizedMsg: msg
      }
    });

    return await modal.present();
  }

  allRequest() {
    this.allPendingVar = false;
    this.allRequestVar = true;
    this.requestVar = false;
    if (this.pendingRequests == null || this.pendingRequests.length == 0 || this.requestedContacts == null || this.requestedContacts.length == 0) {
      this.noDataFound = true;
    } else {
      this.noDataFound = false;
    }
  }
  pendingRequest() {
    this.allPendingVar = true;
    this.allRequestVar = false;
    this.requestVar = false;
    if (this.pendingRequests == null || this.pendingRequests.length == 0) {
      this.noDataFound = true;
    } else {
      this.noDataFound = false;
    }
  }

  requested() {
    this.allPendingVar = false;
    this.allRequestVar = false;
    this.requestVar = true;
    if (this.requestedContacts == null || this.requestedContacts.length == 0) {
      this.noDataFound = true;
    } else {
      this.noDataFound = false;
    }
  }

  gotoDashboard() {
    this.router.navigateByUrl("directory-dashbord-new", { state: { 'refresh': true}});
  }

}
