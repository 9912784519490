<ion-content class="content-main">
    <div id="login-popup">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <button class="modal-close" aria-label="Close" (click)="dismiss('')"><em
                        class="ti ti-close"></em></button>
                <div class="ath-container m-0">
                    <div class="ath-body">
                        <h5 class="ath-heading title"><small class="tc-default">Affiliation for Directory</small></h5>
                        <form [formGroup]="userDirectoryForm" (ngSubmit)="saveDetails()">
                            <!-- This is defaulted to 9999 in the ts file-->
                            <!-- <div class="field-item">
                                <div class="field-wrap">
                                    <h6 class="title title-xs">Type<span class="red">*</span></h6>
                                    <select formControlName="useType" name="userDirectoryType"
                                        [(ngModel)]="userData.relationshipId" class="input-bordered"
                                        [ngClass]="{ 'is-invalid': submitted && dirDetail.useType.errors }"
                                        placeholder="Please seclect Type">
                                        <option value="undefined" selected disabled>Select Type</option>
                                        <option *ngFor="let rel of relationList" [value]="rel.customField1">{{rel.name}}</option>
                                    </select>
                                    <div *ngIf="submitted && dirDetail.useType.errors" class="invalid-feedback">
                                        <div *ngIf="dirDetail.useType.errors.required">Type is required</div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="field-item">
                                <div class="field-wrap">
                                    <h6 class="title title-xs">Organization Name<span class="red">*</span></h6>
                                    <input type="text" class="input-bordered" formControlName="firstName"
                                        [ngClass]="{ 'is-invalid': submitted && dirDetail.firstName.errors }"
                                        placeholder="Enter Organization Name" [(ngModel)]="userData.firstName"/>
                                    <div *ngIf="submitted && dirDetail.firstName.errors" class="invalid-feedback">
                                        <div *ngIf="dirDetail.firstName.errors.required">Organization Name is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="field-item">
                                <div class="field-wrap">
                                    <h6 class="title title-xs">Place<span class="red">*</span></h6>
                                    <input type="text" class="input-bordered" formControlName="lastName"
                                        [ngClass]="{ 'is-invalid': submitted && dirDetail.lastName.errors }"
                                        placeholder="Enter Place" [(ngModel)]="userData.lastName"/>
                                    <div *ngIf="submitted && dirDetail.lastName.errors" class="invalid-feedback">
                                        <div *ngIf="dirDetail.lastName.errors.required">Place is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="field-item">
                                <!-- <div class="field-wrap">
                                    <h6 class="title title-xs">Mobile No<span class="red">*</span></h6>
                                    <input type="number" class="input-bordered" formControlName="mob"
                                        [ngClass]="{ 'is-invalid': submitted && dirDetail.mob.errors }"
                                        placeholder="Enter MOB Number" [(ngModel)]="userData.phoneNumber">
                                    <div *ngIf="submitted && dirDetail.mob.errors" class="invalid-feedback">
                                        <div *ngIf="dirDetail.mob.errors.required">Mobile No. is required</div>
                                        <div *ngIf="dirDetail.mob.errors?.pattern">Mobile No. should be 10 digits in
                                            length.</div>
                                    </div>
                                </div> -->
                                <div class="field-wrap">
                                    <h6 class="title title-xs">Phone Number<span class="red">*</span></h6>
                                    <ngx-intl-tel-input type="text" formControlName="phoneNumber"
                                    [cssClass]="'phoneNumber'"
                                    (hasError)="hasError($event)"
                                    [preferredCountries]="preferredCountries"
                                    [enableAutoCountrySelect]="false"
                                    [enablePlaceholder]="true"
                                    [searchCountryFlag]="true"
                                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                    [selectFirstCountry]="false"
                                    [selectedCountryISO]="CountryISO.UnitedStates"
                                    [maxLength]="15"
                                    [phoneValidation]="true"
                                    [separateDialCode]="separateDialCode"
                                    [numberFormat]="PhoneNumberFormat.International"                  
                                    [(ngModel)]="userData.phoneNumberAll" autocomplete="disabled"
                                    (ngModelChange)="getNumber($event)"
                                    [ngClass]="{ 'is-invalid': submitted && dirDetail.phoneNumber.errors }" required />
                                    <div *ngIf="submitted && dirDetail.phoneNumber.errors" class="invalid-feedback">
                                      <div *ngIf="dirDetail.phoneNumber.errors.required">Phone number is required</div>
                                    </div>
                                    <div *ngIf="phoneMessage && phoneMessage.length > 0" class="mt-1">
                                      <div *ngIf="!isValidPoneNumber" class="text-danger"><i class="fa fa-close"
                                          aria-hidden="true">&nbsp;</i>
                                        <label class="invalid-feedback">{{phoneMessage}}</label>
                                      </div>
                                      <div *ngIf="isValidPoneNumber" class="text-success"><i class="fa fa-check"
                                          aria-hidden="true">&nbsp;</i>
                                        <label class="success-message">{{phoneMessage}}</label>
                                      </div>
                                    </div>
                                  </div>
                            </div>
                            <div class="field-item">
                                <div class="field-wrap">
                                    <h6 class="title title-xs">Year of Affiliation<span class="red">*</span></h6>
                                    <select formControlName="dob" name="userDirectoryType"
                                        [(ngModel)]="userData.yearOfBirth" class="input-bordered"
                                        [ngClass]="{ 'is-invalid': submitted && dirDetail.dob.errors }">
                                        <option value="undefined" selected disabled>Select Year of Affiliation</option>
                                        <option *ngFor="let rel of years" [value]="rel">{{rel}}
                                        </option>
                                    </select>
                                    <div *ngIf="submitted && dirDetail.dob.errors" class="invalid-feedback">
                                        <div *ngIf="dirDetail.dob.errors.required">Year of Affiliation is required</div>
                                        <div *ngIf="dirDetail.dob.errors?.maxlength || dirDetail.dob.errors?.minlength">
                                            Year of Affiliation should be 4 digits in length.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="btn-container mb-5">
                                <div class="btn btn-danger btn-md pull-left" (click)="userDirectoryForm.reset()">Reset
                                </div>
                                <button class="btn btn-primary btn-md pull-right" type="submit">Submit</button>
                            </div>
                        </form>

                    </div>

                </div>
            </div>
        </div>
    </div>
</ion-content>