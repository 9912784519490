import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-complete-reg-confirmation',
  templateUrl: './complete-reg-confirmation.component.html',
  styleUrls: ['./complete-reg-confirmation.component.scss']
})
export class CompleteRegConfirmationComponent {

  constructor(private modalCtrl:ModalController, private router: Router) {}

  async dismiss() {
    this.modalCtrl.dismiss();
  }

  goToCompleteReg() {
    this.router.navigate(['../complete-reg']);
    this.dismiss();
  }


}
