<ion-content class="content-main">
  <div id="login-popup">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <button class="modal-close" aria-label="Close" (click)="dismiss()"><em class="ti ti-close"></em></button>
        <div class="ath-container m-0">
          <div class="ath-body">
            <h5 class="ath-heading title">Upgrade to Business<small class="tc-default">Fill in the form below to upgrade
                profile from Individual to Business</small></h5>
            <form [formGroup]="regForm">
              <div class="field-item">
                <div class="field-wrap">
                  <h6 class="title title-xs">User Name<span class="red">*</span></h6>
                  <input type="text" class="input-bordered" [value]="signupInput.username" disabled
                    placeholder="Enter User Name" required>
                </div>
              </div>
              <div class="field-item">
                <div class="field-wrap">
                  <h6 class="title title-xs">Email ID<span class="red">*</span></h6>
                  <input type="email" class="input-bordered" [value]="signupInput.email" placeholder="Email ID"
                    disabled>
                </div>
              </div>
              <div class="field-item">
                <div class="field-wrap">
                  <h6 class="title title-xs">Phone Number<span class="red">*</span></h6>
                  <input type="text" id="phone" class="input-bordered" [value]="signupInput.phoneNumber"
                    placeholder="Phone Number" disabled />
                </div>
              </div>
              <div class="field-item">
                <div class="field-wrap">
                  <h6 class="title title-xs">Contact Person First Name<span class="red">*</span></h6>
                  <input type="text" formControlName="firstName" class="input-bordered"
                    [(ngModel)]="signupInput.firstName" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }"
                    placeholder="Enter First Name" required>
                  <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                    <div *ngIf="f.firstName.errors.required">First Name is required
                    </div>
                  </div>
                </div>
              </div>
              <div class="field-item">
                <div class="field-wrap">
                  <h6 class="title title-xs">Contact Person Last Name<span class="red">*</span></h6>
                  <input type="text" formControlName="lastName" class="input-bordered"
                    [(ngModel)]="signupInput.lastName" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }"
                    placeholder="Last Name" required>
                  <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                    <div *ngIf="f.lastName.errors.required">Last Name is required
                    </div>
                  </div>
                </div>
              </div>
              <div class="field-item">
                <div class="field-wrap">
                  <h6 class="title title-xs">Company Name<span class="red">*</span></h6>
                  <input type="text" class="input-bordered" formControlName="company" class="input-bordered"
                    [(ngModel)]="signupInput.company" [ngClass]="{ 'is-invalid': submitted && f.company.errors }"
                    placeholder="Your Company Name" required>
                  <div *ngIf="submitted && f.company.errors" class="invalid-feedback">
                    <div *ngIf="f.company.errors.required">Company Name is required</div>
                    <div *ngIf="f.company.errors?.maxlength">Company Name can be max 100 characters long.</div>
                  </div>
                </div>
              </div>
            </form>
            <div class="invalid-feedback text-align-center mb-2" *ngIf="errorMessage && errorMessage.length > 0">
              {{errorMessage}}
            </div>
            <div class="btn-container">
              <div class="btn btn-danger btn-md ml-2" (click)="onReset()">Reset</div>
              <div class="btn btn-primary btn-md ml-2" (click)="checkEquipmentRegistered()">Upgrade</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>