import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { throwError } from 'rxjs';
import { EncryptionUtil } from './util/EncryptionUtil';
import { AppConfig } from '@services/AppConfig';
import { AppPropService } from '@services/app-prop.service';
import { LocalStorageService} from 'ngx-localstorage';

@Injectable()
export class Globals {
  public authenticated: boolean = false;
  public loggingEnabled: boolean = true;
  public authtoken: string = '';
  public tokentype: string = '';
  public role: string;
  public defaultRole: string;
  public name: string = '';
  public userId: string = '';
  public imgUser: string = '';
  public userDetail = {};
  public type: any;
  public message: any;
  public loginAuth: any;
  public currentUserId: any;

  public requestPath: string = 'requestPath';

  public employeeDirectory = [];
  public otherEmployeeDirectory = [];
  public recipientsOriginalList = [];
  public recipients = [];

  public DEVICE_ID = "hiddin.deviceId";
  public OLD_TOKENS = "hiddin.oldTokens";
  public NEW_TOKENS = "hiddin.newTokens";
  public HIDDIN_TOKEN = "hiddins_token";
  public HIDDIN_USER_DETAILS = "hiddins.userDetails";
  public HIDDIN_SIGNUP_TIME = "hiddins.signupTime";
  public USER_IMAGE_URL = "hiddins.userImageUrl";
  public HIDDIN_THEME = "hiddins.theme";
  public HIDDIN_TNC = "hiddins.tnc";
  public DEVICE_INFO = "hiddin.deviceInfo";
  public BOGUS_KEY = 'hiddins.bogusKey';
  public SUBSCRIPTION = 'hiddins.subscription';

  public currency = "USD";
  public appId = 1001;

  appConfig: AppConfig;

  constructor(private appPropService: AppPropService, private storageService: LocalStorageService) {
    this.loadAppPropConfig();
  }

  private loadAppPropConfig() {
    this.appConfig = this.appPropService.appConfig;
  }

  isNullOrEmptyOrUndefined = function (value) {
    return !value;
  }

  isEmpty(value) {
    if (value == null || value == undefined || value.length == 0) {
      return true;
    }
    return false;
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  saveLocalData = function (key: any, value: any) {
    // if (environment.isEncryptionEnable) {
    //   value = this.encryptDefault(value);
    // }
    this.storageService.set(key, value);
  }

  getLocalData = function (key) {
    // var data;
    // if (environment.isEncryptionEnable) {
    //   var decryptedData = this.decryptDefault(storageService.getItem(key));
    //   if (!this.isEmpty(decryptedData)) {
    //     data = JSON.stringify(decryptedData);
    //   }
    // } else {
    //   data = storageService.getItem(key);
    // }
    // return data;
    return this.storageService.get(key);
  }

  removeLocalData = function (key: any) {
    return this.storageService.remove(key);
  }

  debugAlert = function (message) {
    if (environment.isDebugAlertOn) {
      alert(message);
    }
  }

  generateUUID() {
    var d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
      d += performance.now(); //use high-precision timer if available
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  getCombineAesKey() {

    var signupTimeStr = this.getLocalData(this.HIDDIN_SIGNUP_TIME);
    if (this.isEmpty(signupTimeStr)) {
      var userDetail = this.getLocalData(this.HIDDIN_USER_DETAILS);
      if (this.isEmpty(userDetail)) {
        //console.log("Signup time not found");
        throwError("AES Key data not found");
        return null;
      }
      signupTimeStr = JSON.parse(userDetail).signupTime;
    }

    var authTokenObj = this.getLocalData(this.BOGUS_KEY);
    if (!this.isEmpty(authTokenObj)) {
      var secretKey = JSON.parse(authTokenObj);

      if (this.isEmpty(secretKey)) {
        //console.log("Secret Key not found");
        throwError("Secret Key not found");
        return null;
      }
      return this.removeSpecialChars((signupTimeStr + secretKey));
    }
    return null;
  }

  removeSpecialChars(text: string) {
    return text.replace(/[^a-zA-Z0-9]/g, '');
  }

  decrypt(encryptedData: string) {

    if (!environment.isEncryptionEnable) {
      return encryptedData;
    }

    if (this.isEmpty(encryptedData)) {
      return encryptedData;
    }

    var encryptedKey = this.getCombineAesKey();
    // console.log("encryptedKey: " + encryptedKey);

    if (this.isEmpty(encryptedKey)) {
      //console.log("Invalid AES Key");
      throwError("Invalid AES Key");
    }
    var decryptedData = EncryptionUtil.decrypt(encryptedKey, encryptedData);

    // console.log("Decrypted data: " + decryptedData);

    return JSON.parse(decryptedData);
  }

  decryptDefault(encryptedData: string) {

    if (!environment.isEncryptionEnable) {
      return encryptedData;
    }

    if (this.isEmpty(encryptedData)) {
      return encryptedData;
    }

    var decryptedData = EncryptionUtil.decryptDefault(this.appConfig.pop, encryptedData);

    // console.log("Decrypted data: " + decryptedData);

    return JSON.parse(decryptedData);
  }

  encryptDefault(plainText: string) {

    if (!environment.isEncryptionEnable) {
      return plainText;
    }

    if (this.isEmpty(plainText)) {
      return plainText;
    }

    var encryptedData = EncryptionUtil.encryptDefault(this.appConfig.pop, plainText);

    return encryptedData;
  }
}