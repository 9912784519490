<ion-content overflow-scroll="true" class="content-main">
    <div id="login-popup">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <button class="modal-close" aria-label="Close" (click)="dismiss('')"><em
                        class="ti ti-close"></em></button>
                <div class="ath-container m-0">
                    <div class="ath-body">
                        <div class="btn-container animated fadeInDown" data-animate="fadeInDown" data-delay=".85"
                            style="visibility: visible; animation-delay: 0.85s;">
                            <div class="notification-content">
                                <h5 class="ath-heading title">Confimation<small class="tc-default"></small></h5>
                                <p>Are You Want to Delete {{section}}</p>
                                <div class="btn-container">
                                    <div class="btn btn-primary btn-md" (click)="dismiss('')">Cancel</div>
                                    <div class="btn btn-success btn-md" (click)="deleteData()">Confirm</div>
                                </div>
                            </div>
                            <div *ngIf="errorMessage && errorMessage != ''"
                                class="alert alert-danger title title-xs mt-2 mb-2">
                                {{errorMessage}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ion-content>