import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { RegUser } from 'src/app/pages/basic-reg/RegUser';

@Component({
  selector: 'app-thanks-for-reg',
  templateUrl: './thanks-for-reg.component.html',
  styleUrls: ['./thanks-for-reg.component.scss']
})
export class ThanksForRegComponent implements OnInit {

  @Input("user") user: RegUser;

  constructor(private modalCtrl:ModalController) {}

  async dismiss() {
    this.modalCtrl.dismiss();
  }

  ngOnInit(): void {
  }

}
