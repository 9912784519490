<app-header-left-menu [user]="user"></app-header-left-menu>
<ion-content id="main-content" class="full-width-page">
  <div class="bg-theme-alt">
    <div class="bg-grad-special">
      <div class="bg-grad-special-alt">
        <div class="nk-ovm shape-u shape-contain shape-left-top"></div>
        <app-header [user]="user"></app-header>
        <div class="subContent">
          <div class="main-content container">
            <h5 class="ath-heading title">CPA ASSOCIATES<small class="tc-default">Today's encryption will not protect us from quantum computers.</small></h5>
            <p>Today's encryption will not protect us from quantum computers. <b>HIDDIN</b> technology replaces encryption with a security strategy that is quantum proof already. We want to work with CPA firms to incorporate our technology into their client’s digital information systems making both our CPA associates and their clients more secure, private, and profitable. <b>HIDDIN</b> intends to make money and or save money for everyone deploying our services.....</p>
            <p>CPAs are the masters of the information systems that produce the financial data businesses of all kinds require to meet their compliance, control and tax reporting objectives. The clients of these CPA firms typically view these accounting services as part of what it costs to do business. Most clients do not view saving money the same way they perceive the importance of making money. Sales Revenues trump cost savings. <b>HIDDIN</b> wants to expand the CPA service offerings to include all the rest of the information systems that produce their client's communication and marketing data. CPA firms with access and control of all of these data sources will be perceived as a vital part of the money making process rather than seen as just another expense of doing business.</p>
            <p>“Data is eating the world" and "Data is the new currency" are catchy phrases intended to impress us with how important data has become. We are finding new ways to collect, process, store, and use all kinds of information from an ever growing number of sources and yet our appetite for data both in terms of quantity and quality never seems to be satiated. We want this data because we think it is the key to doing a better job of matching buyers with what sellers have to offer. The answer to this age old marketing problem is not that we need more data or just better data. What sellers need is to get all their potential buyers to cooperate with them in the marketing process. The <b>HIDDIN</b> Marketplace accomplishes this objective with the Realization and the Argument mentioned earlier that is made possible with the anonymity our technology provides. Now that we settled that age old question just how are we going to get every seller and every buyer to drop what they have been doing forever and start cooperating with each other? The answer is we do not currently have the kind of clout with either sellers or buyers to get one side or the other involved to a point where we can jump start our digital platform. Fortunately we know a group of people that should have more clout than we will ever need and we simply need to give them enough reasons to help us out and as it turns out they are a logical choice for many reasons.</p>
            <p>The people we are looking to help us should be a trusted member of the community. Someone turned to for advice concerning the kinds of data our platform will be producing. It would be nice if they had knowledge or experience collecting, processing, or working with different data types or the actual information systems that produce the kinds of data we are talking about. It would also be helpful if businesses from almost any industry would be able to find value in their expertise. CPA's are the only professionals that can meet all of these requirements and where they fall short in the areas of communication data and social media data types their experience in designing financial and managerial informational systems will help them catch up fast with these other data types. We want to tap into the industry specific knowledge of the leaders of every industry to build the best adaptations of our tech to meet the needs of our CPA firm’s customers. Who better to make these kinds of introductions than someone everyone already trusts and accepts advice from to make their businesses decisions?</p>
            <p>Communication channel data is typically not part of the CPA’s information system responsibilities making the linking of communication data with all the types of business data CPA’s normally work with an unlikely occurrence. Communication data is currently captured based on each channel’s capture and recording capabilities. E-mail, the main business phone line, individual employee cell phone lines, texts, faxes, snail mail, and social media all represent different communication channels and any enterprise’s ability to capture and make use of this data depends upon how they have structured each of these channels if they have at all. The technologies these channels deploy were not designed to share the data they create and the departments within most enterprises did not design their communication data systems to share this data between the departments within the same enterprise. The typical result is the service department does not share customer interaction data with the sales department or the accounting department and they do not share any of their information on an automated basis with any other department or outside social media outlet.</p>
            <p>Today’s customers however, expect the businesses they deal with to know them and treat them like they share their communication and business data seamlessly across the entire enterprise. Providing customers with this level of coordinated services is referred to as providing an Omni Channel experience. Whole industries have been created to unlock the communication and business data trapped in the historical record silos of each of these data channels. The principal approach to accomplish this is to copy the data in each channel silo and send it to a cloud storage medium. From this single source all this information can be accessed by the enterprise and shared with the departments authorized to get at this information to present a coordinated response to customers across the entire organization. This way a customer can call, text or e-mail any department, walk into any store and visit any local department and any information that enterprise is suppose to be able to get at to serve this client will be there waiting to address their anticipated needs. Accomplishing this with the industries supplying these channel solutions is both costly and presents serious security concerns by opening the enterprise’s historical records and moving sensitive customer data to a cloud central storage.</p>
            <p>Our tech when incorporated into an enterprises information system, with the help of their CPA information system specialist, coordinates all the channel communications and business data in our de-identified cloud as it is being created before it reaches their client’s historical data silos. We create a central repository of all communications and business data that is far more secure than can be accomplished with encryption alone and we control both the enterprises and the customers access to this information making it possible for all enrolled with the <b>HIDDIN</b> Marketplace to enjoy the Omni Channel Experience they want with the security and privacy only our services provide. Best of all it really does not take any more effort or add anymore costs for the <b>HIDDIN</b> Marketplace to provide everyone with the Omni Channel Experience that enterprises want and their customers expect. The CPA enrolled with our services can offer their clients and their customers the Omni Channel Solution they seek by helping the <b>HIDDIN</b> Marketplace to design a custom adaptation of our tech to their specific business and communication channel data requirements. CPA’s are already responsible for the security and design of their client’s business data systems. Now CPA’s can address their clients’ communication channel security, privacy and data coordination needs with the <b>HIDDIN</b> Marketplace’s platform capabilities. CPA’s get a new very valuable service to offer their clients that is extremely affordable for their clients compared to what the competition offers, is profitable for our CPA’s to provide, and the basis for CPA’s to develop a working relationship with the <b>HIDDIN</b> Marketplace.</p>
            <p>Marketing the typical pipeline business with our digital platform marketplace can easily create problems requiring the more traditional services CPA firms offer. An enterprise that can produce 1,000 units a month would be in big trouble if we marketed them like a rock star with our digital platform marketplace delivering them orders for a 1,000 units a day. These pipeline businesses can not scale as fast as we can market them and they will need the advice of an accountant with the direct access to the marketing data our marketplace is going to provide to make these kinds of scale adjustments. Turning to the professionals that design and maintain information systems is the most logical choice. The amazing thing is marketing has traditionally been an area that accountants have avoided and now in the era of big data and data analytics who else would you want to trust with this vital role?</p>
            <p>Focusing on the needs of each industry’s customers is the best way to make these enterprises winners with their customers which will make their CPA's and eventually the <b>HIDDIN</b> Marketplace look like winners to them. We need to present winning solutions for everyone's Quantum Conundrum and demonstrate the advantages that our kind of cooperative marketplace offers everyone. CPA's are already providing trusted services to every industry we want strategic alliance working relationships with. Starting with several CPA firms and reaching out to their clients through their customers is all the jump start we will require before the word of mouth momentum kicks in naturally. The only word of mouth news that spreads as fast as how to make or save money is who is loosing money and why. The <b>HIDDIN</b> Marketplace will provide the answers to both those questions with our Strategic Alliance Initiative.</p>
            <p>Working with CPA firms we can develop the best adaptation of our tech to their client’s specific industry requirements and grow the market share of the CPA firms we work with along with the market shares of their clients. The competitive advantages of our technology will create wins for our CPA Associates and their customers through our strategic marketing alliance initiative.</p>
            <p>Strategic Alliance Agreements will be granted to enterprises within each industry group that collectively represent no more than 30% of that industry’s targeted market share. The commissions the <b>HIDDIN</b> marketplace earns through these agreements is directly related to the value we provide the sellers that join us in each stage of the development of our marketplace with their industry. The first stage called the Ramp Up Period for their particular industry starts with the first enterprise to join from their industry and ends when the combined market share of the enterprises joining from their industry reaches the thirty percent market share target. The second stage of the development of our marketplace with their industry starts upon achieving the thirty percent market share target beginning the five year Exclusive Marketing Period for the enterprises that make up this initial group. The third stage begins when the five year exclusive marketing ends bringing the Open Marketplace Period where everyone is allowed to join.</p>
            <p>Our Strategic Marketing Alliance Agreements offer the initial group of industry experts that join an opportunity to exploit the competitive advantages of the <b>HIDDIN</b> Marketplace before we open to everyone else. The five year period will start at different times for different industries depending on when the initial 30% industry threshold has been reached. The only exception built into our agreements that can shorten their period of exclusivity involves the imminent threat from quantum decryption. The <b>HIDDIN</b> Marketplace will be opened to all enterprises should the Quantum decryption threat become real before the five year exclusive market period expires.</p>
            <p>The commissions the <b>HIDDIN</b> marketplace earns changes depending upon the stage of market development and each enterprises level of achievement based on the higher of the either their increases in potential customers or increases in actual customers. When an enterprise joins the <b>HIDDIN</b> marketplace their number of actual customers becomes their initial base profile number or 100% and their historical annual expenditure on marketing becomes their base marketing expense at 100%. These two base numbers are used to measure the <b>HIDDIN</b> marketplace’s impact on the increases in each enterprise’s potential growth as indicated in increases in new customer profiles and increases in actual sales from new customers and determine the <b>HIDDIN</b> Marketplace commission at each stage of development for that enterprise within their industry group. (See the <b>HIDDIN</b> Marketplace Commission Structure)</p>
            <p>In the ramp up phase we start with their industries traditional marketing expenditure and increase commissions based upon attaining targeted increases in either their number of prospective customer profiles or actual new customers. <b>HIDDIN</b> Measures our effectiveness with either the increases in the number of profiled prospective customers over customers already enrolled or with actual increases in sales to new customers.</p>
            <p>Profile increases of 25% or the increase in actual new sales of 5% for the enterprise in the ramp up period earns the <b>HIDDIN</b> Marketplace increases of 5% in the enterprise’s traditional marketing expenditure and these figures scale to a Profile increase of 200% or gains of 20% in new customers. The exclusive marketing period has Profile targets of 250% in year one to 450% by year 5 or actual increases in customers above their base representing increases of 25% in year one to 45% in year five. The Open market Profile target is 500% or a 50% increase in new customers. In the event the number of prospective customers grows faster than actual new sales increases marketplace commissions are not allowed to advance more than one 5% bracket until actual sales catch up. The <b>HIDDIN</b> marketplace is not advocating increasing what our sellers spend on marketing over their traditional expenditures. The marketplace is looking for the opportunity to earn an increasing share of that traditional expenditure with provable metrics of market success over how they are spending it now on traditional marketing venues.</p>
            <p>Why count both profile and actual new customers? New customers should be the measurement. The problem is our digital platform marketplace allows enterprises to scale their marketing efforts far more quickly than they can scale their Pipeline Production Processes. The only thing potentially as damaging as not having enough customers is not being able to deliver on all the orders your digital marketing strategy produces. If your production max is 350 customers a month landing orders for 400 customers a week is a bad thing. We intend to deliver prospective customers much faster than most enrolled Pipeline enterprises will be able to responsibly expand their capacity to address all the prospects we intend to make available to them. We are interested in controlling the growth of our enrolled enterprises with the objective of maximizing profitability over raw market share. Developing a close working relationship with our enrolled enterprise's accounting professionals is how we intend to help them grow responsibly by keeping a close watch on their growth related costs, revenues and resulting margins. The <b>HIDDIN</b> Marketplace is all about providing quality prospective customers to our enrolled enterprises and quality enterprise offerings to our enrolled buyers. We need to design our interactions to produce wins for our buyers, sellers, and the marketplace. Our technology and the economies of scale and effort it makes possible makes winning for everyone possible.</p>
            <p>When the <b>HIDDIN</b> Marketplace opens to everyone at the end of the five year period for each industry each enterprise that enrolls will have their own commission structure based on their industry’s cost to market savings from utilizing our marketplace platform. We anticipate commission rates close to 45% to 50% of that industries traditional marketing spend taken as the transaction is recorded through our marketplace at the time of sale.</p>
            <p>Prior to and during the exclusive marketing period Strategic Alliance members will be able to offer their customers enrolled with our technology their goods and services with our secure de-identified communication and transaction platform. Their existing customers will be allowed to enroll with our highest level of communication services for free for the first year everyone else will be offered our standard six month trial to try our services. Then they will have the option to pay a monthly fee to continue our highest level of communication services or select the free level. Everyone that completes the enrollment process will have free access to the basic de-identified transaction services of the <b>HIDDIN</b> Marketplace platform. Their customers will be able to conduct secure transactions through our platform with their business and every other Strategic Alliance member representing any of the other industries.</p>
            <p>Once their customers enroll with our technology they can begin conducting business through our platform with any other Strategic Alliance enterprise enrolled with the <b>HIDDIN</b> Marketplace. Many of their customers might have already enrolled with a Strategic Alliance member in an unrelated industry and they can automatically use our technology to do business with them now. The people enrolling with all the other Strategic Alliance members that are not their clients can now use the secure services of our platform to do business with them.</p>
            <p>The Strategic Alliance program is limited to the enterprises within each identified industry that represent up to 30% of the industry market share on a first come first serve basis. What are the chances that every Strategic Alliance member will have the exact same customer base? When we are talking about all consumer service and products industries it is not very likely. Random sampling of thirty percent of all enterprises could easily encompass more than 90% of the total population of customers. Subtract the 30% already signed up with the Strategic Alliance members with in our industry group and we have a total of 60% of new customers that can do business with these Alliance members through our platform immediately. These potential customers only need to be presented with “The Argument” and “The Reasoning”! (See The Argument and The Reasoning in A Better Marketplace) Then each Strategic Alliance enterprise needs to ask how much conventional advertising it would take to reach out to that same 60%? The real answer is nothing. Every Strategic Alliance member from every industry is explaining to their customers why they need to enroll and they are all making the same points with “The Argument” and “The Reasoning” for every other company at the same time.</p>
            <p>If during the five year exclusive marketing period only 50% of the targeted 60% decided to move their business to the companies that have access to the security of our platform and all the Strategic Alliance members get an even share of that new business each member would see their market share double. (60% X 50% = 30%) What level of conventional marketing expenditure would be required to get that kind of result? I doubt that 100% of last years conventional marketing expenditures for each of these industry classifications produced results anything close to what we are potentially talking about here. Strategic Alliance members will have a five year window to take advantage of this compelling reason to shift business relationships. Every new headlined hack attack adds to the sense of urgency inspiring the kind of action that will drive their company's growth and every other member’s growth.</p>
            <p>Part of <b>HIDDIN's</b> compensation under our Strategic Alliance agreement is a formula to share in the growth in market share our members experience from their market share % when they first sign on compared to their % share of the marketplace at the end of the Strategic Alliance 5 year period. Our goal is to increase our member’s market share by 15% each year which would more than double their market share in those five years. We want to share in the value of the market share we help to create for them. We are looking for 20% of the value of that growth paid to us in the form of an issuance of their common stock or equity equivalent paid 10% to <b>HIDDIN</b> and 10% paid to <b>HIDDIN's</b> endowment initiatives. The payment to the endowment initiatives will be structured by our CPA Associates to afford these enterprises the maximum tax deduction possible and they will decide which initiatives they want funded. Payment in the form of a common stock issuance would normally be seen as a dilution in ownership yet the retention of 80 % of their market share increases should more than offset any dilution effects. The issuance of common stock by our Strategic Alliance member’s does not cost them any real cash to fund and rewards both the <b>HIDDIN</b> Marketplace and our Endowment Initiatives.</p>
            <h3 class="title title-md">
              Charitable Endowment Initiatives
            </h3>
            <p>Buyers upon enrollment choose which charitable endowment initiatives they wish to support and as sellers enroll they decide what percentage of their transaction sales price they can afford to contribute to their buyers selected endowment initiatives. When transactions occur the endowment initiatives selected by the buyers are funded in the amount that the sellers previously decided. The funds collected are reduced by a 1.5% fee that becomes a revenue stream for the charitable division of the marketplace. The societal problems these charitable initiatives seek to address are expensive long term projects that might create controversy if we were deciding upon a particular solution. Fortunately this method of solving problems avoids most controversy by focusing on accumulating the funding that will be require regardless of any particular solution that is eventual discussed and implemented. When the only thing we can agree upon is that some funding will be required this approach to the problem avoids controversy and gets everyone working together to raise the funds that will be needed to pursue any solution in the future. Progress no matter how small and no matter how long the journey begins with a first step. Any progress is seen as a positive thing by most and the marketplace provides reporting of the progress of your selected initiatives to reinforce that positive position. Each transaction adds to the value of the transaction history of the marketplace and adds to value of the charitable endowments that encourage buyers and sellers to participate in the <b>HIDDIN</b> marketplace.</p>
          </div>
        </div>
        <app-footer></app-footer>
      </div>
    </div>
  </div>
</ion-content>
