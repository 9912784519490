<app-header-left-menu [user]="user"></app-header-left-menu>
<ion-content id="main-content" class="full-width-page">
  <div class="bg-theme-alt">
    <div class="bg-grad-special">
      <div class="bg-grad-special-alt">
        <div class="nk-ovm shape-u shape-contain shape-left-top"></div>
        <app-header [user]="user"></app-header>
        <div class="subContent">
          <div class="main-content container">
            <div class="info-msg" *ngIf="(user == undefined || user == null) || ((user.roleLevel == 0 || user.roleLevel == 1 || user.roleLevel == 2) && !user.isDocumentUploaded)">&nbsp;* Please continue your profile by clicking on the profile card in the dashboard or your image icon in the menu.</div>
            <div class="info-msg" *ngIf="(user == undefined || user == null) || (user.roleLevel == 3 && user.parentId != undefined && user.parentId != null && user.parentId != 0 && !user.isSeqQueUploaded)">&nbsp;* Please continue your device management by clicking on the Hiddin Platform card below.</div>
            <div>&nbsp;</div>
            <div class="card-container">
              <div *ngFor="let app of apps; index as i" class="card">
                <div (click)="goTo(app.link)" style="cursor: pointer;">
                  <div class="card-image"><img [src]="app.imageSrc"></div>
                  <div class="card-detail" [class]="'bg-' + ((i % 6) + 1)">
                    <div class="desc-container">
                      <p><a>{{app.name}}</a></p>
                      <div class="card-info-container">
                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                        <div class="card-info">{{app.info}}</div>
                      </div>
                    </div>
                    <p>{{app.desc}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
      </div>
    </div>
  </div>
</ion-content>