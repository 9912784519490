import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Globals } from 'src/app/globals';
import { AuthServiceService } from '@services/auth-service.service';
import { LoaderService } from '@services/loader.service';
import { AlertController, ModalController } from '@ionic/angular';
import { RegUser } from '../basic-reg/RegUser';
import { CommonUtil } from 'src/app/util/CommonUtil';
import { ProfileModalComponent } from '@modal/profile-modal/profile-modal.component';

@Component({
  selector: 'app-search-section-new',
  templateUrl: './search-section-new.component.html',
  styleUrls: ['./search-section-new.component.scss', '../../../assets/css/style.scss'],
})
export class SearchSectionNewComponent implements OnInit {

  submitted: boolean = false;

  // Basic Search
  searchTerm: string = null;
  searchTermErrors: string = null;
  searchPerformed: boolean = false;
  searchResults = [];
  loadingData = false;
  userSearchForm: FormGroup;
  advanceSection = false;
  normalSection = true;
  contactIds: number[] = [];

  errorMessage: string;
  successMessage: string;
  requestPath: string;

  user: RegUser = new RegUser();
  profileImageSrc: any;

  ownerId: number;
  contactAdded: boolean = false;
  contactId: number;
  searchCriteria: string;

  contactCategory: string = "friends";
  searchDisplayCriteria: string = null;

  contactTimePreference: any;
  p: number = 1;
  nameRegexp: RegExp = /[!#$%^&*()_+\-=\[\]{};:"\\|,.<>\/?`]/;

  constructor(private fb: FormBuilder,
    public global: Globals,
    public authService: AuthServiceService,
    public formBuilder: FormBuilder,
    public router: Router,
    private loader: LoaderService,
    public atrCtrl: AlertController,
    public modalController: ModalController) {
    this.requestPath = this.global.requestPath;
  }

  ngOnInit() {
    this.userSearchForm = this.formBuilder.group({
      fNameControl: [''],
      lNameControl: [''],
      emailControl: [''],
      pNumberControl: ['']
    });

  }

  ionViewDidEnter() {
    this.ownerId = this.authService.getLoggedInUser().id;
    this.user = this.authService.getLoggedInUser();
    this.profileImageSrc = this.authService.getProfilePicture();
    this.user.imageUrl = this.profileImageSrc;

    this.getUserContacts();
  }

  getUserContacts = function () {
    // this.loader.show("Loading user contacts...");
    this.authService.get('user/directory?' + this.requestPath + '=getMyAllContactIds')
      .subscribe((res: any) => {
        // this.loader.hide();
        if (res.success) {
          var userConnectionIds = this.global.decrypt(res.data);
          // console.log(userConnectionIds);
          if (userConnectionIds != null && userConnectionIds.length > 0) {
            this.contactIds = userConnectionIds;
            this.contactIds.push(this.ownerId);
           }
          //  console.log(this.contactIds);
        } else {
          this.errorMessage = res.message;
        }
      }, (error: any) => {
        // this.loader.hide();
        this.errorMessage = "Something went wrong, please try again.";
      });
  }

  searchContacts(pageNo) {
    this.contactAdded = false;
    this.searchTermErrors = null;
    this.searchPerformed = false;
    this.searchResults = null;

    if (this.searchTerm == null || this.searchTerm.length < 1) {
      this.presentMissingSearchTermAlert("Search criteria is required.");
      return;
    }

    if (this.searchTerm.length < 2) {
      this.presentMissingSearchTermAlert("Please use two or more letters for better search experience.");
      return;
    }

    if (this.nameRegexp.test(this.searchTerm)) {
      this.presentMissingSearchTermAlert("Special characters are not allowed [!#$%^&*()_+\-=\[\]{};:\"\\|,.<>\/?`].");
      return;
    }

    // this.loader.show("Searching Hiddin users...");
    this.loadingData = true;

    this.authService.get('user/directory?' + this.requestPath + '=search' + '&pageNum=' + pageNo + '&searchTerm=' + this.searchTerm)
      .subscribe((res: any) => {
       this.loadingData = false; 
        if (res.success) {
          this.searchPerformed = true;
          this.searchResults = this.global.decrypt(res.data);
        } else {
          this.searchPerformed = false;
          this.searchTermErrors = res.message;
        }
      }, (error: any) => {
        this.loadingData = false; 
        this.searchTermErrors = "Something went wrong, please try again.";
      });
  }


  capitalizeFirstLetter(text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  async presentMissingSearchTermAlert(msg) {
    const alert = await this.atrCtrl.create({
      cssClass: 'my-custom-class',
      header: 'Alert',
      message: msg,
      buttons: ['OK']
    });

    await alert.present();
  }

  // get f() { return this.userSearchForm.controls; }
  searchUserDetails(pageNo) {
    this.submitted = true;
    this.errorMessage = null;

    // stop here if form is invalid
    if (this.userSearchForm.invalid) {
      // console.log("Invalid: " + this.signUpForm.value);
      return;
    }

    this.contactAdded = false;
    this.searchResults = null;
    this.searchTermErrors = null;

    // if (this.formArry.status == "INVALID") {
    //   this.presentMissingSearchTermAlert('Please select a search field and enter search term and special characters are not allowed in search term [!#$%^&*()_+\-=\[\]{};:"\\|,.<>\/?`].');
    //   return;
    // }

    this.searchCriteria = "";
    this.searchDisplayCriteria = "";

    if (!CommonUtil.isEmptyTrim(this.userSearchForm.controls['fNameControl'].value)) {
      this.searchCriteria += "firstName" + ':' + this.userSearchForm.controls['fNameControl'].value + ",";
      this.searchDisplayCriteria += this.capitalizeFirstLetter("firstName") + ': ' + this.userSearchForm.controls['fNameControl'].value + ", ";

    }
    if (!CommonUtil.isEmptyTrim(this.userSearchForm.controls['lNameControl'].value)) {
      this.searchCriteria += "lastName" + ':' + this.userSearchForm.controls['lNameControl'].value + ",";
      this.searchDisplayCriteria += this.capitalizeFirstLetter("lastName") + ': ' + this.userSearchForm.controls['lNameControl'].value + ", ";

    }
    if (!CommonUtil.isEmptyTrim(this.userSearchForm.controls['emailControl'].value)) {
      this.searchCriteria += "email" + ':' + this.userSearchForm.controls['emailControl'].value + ",";
      this.searchDisplayCriteria += this.capitalizeFirstLetter("email") + ': ' + this.userSearchForm.controls['emailControl'].value + ", ";

    }
    if (!CommonUtil.isEmptyTrim(this.userSearchForm.controls['pNumberControl'].value)) {

      this.searchCriteria += "phoneNumber" + ':' + this.userSearchForm.controls['pNumberControl'].value + ",";
      this.searchDisplayCriteria += this.capitalizeFirstLetter("phoneNumber") + ': ' + this.userSearchForm.controls['pNumberControl'].value + ", ";
    }
    // for (var i = 0; i < this.formArry.value.length; i++) {
    //   this.searchCriteria += this.formArry.value[i].searchField + ':' + this.formArry.value[i].searchTerm + ",";
    //   this.searchDisplayCriteria += this.capitalizeFirstLetter(this.formArry.value[i].searchField) + ': ' + this.formArry.value[i].searchTerm + ", ";
    // }

    if (this.searchCriteria.length == 0) {
      this.presentMissingSearchTermAlert('Please fill at least one field');
      return;
    }

    this.loadingData = true; 
    this.searchCriteria = this.searchCriteria.substring(0, this.searchCriteria.length - 1);
    this.searchDisplayCriteria = this.searchDisplayCriteria.substring(0, this.searchDisplayCriteria.length - 2);

    this.authService.get('/user/directory?' + this.requestPath + '=search' + '&pageNum=' + pageNo + '&searchTerm=' + this.searchCriteria + "&isAdvanceSearch=true")
      .subscribe((res: any) => {
        this.searchPerformed = true;
        this.loadingData = false; 
        if (res.success) {
          this.submitted = false;
          this.searchResults = this.global.decrypt(res.data);
        } else {
          this.searchTermErrors = res.message;
        }
      }, (error: any) => {
        this.loadingData = false; 
        this.searchTermErrors = "Error occured";
      });
  }

  addContact() {
    let userContact = {
      "ownerId": this.ownerId,
      "contactId": this.contactId,
      "category": this.contactCategory,
      "status": "Requested",
      "startTime": this.contactTimePreference?.startTime,
      "endTime": this.contactTimePreference?.endTime
    };
    this.contactAdded = false;

    this.loader.show("Please wait...");
    this.authService.postEncrypt('/user/directory?' + this.requestPath + '=save', userContact)
      .subscribe((res: any) => {
        this.loader.hide();
        if (res.success) {
          // Reinitialized the form state
          this.contactAdded = true;
          this.searchResults = [];
        } else {
          this.searchTermErrors = res.message;
        }
      }, (error: any) => {
        this.loader.hide();
        this.searchTermErrors = "Something went wrong, please try again.";
      });
  }

  reset() {
    this.searchPerformed = false;
    this.searchResults = [];
    this.userSearchForm.reset();
    this.searchTerm = "";
  }

  openProfile(user: any, editEnabled: boolean) {
    this.router.navigateByUrl('/profile-details', { state: { 'user': user, 'editEnabled': editEnabled } });
    return false;
  }

  async openModal(userData, isAdd) {
    const modal = await this.modalController.create({
      component: ProfileModalComponent,
      componentProps: {
        user: userData,
        isAddButtonVisible: isAdd
      }
    });

    modal.onDidDismiss().then((dataReturned) => {
      // this.router.navigateByUrl("dashboard");
    });

    modal.present();
  }

  goTo(link: string) {
    this.router.navigateByUrl(link)
  }

  closeResults() {

  }
  advanceTab() {
    this.advanceSection = true;
    this.normalSection = false;
    this.reset();
  }
  normalTab() {
    this.advanceSection = false;
    this.normalSection = true;
    this.reset();
  }
  pagechange = function (event: any) {
    // console.log(event);
    this.p = event;
    if ((event >= ((5 * this.lastLoadedPage) - 1))) {
      this.searchUserDetails(this.lastLoadedPage + 1);
    }
  }
}
