<app-header-left-menu [user]="loggedInUser"></app-header-left-menu>
<ion-content id="main-content" class="full-width-page">
  <div class="bg-theme-alt">
    <div class="bg-grad-special">
      <div class="bg-grad-special-alt">
        <div class="nk-ovm shape-u shape-contain shape-left-top"></div>
        <app-header [user]="loggedInUser"></app-header>
        <div class="subContent">
          <div class="main-content container">
            <div class="bg-white shadow rounded-lg d-block d-sm-flex">
              <div class="profile-tab-nav border-right">
                <div class="p-2 mb-2">
                  <ion-buttons slot="start">
                    <ion-back-button text="" defaultHref=""></ion-back-button>
                  </ion-buttons>
                  <div class="img-circle text-center mb-1">
                    <!-- <img src="img/user2.jpg" alt="Image" class="shadow"> -->
                    <img src="assets/images/team/profile.png" />
                  </div>
                  <h5 class="text-center">{{user?.firstName}} {{user?.lastName}}</h5>
                  <p *ngIf="user?.company">{{user?.company}}</p>
                </div>
                <div class="nav flex-column nav-pills" aria-orientation="vertical">
                  <a class="nav-link" [class.active]="selectedTab == 'basic'" (click)="changeTab('basic')">
                    <i class="fa fa-home text-center mr-1"></i>
                    Basic Detail
                  </a>
                  <a class="nav-link" [class.active]="selectedTab == 'userPref'" (click)="changeTab('userPref')">
                    <i class="fa fa-key text-center mr-1"></i>
                    User Preferances
                  </a>
                  <a class="nav-link" [class.active]="selectedTab == 'myPref'" (click)="changeTab('myPref')">
                    <i class="fa fa-user text-center mr-1"></i>
                    My Preferances
                  </a>
                </div>
              </div>
              <div class="tab-content p-3 p-md-4">
                <div class="tab-pane fade" [class.active]="selectedTab == 'basic'" [class.show]="selectedTab == 'basic'"
                  *ngIf="selectedTab == 'basic'">
                  <h5 class="mb-2">Basic Detail</h5>
                  <div class="row">
                    <div class="col-md-6 mt-4" *ngIf="user?.firstName">
                      <p class="txt-lbl">Full Name</p>
                      <p class="txt-val">{{user?.firstName}} {{user?.lastName}}</p>
                    </div>
                    <div class="col-md-6 mt-4" *ngIf="user?.email">
                      <p class="txt-lbl">Email</p>
                      <p class="txt-val">{{user?.email}}</p>
                    </div>
                    <div class="col-md-6 mt-4" *ngIf="user?.phoneNumber">
                      <p class="txt-lbl">Phone Number</p>
                      <p class="txt-val">{{user?.phoneDialCode}} {{user?.phoneNumber}}</p>
                    </div>
                    <div class="col-md-6 mt-4" *ngIf="user?.birthDate">
                      <p class="txt-lbl">Birth Date</p>
                      <p class="txt-val">{{user?.birthDate}}</p>
                    </div>
                    <div class="col-md-6 mt-4" *ngIf="user?.city">
                      <p class="txt-lbl">City</p>
                      <p class="txt-val">{{user?.city}}</p>
                    </div>
                    <div class="col-md-6 mt-4" *ngIf="user?.state">
                      <p class="txt-lbl">State</p>
                      <p class="txt-val">{{user?.state}}</p>
                    </div>
                    <div class="col-md-6 mt-4" *ngIf="user?.country">
                      <p class="txt-lbl">Country</p>
                      <p class="txt-val">{{user?.country}}</p>
                    </div>
                    <div class="col-md-12 mt-4" *ngIf="user?.aboutMe">
                      <p class="txt-lbl">About Me</p>
                      <p class="txt-val">{{user?.aboutMe}}</p>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" [class.active]="selectedTab == 'userPref'"
                  [class.show]="selectedTab == 'userPref'" *ngIf="selectedTab == 'userPref'">
                  <h5 class="mb-2">User Preferances</h5>
                  <div class="row">
                    <div class="col-md-12 mt-4">
                      <p class="txt-lbl">Category</p>
                      <p class="txt-val text-capitalize">{{userContact.category}}</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 mt-4">
                      <p class="txt-lbl">Channels</p>
                      <p class="row txt-val" *ngIf="userPreferredChannel && userPreferredChannel.length > 0">
                        <label class="col-sm-6 col-md-4 col-lg-3"
                          *ngFor="let commType of userPreferredChannel">{{commType.name}}</label>
                      </p>
                      <p class="row txt-val"
                        *ngIf="userPreferredChannel == undefined || userPreferredChannel.length == 0"><label
                          class="col-3">N/A</label></p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 mt-4"
                      *ngIf="userPreferredTime && userPreferredTime.length > 0 && userPreferredTime[0].length > 0">
                      <p class="txt-lbl">Time Slots</p>
                      <p class="row txt-val">
                        <label class="col-sm-6 col-md-4 col-lg-3"
                          *ngFor="let timeSlot of userPreferredTime">{{timeSlot}}</label>
                      </p>
                      <p class="row txt-val" *ngIf="userPreferredTime == undefined || userPreferredTime.length == 0">
                        <label class="col-3">N/A</label>
                      </p>
                    </div>
                    <div class="col-md-12 mt-4" *ngIf="userStartTime && userStartTime.length > 0">
                      <p class="txt-lbl">Time Slots</p>
                      <p class="row txt-val">
                        <label class="col-6">{{userStartTime}} - {{userEndTime}}</label>
                      </p>
                      <p class="row txt-val" *ngIf="!userStartTime"><label class="col-3">N/A</label></p>
                    </div>
                    <div class="col-md-12 mt-4"
                      *ngIf="(userPreferredTime == undefined || userPreferredTime.length == 0) && (userStartTime == undefined || userStartTime.length == 0)">
                      <p class="txt-lbl">Time Slots</p>
                      <p class="row txt-val"><label class="col-3">N/A</label></p>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" [class.active]="selectedTab == 'myPref'"
                  [class.show]="selectedTab == 'myPref'" *ngIf="selectedTab == 'myPref'">
                  <h5 class="mb-2">My Preferances</h5>
                  <div class="row">
                    <div class="col-md-12 mt-4">
                      <p class="txt-lbl">Category</p>
                      <p class="txt-val text-capitalize">{{userContact.category}}</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 mt-4">
                      <p class="txt-lbl">Channels</p>
                      <p class="row txt-val" *ngIf="myPreferredChannel && myPreferredChannel.length > 0">
                        <label class="col-3" *ngFor="let commType of myPreferredChannel">{{commType.name}}</label>
                      </p>
                      <p class="row txt-val" *ngIf="myPreferredChannel == undefined || myPreferredChannel.length == 0">
                        <label class="col-3">N/A</label>
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 mt-4"
                      *ngIf="myPreferredTime && myPreferredTime.length > 0 && myPreferredTime[0].length > 0">
                      <p class="txt-lbl">Time Slots</p>
                      <p class="row txt-val">
                        <label class="col-3" *ngFor="let timeSlot of myPreferredTime">{{timeSlot}}</label>
                      </p>
                      <p class="row txt-val" *ngIf="myPreferredTime == undefined || myPreferredTime.length == 0">
                        <label class="col-3">N/A</label>
                      </p>
                    </div>
                    <div class="col-md-12 mt-4" *ngIf="myStartTime && myStartTime.length > 0">
                      <p class="txt-lbl">Time Slots</p>
                      <p class="row txt-val">
                        <label class="col-6">{{myStartTime}} - {{myEndTime}}</label>
                      </p>
                    </div>
                    <div class="col-md-12 mt-4"
                      *ngIf="(myPreferredTime == undefined || myPreferredTime.length == 0) && (myStartTime == undefined || myStartTime.length == 0)">
                      <p class="txt-lbl">Time Slots</p>
                      <p class="row txt-val">
                        <label class="col-6">N/A</label>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <app-footer></app-footer>
    </div>
  </div>
</ion-content>