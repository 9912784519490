<ion-content overflow-scroll="true" class="content-main">
  <div id="login-popup">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <button class="modal-close" aria-label="Close" (click)="dismiss()"><em class="ti ti-close"></em></button>
        <div class="ath-container m-0">
          <div class="ath-body">
            <h5 class="ath-heading title">Confirmation <small class="tc-default">Make sure you have following information / documents ready before proceeding</small></h5>
            <ol>
              <li>
                <span>
                  Utility Bill Image / Utility Bill Details
                </span>
              </li>
              <li>
                <span>
                  Cheque Image / Bank Details
                </span>
              </li>
              <li>
                <span>
                  Passport Image (Front Page) / Passport Details
                </span>
              </li>
              <li>
                <span>
                  Driving License Image / Driving License Details
                </span>
              </li>
              <li>
                <span>
                  Picture Password
                </span>
              </li>
            </ol>
            <div class="btn-container animated fadeInDown" data-animate="fadeInDown" data-delay=".85" style="visibility: visible; animation-delay: 0.85s;">
              <div class="btn btn-danger btn-md" (click)="dismiss()" >Cancel</div>
              <div class="btn btn-primary btn-md" (click)="goToCompleteReg()" >Continue</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>