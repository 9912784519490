import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MenuController, ModalController, IonContent } from '@ionic/angular';
import { AuthServiceService } from '@services/auth-service.service';
import { LoaderService } from '@services/loader.service';
import { Globals } from 'src/app/globals';
import { EncryptionUtil } from 'src/app/util/EncryptionUtil';
import { RegUser } from '../basic-reg/RegUser';
import { NgParticlesService } from "@tsparticles/angular";
import { loadSlim } from "@tsparticles/slim";
import { Container, Engine, MoveDirection, OutMode} from "@tsparticles/engine";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements AfterViewInit {

  id = "tsparticles";

  isOverlayActive = false;

  @ViewChild(IonContent) content: IonContent;
  @ViewChild('target') target: any;
  @ViewChild('header', { static: true })
  header: ElementRef<HTMLElement>

  canvasId = 'particles';

  particlesOptions = {
    fpsLimit: 120,
    interactivity: {
      events: {
        onClick: {
          enable: true
        },
        onHover: {
          enable: true
        },
        resize: true,
      },
      modes: {
        push: {
          quantity: 8,
        },
        repulse: {
          distance: 200,
          duration: 0.4,
        },
      },
    },
    particles: {
      color: {
        value: "#ffffff",
      },
      links: {
        color: "#ffffff",
        distance: 150,
        enable: true,
        opacity: 0.3,
        width: 1,
      },
      move: {
        direction: MoveDirection.none,
        enable: true,
        outModes: {
          default: OutMode.bounce,
        },
        random: false,
        speed: 2,
        straight: false,
      },
      number: {
        density: {
          enable: true,
          area: 100,
        },
        value: 80,
      },
      opacity: {
        value: 0.3,
      },
      shape: {
        type: "circle",
      },
      size: {
        value: { min: 1, max: 5 },
      },
    },
    detectRetina: true,
  };

  container: Container;

  activeTabName: string = 'general';

  user: RegUser = new RegUser();
  LoggedIn: boolean;
  isRegCompleted: boolean;

  // Get In Touch Form
  getInTouchForm: FormGroup;
  submitted: boolean = false;
  errorMessage: string;
  successMessage = null;

  // Subscribe Form
  subscribeForm: FormGroup;  
  subscibeEmail;
  sub_submitted = false;
  sub_errorMessage = null;
  sub_successMessage = null;

  constructor(private menuCtrl: MenuController,
    private authService: AuthServiceService,
    private router: Router,
    private global: Globals,
    private formBuilder: FormBuilder,
    private loader: LoaderService,
    private readonly ngParticlesService: NgParticlesService) {
    this.requestPath = this.global.requestPath;
  }

  ngAfterViewInit() {
    this.content
      .ionScroll
      // .pipe(debounceTime(100))
      .subscribe((event: { detail: { scrollTop: number; }; }) => {
        event.detail.scrollTop > 15
          ? this.header.nativeElement.classList.add('bg-blue')
          : this.header.nativeElement.classList.remove('bg-blue');
      });
  }

  // emailPattern = "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$";
  emailPattern = "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?";
  requestPath: string;

  getInTouch = {
    name: '',
    email: '',
    message: ''
  }

  ngOnInit() {
    this.getInTouchForm = this.formBuilder.group({
      name: ['', [Validators.required, this.noWhitespace, Validators.maxLength(100)]],
      message: ['', [this.noWhitespace, Validators.maxLength(500)]],
      email: ['', [Validators.required, this.noWhitespace, Validators.maxLength(50), Validators.pattern(this.emailPattern)]]
    });

    this.subscribeForm = this.formBuilder.group({
      email: ['', [Validators.required, this.noWhitespace, Validators.maxLength(50), Validators.pattern(this.emailPattern)]]
    });

    this.ngParticlesService.init(async (engine: Engine) => {
      await loadSlim(engine);
    });
  }

  ionViewDidEnter() {
    this.checkForCompleteReg();
  }

  checkForCompleteReg() {
    if (this.user && ((this.user.type == 'Individual' && !this.global.isEmpty(this.user.parentId)
      && this.user.parentId != 0 && !this.global.isEmpty(this.user.isSeqQueUploaded)
      && this.user.isSeqQueUploaded) || (!this.global.isEmpty(this.user.isDocumentUploaded) && this.user.isDocumentUploaded))) {
      this.isRegCompleted = true;
    } else {
      this.isRegCompleted = false;
    }
  }

  public noWhitespace(control: FormControl) {
    let isWhitespace = (control.value || '').trim().length === 0;
    let isValid = !isWhitespace;
    return isValid ? null : { 'required': true }
  }

  // convenience getter for easy access to form fields
  get f() { return this.getInTouchForm.controls; }

  resetGetInTouch() {
    this.getInTouchForm.reset();
    this.getInTouch = {
      name: '',
      email: '',
      message: ''
    }
    this.submitted = false;
    this.errorMessage = null;
  }

  resetSubscribeForm() {
    this.subscribeForm.reset();
    this.sub_submitted = false;
    this.subscibeEmail = null;
  }

  submitGetInTouch() {
    this.submitted = true;
    this.errorMessage = null;
    this.successMessage = null;

    // stop here if form is invalid
    if (this.getInTouchForm.invalid) {
      // console.log("Invalid: " + this.getInTouchForm.value);
      return;
    }

    this.loader.show("Please wait...");
    this.authService.postDefaultEncrypt('ns/user?' + this.requestPath + '=saveQuery', this.getInTouch)
      .subscribe(res => {
        this.loader.hide();
        // console.log(res);
        if (res.success) {
          this.successMessage = "Your feedback is submitted successfully. Our customer care executive will connect to you shortly.";
          this.resetGetInTouch();

          setTimeout(() => {
            this.successMessage = null;
          }, 10000);
        } else {
          this.errorMessage = res.message;
        }
      }, (error: any) => {
        this.loader.hide();
        // console.log(error);
        this.errorMessage = "Something went wrong, please try again.";
      });
  }

  submitSubscribe() {
    this.sub_submitted = true;
    this.sub_errorMessage = null;
    this.sub_successMessage = null;

    // stop here if form is invalid
    if (this.subscribeForm.invalid) {
      // console.log("Invalid: " + this.subscribeForm.value);
      return;
    }

    this.loader.show("Please wait...");
    this.authService.postDefaultEncrypt('ns/subs?' + this.requestPath + '=subscribe&email=' + this.subscibeEmail, null)
      .subscribe(res => {
        this.loader.hide();
        // console.log(res);
        if (res.success) {
          this.sub_successMessage = "You have successfully subscribed.";
          this.resetSubscribeForm();

          setTimeout(() => {
            this.sub_successMessage = null;
          }, 10000);
        } else {
          this.sub_errorMessage = res.message;
        }
      }, (error: any) => {
        this.loader.hide();
        // console.log(error);
        this.sub_errorMessage = "Something went wrong, please try again.";
      });
  }

  ionViewWillEnter() {
    this.container.refresh();
    this.user = this.authService.getLoggedInUser();
    this.LoggedIn = this.authService.isLoggedIn();
    if (!this.global.isEmpty(this.user)) {
      this.user.imageUrl = this.authService.getProfilePicture();
    }
    // this.securityTestHiddin();
  }

  particlesLoaded(container: Container): void {
    this.container = container;
  }

  scrollTo(id: string) {
    const target = document.querySelector('#' + id) as HTMLDivElement;

    if (id === 'products') {
      this.content.scrollToPoint(0, target.offsetTop + (target.offsetHeight / 0.5), 1000)
    }
    else if (id === 'teams') {
      this.content.scrollToPoint(0, target.offsetTop + (target.offsetHeight / 0.5), 1000)
    }
    else {
      this.content.scrollToPoint(0, target.offsetTop + (target.offsetHeight / 0.9), 1000)
    }

    this.menuCtrl.close();
  }

  fixHeader($event: any) {
    $event.detail.scrollTop > 15
      ? this.header.nativeElement.classList.add('fixed-header')
      : this.header.nativeElement.classList.remove('fixed-header');
  }

  activeTab(tabName: string) {
    this.activeTabName = tabName;
  }

  @ViewChild('widgetsContent', { read: ElementRef }) public widgetsContent: ElementRef<any>;

  scrollRight(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft + 150), behavior: 'smooth' });
  }

  scrollLeft(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft - 150), behavior: 'smooth' });
  }

  logout() {
    this.authService.logout();
    this.user = null;
    this.LoggedIn = false;
    this.router.navigate(['/home']);
  }

  securityTestHiddin() {
    var lAesKey = "20210522173500eab913b02a024b905d121ced9ccce54a17bfb1b9fad87827d68f27852a879869af0642aa13c59a585541d3019824d539873a9b3614f8272e96543b47ef305b0a";
    var data = "{\"username\":\"D_SHYA_ESKZ47T\",\"firstName\":\"Shyam\",\"lastName\":\"Ji\",\"email\":\"shyam.ji@hiddin.net\",\"type\":\"Demo\",\"birthDate\":\"2021-05-01\",\"phoneNumber\":\"917398154600\",\"expiryTime\":\"2021-05-22 19:38:53.456\",\"verified\":false,\"systemUser\":false,\"parentId\":0,\"isSeqQueUploaded\":false,\"isDocumentUploaded\":false,\"isAccDetailVerfied\":false,\"isBluetoothLocationEnabled\":false,\"isGpsLocationEnabled\":false,\"rowCount\":0,\"columnCount\":0,\"signupDeviceId\":\"523bca3d-4a2d-48ab-a3cf-2afff4cb1ffd\",\"signupTime\":\"2021-05-22 17:35:00\",\"id\":1,\"creationDate\":\"May 22, 2021 5:38:49 PM\",\"updationDate\":\"May 22, 2021 5:38:53 PM\",\"archived\":false,\"imageUrl\":null,\"userPassword\":\"Shyam@123\",\"confirmPassword\":\"Shyam@123\",\"password\":\"U2h5YW1AMTIz\"}";

    var encData = EncryptionUtil.encrypt(lAesKey, data);
    // console.log("encData: " + encData);

    // var encData = "BCrbE218ZMhj+pGhDtISe9168fnHY4lwMZAdygxQtJhQqxkQ7tG3xFvhWOUD8wOC30reFTaSSBIimcFRnDhf0KrJKxkzuiwwYkxtLedsUnZN0ZAYwsD0mxxUcPQzdHlfCYOlmblC0aGTogKRMGhcROyYJlrvl23uhBhdNFXAljO+LXyde4xWVKTBTfPfNCve";

    var body = EncryptionUtil.decrypt(lAesKey, encData);
    // console.log("Body: " + body);
  }

}
