import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-email-verification-info',
  templateUrl: './email-verification-info.component.html',
  styleUrls: ['./email-verification-info.component.scss'],
})
export class EmailVerificationInfoComponent implements OnInit {
  @Input() emailAdd: string;

  constructor(private modalCtrl:ModalController) {}

  async dismiss() {
    this.modalCtrl.dismiss();
  }

  ngOnInit(): void {
  }

}
