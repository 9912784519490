import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { NgxParticlesModule } from "@tsparticles/angular";
import { provideHttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideNgxLocalstorage } from 'ngx-localstorage';

import { Globals } from './globals';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { DndDirective } from './dnd.directive';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TooltipModule } from 'ng2-tooltip-directive';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { DashboardPage } from './pages/dashboard/dashboard.page';
import { KnowMoreComponent } from './pages/know-more/know-more.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { CompleteRegistrationComponent } from './pages/complete-registration/complete-registration.component';
import { BasicRegComponent } from './pages/basic-reg/basic-reg.component';
import { LoginComponent } from './pages/login/login.component';
import { OtpComponent } from './pages/otp/otp.component';
import { ForgotPassComponent } from './pages/forgot-pass/forgot-pass.component';
import { HomeComponent } from './pages/home/home.component';
import { ThemePageComponent } from './pages/theme-page/theme-page.component';

import { HeaderComponent } from './components/header/header.component';
import { LeftMenuComponent } from './components/left-menu/left-menu.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderLeftMenuComponent } from './components/header-left-menu/header-left-menu.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { CompleteRegConfirmationComponent } from './modal/complete-reg-confirmation/complete-reg-confirmation.component';
import { NotifiactionPopupComponent } from './modal/notifiaction-popup/notifiaction-popup.component';
import { MoreInfoComponent } from './pages/more-info/more-info.component';
import { RegAlertComponent } from './modal/reg-alert/reg-alert.component';
import { AuthInterceptor } from './interceptor/auth.interceptor';
import { LoggingInterceptor } from './interceptor/logging.interceptor';
import { OurProductsComponent } from './pages/our-products/our-products.component';
import { ContactComponent } from './pages/contact/contact.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { SolutionsComponent } from './pages/solutions/solutions.component';
import { MarketplaceComponent } from './pages/marketplace/marketplace.component';
import { CommunicationComponent } from './pages/communication/communication.component';
import { TransactionComponent } from './pages/transaction/transaction.component';
import { SecurityComponent } from './pages/security/security.component';
import { CpaComponent } from './pages/cpa/cpa.component';
import { PlatformVsPipelineComponent } from './pages/platform-vs-pipeline/platform-vs-pipeline.component';
import { PlatformDynamicsComponent } from './pages/platform-dynamics/platform-dynamics.component';
import { MarketingEconomiesComponent } from './pages/marketing-economies/marketing-economies.component';
import { ThanksForRegComponent } from './modal/thanks-for-reg/thanks-for-reg.component';
import { PaymentConfirmationComponent } from './pages/payment-confirmation/payment-confirmation.component';
import { UpgradeToBusinessComponent } from '@modal/upgrade-to-business/upgrade-to-business.component';
import { ThanksForUpgradeComponent } from '@modal/thanks-for-upgrade/thanks-for-upgrade.component';
import { RenewSubscriptionComponent } from './pages/renew-subscription/renew-subscription.component';
import { SubscriptionDoneComponent } from './pages/subscription-done/subscription-done.component';
import { FamilyMemberComponent } from './pages/family-member/family-member.component';
import { AddFamilyMemberComponent } from '@modal/add-family-member/add-family-member.component';
import { ConfirmationBoxComponent } from '@modal/confirmation-box/confirmation-box.component';
import { SecureCommunicationComponent } from './pages/secure-communication/secure-communication.component';
import { LoginGlobalComponent } from './pages/login-global/login-global.component';
import { RegGlobalComponent } from './pages/reg-global/reg-global.component';
import { ProfileEnrichmentComponent } from './pages/profile-enrichment/profile-enrichment.component';
import { CreatePasswordComponent } from './pages/create-password/create-password.component';
import { ConfirmationComponent } from '@modal/confirmation/confirmation.component';
import { ConfirmForUpgradeToBusinessComponent } from '@modal/confirm-for-upgrade-to-business/confirm-for-upgrade-to-business.component';
import { SuccessMessageComponent } from '@modal/success-message/success-message.component';
import { ErrorComponent } from './pages/error/error.component';
import { EmailVerificationInfoComponent } from '@modal/email-verification-info/email-verification-info.component';
import { SearchSectionComponent } from './pages/search-section/search-section.component';
import { DirectoryDashbordComponent } from './pages/profile-enrichment/directory-dashbord/directory-dashbord.component';
import { ProfileDetailsComponent } from './pages/profile-enrichment/profile-details/profile-details.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { CustomValidators } from './util/customname.validator';
import { CollectDirectoryFormComponent } from '@modal/user-profile/collect-directory-form/collect-directory-form.component';
import { EncryptionDecryptionComponent } from './pages/encryption-decryption/encryption-decryption.component';
import { SearchSectionNewComponent } from './pages/search-section-new/search-section-new.component';
import { DirectoryDashbordNewComponent } from './pages/profile-enrichment/directory-dashbord-new/directory-dashbord-new.component';
import { ProfileModalComponent } from '@modal/profile-modal/profile-modal.component';
import { ContactSettingComponent } from '@modal/contact-setting/contact-setting.component';
import { PendingRequestNewComponent } from './pages/profile-enrichment/pending-request-new/pending-request-new.component';
import { MessagesPopupComponent } from '@modal/messages-popup/messages-popup.component';
import { MathcesCategoryPipe } from './pipe/matchesCategory.pipe';
import { ProfileDetailsNewComponent } from './pages/profile-enrichment/profile-details-new/profile-details-new.component';
import { AppPropService } from '@services/app-prop.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent,
    DashboardPage,
    HeaderComponent,
    LeftMenuComponent,
    FooterComponent,
    KnowMoreComponent,
    ProfileComponent,
    CompleteRegistrationComponent,
    HeaderLeftMenuComponent,
    ThemePageComponent,
    BasicRegComponent,
    LoginComponent,
    OtpComponent,
    ForgotPassComponent,
    HomeComponent,
    DndDirective,
    ProgressBarComponent,
    CompleteRegConfirmationComponent,
    NotifiactionPopupComponent,
    MoreInfoComponent,
    RegAlertComponent,
    OurProductsComponent,
    ContactComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    SolutionsComponent,
    MarketplaceComponent,
    CommunicationComponent,
    TransactionComponent,
    SecurityComponent,
    CpaComponent,
    PlatformVsPipelineComponent,
    PlatformDynamicsComponent,
    MarketingEconomiesComponent,
    ThanksForRegComponent,
    PaymentConfirmationComponent,
    UpgradeToBusinessComponent,
    ThanksForUpgradeComponent,
    RenewSubscriptionComponent,
    SubscriptionDoneComponent,
    FamilyMemberComponent,
    AddFamilyMemberComponent,
    ConfirmationBoxComponent,
    SecureCommunicationComponent,
    LoginGlobalComponent,
    RegGlobalComponent,
    ProfileEnrichmentComponent,
    CreatePasswordComponent,
    ConfirmationComponent,
    DirectoryDashbordComponent,
    ConfirmForUpgradeToBusinessComponent,
    SuccessMessageComponent,
    ErrorComponent,
    EmailVerificationInfoComponent,
    SearchSectionComponent,
    ProfileDetailsComponent,
    CollectDirectoryFormComponent,
    EncryptionDecryptionComponent,
    SearchSectionNewComponent,
    DirectoryDashbordNewComponent,
    ContactSettingComponent,
    ProfileModalComponent,
    PendingRequestNewComponent,
    MessagesPopupComponent,
    MathcesCategoryPipe,
    ProfileDetailsNewComponent
  ],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, NgxParticlesModule,
    NgbModule, FormsModule, ReactiveFormsModule, TooltipModule, NgxIntlTelInputModule, 
    NgxPaginationModule, BrowserAnimationsModule],
  providers: [
    StatusBar,
    SplashScreen,
    CustomValidators,
    MathcesCategoryPipe,
    AppPropService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Globals,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoggingInterceptor,
      multi: true,
    },
    provideNgxLocalstorage({
      prefix: 'hiddin',
      delimiter: '@'
    }),
    provideHttpClient()
  ],
  exports: [FormsModule, ReactiveFormsModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
