<ion-menu class="menu-main" side="start" content-id="main-content">
  <nav class="header-menu justify-content-between" id="example-menu-04">
    <div class="userDetails" *ngIf="LoggedIn">
      <div class="userImage" [routerLink]="['/profile']">
        <img src="assets/images/team/profile.png" />
        <span *ngIf="user" class="tc-light">{{user.firstName}}</span>
        <span *ngIf="!user" class="tc-light"></span>
      </div>
    </div>
    <ul class="menu menu-s2">
      <li class="menu-item"><a class="menu-link nav-link" [routerLink]="['/home']">Home</a></li>
      <li class="menu-item" *ngIf="LoggedIn"><a class="menu-link nav-link" [routerLink]="['/dashboard']">Dashboard</a></li>
      <li class="menu-item"><a class="menu-link nav-link" [routerLink]="['/know-more']">About Us</a>
      </li>
      <li class="menu-item" *ngIf="!isHome"><a class="menu-link nav-link" [routerLink]="['/our-products']">Our Products</a>
      </li>
      <li class="menu-item" *ngIf="!isHome"><a class="menu-link nav-link" [routerLink]="['/contact']">Contact</a>
      </li>
      <li class="menu-item" *ngIf="isHome"><a class="menu-link nav-link" href="javascript:" (click)="scrollTo('products')">Our
          Products</a></li>
      <li class="menu-item" *ngIf="isHome"><a class="menu-link nav-link" href="javascript:" (click)="scrollTo('contact')">Contact</a>
      </li>
    </ul>
    <button *ngIf="LoggedIn" (click)="logout()"
      class="btn btn-rg btn-auto btn-outline btn-grad on-bg-theme-dark-alt tc-light">Log Out</button>
    <ul class="menu-btns animated fadeInDown" data-animate="fadeInDown" data-delay=".85"
      style="visibility: visible; animation-delay: 0.85s;" *ngIf="!LoggedIn">
      <li><a [routerLink]="['/basic-reg']" class="btn btn-rg btn-auto btn-outline btn-grad on-bg-theme-dark-alt"><span>Sign Up</span></a></li>
      <li><a [routerLink]="['/login']" class="btn btn-rg btn-auto btn-outline btn-grad on-bg-theme-dark-alt"><span>Log In</span></a></li>
    </ul>
  </nav>

</ion-menu>