import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { AuthServiceService } from '@services/auth-service.service';
import { LoaderService } from '@services/loader.service';
import { Globals } from 'src/app/globals';
import { RegUser } from '../../basic-reg/RegUser';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-directory-dashbord',
  templateUrl: './directory-dashbord.component.html',
  styleUrls: ['./directory-dashbord.component.scss'],
})
export class DirectoryDashbordComponent implements OnInit {
  errorMessage: string;
  successMessage: string;
  requestPath: string;
  lastLoadedPage: number = 1;
  user: RegUser = new RegUser();
  ownerId: number;
  LoggedIn: boolean;
  profileImage: boolean = false;
  profileImageSrc: any;
  contactAdded: boolean = false;
  contactId: number;
  contactIds: number[] = [];

  userContacts: [] = [];
  requestedContacts: [] = [];
  pendingRequests: [] = [];

  favoriteContacts: [] = [];
  personalContacts: [] = [];
  workContacts: [] = [];
  generalContacts: [] = [];

  searchTerm: string = null;
  searchTermErrors: string = null;
  contactCategory: string = 'friends';
  contactTimePreference: any;

  contactsLoaded: boolean = false;
  searchPerformed: boolean = false;
  searchResults: [] = [];
  p: number = 1;

  nameRegexp: RegExp = /[!#$%^&*()_+\-=\[\]{};:"\\|,.<>\/?`]/;

  constructor(public global: Globals,
    public authService: AuthServiceService,
    public modalController: ModalController,
    public formBuilder: FormBuilder,
    public router: Router,
    private loader: LoaderService,
    public atrCtrl: AlertController) {
    this.requestPath = this.global.requestPath;
  }

  ngOnInit() {
  }

  ionViewDidEnter() {
    this.resetInputs();
    this.LoggedIn = this.authService.isLoggedIn();
    this.ownerId = this.authService.getLoggedInUser().id;

    this.user = this.authService.getLoggedInUser();
    if (this.global.isEmpty(this.user)) {
      this.getMyProfile();
    } else {
      this.profileImageSrc = this.authService.getProfilePicture();
      this.user.imageUrl = this.profileImageSrc;
    }

    this.getUserContacts();
    this.getPendingRequestWithMe();
    this.getRequestedContacts();
  }

  getMyProfile = function () {
    this.authService.get('user?' + this.requestPath + '=getUserDetail')
      .subscribe((res: any) => {
        this.loader.hide();
        if (res.success) {
          // Update user data
          this.user = this.global.decrypt(res.data);;
          this.user.imageUrl = this.profileImageSrc;
          this.ownerId = this.user.id;

          // Save data in local storage
          this.global.saveLocalData(this.global.HIDDIN_USER_DETAILS, JSON.stringify(this.user));
        } else {
          this.searchTermErrors = res.message;
        }
      }, (error: any) => {
        this.loader.hide();
        this.searchTermErrors = "Something went wrong, please try again.";
      });
  }

  getRequestedContacts = function () {
    this.requestedContacts = [];
    this.authService.get('user/directory?' + this.requestPath + '=requestedList')
      .subscribe((res: any) => {
        if (res.success) {
          this.requestedContacts = this.global.decrypt(res.data);
          if (!this.global.isEmpty(this.requestedContacts)) {
            for (var i = 0; i < this.requestedContacts.length; i++) {
              this.contactIds.push(this.requestedContacts[i].contactUser.id);
            }
          }

        } else {
          this.searchTermErrors = res.message;
        }
      }, (error: any) => {
        this.searchTermErrors = "Something went wrong, please try again.";
      });
  }

  getPendingRequestWithMe = function () {
    this.pendingRequests = [];
    this.authService.get('user/directory?' + this.requestPath + '=pendingWithMeList')
      .subscribe((res: any) => {
        if (res.success) {
          this.pendingRequests = this.global.decrypt(res.data);
        } else {
          this.searchTermErrors = res.message;
        }
      }, (error: any) => {
        this.searchTermErrors = "Something went wrong, please try again.";
      });
  }

  getUserContacts = function () {
    this.loader.show("Loading user contacts...");
    this.authService.get('user/directory?' + this.requestPath + '=list')
      .subscribe((res: any) => {
        this.loader.hide();
        this.contactsLoaded = true;
        if (res.success) {
          this.userContacts = this.global.decrypt(res.data);
          if (this.userContacts != null && this.userContacts.length > 0) {
            this.contactIds = this.userContacts.map(function (contact: any) { return contact.contactUser.id; });
            this.contactIds.push(this.ownerId);
            this.favoriteContacts = this.userContacts.filter((contact: any) => { return contact.category === 'favorites'; });
            this.personalContacts = this.userContacts.filter((contact: any) => { return contact.category === 'family'; });
            this.workContacts = this.userContacts.filter((contact: any) => { return contact.category === 'work'; });
            this.generalContacts = this.userContacts.filter((contact: any) => { return contact.category === 'friends'; });
          }
        } else {
          this.searchTermErrors = res.message;
        }
      }, (error: any) => {
        this.loader.hide();
        this.searchTermErrors = "Something went wrong, please try again.";
      });
  }

  searchContacts(pageNo) {
    this.contactAdded = false;
    this.searchTermErrors = null;
    this.searchPerformed = false;
    this.searchResults = null;

    if (this.searchTerm == null || this.searchTerm.length < 1) {
      this.searchTermErrors = "Search criteria is required.";
      return;
    }

    if (this.searchTerm.length < 2) {
      this.searchTermErrors = "Please use two or more letters for better search experience.";
      return;
    }

    if (this.nameRegexp.test(this.searchTerm)) {
      this.searchTermErrors = "Special characters are not allowed [!#$%^&*()_+\-=\[\]{};:\"\\|,.<>\/?`].";
      return;
    }

    this.loader.show("Searching Hiddin users...");

    this.authService.get('user/directory?' + this.requestPath + '=search' + '&pageNum=' + pageNo + '&searchTerm=' + this.searchTerm)
      .subscribe((res: any) => {
        this.loader.hide();
        if (res.success) {
          this.searchPerformed = true;
          this.searchResults = this.global.decrypt(res.data);
        } else {
          this.searchPerformed = false;
          this.searchTermErrors = res.message;
        }
      }, (error: any) => {
        this.loader.hide();
        this.searchTermErrors = "Something went wrong, please try again.";
      });
  }

  addContact() {
    let userContact = {
      "ownerId": this.ownerId,
      "contactId": this.contactId,
      "category": this.contactCategory,
      "status": "Requested",
      "startTime": this.contactTimePreference?.startTime,
      "endTime": this.contactTimePreference?.endTime
    };
    this.contactAdded = false;
    this.createContact(userContact);
  }

  createContact(userContact: any) {
    this.loader.show("Please wait...");
    this.authService.postEncrypt('user/directory?' + this.requestPath + '=save', userContact)
      .subscribe((res: any) => {
        this.loader.hide();
        if (res.success) {
          // Reinitialized the form state
          this.contactAdded = true;

          // Hide Search data
          this.searchTermErrors = null;
          this.searchPerformed = false;
          this.searchResults = null;

          this.getUserContacts();
          this.getRequestedContacts();
        } else {
          this.searchTermErrors = res.message;
        }
      }, (error: any) => {
        this.loader.hide();
        this.searchTermErrors = "Something went wrong, please try again.";
      });
  }

  approveContact(userContact: any) {
    userContact.status = "Confirmed";
    this.loader.show("Please wait...");
    this.authService.postEncrypt('user/directory?' + this.requestPath + '=save', userContact)
      .subscribe((res: any) => {
        this.loader.hide();
        if (res.success) {
          //add reverse contact now
          // let reverseContact = {
          //   "ownerId": userContact.contactId,
          //   "contactId": userContact.ownerId,
          //   "category": this.contactCategory,
          //   "status": "Confirmed"
          // };
          // this.createContact(reverseContact);
          // Reinitialized the form state
          this.getUserContacts();
          this.getPendingRequestWithMe();
        } else {
          this.searchTermErrors = res.message;
        }
      }, (error: any) => {
        this.loader.hide();
        this.searchTermErrors = "Something went wrong, please try again.";
      });
    return false;
  }

  rejectContact(userContact: any) {
    userContact.status = "Rejected";
    this.loader.show("Please wait...");
    this.authService.postEncrypt('user/directory?' + this.requestPath + '=save', userContact)
      .subscribe((res: any) => {
        this.loader.hide();
        if (res.success) {
          // Reinitialized the form state
          this.getUserContacts();
          this.getRequestedContacts();
        } else {
          this.searchTermErrors = res.message;
        }
      }, (error: any) => {
        this.loader.hide();
        this.searchTermErrors = "Something went wrong, please try again.";
      });
  }

  async showApproveCategoryOptions(userContact: any) {
    const alert = await this.atrCtrl.create({
      cssClass: 'preference-box',
      header: 'Contact Preference',
      inputs: [
        {
          name: 'favorites',
          type: 'radio',
          label: 'Favorites',
          value: 'favorites',
          checked: false,
          handler: () => {
            this.contactCategory = 'favorites';
          }
        },
        {
          name: 'family',
          type: 'radio',
          label: 'Family',
          value: 'family',
          checked: false,
          handler: () => {
            this.contactCategory = 'family';
          }
        },
        {
          name: 'work',
          type: 'radio',
          label: 'Work',
          value: 'work',
          checked: false,
          handler: () => {
            this.contactCategory = 'work';
          }
        },
        {
          name: 'friends',
          type: 'radio',
          label: 'Friends',
          value: 'friends',
          checked: true,
          handler: () => {
            this.contactCategory = 'friends';
          }
        }
      ],
      buttons: [
        {
          text: 'Confirm',
          cssClass: 'preference-button',
          handler: () => {
            this.approveContact(userContact);
          }
        }
      ]
    });
    await alert.present();
  }

  async showContactCategoryOptions(userId: number) {
    this.contactId = userId;

    const alert = await this.atrCtrl.create({
      cssClass: 'preference-box',
      header: 'Contact Preference',
      inputs: [
        {
          name: 'favorites',
          type: 'radio',
          label: 'Favorites',
          value: 'favorites',
          checked: false,
          handler: () => {
            this.contactCategory = 'favorites';
          }
        },
        {
          name: 'family',
          type: 'radio',
          label: 'Family',
          value: 'family',
          checked: false,
          handler: () => {
            this.contactCategory = 'family';
          }
        },
        {
          name: 'work',
          type: 'radio',
          label: 'Work',
          value: 'work',
          checked: false,
          handler: () => {
            this.contactCategory = 'work';
          }
        },
        {
          name: 'friends',
          type: 'radio',
          label: 'Friends',
          value: 'friends',
          checked: true,
          handler: () => {
            this.contactCategory = 'friends';
          }
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          cssClass: 'preference-button',
          handler: () => {

          }
        },
        {
          text: 'Time Preference',
          cssClass: 'preference-button',
          handler: () => {
            this.openTimePreferenceModal();
          }
        }
      ]
    });
    await alert.present();
  }

  async openTimePreferenceModal() {
    const alert = await this.atrCtrl.create({
      cssClass: 'preference-box',
      header: 'Time Preference',
      inputs: [
        {
          name: 'startTime',
          type: 'time'
        },
        {
          name: 'endTime',
          type: 'time'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'preference-button',
          handler: () => {
            // console.log('Confirm Cancel');
          }
        }, {
          text: 'Add Contact',
          cssClass: 'preference-button',
          handler: (values) => {
            this.contactTimePreference = values;
            this.addContact();
          }
        }
      ]
    });

    await alert.present();
  }

  advanceSearch() {
    this.router.navigateByUrl('/search', { state: { 'contactIds': this.contactIds } });
    return false;
  }

  openProfile(userContact: any, user: any, editEnabled: boolean, isSearchResult: boolean) {
    this.router.navigateByUrl('/profile-details', { state: { 'userContact': userContact, 'user': user, 'editEnabled': editEnabled, 'addEnabled': !this.contactIds.includes(user.id), 'isSearchResult': isSearchResult } });
    return false;
  }

  resetInputs() {
    this.searchTermErrors = null;
    this.successMessage = null;
    this.errorMessage = null;
  }

  goTo(link: string) {
    this.router.navigateByUrl(link)
  }

  closeResults() {
    this.searchPerformed = false;
    this.searchResults = [];
  }

  convertTime(time) {
    if (!time) {
      return '';
    }
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }
  pagechange = function (event: any) {
    // console.log(event);
    this.p = event;
    if ((event >= ((5 * this.lastLoadedPage) - 1))) {
      this.searchContacts(this.lastLoadedPage + 1);
    }
  }
}
