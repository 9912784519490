import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { throwError, Observable } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { AuthServiceService } from '../services/auth-service.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authservice: AuthServiceService) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // if (!req.headers.has('Content-Type')) {
    //   req = req.clone({
    //     headers: req.headers.set('Content-Type', 'application/json')
    //   });
    // }

    return next.handle(req).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          if (!event.headers.has('Content-Type')) {
            event.headers.set('Content-Type', 'application/json');
          }
          var res = event;
          if (res && res.status == 401) {
            // When token is invalid or expired, signin is required in this case
            this.authservice.unauthorized();
          }
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (error && error.status === 401) {
          // When token is invalid or expired, signin is required in this case
          this.authservice.unauthorized();
        } else if (error && error.status === 404) {
          // When path is not found
          this.authservice.error404();
        } else {
          return throwError(error);
        }
      })
    );
  }
}
