<app-header-left-menu [user]="user"></app-header-left-menu>
<ion-content id="main-content" class="full-width-page">
  <div class="bg-theme-alt">
    <div class="bg-grad-special">
      <div class="bg-grad-special-alt">
        <div class="nk-ovm shape-u shape-contain shape-left-top"></div>
        <app-header [user]="user"></app-header>
        <section class="section bg-theme tc-light animated fadeInUp" data-animate="fadeInUp" data-delay="0.4"
        style="visibility: visible; animation-delay: 0.4s;">
          <div class="container">
            <div class="nk-block nk-block-features-s2">
                <div class="row align-items-center gutter-vr-30px">
                    <div class="col-md-6">
                      <img src="./assets/img/01.png" alt="gfx">
                    </div>
                    <div class="col-md-6">
                        <div class="nk-block-text">
                            <h2 class="title title-semibold">What HIDDIN does!</h2>
                            <p>
                              HIDDIN Protects you and your associates as you send, store and create your information living in this digital world.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </section>
        <section class="section bg-theme tc-light">
          <div class="container">
            <div class="nk-block nk-block-features-s2">
                <div class="row align-items-center gutter-vr-30px">
                    <div class="col-md-6">
                      <div class="nk-block-text">
                          <h2 class="title title-semibold">The HIDDIN Mission</h2>
                          <p>
                            <strong>1. Permanently</strong> hiding your identity, how you pay and get paid and the identities and payment details of everyone you communicate and conduct your business.
                          </p>
                          <p>
                            <strong>2. Prove</strong> who you are to those you contact then proves who they are to you before any information of value is exchanged with our patent pending reciprocal authentication technology.
                          </p>
                          <p><strong>3. Permanently</strong> put remote control in your hands with our reciprocal authentication as it makes time your weapon.</p>
                          <p><strong>4. Provide</strong> superior quantum resistant protections for everything you send and store with our patent pending version of deploying AES 256 symmetric encryption.</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                      <img src="./assets/img/01.png" alt="gfx">
                    </div>
                </div>
            </div>
          </div>
        </section>
        <section class="section bg-theme tc-light">
          <div class="container">
            <div class="nk-block nk-block-features-s2">
                <div class="row align-items-center gutter-vr-30px">
                    <div class="col-md-6">
                      <img src="./assets/img/01.png" alt="gfx">
                    </div>
                    <div class="col-md-6">
                        <div class="nk-block-text">
                            <h2 class="title title-semibold">Why HIDDIN does it! The Problem</h2>
                            <p>
                              The asymmetric encryption that protects your transactions, communications and the resulting stored data will fail you when quantum computers mature in the next five to ten years.
                            </p>
                            <p>
                              All the asymmetrically encrypted data from a lifetime of business and government interactions is at risk from this quantum decryption event. Any stores of your information relying on symmetric encryption and hashes at or below 128 characters are also vulnerable.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </section>
        <section class="section bg-theme tc-light">
          <div class="container">
            <div class="nk-block nk-block-features-s2">
                <div class="row align-items-center gutter-vr-30px">
                    <div class="col-md-6">
                        <div class="nk-block-text">
                            <h2 class="title title-semibold">What is the US Government Response?</h2>
                            <p>
                              The National Institute for Standards and Technology (NIST) is evaluating new forms of quantum resistant encryption to provide a temporary fix for this decryption problem. They could present several options for us to choose from by the first quarter of 2022. The last decryption event of this kind was in the 70's and it took almost 20 years to upgrade everything. We have a lot more data now, less time to get it done, and the costs of being too late is not acceptable.
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6">
                      <img src="./assets/img/01.png" alt="gfx">
                    </div>
                </div>
            </div>
          </div>
        </section>  
        <section class="section bg-theme tc-light">
          <div class="container">
            <div class="nk-block nk-block-features-s2">
                <div class="row align-items-center gutter-vr-30px">
                    <div class="col-md-6">
                        <div class="nk-block-text">
                            <h2 class="title title-semibold">The Government Encryption Solution</h2>
                            <p>
                              The encryption strategy works by hiding everything sent and stored using complex math until decryption technologies catch up simplifying that math revealing everything once hid. All forms of encryption are temporary and that can mean 5 months, 5 years or 50 years it is like any math problem. It is unsolved until the moment it is solved.
                            </p>
                            <p>
                              In the past as decryption tech caught up the quick fix going forward was to increase the size of the encryption keys and re-encrypt all your old data to stay ahead of the problem. The re-encryption strategy only works as long as no one has already copied your old data. Quantum computers are expected to make the increasing key size fix unworkable for asymmetric encryption and force symmetric key and hash sizes above 128 characters to become quantum resistant. (See why symmetric encryption alone is not a solution.)
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6">
                      <img src="./assets/img/01.png" alt="gfx">
                    </div>
                </div>
            </div>
          </div>
        </section>
        <section class="section bg-theme tc-light">
          <div class="container">
            <div class="nk-block nk-block-features-s2">
                <div class="row align-items-center gutter-vr-30px">
                  <div class="col-md-6">
                    <img src="./assets/img/01.png" alt="gfx">
                  </div>
                    <div class="col-md-6">
                        <div class="nk-block-text">
                            <h2 class="title title-semibold">The HIDDIN Solutions</h2>
                            <p>
                              1. HIDDIN deploys a different security strategy. Our first patent licensed from STRAT ID GIC removes your identity and payment details from the data we send and store never putting it at risk by issuing a one time used token instead. STRAT ID GIC technologies offer the only permanent security solutions for identity and payment details to this decryption problem. The STRAT ID GIC first patent did not protect any of the other data sent and stored. STRAT ID GIC’s patent pending technology covers that problem and HIDDIN has the license to develop it for our private sector applications.
                            </p>
                            <p>
                              2. The technology STRAT ID GIC submitted for patent protection 12/30/2020 provides reciprocal authentication solutions for both simple IOT devices and complex bidirectional devices. (See the post quantum remote control problem.) Reciprocal authentication mathematically proves the identity of the sender, to our server. Then our tech proves the identity of our server to the sender. Once reciprocally authenticated the exchange of valuable information or the establishment of a connection with a similarly authenticated party is permitted. (See the authentication limits of asymmetric encryption.)
                            </p>
                            <p>
                              No form of encryption is required in this process and no form of decryption can disrupt it. This technology constitutes a permanently secure method of reciprocal authentication for as long secrets remain secret.
                            </p>
                            <p>
                              3. STRAT ID GIC’s second patent pending technology also deploys our own method of deploying <strong>NIST </strong>level quantum resistant symmetric encryption. It provides temporary protections to all the data HIDDIN applications send and store. (See our version of symmetric encryption)
                            </p>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </section>  
        <section class="section bg-theme tc-light">
          <div class="container">
            <div class="nk-block nk-block-features-s2">
                <div class="row align-items-center gutter-vr-30px">
                    <div class="col-md-6">
                        <div class="nk-block-text">
                            <h2 class="title title-semibold">The HIDDIN and <strong>NIST </strong>differences</h2>
                            <p>
                              HIDDIN permanently secures identity and payment details and <strong>NIST </strong>can not. HIDDIN provides for reciprocal authentication of the parties involved in our transmission processes and <strong>NIST </strong>does not. HIDDIN generates above <strong>NIST </strong>level quantum resistant encryption protections that can quickly adapt to today's digital infrastructure and respond to future advances in decryption technologies. The <strong>NIST </strong>alternatives ability to adapt to our current digital infrastructure or respond to future advances in decryption technology is not known at this time. (See the HIDDIN <strong>NIST </strong>cost / timing differences)
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6">
                      <img src="./assets/img/01.png" alt="gfx">
                    </div>
                </div>
            </div>
          </div>
        </section>
        <section class="section bg-theme tc-light">
          <div class="container">
            <div class="nk-block nk-block-features-s2">
                <div class="row align-items-center gutter-vr-30px">
                  <div class="col-md-6">
                    <img src="./assets/img/01.png" alt="gfx">
                  </div>
                    <div class="col-md-6">
                        <div class="nk-block-text">
                            <h2 class="title title-semibold">How you can make a difference!</h2>
                            <p>
                              The HIDDIN Marketplace and Communication Platform built with STRAT ID GIC technologies is looking for an army of first adopter buyers and sellers to help us kick the tires on our pre-launch version of these technologies as we roll them out and adapt them to each communication and transaction processing channel and customize them for each industry type.
                            </p>
                            <p>
                              Buyers we need your feed back on what works and especially on what does not. Sellers we need more than just your feed back we need the help of your accountant. Your accountant can help maintain the balance between your operations limited ability to scale quickly and the HIDDIN Marketplace's digital platforms ability to scale your sales very quickly.
                            </p>
                            <p>
                              The only thing that might be worse than not having enough sales is selling more than you can afford to bring to market. You will only get one shot at a first impression with a new customer and you want that experience to be one that promotes a long term relationship. That is the kind of relationship we want with you and why we need your help with this pre-launch to get a chance at a good impression with everyone that will come after you.
                            </p>
                            <p>
                              This pre-launch is not just the introduction of a few new technologies it is part of a much greater effort to help make sure that we the people will finally take control of our identity, contact and marketing information. Then control our data in the future. Right now everyone we have ever done business with has collected identity, contact, and marketing information about us and likely sold it to others. It is never complete, never up to date, neither secure nor very useful. They control it and we the people do not. HIDDIN is changing that by altering the marketing dynamics between buyers and sellers and giving them reasons to cooperate. (See Benefits of the HIDDIN Marketplace)
                            </p>
                            <p>
                              De-identification of everyone's marketing, communication and transaction data makes it secure to send and store. It also makes it possible for HIDDIN to reunite marketing communication and transaction data without including any identity, contact or payment information. Sellers can query this data, form marketing campaigns that HIDDIN can then deliver to the correct parties anonymously. Then each buyer decides what if anything happens next. Sellers get the access they need and buyers get the control they have never had and everyone enjoys the efficiency and shares in the rewards HIDDIN provides for cooperating. (See the benefits of the HIDDIN Marketplace)
                            </p>
                            <p>
                              HIDDIN physically controls all the information on our platform but communally shares ownership with our buyers and their anonymity is critical to the success of the HIDDIN mission. Making our information available to sellers is how the bills get paid and where most of the rewards we share come from, but the data does not belong to them anymore. Should HIDDIN fail to honor that mission or breach the trust that communal ownership requires the buyer participation binding this venture together would be at risk of unraveling.
                            </p>
                            <p>
                              We need buyers and sellers that are tired of business as usual, that understand the urgency of this quantum threat, and believe the power of our data belongs to the people. Join us by sharing just enough of your identity and contact information so we can show you what it is like to go de-identified with our trial communication membership.
                            </p>
                            <p>
                              What use is a communication medium if you do not have anyone you want to contact? How are you going to find out what works and does not work if you have no one to use it with?
                            </p>
                            <p>
                              Everyone that joins our free trial is encouraged to invite at least twelve others and they will be able to invite twelve more. Now that you have someone to communicate with get at it! We will add the marketplace as soon as it is built so you can kick it around too.
                            </p>
                            <p>
                              If we do not take control of our information now with this effort we may never get another chance! It is our data only if we make it ours.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </section>
        <section class="section bg-theme tc-light">
          <div class="container">
            <div class="nk-block nk-block-features-s2">
                <div class="row align-items-center gutter-vr-30px">
                    <div class="col-md-6">
                        <div class="nk-block-text">
                            <h2 class="title title-semibold">Why Symmetric Encryption Alone Is Not A Solution.</h2>
                            <p>
                              The reasons for not using symmetric encryption for everything are that each reuse of the same symmetric key is revealing and all the key management problems associated with requiring a unique key for everyone you contact. For example Sue and BOB would need a unique key to encrypt and then decrypt the transmissions between them and then both Sue and Bob would need unique keys for all the other 998 people they would want to contact and then each of those people would need another 998 unique keys to contact all those same people. This creates a key management problem and when any key is comprised the parties that share that key must get yet another unique key. Then reusing that same key over time reveals a little about that key each time it is used.
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6">
                      <img src="./assets/img/01.png" alt="gfx">
                    </div>
                </div>
            </div>
          </div>
        </section>
        <section class="section bg-theme tc-light">
          <div class="container">
            <div class="nk-block nk-block-features-s2">
                <div class="row align-items-center gutter-vr-30px">
                  <div class="col-md-6">
                    <img src="./assets/img/01.png" alt="gfx">
                  </div>
                    <div class="col-md-6">
                        <div class="nk-block-text">
                            <h2 class="title title-semibold">Why Symmetric Encryption Alone Is Not A Solution.</h2>
                            <p>
                              The reasons for not using symmetric encryption for everything are that each reuse of the same symmetric key is revealing and all the key management problems associated with requiring a unique key for everyone you contact. For example Sue and BOB would need a unique key to encrypt and then decrypt the transmissions between them and then both Sue and Bob would need unique keys for all the other 998 people they would want to contact and then each of those people would need another 998 unique keys to contact all those same people. This creates a key management problem and when any key is comprised the parties that share that key must get yet another unique key. Then reusing that same key over time reveals a little about that key each time it is used.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </section>
        <section class="section bg-theme tc-light">
          <div class="container">
            <div class="nk-block nk-block-features-s2">
                <div class="row align-items-center gutter-vr-30px">
                    <div class="col-md-6">
                        <div class="nk-block-text">
                            <h2 class="title title-semibold">The Post Quantum Remote Control Problem</h2>
                            <p>
                              The use of asymmetric encryption to remotely control drones, spacecraft, automated cars, and any of our critical infrastructure IOT devices involved in the control of our electric grid, aqueducts, gas & oil pipelines, manufacturing processing plants and such will be useless in a post quantum world. Given the power, WiFi and CPU limitations of most IOT devices currently deployed their ability to adapt to the temporary protections that the <strong>NIST </strong>alternatives might offer is questionable. The answer to that question will determine how many of these IOT devices in the field will need to be replaced for us to retain remote control over our digital infrastructure.
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6">
                      <img src="./assets/img/01.png" alt="gfx">
                    </div>
                </div>
            </div>
          </div>
        </section>
        <section class="section bg-theme tc-light">
          <div class="container">
            <div class="nk-block nk-block-features-s2">
                <div class="row align-items-center gutter-vr-30px">
                  <div class="col-md-6">
                    <img src="./assets/img/01.png" alt="gfx">
                  </div>
                    <div class="col-md-6">
                        <div class="nk-block-text">
                          <h6 class="title title-xs title-s1 tc-primary">THE IOT USE CASE.</h6>
                            <h2 class="title title-semibold">HIDDIN Remote Control Solution</h2>
                            <p>
                              Reciprocal authentication is not just for phone calls and messaging. Our tech was originally designed for simple IOT devices with limited power, WiFi and CPU capabilities that might just need a little more program storage space to adapt to our tech. IOT devices power up when they have data to report and initiate a WiFi connection to a specific server recipient to synchronize time and then send the results of two simple calculations for the server receiving this information to mathematically prove the identity of this specific IOT device and send two simple calculations back to the IOT device for it to mathematically prove the identity of the server and establish the reciprocal trust that is required before deploying our proprietary version of generating AES 256 encryption keys to encrypt and send the data to report plus the results of two more simple calculations to the server to mathematically prove the identity of the IOT device again before accepting, decrypting and recording the data being reported to the server.
                            </p>
                            <p>
                              The last exchange of two calculated sums finishes out some house keeping issues to get ready for the next transmission between them that completely changes all the values held at both locations and proves that after those changes the data sets at both locations remain equal to one another. Sending a hash of one table to match to the hash of the other table proves both data sets are still equal if the hashes are equal without compromising the security of either data set.
                            </p>
                            <p>
                              To provide the IOT device with instructions the server encrypts the control instructions and sends them in the last exchange as they are both working on their house keeping issues. The IOT device then decrypts the instructions, records them, completes all housekeeping duties, and then executes the remote control instructions independently.
                            </p>
                            <p>
                              Computers, smart phones and the more sophisticated devices are not as limited by power, communication range, CPU capacities, program storage capabilities, and are able to sustain bidirectional communications that simple IOT devices can not. Bidirectional communications make data collection activities and receiving remote control instructions much more efficient. The extent today’s bidirectional devices depend upon asymmetric encryption for the protection of their remote control functions makes them vulnerable in a post quantum world.
                            </p>
                            <h6 class="title title-xs title-s1 tc-primary">THE BIDIRECTIONAL USE CASE</h6>
                            <p>
                              The earlier discussion of reciprocal authentication where Sue was contacting Bob was a demonstration of a bidirectional use case for messaging, phone calls and video connections that required Sue to select Bob and then the method of contacting Bob. Sue could have selected anyone listed in the directories available to her and any contact method that was made available to her.
                            </p>
                            <p>
                              The bidirectional use case for remote control the bidirectional devise although capable of using different contact methods is restricted for control purposes to only a few methods to engage in transmissions. The most important feature in any remote control environment is making sure the correct party has and maintains control at all times. The HIDDIN Implementation of reciprocal authentication records and uses time as a weapon against any attempts to interrupt our ability to establish and maintain remote control. It is our position that it will be impossible for some attacking force to first decipher our processes, change them to meet their objectives and then conduct our processes faster than we can conduct our processes knowing both the secret calculations and secret data sets that our processes require.
                            </p>
                            <p>
                              Completely changing the values of the variables in the secret calculations we use makes discovering any previously used calculations worthless for determining any future calculations. Changing the variables with each transmission adheres to the principal of maintaining perfect forward security. For these reasons we believe our processes permanently secure your ability to retain remote control.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </section>
        <section class="section bg-theme tc-light">
          <div class="container">
            <div class="nk-block nk-block-features-s2">
                <div class="row align-items-center gutter-vr-30px">
                    <div class="col-md-6">
                        <div class="nk-block-text">
                            <h2 class="title title-semibold">The Authentication Limits Of Asymmetric Encryption.</h2>
                            <p>
                              When Sue contacts Bob how does she know it is Bob she is contacting? When Bob gets contacted by Sue how does Bob know it is Sue that is contacting him? The asymmetric encryption we use today works with a private key that the person to receive contacts keeps to themselves and a public key they share with the world. Everyone just needs these two keys which is so much more efficient than the symmetric encryption explained earlier. Bob shares his public key so Sue can encrypt and send a message to BOB who is the only one with the private key needed to decrypt Sue’s message. If Sue hashed her message before encrypting it and included that hash value in the message she sent, Bob could open her message, use his private key to decrypt it, hash the message himself, and match it to the hash Sue sent so Bob can be confident that the message as sent has not been altered, but that does not prove that it was Sue that sent the message.
                            </p>
                            <p>
                              Sue could have registered with a certificate authority and created a digital signature that uses both types of encryption that she could have sent in the message before hashing and encrypting it. Then when Bob uses his private key to decrypt, confirms the hash, he could check the digital certificate against the certificate authority to prove the message was from Sue and the hash shows it had not been altered. Sue would still not know if Bob ever got her message unless Bob uses Sue’s public key and a hash to send an encrypted and hashed message with his digital certificate to Sue telling her he got the original message. In most cases this second transmission never happens and Sue just assumes Bob will get her message creating a weakness in the current process.
                            </p>
                            <p>
                              When quantum computers get real the whole public private key asymmetric encryption and the digital certificates based on asymmetric encryption will no longer work and we will need to increase the size of our hashes and the size of our symmetric keys above 128 characters for them to be viable. Providing a temporary fix to this authentication problem is part of what the <strong>NIST </strong>technologies will need to address when rolled out the first quarter of 2022.
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6">
                      <img src="./assets/img/01.png" alt="gfx">
                    </div>
                </div>
            </div>
          </div>
        </section>
        <section class="section bg-theme tc-light">
          <div class="container">
            <div class="nk-block nk-block-features-s2">
                <div class="row align-items-center gutter-vr-30px">
                  <div class="col-md-6">
                    <img src="./assets/img/01.png" alt="gfx">
                  </div>
                    <div class="col-md-6">
                        <div class="nk-block-text">
                            <h2 class="title title-semibold">Reciprocal Authentication with STRAT ID GIC</h2>
                            <p>
                              HIDDIN provides a permanent fix for this problem now, with STRAT ID GIC’s reciprocal authentication technology. Sue and Bob enroll with HIDDIN services and when Sue wants to contact Bob she signs into our service on one of her enrolled devices providing her password and credentials to prove who she is. The sign in process proves mathematically the identity of the enrolled device she is using to our server and our server proves mathematically the server’s identity to Sue’s enrolled device. Sue is then presented with her communication console where she can check any of her messages, missed calls, missed video contacts, or access one of her directories where Bob is listed to select him to make contact.
                            </p>
                            <p>
                              Once selected, Sue is presented with the contact methods Bob has made available for her at this time and on this day. The method chosen determines which of Bob’s enrolled devises will be used in this contact attempt. The server initiates a contact to Bob on the selected device and if the device is not available the contact method redirects to an established missed contact attempt messaging format and Sue is informed and allowed to leave a message that Bob can retrieve latter.
                            </p>
                            <p>
                              If the device is available the server provides a mathematical proof of the server’s identity to Bob’s device and the device reciprocates mathematically proving the device’s identity to the server and if accepted by the server Bob is prompted to engage the device directly and provide his personal credentials and password to prove he is Bob on Bob’s enrolled device. The Server upon confirmation of Bob’s information acts as the monkey in the middle between Bob and Sue for the contact method selected establishing a connection between them.
                            </p>
                            <p>
                              Mathematically proving the identity of Sue’s device to the server on sign in and then proving the server’s identity to Sue’s device establishes the trust we require between Sue and server that our reciprocal authentication makes possible. Once that same trust has been established between server and Bob with our reciprocal authentication process, the server can use that common trust with Sue and with Bob to establish a trustworthy anonymous connection between Sue and Bob. This arrangement assures Sue that she is contacting Bob and that Bob has been contacted by Sue. Incorporating one way hashes in excess of 128 characters in the messages sent between Sue and Bob provides them both with assurances that the content of these messages have not been altered. So what Sue intend for Bob is what he got and what Bob intended for Sue is what she gets.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </section>
        <section class="section bg-theme tc-light">
          <div class="container">
            <div class="nk-block nk-block-features-s2">
                <div class="row align-items-center gutter-vr-30px">
                    <div class="col-md-6">
                        <div class="nk-block-text">
                            <h2 class="title title-semibold">Our Version Of Symmetric Encryption</h2>
                            <p>
                              Our unique version of deploying symmetric encryption avoids the traditional key management and the same key reuse problems.
                            </p>
                            <p>
                              We provide a different symmetric key for each transmission event which adheres to the objective of perfect forward security. Our symmetric keys start at 256 characters well above the <strong>NIST </strong>initial quantum resistant level and our processes can be easily adapted to grow the keys we use 128 characters each time to stay ahead of any post quantum advances in technology. When required to make jumps of this kind to stay ahead of up and coming decryption technologies all past copies of stored data will need to be re-encrypted at the higher size keys to stay ahead as well.
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6">
                      <img src="./assets/img/01.png" alt="gfx">
                    </div>
                </div>
            </div>
          </div>
        </section>
        <section class="section bg-theme tc-light">
          <div class="container">
            <div class="nk-block nk-block-features-s2">
                <div class="row align-items-center gutter-vr-30px">
                  <div class="col-md-6">
                    <img src="./assets/img/01.png" alt="gfx">
                  </div>
                    <div class="col-md-6">
                        <div class="nk-block-text">
                            <h2 class="title title-semibold">The STRAT ID GIC <strong>NIST </strong>Cost / Timing Differences.</h2>
                            <p>
                              The actual costs in terms of time and resources to update our digital infrastructure with either STRAT ID GIC or any of the <strong>NIST </strong>alternatives are not as yet known. What we do know is that STRAT ID GIC holds the promise of benefits far greater than any implementation costs. Benefits of the HIDDIN Marketplace) We have no reason to expect benefits of a similar magnitude if any from implementation of the <strong>NIST </strong>alternatives.
                            </p>
                            <p>
                              STRAT ID GIC is ready to go to work protecting what you do going forward and start the job of reworking all your historical data right now. The <strong>NIST </strong>alternatives will not be decided on until the first quarter of 2022 and will not be available until after that as yet unknown date.
                            </p>
                            <p>
                              The re-encryption trick has resulted in asymmetric keys growing to 4096 characters, five times the size of symmetric keys at 128 characters. This difference in key size is why most encryption schemes use the less efficient but easier to use asymmetric key to send the smaller more secure one time used symmetric key to encrypt and decrypt the bulk of the data transmitted.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </section>
        <section class="section bg-theme tc-light">
          <div class="container">
            <div class="nk-block nk-block-features-s2">
                <div class="row align-items-center gutter-vr-30px">
                    <div class="col-md-6">
                        <div class="nk-block-text">
                            <h2 class="title title-semibold">The Benefits of the HIDDIN Marketplace</h2>
                            <p>
                              The STRAT ID GIC Benefits via licensing our tech to the HIDDIN Marketplace and Communication Platform
                            </p>
                            <p>
                              Removing identity and payment details from the data we send and store renders most of what is sent useless to would be identity thieves and hackers. It also kills the value for marketing and research purposes. Using our enrollment process to collect detailed marketing information from everyone into a de-identified data format allows our tech to reunite a person’s marketing profile with their communications and transactions without including or exposing any of their identity or contact information. The resulting research capabilities are unlike anything that has ever existed. A near real time research tool able to conduct queries that encompasses all industries that preserve the anonymity of all participants.
                            </p>
                            <p>
                              The HIDDIN Marketplace and Communication platform built with this technology makes use of the efficiencies inherent within the digital platform business model. These advantages will make this business forum the best informed and most efficacious place to do business.
                            </p>
                            <p>
                              Look back over the last few weeks and examine all the marketing and advertising you have been exposed to. What percentage of those experiences moved you to make a purchase or even consider making one? 5%? Surely less than 10%? The point is that 90-95% of those expenditures were wasted on you and most likely everyone else. Prove it for yourself and ask at least ten people this same question to get a feel for the answer.
                            </p>
                            <p>
                              The concept of a market is to arrange for the exchange of information required for the buyer to make a decision and the seller to facilitate a transaction. The whole marketing and advertising thing boils down to exchanging information no matter what is being bought and sold. Your answer to the marketing question illustrates just how inefficient traditional marketing and advertising efforts are working.
                            </p>
                            <p>
                              Gartner the marketing experts say that close to 11% of what we pay for B2C items and 6-7% of what we pay for B2B items goes to pay for marketing and advertising and in the US that is close to 400 billion dollars a year. What is worse that 90-95% of those expenditures are likely wasted or that you as one of those buyers is paying for all that waste? There has to be a better way and there is.
                            </p>
                            <p>
                              The HIDDIN Marketplace and Communication Platform will charge sellers transaction fees targeting their industries historical spend on advertising and marketing. (Their industries share of that 400 billion dollars.) Leveraging our platforms superior research and internal communication capabilities and the efficiencies of deploying a digital platform business model approach our efforts should be far more productive than the traditional methods.
                            </p>
                            <p>
                              Our unique competitive advantage over traditional marketing and advertising (M&A) is the degree of buyer participation we inspire. Remember that 400 billion dollars buyers end up paying each year that we are going to collect at the point of sale in a transaction fee? HIDDIN is only taking 60% of it and using the other 40% to inspire our buyers and sellers to create a new working dynamic between them. Every percentage a buyer gets effectively lowers their costs creating an almost equal competitive advantage for our sellers with lower pricing compared to the sellers off platform competition. Remember we are starting with the seller’s traditional marketing and advertising spend adding platform efficiencies, inspired buyer participation and then reducing that cost by the seller’s share of the 40% with the competitive boost of a lower price point compared to their off platform competition for every percentage the buyer gets and the benefits do not stop there.
                            </p>
                            <p>
                              The Birds Eye View comparing traditional M&A and The HIDDIN Marketplace and Communication Platform.
                            </p>
                            <p>
                              From on high, looking down on all the different sellers working traditional M&A reveals that every seller is conducting their own marketing, research, and advertising activities typically working with old, borrowed or purchased data. Most of their efforts create data security and liability issues for themselves, their customers and their prospects generating data of questionable quality and timeliness. Every new customer added has an impact of one on just that seller.
                            </p>
                            <p>
                              Looking down on The HIDDIN Marketplace and Communication Platform shows every seller working with one data set spanning the entire marketplace that is continuously updated by every communication and transaction of every buyer enrolled in the market place. A closer look at the data itself shows it is void of identity, contact information, and payment details making it perfectly safe to work with and every transmission event has its own unique token.
                            </p>
                            <p>
                              The buyers are seen willingly participating in surveys and buyer decision reasoning studies where they explain why they purchased or did not purchase an item. This gives sellers the feed back they need to design more effective future marketing campaigns. Buyers cooperate to get their share of the 40%, escape all the worthless ads that waste buyer’s time, and because they realize that they are eventually paying for all these ads in the prices they do pay.
                            </p>
                            <p>
                              Every new customer enrolled into the HIDDIN marketplace has a potential multiplier impact on the marketplace equal to the number of sellers currently enrolled. All those sellers now have access to that new customer’s de-identified marketing profile information and all their de-identified communication and transaction data. Utilizing the HIDDIN Marketplace and Communication Platform every seller now can conduct marketing queries that will include this new potential customer. Then formulate marketing campaigns that our communication platform can deliver to these new customers based on their de-identified marketing information rather than their identity. The process preserves their anonymity while delivering the sellers marketing campaign to precisely the buyers the seller wants to reach.
                            </p>
                            <p>
                              Every new seller enjoys a potential multiplier impact equal to the number of buyers currently enrolled in the HIDDIN Marketplace less the number of their existing customers already enrolled. The new seller now has access to every enrolled buyer that was not already their customer and their de-identified marketing information that will help them both effectively determine if they should do business together. These multiplier impacts are unique to the HIDDIN Marketplace and give startups unprecedented access to marketing research and advertising capabilities on all the buyers enrolled in our marketplace. Designing, building and implementing these marketing research and advertising campaigns are offered at our cost plus 25% and then we credit a third of these costs against the transaction fees we collect at the point of sales. It does not get any more cost efficient than that especially when you factor in the 40% of those traditional costs we give back to our buyers and sellers to inspire them to work together in this new marketplace dynamic.
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6">
                      <img src="./assets/img/01.png" alt="gfx">
                    </div>
                </div>
            </div>
          </div>
        </section>
        <app-footer></app-footer>
      </div>
    </div>
  </div>
</ion-content>
