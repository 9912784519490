<app-header-left-menu [user]="user"></app-header-left-menu>
<ion-content id="main-content" class="full-width-page">
  <div class="bg-theme-alt">
    <div class="bg-grad-special">
      <div class="bg-grad-special-alt">
        <div class="nk-ovm shape-u shape-contain shape-left-top"></div>
        <app-header [user]="user"></app-header>
        <div class="subContent">
          <div class="main-content container">
            <h5 class="ath-heading title">Terms of Use</h5>
            <h3 class="title title-md">
              Terms of Use HIDDIN
            </h3>
            <p>Please read these Terms of Use carefully before using the https://hiddin.net website website operated by HIDDIN Inc. Your access to and use of the website is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use this website.</p>
            <p><b>By accessing or using the website you agree to be bound by these Terms.If you disagree with any part of the terms then you may not access the website.</b></p>
            <h3 class="title title-md">
              Enroll
            </h3>
            <p>If you wish to enroll to take part in any free trial offers made available through the website ("Enroll"),you may be asked to supply certain information relevant to your Enrollment including, without limitation, your Name, address, smart phone number, e-mail address.</p>
            <h3 class="title title-md">
              Termination
            </h3>
            <p>We may terminate or suspend access to our Service immediately, without prior notice or liability,for any reason whatsoever, including without limitation if you breach the Terms. All provisions of the Terms which by their nature should survive termination shall survive termination,including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>
            <h3 class="title title-md">
              Content
            </h3>
            <p>Our Services allows you to communicate, post, link, store, share and otherwise make available certain information, text, graphics, videos, or other material ("Content"). You are responsible for the ...</p>
            <h3 class="title title-md">
              Links To Other Web Sites
            </h3>
            <p>Our Service may contain links to third-party web sites or services that are not owned or controlled by <b>HIDDIN</b>.</p>
            <p><b>HIDDIN</b> has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that <b>HIDDIN</b> shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.</p>
            <h3 class="title title-md">
              Changes
            </h3>
            <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we willtry to provide at least 30 days' notice prior to any new terms taking effect.What constitutes a material change will be determined at our sole discretion.</p>
            <h3 class="title title-md">
              Contact Us
            </h3>
            <p>If you have any questions about these Terms, please contact us. <br/>
              Agreements like this are usually composed with the following clauses added:</p>
            <ul>
              <li>Introduction</li>
              <li>User Content, if the website or mobile app allows for users to create or share content</li>
              <li>Copyright infringement or DMCA (DMCA is for US only)</li>
              <li>Warranty</li>
              <li>Limitation of Liability</li>
              <li>Indemnification</li>
              <li>Arbitration</li>
              <li>Choice of law or Governing law</li>
            </ul>
            <h3 class="title title-md">
              Sample disclosures for Terms of Use
            </h3>
            <p>Our Terms of Use agreement should include at least these disclosures to protect your business from abuses. Then additional clauses to cover other situations:</p>
            <ul>
              <li>The text where you state that if your users don’t agree with the terms you posted online, in the presented agreement, they can not register an account.</li>
              <li>The enforceability of your agreement is important and it depends if you implement this agreement as a browsewrap or as a clickwrap agreement.</li>
              <li>A warranty disclaimer: this section discloses that you can not guarantee that the information provided on your website or mobile app is accurate, complete or suitable for any purpose.</li>
              <li>A termination disclosure in which you will reserve the right to terminate accounts that you may think are abusive towards other users of the community or against your business</li>
              <li>And so on</li>
            </ul>
            <h3 class="title title-md">
              Termination disclosure
            </h3>
            <p>
              We may terminate your access to the Site, without cause or notice, which may result in the forfeiture and destruction of all information associated with you. All provisions of this Agreement that by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability. The Terms of Service page of The Guardian mentions a similar “Termination” clause:<br/>
              It Reads:
            </p>
            <p>If, for any reason, we believe that you have not complied with these terms and conditions, we may, at our sole discretion, cancel your access to the registration areas of Guardian Site immediately and without prior notice.</p>
            <p>If you no longer wish to have a registered account, you may terminate your account by sending an email to "admin&#64;hiddin.com". if you no longer accept these terms and conditions, or any future modification to these terms and conditions, you must cease using the Guardian Site. Continued use of the Guardian Site indicate you continued acceptance of these terms and conditions.</p>
            <p>If, for any reason ,we believe that you have not complied with these terms and conditions, we may, at our sole discretion,cancle your access to the registration areas of Guardian Site immediatly and without prior notic.
              We may terminate your registered account, at our solo discretion, by emailing you at the address you have registered stating that the agreement has terminated.</p>
            <h3 class="title title-md">
              Intellectual Property disclosure
            </h3>
            <p>
              Another useful clause is the “Intellectual Property” which can explain to users that the content, logo, and design of your website or mobile is your property (your company’s intellectual property).<br/>
              The Site and its original content, features, and functionality are owned by My Company and are protected by international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws. The Terms of Use page of BBC includes a <b>“Rights in BBC Online Services and BBC Content”</b> disclosure that clearly explains that the content is owned by BBC.
            </p>
            <p>A Terms of use agreement is useful even if you have a general, presentation-only, website or mobile app as you can limit abuses that some users may do to your business.</p>
            <h3 class="title title-md">
              Download Terms of Use Template
            </h3>
            <p>Use the <b>Terms of Use Generator</b> to create this legal agreement.If you’re looking for the template, download the Terms of Use Template as a PDF file or download the Terms of Use Template as a DOCX file. It’s free. This free <b>Generic Terms of Use Template</b> is available to download and it includes the following sections</p>
            <ul>
              <li>Introduction</li>
              <li>Termination</li>
              <li>Links To Other Websites</li>
              <li>Governing Law</li>
              <li>Changes To This Terms of Use</li>
              <li>Contact Information</li>
            </ul>
            <h3 class="title title-md">
              Reason #1: Prevent Abuses
            </h3>
            <p>
              A Terms and Conditions acts as a legally binding contract between you and your users.is is the agreement that sets the rules and guidelines that users must agree to and follow in order to use and access your website or mobile app. he Privacy Policy agreement informs users what kind of data you collect and how you are using that data. In this agreement, you can include the necessary sections to inform users of the guidelines of using your website or mobile app, what happens if users are abusing your website or mobile app, and so on.<br/>
              Examples of actions of abusive users can include: spamming other users, posting defamatory content, etc.If your website or mobile app hosts content that is generated by users, you can include a clause in the Terms and Conditions to inform users that harmful language won’t be tolerated, as well as spamming other users (depending on the function of your website: via public or private messages). All of these can result in having those users who are found abusing your website temporarily banned.eBay informs users of the guidelines for using the website in their User Agreement.
            </p>
            <h3 class="title title-md">
              Reason #2: Own Your Content
            </h3>
            <p>
              The website owner, you’re the owner of your logo, content (except for user-generated content, as most websites will inform users that any content created by users is theirs), the design of the website, and so on. In the Terms and Conditions, you can inform users that you are the owner of such content (as mentioned above) and thatthe content you own is protected by international copyright laws. This kind of clause is commonly referred as the Intellectual Property clause, and it usually looks like this:<br/>
              The Site and its original content, features, and functionality are owned by [Owner of Website] and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws. The Terms of Use page of BBC includes a Rights in BBC Online Services and BBC Content disclosure that clearly explains that the content is owned by BBC.
            </p>
            <h3 class="title title-md">
              Reason #3: Terminate Accountst
            </h3>
            <p>
              Suggested that you could temporarily ban users, another common clause that Terms and Conditions agreements include is the Termination clause. This clause informs users that abusive accounts will be terminated and banned from using the service.The Termination clause is aimed at websites that have a registration section (e.g. user must register before using and/or accessing certain sections of the website), as you can disable or ban the abusive users based on the activity of their accounts.The Termination clause usually looks like: We may terminate your access to the Site, without cause or notice, which may resultin the forfeiture and destruction of all information associated with your account. All provisions of this Agreement that, by their nature, should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability. Here is how the Terms & Conditions ofThe Guardian presents this clause.
            </p>
            <h3 class="title title-md">
              Reason #4: Limit Liability
            </h3>
            <p>
              Terms and Conditions agreements commonly include a warranty disclaimer that tries to limit the website owner’s liability in cases where errors are found in the content presented on the website.<br />
              This kind of clause notifies users that the owner can’t be held responsible for any errors in the content presented, or for the information provided being accurate, complete, or suitable for any purpose.
            </p>
            <h3 class="title title-md">
              Reason #5: Set The Governing Law
            </h3>
            <p>
              Jurisdiction that applies to the terms presented in the agreement.For instance, if your website is operated by a registered business in the state of New York in the United States, then the governing law of your Terms and Conditions would be presented something like this: These terms and conditions are governed by the laws of the United States of America and the laws of the State of New York.<br />
              If you (or your company) operate the website from London in the United Kingdom, that clause can look something like this:These terms and conditions are governed by the laws of United Kingdom.<br />
              If you operate your website from another country (Australia, UK, Canada, South Africa), update the agreement to include the home country or the country in which your company (that owns and operates the website) is registered in
            </p>
            <p><b>PLEASE READ THESE WEBSITE TERMS OF USE CAREFULLY BEFORE USING THIS WEBSITE (THE "WEBSITE"). THESE WEBSITE TERMS OF USE (THE "TERMS OF USE") GOVERN YOUR ACCESS TO AND USE OF THE WEBSITE. THE WEBSITE IS AVAILABLE FOR YOUR USE ONLY ON THE CONDITION THAT YOU AGREE TO THE TERMS OF USE SET FORTH BELOW. IF YOU DO NOT AGREE TO ALL OF THE TERMS OF USE, DO NOT ACCESS OR USE THE WEBSITE. BY ACCESSING OR USING THE WEBSITE, YOU AND THE ENTITY YOU ARE AUTHORIZED TO REPRESENT ("YOU" OR "YOUR") SIGNIFY YOUR AGREEMENT TO BE BOUND BY THE TERMS OF USE.</b></p>
          </div>
        </div>
        <app-footer></app-footer>
      </div>
    </div>
  </div>
</ion-content>
