import { Component, OnInit } from '@angular/core';
import { IonContent, MenuController, ModalController } from '@ionic/angular';
import { AuthServiceService } from '@services/auth-service.service';
import { RegUser } from '../basic-reg/RegUser';
@Component({
  selector: 'app-profile-enrichment',
  templateUrl: './profile-enrichment.component.html',
  styleUrls: ['./profile-enrichment.component.scss'],
})
export class ProfileEnrichmentComponent implements OnInit {
  showId: string = '';
  user: RegUser = new RegUser();

  constructor(public modalController: ModalController,
    public authService: AuthServiceService) { }

  ngOnInit() { }

  toggleShow(id: string) {
    this.showId = id;
  }

  ionViewDidEnter() {
    this.user = this.authService.getLoggedInUser();
    this.user.imageUrl = this.authService.getProfilePicture();
  }

}
