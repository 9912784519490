import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-confirmation-box',
  templateUrl: './confirmation-box.component.html',
  styleUrls: ['./confirmation-box.component.scss'],
})
export class ConfirmationBoxComponent implements OnInit {

  @Input("title") title: string;
  @Input("message") message: string;
  @Input("subtitle") subtitle: string;

  constructor(public modalCtrl: ModalController) { }

  ngOnInit(): void {
  }

  async dismiss(data) {
    this.modalCtrl.dismiss(data);
  }

}
