import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-confirm-for-upgrade-to-business',
  templateUrl: './confirm-for-upgrade-to-business.component.html',
  styleUrls: ['./confirm-for-upgrade-to-business.component.scss'],
})
export class ConfirmForUpgradeToBusinessComponent implements OnInit {

  constructor(public modalController: ModalController) {

  }

  ngOnInit() { }

  async dismiss(data) {
    this.modalController.dismiss(data);
  }

}
