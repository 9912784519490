import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Globals } from 'src/app/globals';
import { AuthServiceService } from '@services/auth-service.service';
import { LoaderService } from '@services/loader.service';
import { AlertController } from '@ionic/angular';
import { RegUser } from '../basic-reg/RegUser';
import { CustomValidators } from 'src/app/util/customname.validator';

@Component({
  selector: 'app-search-section',
  templateUrl: './search-section.component.html',
  styleUrls: ['./search-section.component.scss'],
})
export class SearchSectionComponent implements OnInit {

  searchField: string[];
  searchTerm: string[];

  userSearchForm: FormGroup;
  dataDetailSection = false;
  selectedItem = {};
  contactIds: number[] = [];

  errorMessage: string;
  successMessage: string;
  requestPath: string;

  user: RegUser = new RegUser();
  profileImageSrc: any;

  ownerId: number;
  contactAdded: boolean = false;
  contactId: number;
  searchCriteria: string;

  searchTermErrors: string = null;
  contactCategory: string = "friends";
  searchDisplayCriteria: string = null;

  searchPerformed: boolean = false;
  searchResults: [] = [];
  contactTimePreference: any;
  p: number = 1;
  nameRegexp: RegExp = /[!#$%^&*()_+\-=\[\]{};:"\\|,.<>\/?`]/;

  constructor(private fb: FormBuilder,
    public global: Globals,
    public authService: AuthServiceService,
    public formBuilder: FormBuilder,
    public router: Router,
    private loader: LoaderService,
    private customValidator : CustomValidators,
    public atrCtrl: AlertController) {
    this.requestPath = this.global.requestPath;
  }

  ngOnInit() {
    this.contactIds = this.router.getCurrentNavigation().extras.state.contactIds;
    this.userSearchForm = this.fb.group({
      searchRow: this.fb.array([this.initItemRows()]),
    });
  }

  ionViewDidEnter() {
    this.ownerId = this.authService.getLoggedInUser().id;

    this.user = this.authService.getLoggedInUser();
    this.profileImageSrc = this.authService.getProfilePicture();
    this.user.imageUrl = this.profileImageSrc;
  }

  get formArry() {
    return this.userSearchForm.get('searchRow') as FormArray;
  }

  initItemRows() {
    return this.fb.group({
      searchField: '',
      searchTerm: ["", [Validators.required, this.customValidator.nameValidator]]
    });
  }

  addSearchDetails(val: string, n: number) {
    this.selectedItem[n] = val
    this.formArry.push(this.initItemRows())
  }

  capitalizeFirstLetter(text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  isSelectedItem(val: string, n: number) {
    return this.selectedItem[n] && this.selectedItem[n] === val;
  };

  async presentMissingSearchTermAlert(msg) {
    const alert = await this.atrCtrl.create({
      cssClass: 'my-custom-class',
      header: 'Alert',
      message: msg,
      buttons: ['OK']
    });

    await alert.present();
  }

  searchUserDetails(pageNo) {
    this.contactAdded = false;
    this.dataDetailSection = false;
    this.searchResults = null;
    this.searchTermErrors = null;
    if (this.formArry.status == "INVALID") {
      this.presentMissingSearchTermAlert('Please select a search field and enter search term and special characters are not allowed in search term [!#$%^&*()_+\-=\[\]{};:"\\|,.<>\/?`].');
      return;
    }

    this.loader.show("Searching Hiddin users...");
    this.searchCriteria = "";
    this.searchDisplayCriteria = "";
    for (var i = 0; i < this.formArry.value.length; i++) {
      this.searchCriteria += this.formArry.value[i].searchField + ':' + this.formArry.value[i].searchTerm + ",";
      this.searchDisplayCriteria += this.capitalizeFirstLetter(this.formArry.value[i].searchField) + ': ' + this.formArry.value[i].searchTerm + ", ";
    }
    this.searchCriteria = this.searchCriteria.substring(0, this.searchCriteria.length - 1);
    this.searchDisplayCriteria = this.searchDisplayCriteria.substring(0, this.searchDisplayCriteria.length - 2);

    this.authService.get('/user/directory?' + this.requestPath + '=search' + '&pageNum=' + pageNo + '&searchTerm=' + this.searchCriteria + "&isAdvanceSearch=true")
      .subscribe((res: any) => {
        this.searchPerformed = true;
        this.loader.hide();
        if (res.success) {
          this.dataDetailSection = true;
          this.searchResults = this.global.decrypt(res.data);
          //this.searchResults = res.data;
        } else {
          this.searchTermErrors = res.message;
        }
      }, (error: any) => {
        this.loader.hide();
        this.searchTermErrors = "Error occured";
      });
  }

  addContact() {
    let userContact = {
      "ownerId": this.ownerId,
      "contactId": this.contactId,
      "category": this.contactCategory,
      "status": "Requested",
      "startTime": this.contactTimePreference?.startTime,
      "endTime": this.contactTimePreference?.endTime
    };
    this.contactAdded = false;

    this.loader.show("Please wait...");
    this.authService.postEncrypt('/user/directory?' + this.requestPath + '=save', userContact)
      .subscribe((res: any) => {
        this.loader.hide();
        if (res.success) {
          // Reinitialized the form state
          this.contactAdded = true;

          this.dataDetailSection = false;
          this.searchResults = [];
        } else {
          this.searchTermErrors = res.message;
        }
      }, (error: any) => {
        this.loader.hide();
        this.searchTermErrors = "Something went wrong, please try again.";
      });
  }


  async showContactCategoryOptions(userId: number) {
    this.contactId = userId;

    const alert = await this.atrCtrl.create({
      cssClass: 'preference-box',
      header: 'Contact Preference',
      inputs: [
        {
          name: 'favorites',
          type: 'radio',
          label: 'Favorites',
          value: 'favorites',
          checked: false,
          handler: () => {
            this.contactCategory = 'favorites';
          }
        },
        {
          name: 'family',
          type: 'radio',
          label: 'Family',
          value: 'family',
          checked: false,
          handler: () => {
            this.contactCategory = 'family';
          }
        },
        {
          name: 'work',
          type: 'radio',
          label: 'Work',
          value: 'work',
          checked: false,
          handler: () => {
            this.contactCategory = 'work';
          }
        },
        {
          name: 'friends',
          type: 'radio',
          label: 'Friends',
          value: 'friends',
          checked: true,
          handler: () => {
            this.contactCategory = 'friends';
          }
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          cssClass: 'preference-button',
          handler: () => {

          }
        },
        {
          text: 'Time Preference',
          cssClass: 'preference-button',
          handler: () => {
            this.openTimePreferenceModal();
          }
        }
      ]
    });
    await alert.present();
  }

  async openTimePreferenceModal() {
    const alert = await this.atrCtrl.create({
      cssClass: 'preference-box',
      header: 'Time Preference',
      inputs: [
        {
          name: 'startTime',
          type: 'time'
        },
        {
          name: 'endTime',
          type: 'time'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'preference-button',
          handler: () => {
            // console.log('Confirm Cancel');
          }
        }, {
          text: 'Add Contact',
          cssClass: 'preference-button',
          handler: (values) => {
            this.contactTimePreference = values;
            this.addContact();
          }
        }
      ]
    });

    await alert.present();
  }

  openProfile(user: any, editEnabled: boolean) {
    this.router.navigateByUrl('/profile-details', { state: { 'user': user, 'editEnabled': editEnabled } });
    return false;
  }

  resetRows() {
    this.formArry.controls.splice(1)
    this.dataDetailSection = false;
  }

  changeType() {

  }

  goTo(link: string) {
    this.router.navigateByUrl(link)
  }

  closeResults() {

  }
  pagechange = function (event: any) {
    // console.log(event);
    this.p = event;
    if ((event >= ((5 * this.lastLoadedPage) - 1))) {
      this.searchUserDetails(this.lastLoadedPage + 1);
    }
  }
}
