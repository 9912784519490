import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthServiceService } from '@services/auth-service.service';
import { LoaderService } from '@services/loader.service';
import { Globals } from 'src/app/globals';
import { environment } from 'src/environments/environment';
import { RegUser } from '../basic-reg/RegUser';

@Component({
  selector: 'app-subscription-done',
  templateUrl: './subscription-done.component.html',
  styleUrls: ['./subscription-done.component.scss'],
})
export class SubscriptionDoneComponent implements OnInit {

  requestPath: string;
  
  errorMessage: string;
  successMessage: string;

  paymentSuccessful;

  sessionId;
  transactionNo;

  user: RegUser;

  constructor(public global: Globals
    , public authService: AuthServiceService
    , private loader: LoaderService
    , private route: ActivatedRoute
    , private router: Router
    , private http: HttpClient) {

    this.requestPath = this.global.requestPath;

    this.route.params.subscribe(params => {

      this.paymentSuccessful = params['success'];
      this.transactionNo = params['transactionNo'];
      this.sessionId = params['sessionId'];

      // console.log("paymentSuccessful: " + this.paymentSuccessful);
      // console.log("transactionNo: " + this.transactionNo);
      // console.log("sessionId: " + this.sessionId);

      if (this.global.isEmpty(this.transactionNo) || this.global.isEmpty(this.sessionId) || this.global.isEmpty(this.paymentSuccessful)) {
        this.router.navigate(['/home']);
      }

      if (this.paymentSuccessful == "true") {
        this.checkForTransaction();
      }
    });
  }

  ngOnInit() { }

  ionViewDidEnter() {
    this.user = this.authService.getLoggedInUser();
  }

  checkForTransaction = function () {
    
    this.errorMessage = null;
    this.successMessage = null;
    
    this.loader.show("Validating transaction...");
    var path = "history/session/" + this.sessionId;
    this.http.get(environment.paymentURL + path,
      {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
      }).subscribe((res) => {
        this.loader.hide();
        // console.log(res);
        if (res.success) {
          if (!this.global.isEmpty(res.data)) {
            this.renew();
          } else {
            this.errorMessage = "Invalid transaction found";
          }
        } else {
          // console.log(res.message);
          this.errorMessage = res.message;
        }
      }, error => {
        this.loader.hide();
        // console.log(error);
        this.errorMessage = "Error occured";
      });
  }

  renew() {
    this.errorMessage = null;
    this.successMessage = null;

    this.loader.show("Please wait...");
    this.authService.postEncrypt('subscription?' + this.requestPath + '=renewSubscription', null)
      .subscribe(res => {
        this.loader.hide();

        // console.log(res);
        if (res.success) {
          this.successMessage = "You have successfully renewed subscription.";
          this.global.saveLocalData(this.global.SUBSCRIPTION, JSON.stringify(false));
        } else {
          this.errorMessage = res.message;
        }
      }, error => {
        this.loader.hide();
        // console.log(error);
        this.errorMessage = "Something went wrong, please try again.";
      });
  }

}
