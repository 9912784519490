<app-header-left-menu [user]="signupInput"></app-header-left-menu>
<ion-content id="main-content" class="full-width-page">
  <div class="bg-theme-alt">
    <div class="bg-grad-special">
      <div class="bg-grad-special-alt">
        <div class="nk-ovm shape-u shape-contain shape-left-top"></div>
        <app-header [user]="signupInput"></app-header>
        <div class="subContent">
          <div class="main-content container">
            <div class="complete-reg">
              <h5 class="ath-heading title">Complete Registration</h5>
              <form [formGroup]="regForm">
                <div class="step-1 animated fadeInRight" data-animate="fadeInRight" data-delay=".35"
                  style="visibility: visible; animation-delay: 0.35s;" *ngIf="stepCounter === 1">
                  <!-- <ul class="d-flex flex-wrap gutter-30px">
                    <li class="mb-3">
                      <div class="field-wrap"><input class="input-radio" id="Individual" name="rdi" type="radio"
                          required="" aria-required="true" (click)="setType('Individual')" [checked]="true"><label
                          for="Individual"><span class="title title-xs tc-primary">Individual</span></label></div>
                    </li>
                    <li class="mb-3">
                      <div class="field-wrap"><input class="input-radio" id="Business" name="rdi" type="radio"
                          required="" aria-required="true" (click)="setType('Business')"><label for="Business"><span
                            class="title title-xs tc-primary">Business</span></label></div>
                    </li>
                  </ul> -->
                  <div class="flex-form">
                    <div class="width-50">
                      <div class="field-item">
                        <div class="field-wrap">
                          <h6 class="title title-xs">User Name<span class="red">*</span></h6>
                          <input type="text" formControlName="userName" class="input-bordered"
                            [(ngModel)]="signupInput.username"
                            [ngClass]="{ 'is-invalid': submitted && f.userName.errors }" placeholder="User Name">
                          <div *ngIf="submitted && f.userName.errors" class="invalid-feedback">
                            <div *ngIf="f.userName.errors.required">User Name is required</div>
                            <div *ngIf="f.userName.errors?.maxlength">User Name can be max 100 characters long</div>
                          </div>
                        </div>
                      </div>
                      <div class="field-item" *ngIf="type ==='Individual'">
                        <div class="field-wrap">
                          <h6 class="title title-xs">First Name<span class="red">*</span></h6>
                          <input type="text" formControlName="firstName" class="input-bordered"
                            [(ngModel)]="signupInput.firstName"
                            [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" placeholder="First Name"
                            required>
                          <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                            <div *ngIf="f.firstName.errors.required">First Name is required</div>
                            <div *ngIf="f.firstName.errors?.maxlength">First Name can be max 100 characters long</div>
                          </div>
                        </div>
                      </div>
                      <div class="field-item" *ngIf="type ==='Individual'">
                        <div class="field-wrap">
                          <h6 class="title title-xs">Middle Name</h6>
                          <input type="text" formControlName="middleName" class="input-bordered"
                            [(ngModel)]="signupInput.middleName" placeholder="Middle Name">
                          <div *ngIf="submitted && f.middleName.errors" class="invalid-feedback">
                            <div *ngIf="f.middleName.errors?.maxlength">Middle Name can be max 100 characters long.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="field-item" *ngIf="type ==='Individual'">
                        <div class="field-wrap">
                          <h6 class="title title-xs">Last Name<span class="red">*</span></h6>
                          <input type="text" formControlName="lastName" class="input-bordered"
                            [(ngModel)]="signupInput.lastName"
                            [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" placeholder="Last Name"
                            required>
                          <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                            <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                            <div *ngIf="f.lastName.errors?.maxlength">Last Name can be max 100 characters long.</div>
                          </div>
                        </div>
                      </div>
                      <div class="field-item">
                        <div class="field-wrap">
                          <h6 class="title title-xs">Email</h6>
                          <input type="email" formControlName="emailAddress" class="input-bordered"
                            [(ngModel)]="signupInput.email" placeholder="Your Email">
                        </div>
                      </div>
                      <!-- <div class="field-item">
                        <div class="field-wrap">
                          <h6 class="title title-xs">Password</h6>
                          <input type="password" formControlName="userPassword" class="input-bordered"
                            [(ngModel)]="signupInput.userPassword"
                            [ngClass]="{ 'is-invalid': submitted && f.userPassword.errors }" placeholder="Password"
                            id="pwd" onPaste="return false" required>
                          <div *ngIf="submitted && f.userPassword.errors" class="invalid-feedback">
                            <div *ngIf="f.userPassword.errors.required">Password is required
                            </div>
                            <div *ngIf="f.userPassword.errors.minlength">Password must be at
                              least 6 characters</div>
                          </div>
                        </div>
                      </div>
                      <div class="field-item">
                        <div class="field-wrap">
                          <h6 class="title title-xs">Confirm Password</h6>
                          <input type="password" formControlName="confirmPassword" class="input-bordered"
                            [(ngModel)]="signupInput.confirmPassword"
                            [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }"
                            placeholder="Repeat Password" onPaste="return false" required>
                          <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                            <div *ngIf="f.confirmPassword.errors.required">Confirm Password
                              is required</div>
                            <div *ngIf="f.confirmPassword.errors.mustMatch">Passwords must
                              match</div>
                          </div>
                        </div>
                      </div> -->
                      <div class="field-item" *ngIf="type ==='Business'">
                        <div class="field-wrap">
                          <h6 class="title title-xs">Company Name<span class="red">*</span></h6>
                          <input type="text" class="input-bordered" formControlName="company" class="input-bordered"
                            [(ngModel)]="signupInput.company"
                            [ngClass]="{ 'is-invalid': submitted && f.company.errors }" placeholder="Your Company Name"
                            required>
                          <div *ngIf="submitted && f.company.errors" class="invalid-feedback">
                            <div *ngIf="f.company.errors.required">Company Name is required</div>
                            <div *ngIf="f.company.errors?.maxlength">Company Name can be max 100 characters long.</div>
                          </div>
                        </div>
                      </div>
                      <div class="field-item" *ngIf="type ==='Business'">
                        <div class="field-wrap">
                          <h6 class="title title-xs">Contact Person<span class="red">*</span></h6>
                          <input type="text" class="input-bordered" formControlName="firstName" class="input-bordered"
                            [(ngModel)]="signupInput.firstName"
                            [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" placeholder="Contact Person"
                            required>
                          <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                            <div *ngIf="f.firstName.errors.required">Contact Person Name is required</div>
                            <div *ngIf="f.firstName.errors?.maxlength">Contact Person Name can be max 100 characters
                              long.</div>
                          </div>
                        </div>
                      </div>
                      <div class="field-item">
                        <div class="field-wrap">
                          <h6 class="title title-xs">Phone Number</h6>
                          <input type="text" id="phone" class="input-bordered" formControlName="phoneNumber"
                            [(ngModel)]="signupInput.phoneNumber" placeholder="Phone Number" />
                        </div>
                      </div>
                      <div class="field-item">
                        <div class="field-wrap">
                          <h6 class="title title-xs">Zip Code<span class="red">*</span></h6>
                          <input type="tel" formControlName="zipCode" class="input-bordered"
                            [(ngModel)]="signupInput.zipCode"
                            [ngClass]="{ 'is-invalid': (submitted || f.zipCode.touched) && f.zipCode.errors }"
                            placeholder="Zip Code " required>
                          <div *ngIf="(submitted || f.zipCode.touched) && f.zipCode.errors" class="invalid-feedback">
                            <div *ngIf="f.zipCode.errors.required">Zip Code is required</div>
                            <div *ngIf="f.zipCode.errors.minlength">Zip Code cannot be less than 5 digits in length.
                            </div>
                            <div *ngIf="f.zipCode.errors.maxlength">Zip Code cannot be greater than 10 digits in length.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="width-50">
                      <div class="field-item">
                        <h6 class="title title-xs">Security Question 1<span class="red">*</span></h6>
                        <div class="field-wrap">
                          <select formControlName="question1" class="input-bordered"
                            [(ngModel)]="signupInput.securityQuestions.question1"
                            [ngClass]="{ 'is-invalid': submitted && f.question1.errors }"
                            (ngModelChange)="changeQuestion1(signupInput.securityQuestions.question1)" id="question11">
                            <option [value]="null" [attr.selected]="signupInput.securityQuestions.question1 === null"
                              disabled>Question 1</option>
                            <option *ngFor="let question1 of questions1" [value]="question1">{{question1}}</option>
                          </select>
                          <div *ngIf="submitted && f.question1.errors" class="invalid-feedback">
                            <div *ngIf="f.question1.errors.required">Question 1 is required</div>
                          </div>
                        </div>
                      </div>
                      <div class="field-item">
                        <div class="field-wrap">
                          <h6 class="title title-xs">Answer 1<span class="red">*</span></h6>
                          <input type="text" formControlName="answer1" class="input-bordered"
                            [(ngModel)]="signupInput.securityQuestions.answer1"
                            [ngClass]="{ 'is-invalid': submitted && f.answer1.errors }" placeholder="Answer 1" required>
                          <div *ngIf="submitted && f.answer1.errors" class="invalid-feedback">
                            <div *ngIf="f.answer1.errors.required">Answer 1 is required</div>
                            <div *ngIf="f.answer1.errors?.maxlength">Answer 1 can be max 100 characters long.</div>
                          </div>
                        </div>
                      </div>
                      <div class="field-item">
                        <h6 class="title title-xs">Security Question 2<span class="red">*</span></h6>
                        <div class="field-wrap">
                          <select formControlName="question2" class="input-bordered"
                            [(ngModel)]="signupInput.securityQuestions.question2"
                            [ngClass]="{ 'is-invalid': submitted && f.question2.errors }"
                            (ngModelChange)="changeQuestion2(signupInput.securityQuestions.question2)" id="question2">
                            <option [value]="null" [attr.selected]="signupInput.securityQuestions.question2 === null"
                              disabled>Question 2</option>
                            <option *ngFor="let question2 of questions2" [value]="question2">{{question2}}</option>
                          </select>
                          <div *ngIf="submitted && f.question2.errors" class="invalid-feedback">
                            <div *ngIf="f.question2.errors.required">Question 2 is required</div>
                          </div>
                        </div>
                      </div>
                      <div class="field-item">
                        <div class="field-wrap">
                          <h6 class="title title-xs">Answer 2<span class="red">*</span></h6>
                          <input type="text" formControlName="answer2" class="input-bordered"
                            [(ngModel)]="signupInput.securityQuestions.answer2"
                            [ngClass]="{ 'is-invalid': submitted && f.answer2.errors }" placeholder="Answer 2" required>
                          <div *ngIf="submitted && f.answer2.errors" class="invalid-feedback">
                            <div *ngIf="f.answer2.errors.required">Answer 2 is required</div>
                            <div *ngIf="f.answer2.errors?.maxlength">Answr 2 can be max 100 characters long.</div>
                          </div>
                        </div>
                      </div>
                      <div class="field-item">
                        <h6 class="title title-xs">Security Question 3<span class="red">*</span></h6>
                        <div class="field-wrap">
                          <select formControlName="question3" class="input-bordered"
                            [(ngModel)]="signupInput.securityQuestions.question3"
                            [ngClass]="{ 'is-invalid': submitted && f.question3.errors }"
                            (ngModelChange)="changeQuestion3(signupInput.securityQuestions.question3)" id="question3">
                            <option [value]="null" [attr.selected]="signupInput.securityQuestions.question3 === null"
                              disabled>Question 3</option>
                            <option *ngFor="let question3 of questions3" [value]="question3">{{question3}}</option>
                          </select>
                          <div *ngIf="submitted && f.question3.errors" class="invalid-feedback">
                            <div *ngIf="f.question3.errors.required">Question 3 is required</div>
                          </div>
                        </div>
                      </div>
                      <div class="field-item">
                        <div class="field-wrap">
                          <h6 class="title title-xs">Answer 3<span class="red">*</span></h6>
                          <input type="text" formControlName="answer3" class="input-bordered"
                            [(ngModel)]="signupInput.securityQuestions.answer3"
                            [ngClass]="{ 'is-invalid': submitted && f.answer3.errors }" placeholder="Answer 3" required>
                          <div *ngIf="submitted && f.answer3.errors" class="invalid-feedback">
                            <div *ngIf="f.answer3.errors.required">Answer 3 is required</div>
                            <div *ngIf="f.answer3.errors?.maxlength">Answer 3 can be max 100 characters long.</div>
                          </div>
                        </div>
                      </div>
                      <div class="field-item">
                        <h6 class="title title-xs">Security Question 4<span class="red">*</span></h6>
                        <div class="field-wrap">
                          <select formControlName="question4" class="input-bordered"
                            [(ngModel)]="signupInput.securityQuestions.question4"
                            [ngClass]="{ 'is-invalid': submitted && f.question4.errors }"
                            (ngModelChange)="changeQuestion4(signupInput.securityQuestions.question4)" id="question4">
                            <option [value]="null" [attr.selected]="signupInput.securityQuestions.question4 === null"
                              disabled>Question 4</option>
                            <option *ngFor="let question4 of questions4" [value]="question4">{{question4}}</option>
                          </select>
                          <div *ngIf="submitted && f.question4.errors" class="invalid-feedback">
                            <div *ngIf="f.question4.errors.required">Question 4 is required</div>
                          </div>
                        </div>
                      </div>
                      <div class="field-item">
                        <div class="field-wrap">
                          <h6 class="title title-xs">Answer 4<span class="red">*</span></h6>
                          <input type="text" formControlName="answer4" class="input-bordered"
                            [(ngModel)]="signupInput.securityQuestions.answer4"
                            [ngClass]="{ 'is-invalid': submitted && f.answer4.errors }" placeholder="Answer 4" required>
                          <div *ngIf="submitted && f.answer4.errors" class="invalid-feedback">
                            <div *ngIf="f.answer4.errors.required">Answer 4 is required</div>
                            <div *ngIf="f.answer4.errors?.maxlength">Answer 4 can be max 100 characters long.</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="step-2 animated fadeInRight" data-animate="fadeInRight" data-delay=".35"
                  style="visibility: visible; animation-delay: 0.35s;" *ngIf="stepCounter === 2">
                  <div class="utility step-container">
                    <h5 class="sub-head bg-light-alt" (click)="toggleSection('utility')">Utility Bill<span
                        *ngIf="!displayUtility">+</span><span *ngIf="displayUtility">-</span></h5>
                    <div *ngIf="displayUtility" class="step-content">
                      <span class="title title-xs tc-primary">Upload Image Or Fill Form Manually</span>
                      <span class="tc-warning manual-info"><small>(Click Edit Button below to enter information manually)</small></span>
                      <div class="step-section">
                        <div class="width-50">
                          <div class="upload-section">
                            <div class="upload" appDnd (fileDropped)="onFileDropped($event, 'utility')"
                              *ngIf="utilityFiles.length === 0">
                              <input type="file" #fileDropRef id="utilityFileDrop"
                                (change)="fileBrowseHandler($event.target.files, 'utility')"
                                accept=".png, .jpg, .jpeg" />
                              <i class="fa fa-upload title upload-icon" aria-hidden="true"></i>
                              <p>Drag and drop file here</p>
                              <p>or</p>
                              <label for="utilityFileDrop" class="tc-info">Browse For File</label>
                            </div>
                            <div class="preview" *ngIf="utilityFiles.length > 0">
                              <div class="preview-image" *ngFor="let file of utilityFiles; let i = index">
                                <img [src]="file?.url || 'assets/images/placeholder-2.png'" />
                                <!-- <app-progress-bar [progress]="file?.progress"></app-progress-bar> -->
                              </div>
                              <div class="upload-info" *ngFor="let file of utilityFiles; let i = index">
                                <div class="info">
                                  <span class="name title-xs title">
                                    {{ file?.name }}
                                  </span>
                                  <p class="size" *ngIf="file.size">
                                    {{ formatBytes(file?.size, 2) }}
                                  </p>
                                </div>
                                <div class="delete" (click)="deleteFile(i, 'utility')">
                                  <i class="fa fa-trash" aria-hidden="true"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="ubErrorMessage && ubErrorMessage.length > 0" class="invalid-feedback">
                            {{ubErrorMessage}}
                          </div>
                          <div *ngIf="ubSuccessMessage && ubSuccessMessage.length > 0" class="text-success">
                            {{ubSuccessMessage}}
                          </div>
                          <div class="btn-container animated fadeInDown" data-animate="fadeInDown" data-delay=".85"
                            style="visibility: visible; animation-delay: 0.85s;">
                            <div class="btn btn-danger btn-md" [ngClass]="{ 'disabledbutton': ubUploaded }"
                              type="button" *ngIf="utilityType ==='upload'" (click)="uploadUtilityBillImage()"><i
                                [hidden]="!ubInProgress" style="margin-right: 6px; color: white;"
                                class="fa fa-circle-o-notch fa-spin"></i>Upload
                            </div>
                          </div>
                        </div>
                        <div class="width-50">
                          <form [formGroup]="f_ubForm">
                            <div>
                              <div class="field-item">
                                <div class="field-wrap">
                                  <h6 class="title title-xs">Customer ID<span class="red">*</span></h6>
                                  <input type="text" formControlName="accountNumber" class="input-bordered"
                                    placeholder="Customer ID" [(ngModel)]="utilityBill.accountNumber"
                                    [ngClass]="{ 'is-invalid': f_ubSubmitted && f_ub.accountNumber.errors }">
                                  <div *ngIf="f_ubSubmitted && f_ub.accountNumber.errors" class="invalid-feedback">
                                    <div *ngIf="f_ub.accountNumber.errors.required">Customer ID is required</div>
                                    <div *ngIf="f_ub.accountNumber.errors.minlength">Customer ID should be minimum
                                      length of 5 digits.</div>
                                    <div *ngIf="f_ub.accountNumber.errors?.maxlength">Customer ID can be max 50
                                      characters long.</div>
                                  </div>
                                </div>
                              </div>
                              <div class="field-item">
                                <div class="field-wrap">
                                  <h6 class="title title-xs">Name<span class="red">*</span></h6>
                                  <input type="text" formControlName="name" class="input-bordered" placeholder="Name"
                                    [(ngModel)]="utilityBill.name"
                                    [ngClass]="{ 'is-invalid': f_ubSubmitted && f_ub.name.errors }">
                                  <div *ngIf="f_ubSubmitted && f_ub.name.errors" class="invalid-feedback">
                                    <div *ngIf="f_ub.name.errors.required">Name is required</div>
                                    <div *ngIf="f_ub.name.errors?.maxlength">Name can be max 100 characters long</div>
                                  </div>
                                </div>
                              </div>
                              <div class="field-item">
                                <div class="field-wrap">
                                  <h6 class="title title-xs">Address<span class="red">*</span></h6>
                                  <textarea type="text" formControlName="address" class="input-bordered"
                                    placeholder="Address" [(ngModel)]="utilityBill.address"
                                    [ngClass]="{ 'is-invalid': f_ubSubmitted && f_ub.address.errors }"
                                    placeholder="Enter Address"></textarea>
                                  <div *ngIf="f_ubSubmitted && f_ub.address.errors" class="invalid-feedback">
                                    <div *ngIf="f_ub.address.errors.required">Address is required</div>
                                    <div *ngIf="f_ub.address.errors?.maxlength">Address can be max 250 characters long
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div *ngIf="f_ubErrorMessage" class="invalid-feedback">
                              {{f_ubErrorMessage}}
                            </div>
                            <div *ngIf="f_ubSuccessMessage" class="text-success">
                              {{f_ubSuccessMessage}}
                            </div>
                            <div class="btn-container animated fadeInDown" data-animate="fadeInDown" data-delay=".85"
                              style="visibility: visible; animation-delay: 0.85s;">
                              <div class="btn btn-success btn-md" (click)="editForm('f_ubForm')"><span>Edit</span>
                              </div>
                              <div class="btn btn-primary btn-md" type="button" (click)="submitUtilityForm()"
                                [ngClass]="{ 'disabledbutton': utilityType === 'upload'}"><span><i
                                    [hidden]="!f_ubInProgress" style="margin-right: 6px; color: white;"
                                    class="fa fa-circle-o-notch fa-spin"></i>Save</span>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="bank step-container">
                    <h5 class="sub-head bg-light-alt" (click)="toggleSection('bank')">Bank Details <span
                        *ngIf="!displayBank">+</span><span *ngIf="displayBank">-</span></h5>
                    <div *ngIf="displayBank" class="step-content">
                      <span class="title title-xs tc-primary">Upload Image Or Fill Form Manually</span>
                      <span class="tc-warning manual-info"><small>(Click Edit Button below to enter information manually)</small></span>
                      <div class="step-section mt-2">
                        <div class="width-50">
                          <div class="field-item">
                            <h6 class="title title-xs">Bank Name<span class="red">*</span></h6>
                            <div class="field-wrap">
                              <select class="input-bordered" id="selectBank" [(ngModel)]="selectedBank"
                                [ngModelOptions]="{standalone: true}">
                                <option value="" selected disabled>Select Bank</option>
                                <option *ngFor="let bank of usaBanks" [value]="bank.name">{{bank.name}}</option>
                              </select>
                            </div>
                          </div>
                          <div class="upload-section">
                            <div class="upload" appDnd (fileDropped)="onFileDropped($event, 'bank')"
                              *ngIf="bankFiles.length === 0">
                              <input type="file" #fileDropRef id="bankFileDrop"
                                (change)="fileBrowseHandler($event.target.files, 'bank')" accept=".png, .jpg, .jpeg" />
                              <i class="fa fa-upload title upload-icon" aria-hidden="true"></i>
                              <p>Drag and drop file here</p>
                              <p>or</p>
                              <label for="bankFileDrop" class="tc-info">Browse For File</label>
                            </div>
                            <div class="preview" *ngIf="bankFiles.length > 0">
                              <div class="preview-image" *ngFor="let file of bankFiles; let i = index">
                                <img [src]="file?.url || 'assets/images/placeholder.png'" />
                                <!-- <app-progress-bar [progress]="file?.progress"></app-progress-bar> -->
                              </div>
                              <div class="upload-info" *ngFor="let file of bankFiles; let i = index">
                                <div class="info">
                                  <span class="name title-xs title">
                                    {{ file?.name }}
                                  </span>
                                  <p class="size" *ngIf="file.size">
                                    {{ formatBytes(file?.size, 2) }}
                                  </p>
                                </div>
                                <div class="delete" (click)="deleteFile(i, 'bank')">
                                  <i class="fa fa-trash" aria-hidden="true"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="bcErrorMessage" class="invalid-feedback">
                            {{bcErrorMessage}}
                          </div>
                          <div *ngIf="bcSuccessMessage" class="text-success">
                            {{bcSuccessMessage}}
                          </div>
                          <div class="btn-container animated fadeInDown" data-animate="fadeInDown" data-delay=".85"
                            style="visibility: visible; animation-delay: 0.85s;">
                            <div class="btn btn-danger btn-md" type="button" (click)="uploadBankChequeImage()"
                              [ngClass]="{ 'disabledbutton': bcUploaded }" *ngIf="bankType ==='upload'"><i
                                [hidden]="!bcInProgress" style="margin-right: 6px; color: white;"
                                class="fa fa-circle-o-notch fa-spin"></i>Upload
                            </div>
                          </div>
                        </div>
                        <div class="width-50">
                          <form [formGroup]="f_bcForm">
                            <div class="field-item">
                              <div class="field-wrap">
                                <h6 class="title title-xs">Account Number<span class="red">*</span></h6>
                                <input type="text" formControlName="accountNumber" class="input-bordered"
                                  [(ngModel)]="bankCheque.accountNumber"
                                  [ngClass]="{ 'is-invalid': f_bcSubmitted && f_bc.accountNumber.errors }"
                                  placeholder="Account Number">
                                <div *ngIf="f_bcSubmitted && f_bc.accountNumber.errors" class="invalid-feedback">
                                  <div *ngIf="f_bc.accountNumber.errors.required">Account Number is required</div>
                                  <div *ngIf="f_bc.accountNumber.errors.minlength">Account Number should be minimum
                                    length of 5 digit</div>
                                  <div *ngIf="f_bc.accountNumber.errors?.maxlength">Account Number can be max 50
                                    characters long</div>
                                </div>
                              </div>
                            </div>
                            <div class="field-item">
                              <div class="field-wrap">
                                <h6 class="title title-xs">Bank Routing Number<span class="red">*</span></h6>
                                <input type="text" formControlName="bankRoutingNumber" class="input-bordered"
                                  [(ngModel)]="bankCheque.bankRoutingNumber"
                                  [ngClass]="{ 'is-invalid': f_bcSubmitted && f_bc.bankRoutingNumber.errors }"
                                  placeholder="Bank Routing Number">
                                <div *ngIf="f_bcSubmitted && f_bc.bankRoutingNumber.errors" class="invalid-feedback">
                                  <div *ngIf="f_bc.bankRoutingNumber.errors.required">Bank Routing Number is required
                                  </div>
                                  <div *ngIf="f_bc.bankRoutingNumber.errors.minlength">Bank Routing Number should be
                                    minimum length of 5 digit</div>
                                  <div *ngIf="f_bc.bankRoutingNumber.errors?.maxlength">Bank Routing Number can be max
                                    50 characters long</div>
                                </div>
                              </div>
                            </div>
                            <div class="field-item">
                              <div class="field-wrap">
                                <h6 class="title title-xs">Cheque Number<span class="red">*</span></h6>
                                <input type="text" formControlName="chequeId" class="input-bordered"
                                  [(ngModel)]="bankCheque.chequeId"
                                  [ngClass]="{ 'is-invalid': f_bcSubmitted && f_bc.chequeId.errors }"
                                  placeholder="Cheque Number">
                                <div *ngIf="f_bcSubmitted && f_bc.chequeId.errors" class="invalid-feedback">
                                  <div *ngIf="f_bc.chequeId.errors.required">Cheque Number is required</div>
                                  <div *ngIf="f_bc.chequeId.errors.minlength">Cheque Number should be minimum length of
                                    3 digit</div>
                                  <div *ngIf="f_bc.chequeId.errors?.maxlength">Cheque Number can be max 50 characters
                                    long</div>
                                </div>
                              </div>
                            </div>
                            <div class="field-item">
                              <div class="field-wrap">
                                <h6 class="title title-xs">Name<span class="red">*</span></h6>
                                <input type="text" formControlName="name" class="input-bordered"
                                  [(ngModel)]="bankCheque.name"
                                  [ngClass]="{ 'is-invalid': f_bcSubmitted && f_bc.name.errors }" placeholder="Name">
                                <div *ngIf="f_bcSubmitted && f_bc.name.errors" class="invalid-feedback">
                                  <div *ngIf="f_bc.name.errors.required">Name is required</div>
                                  <div *ngIf="f_bc.name.errors?.maxlength">Name can be max 100 characters long</div>
                                </div>
                              </div>
                            </div>
                            <div *ngIf="f_bcErrorMessage" class="invalid-feedback">
                              {{f_bcErrorMessage}}
                            </div>
                            <div *ngIf="f_bcSuccessMessage" class="text-success">
                              {{f_bcSuccessMessage}}
                            </div>
                            <div class="btn-container animated fadeInDown" data-animate="fadeInDown" data-delay=".85"
                              style="visibility: visible; animation-delay: 0.85s;">
                              <div class="btn btn-success btn-md" (click)="editForm('f_bcForm')"><span>Edit</span>
                              </div>
                              <div class="btn btn-primary btn-md" (click)="submitChequeForm()"
                                [ngClass]="{ 'disabledbutton': bankType === 'upload'}"><span><i
                                    [hidden]="!f_bcInProgress" style="margin-right: 6px; color: white;"
                                    class="fa fa-circle-o-notch fa-spin"></i>Save</span>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="passport step-container">
                    <h5 class="sub-head bg-light-alt" (click)="toggleSection('passport')">Passport Details<span
                        *ngIf="!displayPassport">+</span><span *ngIf="displayPassport">-</span></h5>
                    <div *ngIf="displayPassport" class="step-content">
                      <span class="title title-xs tc-primary">Upload Image Or Fill Form Manually</span>
                      <span class="tc-warning manual-info"><small>(Click Edit Button below to enter information manually)</small></span>
                      <div class="step-section">
                        <div class="width-50">
                          <div class="upload-section">
                            <div class="upload" appDnd (fileDropped)="onFileDropped($event, 'passport')"
                              *ngIf="passportFiles.length === 0">
                              <input type="file" #fileDropRef id="passportFileDrop"
                                (change)="fileBrowseHandler($event.target.files, 'passport')"
                                accept=".png, .jpg, .jpeg" />
                              <i class="fa fa-upload title upload-icon" aria-hidden="true"></i>
                              <p>Drag and drop file here</p>
                              <p>or</p>
                              <label for="passportFileDrop" class="tc-info">Browse For File</label>
                            </div>
                            <div class="preview" *ngIf="passportFiles.length > 0">
                              <div class="preview-image" *ngFor="let file of passportFiles; let i = index">
                                <img [src]="file?.url || 'assets/images/placeholder.png'" />
                                <!-- <app-progress-bar [progress]="file?.progress"></app-progress-bar> -->
                              </div>
                              <div class="upload-info" *ngFor="let file of passportFiles; let i = index">
                                <div class="info">
                                  <span class="name title-xs title">
                                    {{ file?.name }}
                                  </span>
                                  <p class="size" *ngIf="file.size">
                                    {{ formatBytes(file?.size, 2) }}
                                  </p>
                                </div>
                                <div class="delete" (click)="deleteFile(i, 'passport')">
                                  <i class="fa fa-trash" aria-hidden="true"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="passErrorMessage" class="invalid-feedback">
                            {{passErrorMessage}}
                          </div>
                          <div *ngIf="passSuccessMessage" class="text-success">
                            {{passSuccessMessage}}
                          </div>
                          <div class="btn-container animated fadeInDown" data-animate="fadeInDown" data-delay=".85"
                            style="visibility: visible; animation-delay: 0.85s;">
                            <div class="btn btn-danger btn-md" type="button"
                              [ngClass]="{ 'disabledbutton': passUploaded }" (click)="uploadPassportImage()"
                              *ngIf="passportType ==='upload'"><i [hidden]="!passInProgress"
                                style="margin-right: 6px; color: white;" class="fa fa-circle-o-notch fa-spin"></i>Upload
                            </div>
                          </div>
                        </div>
                        <div class="width-50">
                          <form [formGroup]="f_passForm">
                            <div class="field-item">
                              <div class="field-wrap">
                                <h6 class="title title-xs">Passport Number<span class="red">*</span></h6>
                                <input type="text" formControlName="passportId" class="input-bordered"
                                  [(ngModel)]="passport.passportId"
                                  [ngClass]="{ 'is-invalid': f_passSubmitted && f_pass.passportId.errors }"
                                  placeholder="Enter Passport Number">
                                <div *ngIf="f_passSubmitted && f_pass.passportId.errors" class="invalid-feedback">
                                  <div *ngIf="f_pass.passportId.errors.required">Passport Number is required</div>
                                  <div *ngIf="f_pass.passportId.errors.minlength">Passport Number should be minimum
                                    length of 5 digit</div>
                                  <div *ngIf="f_pass.passportId.errors?.maxlength">Passport Number can be max 50
                                    characters long</div>
                                </div>
                              </div>
                            </div>
                            <div class="field-item">
                              <div class="field-wrap">
                                <h6 class="title title-xs">Name<span class="red">*</span></h6>
                                <input type="text" formControlName="name" class="input-bordered"
                                  [(ngModel)]="passport.name"
                                  [ngClass]="{ 'is-invalid': f_passSubmitted && f_pass.name.errors }"
                                  placeholder="Name">
                                <div *ngIf="f_passSubmitted && f_pass.name.errors" class="invalid-feedback">
                                  <div *ngIf="f_pass.name.errors.required">Name is required</div>
                                  <div *ngIf="f_pass.name.errors?.maxlength">Name can be max 100 characters long</div>
                                </div>
                              </div>
                            </div>
                            <div class="field-item">
                              <h6 class="title title-xs">Gender<span class="red">*</span></h6>
                              <div class="field-wrap">
                                <select class="input-bordered" formControlName="gender" [(ngModel)]="passport.gender"
                                  [ngClass]="{ 'is-invalid': f_passSubmitted && f_pass.gender.errors }">
                                  <option value="undefined" selected disabled>Select Gender</option>
                                  <option *ngFor="let gen of genders" [value]="gen">{{gen}}</option>
                                </select>
                                <div *ngIf="f_passSubmitted && f_pass.gender.errors" class="invalid-feedback">
                                  <div *ngIf="f_pass.gender.errors.required">Gender is required
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="field-item">
                              <div class="field-wrap">
                                <h6 class="title title-xs">Date Of Birth<span class="red">*</span></h6>
                                <input type="date" formControlName="dob" class="input-bordered"
                                  [(ngModel)]="passport.dob" (change)="dateCheck($event, 'ppDob')"
                                  [ngClass]="{ 'is-invalid': f_passSubmitted && f_pass.dob.errors }"
                                  placeholder="Date of Birth">
                                <div *ngIf="f_passSubmitted && f_pass.dob.errors" class="invalid-feedback">
                                  <div *ngIf="f_pass.dob.errors.required">Date of Birth is required
                                  </div>
                                </div>
                                <div *ngIf="ppDobErrMsg != null" class="invalid-feedback" style="display:block">
                                  {{ppDobErrMsg}}
                                </div>
                              </div>
                            </div>
                            <div class="field-item">
                              <div class="field-wrap">
                                <h6 class="title title-xs">Issue Date<span class="red">*</span></h6>
                                <input type="date" formControlName="issued" class="input-bordered"
                                  [(ngModel)]="passport.issued" (change)="dateCheck($event, 'ppIssued')"
                                  [ngClass]="{ 'is-invalid': f_passSubmitted && f_pass.issued.errors }"
                                  placeholder="Issued Date">
                                <div *ngIf="f_passSubmitted && f_pass.issued.errors" class="invalid-feedback">
                                  <div *ngIf="f_pass.issued.errors.required">Issued Date is required
                                  </div>
                                </div>
                                <div *ngIf="ppIssuedErrMsg != null" class="invalid-feedback" style="display:block">
                                  {{ppIssuedErrMsg}}
                                </div>
                              </div>
                            </div>
                            <div class="field-item">
                              <div class="field-wrap">
                                <h6 class="title title-xs">Expiry Date<span class="red">*</span></h6>
                                <input type="date" formControlName="expiry" class="input-bordered"
                                  [(ngModel)]="passport.expiry"
                                  [ngClass]="{ 'is-invalid': f_passSubmitted && f_pass.expiry.errors }"
                                  placeholder="Expiry Date">
                                <div *ngIf="f_passSubmitted && f_pass.expiry.errors" class="invalid-feedback">
                                  <div *ngIf="f_pass.expiry.errors.required">Expiry Date is required</div>
                                  <div *ngIf="f_pass.expiry.errors?.past">Expiry Date cannot be past date</div>
                                </div>
                                <div *ngIf="ppExpiryErrMsg && ppExpiryErrMsg.length > 0" class="invalid-feedback"
                                  style="display:block">
                                  {{ppExpiryErrMsg}}
                                </div>
                              </div>
                            </div>
                            <div *ngIf="f_passErrorMessage" class="invalid-feedback">
                              {{f_passErrorMessage}}
                            </div>
                            <div *ngIf="f_passSuccessMessage" class="text-success">
                              {{f_passSuccessMessage}}
                            </div>
                            <div class="btn-container animated fadeInDown" data-animate="fadeInDown" data-delay=".85"
                              style="visibility: visible; animation-delay: 0.85s;">
                              <div class="btn btn-success btn-md" (click)="editForm('f_passForm')"><span>Edit</span>
                              </div>
                              <div class="btn btn-primary btn-md" (click)="submitPassportForm()"
                                [ngClass]="{ 'disabledbutton': passportType === 'upload'}"><span><i
                                    [hidden]="!f_passInProgress" style="margin-right: 6px;color:white;"
                                    class="fa fa-circle-o-notch fa-spin"></i>Save</span>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="dl step-container">
                    <h5 class="sub-head bg-light-alt" (click)="toggleSection('dl')">Driving License Details<span
                        *ngIf="!displayDl">+</span><span *ngIf="displayDl">-</span></h5>
                    <div *ngIf="displayDl" class="step-content">
                      <span class="title title-xs tc-primary">Upload Image Or Fill Form Manually</span>
                      <span class="tc-warning manual-info"><small>(Click Edit Button below to enter information manually)</small></span>
                      <div class="step-section mt-2">
                        <div class="width-50">
                          <div class="field-item">
                            <h6 class="title title-xs">State Name<span class="red">*</span></h6>
                            <div class="field-wrap">
                              <select class="input-bordered" id="selectState" [(ngModel)]="selectedState"
                                [ngModelOptions]="{standalone: true}">
                                <option value="" selected disabled>Select State</option>
                                <option *ngFor="let state of usaStates" [value]="state.name">{{state.name}}</option>
                              </select>
                            </div>
                          </div>
                          <div class="upload-section">
                            <div class="upload" appDnd (fileDropped)="onFileDropped($event, 'dl')"
                              *ngIf="dlFiles.length === 0">
                              <input type="file" #fileDropRef id="dlFileDrop"
                                (change)="fileBrowseHandler($event.target.files, 'dl')" accept=".png, .jpg, .jpeg" />
                              <i class="fa fa-upload title upload-icon" aria-hidden="true"></i>
                              <p>Drag and drop file here</p>
                              <p>or</p>
                              <label for="dlFileDrop" class="tc-info">Browse For File</label>
                            </div>
                            <div class="preview" *ngIf="dlFiles.length > 0">
                              <div class="preview-image" *ngFor="let file of dlFiles; let i = index">
                                <img [src]="file?.url || 'assets/images/placeholder.png'" />
                                <!-- <app-progress-bar [progress]="file?.progress"></app-progress-bar> -->
                              </div>
                              <div class="upload-info" *ngFor="let file of dlFiles; let i = index">
                                <div class="info">
                                  <span class="name title-xs title">
                                    {{ file?.name }}
                                  </span>
                                  <p class="size" *ngIf="file.size">
                                    {{ formatBytes(file?.size, 2) }}
                                  </p>
                                </div>
                                <div class="delete" (click)="deleteFile(i, 'dl')">
                                  <i class="fa fa-trash" aria-hidden="true"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="dlErrorMessage" class="invalid-feedback">
                            {{dlErrorMessage}}
                          </div>
                          <div *ngIf="dlSuccessMessage" class="text-success">
                            {{dlSuccessMessage}}
                          </div>
                          <div class="btn-container animated fadeInDown" data-animate="fadeInDown" data-delay=".85"
                            style="visibility: visible; animation-delay: 0.85s;">
                            <div class="btn btn-danger btn-md" type="button" (click)="uploadDrivingLicImage()"
                              [ngClass]="{ 'disabledbutton': dlUploaded }" *ngIf="dlType ==='upload'"><i
                                [hidden]="!dlInProgress" style="margin-right: 6px;color:white;"
                                class="fa fa-circle-o-notch fa-spin"></i>Upload
                            </div>
                          </div>
                        </div>
                        <div class="width-50">
                          <form [formGroup]="f_dlForm">
                            <div class="field-item">
                              <div class="field-wrap">
                                <h6 class="title title-xs">Driving License ID<span class="red">*</span></h6>
                                <input type="text" formControlName="drivingLicenseId" class="input-bordered"
                                  [(ngModel)]="drivingLic.drivingLicenseId"
                                  [ngClass]="{ 'is-invalid': f_dlSubmitted && f_dl.drivingLicenseId.errors }"
                                  placeholder="Driving License Number">
                                <div *ngIf="f_dlSubmitted && f_dl.drivingLicenseId.errors" class="invalid-feedback">
                                  <div *ngIf="f_dl.drivingLicenseId.errors.required">Driving License Number is required
                                  </div>
                                  <div *ngIf="f_dl.drivingLicenseId.errors.minlength">Driving License Number should be
                                    minimum length of 5 digit</div>
                                  <div *ngIf="f_dl.drivingLicenseId.errors?.maxlength">Driving License Number can be max
                                    50 characters long</div>
                                </div>
                              </div>
                            </div>
                            <div class="field-item">
                              <div class="field-wrap">
                                <h6 class="title title-xs">Name<span class="red">*</span></h6>
                                <input type="text" formControlName="name" class="input-bordered"
                                  [(ngModel)]="drivingLic.name"
                                  [ngClass]="{ 'is-invalid': f_dlSubmitted && f_dl.name.errors }" placeholder="Name">
                                <div *ngIf="f_dlSubmitted && f_dl.name.errors" class="invalid-feedback">
                                  <div *ngIf="f_dl.name.errors.required">Name is required</div>
                                  <div *ngIf="f_dl.name.errors?.maxlength">name can be max 100 characters long</div>
                                </div>
                              </div>
                            </div>
                            <div class="field-item">
                              <div class="field-wrap">
                                <h6 class="title title-xs">Address<span class="red">*</span></h6>
                                <input type="text" formControlName="address" class="input-bordered"
                                  [(ngModel)]="drivingLic.address"
                                  [ngClass]="{ 'is-invalid': f_dlSubmitted && f_dl.address.errors }"
                                  placeholder="Address">
                                <div *ngIf="f_dlSubmitted && f_dl.address.errors" class="invalid-feedback">
                                  <div *ngIf="f_dl.address.errors.required">Address is required</div>
                                  <div *ngIf="f_dl.address.errors?.maxlength">Address can be max 250 characters long
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="field-item">
                              <h6 class="title title-xs">Gender<span class="red">*</span></h6>
                              <div class="field-wrap">
                                <select class="input-bordered" formControlName="gender" [(ngModel)]="drivingLic.gender"
                                  [ngClass]="{ 'is-invalid': f_dlSubmitted && f_dl.gender.errors }">
                                  <option value="undefined" selected disabled>Select Gender</option>
                                  <option *ngFor="let gen of genders" [value]="gen">{{gen}}</option>
                                </select>
                                <div *ngIf="f_dlSubmitted && f_dl.gender.errors" class="invalid-feedback">
                                  <div *ngIf="f_dl.gender.errors.required">Gender is required
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="field-item">
                              <div class="field-wrap">
                                <h6 class="title title-xs">Date Of Birth<span class="red">*</span></h6>
                                <input type="date" formControlName="dob" class="input-bordered"
                                  [(ngModel)]="drivingLic.dob" (change)="dateCheck($event, 'dlDob')"
                                  [ngClass]="{ 'is-invalid': f_dlSubmitted && f_dl.dob.errors }"
                                  placeholder="Date of Birth">
                                <div *ngIf="f_dlSubmitted && f_dl.dob.errors" class="invalid-feedback">
                                  <div *ngIf="f_dl.dob.errors.required">Date of Birth is required
                                  </div>
                                </div>
                                <div *ngIf="dlDobErrMsg" class="invalid-feedback">
                                  {{dlDobErrMsg}}
                                </div>
                              </div>
                            </div>
                            <div class="field-item">
                              <div class="field-wrap">
                                <h6 class="title title-xs">Issue Date<span class="red">*</span></h6>
                                <input type="date" formControlName="issued" class="input-bordered"
                                  [(ngModel)]="drivingLic.issued" (change)="dateCheck($event, 'dlIssued')"
                                  [ngClass]="{ 'is-invalid': f_dlSubmitted && f_dl.issued.errors }"
                                  placeholder="Issued Date">
                                <div *ngIf="f_dlSubmitted && f_dl.issued.errors" class="invalid-feedback">
                                  <div *ngIf="f_dl.issued.errors.required">Issue Date is required
                                  </div>
                                </div>
                                <div *ngIf="dlIssuedErrMsg != null" class="invalid-feedback" style="display:block">
                                  {{dlIssuedErrMsg}}
                                </div>
                              </div>
                            </div>
                            <div class="field-item">
                              <div class="field-wrap">
                                <h6 class="title title-xs">Expiry Date<span class="red">*</span></h6>
                                <input type="date" formControlName="expiry" class="input-bordered"
                                  [(ngModel)]="drivingLic.expiry"
                                  [ngClass]="{ 'is-invalid': f_dlSubmitted && f_dl.expiry.errors }"
                                  placeholder="Expiry Date">
                                <div *ngIf="f_dlSubmitted && f_dl.expiry.errors" class="invalid-feedback">
                                  <div *ngIf="f_dl.expiry.errors.required">Expiry Date is required</div>
                                  <div *ngIf="f_dl.expiry.errors?.past">Expiry Date cannot be past date</div>
                                </div>
                                <div *ngIf="dlExpiryErrMsg != null" class="invalid-feedback" style="display:block">
                                  {{dlExpiryErrMsg}}
                                </div>
                              </div>
                            </div>
                            <div *ngIf="f_dlErrorMessage" class="invalid-feedback">
                              {{f_dlErrorMessage}}
                            </div>
                            <div *ngIf="f_dlSuccessMessage" class="text-success">
                              {{f_dlSuccessMessage}}
                            </div>
                            <div class="btn-container animated fadeInDown" data-animate="fadeInDown" data-delay=".85"
                              style="visibility: visible; animation-delay: 0.85s;">
                              <div class="btn btn-success btn-md" (click)="editForm('f_dlForm')"><span>Edit</span></div>
                              <div class="btn btn-primary btn-md" (click)="submitDLForm()"
                                [ngClass]="{ 'disabledbutton': dlType === 'upload'}"><span><i [hidden]="!f_dlInProgress"
                                    style="margin-right: 6px;" class="fa fa-circle-o-notch fa-spin"></i>Save</span>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="picPwd step-container">
                    <h5 class="sub-head bg-light-alt" (click)="toggleSection('picPwd')">Picture Password<span
                        *ngIf="!displayPicPwd">+</span><span *ngIf="displayPicPwd">-</span></h5>
                    <div *ngIf="displayPicPwd" class="step-content">
                      <div class="step-section">
                        <div class="width-50">
                          <div class="upload-section">
                            <div class="upload" appDnd (fileDropped)="onFileDropped($event, 'picPwd')"
                              *ngIf="picPwd.length === 0">
                              <input type="file" #fileDropRef id="picPwdDrop"
                                (change)="fileBrowseHandler($event.target.files, 'picPwd')"
                                accept=".png, .jpg, .jpeg" />
                              <i class="fa fa-upload title upload-icon" aria-hidden="true"></i>
                              <p>Drag and drop file here</p>
                              <p>or</p>
                              <label for="picPwdDrop" class="tc-info">Browse For File</label>
                            </div>
                            <div class="preview" *ngIf="picPwd.length > 0">
                              <div class="preview-image" *ngFor="let file of picPwd; let i = index">
                                <img [src]="file?.url || 'assets/images/placeholder.png'" />
                                <!-- <app-progress-bar [progress]="file?.progress"></app-progress-bar> -->
                              </div>
                              <div class="upload-info" *ngFor="let file of picPwd let i = index">
                                <div class="info">
                                  <span class="name title-xs title">
                                    {{ file?.name }}
                                  </span>
                                  <p class="size" *ngIf="file.size">
                                    {{ formatBytes(file?.size, 2) }}
                                  </p>
                                </div>
                                <div class="delete" (click)="deleteFile(i, 'picPwd')">
                                  <i class="fa fa-trash" aria-hidden="true"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="ppErrorMessage" class="invalid-feedback">
                            {{ppErrorMessage}}
                          </div>
                          <div *ngIf="ppSuccessMessage" class="text-success">
                            {{ppSuccessMessage}}
                          </div>
                          <div class="btn-container animated fadeInDown" data-animate="fadeInDown" data-delay=".85"
                            style="visibility: visible; animation-delay: 0.85s;">
                            <div class="btn btn-danger btn-md" [ngClass]="{ 'disabledbutton': ppUploaded }"
                              type="button" (click)="uploadPicturePassword()"><i [hidden]="!ppUploadInProgress"
                                style="margin-right: 6px;color: white;" class="fa fa-circle-o-notch fa-spin"></i>Upload
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="btn-container animated fadeInDown" data-animate="fadeInDown" data-delay=".85"
                  style="visibility: visible; animation-delay: 0.85s;">
                  <div class="btn btn-danger btn-md" type="button" *ngIf="stepCounter === 1" (click)="resetFirstForm()">Reset
                  </div>
                  <div class="btn btn-success btn-md" *ngIf="stepCounter > 1 && !firstStepDone" (click)="goBack()">
                    <span>Back</span>
                  </div>
                  <div class="btn btn-primary btn-md" *ngIf="stepCounter < 2" (click)="goNext()"><span>Next</span></div>
                  <div class="btn btn-primary btn-md" *ngIf="stepCounter === 2" (click)="completeProcess()"><span>Final
                      Submit</span></div>
                </div>
                <div *ngIf="completionErrorMsg" class="invalid-feedback mt-2" style="font-weight: 500;">
                  {{completionErrorMsg}}
                </div>
              </form>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
      </div>
    </div>
  </div>
</ion-content>