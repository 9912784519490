<div class="header-main fixed-header" #header slot="fixed">
    <div class="header-container container">
        <div class="header-wrap">
            <ion-header class="ion-no-border">
                <ion-toolbar class="header-main">
                    <ion-title slot="start">
                        <a [routerLink]="['/home']"><img src="assets/images/hiddin-logo.png" />
                        </a>
                    </ion-title>
                    <div class="header-navbar header-navbar-s3" slot="end">
                        <nav class="header-menu justify-content-between" id="example-menu-04">
                            <ul class="menu-desktop menu-s2 animated fadeInDown" data-animate="fadeInDown" data-delay=".75" style="visibility: visible; animation-delay: 0.75s; margin-bottom: 0;">
                                <li class="menu-item"><a class="menu-link nav-link" [routerLink]="['/home']">Home</a></li>
                                <!-- <li class="menu-item"><a class="menu-link nav-link" (click)="verificationMail()">email</a></li> -->

                                <li class="menu-item" *ngIf="LoggedIn"><a class="menu-link nav-link" [routerLink]="['/dashboard']">Dashboard</a></li>
                                <li class="menu-item"><a class="menu-link nav-link" [routerLink]="['/know-more']">About Us</a></li>
                                <li class="menu-item"><a class="menu-link nav-link" [routerLink]="['/our-products']">Our Products</a>
                                </li>
                                <li class="menu-item"><a class="menu-link nav-link" [routerLink]="['/contact']">Contact</a></li>
                            </ul>
                            <ul class="menu-btns animated fadeInDown" data-animate="fadeInDown" data-delay=".85" style="visibility: visible; animation-delay: 0.85s;" *ngIf="!LoggedIn">
                                <li>
                                    <button [routerLink]="['/basic-reg']" class="btn btn-rg btn-auto btn-outline btn-grad on-bg-theme-dark-alt">
                          <span style="color: #ffffff">Sign Up</span></button>
                                </li>
                                <li>
                                    <button [routerLink]="['/login']" class="btn btn-rg btn-auto btn-outline btn-grad on-bg-theme-dark-alt">
                          <span style="color: #ffffff">Login</span>
                        </button>
                                </li>
                            </ul>
                            <div class="userDetails" *ngIf="LoggedIn">
                                <span *ngIf="user" class="tc-light">| &nbsp;{{user.firstName}}</span>
                                <span *ngIf="!user" class="tc-light">| &nbsp;</span>
                                <div class="userImage">
                                    <img *ngIf="!user || !user.imageUrl" src="assets/images/team/profile.png" [routerLink]="['/profile']" />
                                    <i class="fa fa-star" *ngIf="user && (((user.roleLevel == 3 && user.parentId != undefined && user.parentId != null && user.parentId != 0 && user.isSeqQueUploaded) || (user.roleLevel == 4 || user.roleLevel == 5)) || user.isDocumentUploaded)"></i>
                                    <a class="register-btn" (click)="openCompleteRegConfirmation()" *ngIf="(user == undefined || user == null) || ((user.roleLevel == 3 && user.parentId != undefined && user.parentId != null && user.parentId != 0 && !user.isSeqQueUploaded) || ((user.roleLevel == 0 || user.roleLevel == 1 || user.roleLevel == 2) && !user.isDocumentUploaded))"><i class="fa fa-exclamation-circle" tooltip="Click Here to complete your registration" placement="bottom" theme="light"></i></a>
                                    <img *ngIf="user && user.imageUrl" src="{{user.imageUrl}}" [routerLink]="['/profile']" />
                                </div>
                                <button (click)="logout()" class="btn btn-rg btn-auto btn-outline btn-grad on-bg-theme-dark-alt tc-light">Log Out</button>
                            </div>
                        </nav>
                        <div class="header-navbar-overlay"></div>
                    </div>
                    <ion-buttons slot="end" class="header-nav-toggle">
                        <ion-menu-button></ion-menu-button>
                    </ion-buttons>
                </ion-toolbar>
            </ion-header>
        </div>
    </div>
</div>