import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AuthServiceService } from '@services/auth-service.service';
import { LoaderService } from '@services/loader.service';
import { Globals } from 'src/app/globals';
import { environment } from 'src/environments/environment';
import { RegUser } from '../basic-reg/RegUser';

@Component({
  selector: 'app-renew-subscription',
  templateUrl: './renew-subscription.component.html',
  styleUrls: ['./renew-subscription.component.scss'],
})
export class RenewSubscriptionComponent implements OnInit {

  requestPath: string;

  errorMessage: string;

  amount = 100;
  isSubscriptionExpired = true;
  remainingDays: number;
  user: RegUser;

  constructor(public global: Globals
    , public authService: AuthServiceService
    , private loader: LoaderService) {
    this.requestPath = this.global.requestPath;
  }

  ngOnInit() { }

  ionViewDidEnter() {
    this.errorMessage = null;
    this.amount = 100;
    this.isSubscriptionExpired = true;
    this.user = this.authService.getLoggedInUser();

    this.getCurrentSubscription();
  }

  getCurrentSubscription = function () {
    this.authService.get('subscription?' + this.requestPath + '=getSubscriptionDetail')
      .subscribe((res) => {
        this.loader.hide();
        // console.log(res);
        if (res.success) {
          var userSubscription = this.global.decrypt(res.data);
          var remaining = userSubscription.expiryTime - userSubscription.startTime;
          this.remainingDays = Math.trunc(remaining / (24 * 60 * 60 * 100));

          if (this.remainingDays <= 0) {
            this.isSubscriptionExpired = true;
          } else {
            this.isSubscriptionExpired = false;
          }
        } else {
          // console.log(res.message);
          this.errorMessage = res.message;
        }
      }, error => {
        this.loader.hide();
        // console.log(error);
        this.errorMessage = "Error occured";
      });
  }

  subscriptNow = function () {
    // Redirect to Payment Site
    var name = "Hiddin Subscription Charge";
    var description = "Charges for renewing the existing subscription to continue services.";

    var successUrl = environment.appBaseUrl + '?page=subscription-done&success=true';
    var cancelUrl = environment.appBaseUrl + '?page=subscription-done&success=false';

    var url = environment.paymentURL + 'checkout?appId=' + this.global.appId + '&amount=' + this.amount + '&currency=' + this.global.currency
      + '&name=' + name + '&userId=' + this.user.id + '&description=' + description
      + '&successUrl=' + encodeURIComponent(successUrl) + '&cancelUrl=' + encodeURIComponent(cancelUrl);
    window.open(url, "_self");
  }

}
