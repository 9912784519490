<ion-content class="content-main">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="subContent">
        <div class="mt-2">
          <ion-row>
            <ion-col class="bordered" size-lg="12" size-md="12" size-sm="12" size-xs="12">
              <div class="dispFlx">
                <a (click)="openProfileModal(user)">
                  <ion-icon name="arrow-back-outline"></ion-icon>
                </a>
                <h6 class="ml-2">Contact Setting</h6>
              </div>
              <div class="dispFlxSpace-bet mb-2">
                <button shape="round" class="cs_button mt-4" (click)="changeCategory('friends')"
                  [class.btn-frnd]="selectedCategory == 'friends'"
                  [class.btn-empty]="selectedCategory != 'friends'">Friends</button>
                <button shape="round" class="cs_button1 mt-4" (click)="changeCategory('work')"
                  [class.btn-work]="selectedCategory == 'work'"
                  [class.btn-empty]="selectedCategory != 'work'">Work</button>
                <button shape="round" class="cs_button2 mt-4" (click)="changeCategory('family')"
                  [class.btn-family]="selectedCategory == 'family'"
                  [class.btn-empty]="selectedCategory != 'family'">Family</button>
                <button shape="round" class="cs_button3 mt-4" (click)="changeCategory('others')"
                  [class.btn-others]="selectedCategory == 'others'"
                  [class.btn-empty]="selectedCategory != 'others'">Others</button>
              </div>
            </ion-col>
            <ion-col class="pluses" size-lg="12" size-md="12" size-sm="12" size-xs="12">
              <!-- <h1>friends</h1> -->
              <div class="tab-slider--nav">
                <ul class="tab-slider--tabs">
                  <li class="tab-slider--trigger" [class.active]="selectedTab == 'tab1' && selectedCategory=='friends'"
                    [class.active1]="selectedTab == 'tab1' && selectedCategory=='work'"
                    [class.active2]="selectedTab == 'tab1' && selectedCategory=='family'"
                    [class.active3]="selectedTab == 'tab1' && selectedCategory=='others'" rel="tab1"
                    (click)="changeTab('tab1')"> Select Channels </li>
                  <li class="tab-slider--trigger" [class.active]="selectedTab == 'tab2' && selectedCategory=='friends'"
                    [class.active1]="selectedTab == 'tab2' && selectedCategory=='work'"
                    [class.active2]="selectedTab == 'tab2' && selectedCategory=='family'"
                    [class.active3]="selectedTab == 'tab2' && selectedCategory=='others'" rel="tab2"
                    (click)="changeTab('tab2')"> Select Time Slot </li>
                </ul>
              </div>
              <div class="mainContainer" [ngSwitch]="selectedTab" [ngStyle]="{'border':'2px solid #E17538'}">
                <div id="tab1" class="tab-slider--body" *ngSwitchCase="'tab1'">
                  <form>
                    <p *ngFor="let commType of communicationTypes">
                      <label>
                        <input type="checkbox" id="commType.id" (change)="changeChanel(commType.id)"
                          [checked]="commType.checked">
                        <span>{{commType.name}}</span>
                      </label>
                    </p>
                  </form>
                  <!-- <div class="ftr-icon"><ion-icon name="help-circle"></ion-icon><span class="fnt-12">Selecting channel means your allowing or restricting the person how
                        he
                        can
                        contact you.</span></div> -->
                </div>
                <div id="tab2" class="tab-slider--body" *ngSwitchCase="'tab2'">
                  <!-- <p>Pick time according to your convenience</p> -->
                  <div *ngIf="timeSlotType=='Custom'">
                    <div class="row">
                      <div class="col-12 cust-tab">
                        <span (click)="changeTimeSlot('Anytime')" [class.btn-others]="selectedCategory == 'others'"
                          [class.btn-family]="selectedCategory == 'family'"
                          [class.btn-frnd]="selectedCategory == 'friends'"
                          [class.btn-work]="selectedCategory == 'work'">Choose Slots</span>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-6">
                        <label>From Time </label>
                        <input type="time" class="form-control" [(ngModel)]="fromTime"
                          [ngModelOptions]="{standalone: true}" />
                      </div>
                      <div class="col-6">
                        <label>To Time </label>
                        <input type="time" class="form-control" [(ngModel)]="toTime"
                          [ngModelOptions]="{standalone: true}" />
                      </div>
                    </div>
                  </div>
                  <div *ngIf="timeSlotType=='Anytime'">
                    <div class="row">
                      <div class="col-md-6 col-lg-6 col-xl-6">
                        <input type="checkbox" (change)="changeTimeSelectionType($event)" /><span class="font-14">
                          Select All </span>
                      </div>
                      <div class="col-md-6 col-lg-6 col-xl-6">
                        <span (click)="changeTimeSlot('Custom')" class="custom-btn"
                          [class.btn-others]="selectedCategory == 'others'"
                          [class.btn-family]="selectedCategory == 'family'"
                          [class.btn-frnd]="selectedCategory == 'friends'"
                          [class.btn-work]="selectedCategory == 'work'">Custom Time</span>
                      </div>
                    </div>
                    <hr>
                    <div class="row mt-2">
                      <div class="col-6 time-left" id="leftPanel">
                        <p *ngFor="let timeSlot of timeSlotsCol1">
                          <input type="checkbox" id="timeSlot.id" [checked]="timeSlot.checked"
                            (change)="markChecked(timeSlot.id)">
                          <span> {{timeSlot.view}}</span>
                        </p>
                      </div>
                      <div class="col-6 time-right" id="rightPanel">
                        <p *ngFor="let timeSlot of timeSlotsCol2">
                          <input type="checkbox" id="timeSlot.id" [checked]="timeSlot.checked"
                            (change)="markChecked(timeSlot.id)">
                          <span> {{timeSlot.view}}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="ftr-icon mt-5"><ion-icon name="help-circle"></ion-icon><span class="fnt-12">Selecting time slot means your allowing or restricting the person at
                        what
                        time they can contact you.</span></div> -->
                </div>
              </div>
            </ion-col>
            <div *ngIf="errorMessage && errorMessage.length > 0">
              <p class="red">{{errorMessage}}</p>
            </div>
            <div class="dispFlxSpace-bet" style="width: 100%;">
              <button class="btn cs_button" *ngIf="isSender" (click)="addContact()"
                [class.btn-others]="selectedCategory == 'others'" [class.btn-family]="selectedCategory == 'family'"
                [class.btn-frnd]="selectedCategory == 'friends'" [class.btn-work]="selectedCategory == 'work'">Send Add
                Request</button>
              <button class="btn cs_button" *ngIf="!isSender" (click)="addContact()">Approve</button>
              <button class="btn cs_button" (click)="dismiss('')">Cancel</button>
            </div>
          </ion-row>
        </div>
      </div>
    </div>
  </div>
</ion-content>