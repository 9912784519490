<app-header-left-menu [user]="user"></app-header-left-menu>
<ion-content id="main-content" class="full-width-page">
  <div class="bg-theme-alt">
    <div class="bg-grad-special">
      <div class="bg-grad-special-alt">
        <div class="nk-ovm shape-u shape-contain shape-left-top"></div>
        <app-header [user]="user"></app-header>
        <div class="subContent">
          <div class="main-content container">
            <h5 class="ath-heading title">PLATFORM VERSUS PIPELINE<small class="tc-default">The traditional pipeline business model involves combining raw material.</small></h5>
            <h3 class="title title-md">
              Business Models Pipeline versus Digital Platform
            </h3>
            <p>The traditional pipeline business model involves combining raw material, labor and plant and equipment together to form a product that can be marketed at a price greater than the costs to produce and lower than the value your buyers would be willing to pay. This is a one to one relationship between buyers and sellers. Value is created in this relationship to the extent each buyer believes that what they bought was worth something more than they paid. The customers of any one seller do not impact the customers of any other seller in any meaningful way and therefore no marketplace wide dynamics materialize.</p>
            <p>The digital platform business strategy cultivates an ecosystem of synergic network effects altering how
              value is created in the relationships that develop. The platform business model creates relationships
              between the following players:
              <br> <b>1.</b> &nbsp;&nbsp;&nbsp; Producers create content.
              <br><b> 2.</b> &nbsp;&nbsp;&nbsp; Users seek this content.
              <br> <b>3.</b> &nbsp;&nbsp;&nbsp; The business provides the infrastructure to showcase this content.
              <br><b> 4.</b> &nbsp;&nbsp;&nbsp; Sales monetize the platform
            </p>
            <p>Sometimes these players wear multiple hats and depending on the circumstances they may play different
              roles at different times. Uber, Google, and Pay Pal are all examples of successful digital platform
              companies that demonstrate these differences and dynamics.
            </p>
            <div class="row">
              <div class="col-md-2">
                <p>
                  <b>Uber</b>
                </p>
              </div>
              <div class="col-md-10">
                <p>1 Producers offer rides.
                  <br>2 Users seek rides.
                  <br>3&amp;4 Uber provides the infrastructure for a slice of the fares arranged between the producers
                  and users.</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">
                <p>
                  <b>Dynamics</b>
                </p>
              </div>
              <div class="col-md-10">
                <p>
                  More drivers encourage more riders and more riders encourage more drivers as long as the
                  infrastructure grows to fill the demand in a timely fashion healthy growth results.
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">
                <p>
                  <b>GOOGLE</b>
                </p>
              </div>
              <div class="col-md-10">
                <p>
                  1&amp;3 GOOGLE produces search content and the showcase infrastructure.
                  <br> 2 Users seek answers from content and view the paid advertising from the sellers monetizing the
                  platform.
                  <br> 4 &amp; 1 sellers pay for advertising spots and advertisers contribute more content.

                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">
                <p>
                  <b>Dynamics</b>
                </p>
              </div>
              <div class="col-md-10">
                <p>
                  The more quality content added by GOOGLE or sellers the more benefit seekers get from searching and
                  the more likely they will return to search again and the more ads they will view and potentially
                  respond to impacting the sellers advertising on the platform.

                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">
                <p>
                  <b>Pay Pal</b>
                </p>
              </div>
              <div class="col-md-10">
                <p>
                  1 Sellers and buyers agree to terms of service of a secure payment system.
                  <br> 2 Buyers and sellers seek to use the payment service to conduct transactions.
                  <br> 3&amp;4 Pay Pal provides the service infrastructure for a fee based on a percentage of the
                  transaction price between the buyer and seller.
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">
                <p>
                  <b>Dynamics</b>
                </p>
              </div>
              <div class="col-md-10">
                <p>
                  The ease of use and the level of security achieved after setup encourages both the buyer and seller
                  to use these services to conduct more business and the more they do the more comfortable they become
                  with the process and the results leading to more use of the payment system between them which
                  encourages other buyers and sellers to join.
                </p>
              </div>
            </div>
            <h3 class="title title-md">
              Pipeline & Digital Platform value creation and competition comparison
            </h3>
            <p>
              The typical pipe line business creates value one client at a time in the goods and services they produce
              and their clients consume. The objective of a platform business is to find producers to contribute
              content that makes the platform valuable to a market of users that consume this content and generate a
              revenue stream as a direct result of the interactions occurring on the platform. When pipe line and
              platform businesses compete head on the pipe line businesses loose badly.</p>
            <p>Platform businesses require significantly less financial and human capital to launch or scale and they
              do both far more quickly and efficiently than any pipe line alternative. Where pipe line businesses
              require factories, workers and raw materials just to make something to sell that might require yet
              another physical plant and employees to provide a marketplace to make those sales the platform business
              model primarily requires access to digital media. Platform businesses access digital media to create the
              infrastructure where their producers, users, and sellers can interact. Generating these interactions is
              the business opportunity for these platform business companies. </p>
            <p>Uber needs access to the internet and a mobile communication platform to create the infrastructure
              where drivers and riders can arrange the fares that generate Uber's revenues. A traditional cab company
              needs to hire drivers provide cars and still needs mobile communications and perhaps internet access to
              produce it's fare revenues. In a head on comparison there is nothing fair about what it takes for each
              business model to generate their fare revenues and a look at how each of these business models reacts to
              changing business conditions illustrates the differences. The cab company would need to hire another
              driver and acquire another car to respond to a perceived increase in demand for rides and lay off
              drivers and sell off unused cars in response to actual demand decreases. Uber passes most of the
              financial risks of fluctuating demand to their producers who own the cars and are the drivers. </p>
            <p> The trick to becoming a successful platform company is not just the investment in the digital
              infrastructure, but creating the synergies that drive the ecosystem to attract enough users, and
              producers for sales of some kind to monetize the platform. Upon attaining that critical mass of users
              and producers this business models ability to scale then depends upon the synergies it continues to
              generate and the capability of digital infrastructure to respond. Pipe line and platform businesses both
              use digital technologies. The difference is in how they use these technologies. Both use them for
              communication and transaction capabilities. Platform businesses use digital media to develop interaction
              forums where content is created and shared by producers and users in a manner that allows sales to
              monetize the interaction process. The specific capabilities and limitations of the digital media
              utilized impacts the quality of the interactions between these participants and the effective
              functioning of these forums. If Pay Pal lost it's ability to provide a secure forum to conduct payments
              would it stay in business? If the data GOOGLE searches produced proved to be inaccurate would people be
              less likely to use it? If Uber was continually hacked so that a rival driver could steal Uber fares
              would the user’s loyalty sustain the company?</p>
            <p>Pipe line or platform the quality of what you offer matters and in a digital platform business the
              qualities of the digital media you use to create your interactive forum matters. The reliability, the
              security, the privacy and the integrity of your platform business depends upon it. Innovations in
              digital technology can bring improvements and or create problems for platform businesses designed with
              previous technologies. Innovations that lead to hacking and decryption are threatening and those that
              provide greater security and privacy are usually welcomed as improvements in the long run. In the short
              run all the platform businesses locked into older digital technologies will be at a disadvantage when
              compared to any new competitor that successfully deploys these innovative improvements.</p>
            <p>The greatest source of competition for platform businesses is from innovation in digital media leading
              to forces that either threaten their operations directly or create an adversary with a competitive
              advantage. The patented technology that the <b>HIDDIN</b> Marketplace platform is based on represents an
              innovation of the digital transmission process creating new formulations of the traditional media. Our
              technology changes how digital communications and transactions are accomplished. The digital platforms
              and the interactive forums developed for them with our technology will not operate the same way nor
              provide the same capabilities as the older technologies the other platform businesses use. The security,
              privacy and the marketing innovations impacting research, advertising delivery and the cost to market
              savings offered by the <b>HIDDIN</b> Marketplace platform present a formidable challenge to existing
              platform businesses stuck with the older digital technologies.
            </p>
          </div>
        </div>
        <app-footer></app-footer>
      </div>
    </div>
  </div>
</ion-content>