import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { AddFamilyMemberComponent } from '@modal/add-family-member/add-family-member.component';
import { ConfirmationBoxComponent } from '@modal/confirmation-box/confirmation-box.component';
import { AuthServiceService } from '@services/auth-service.service';
import { LoaderService } from '@services/loader.service';
import { Globals } from 'src/app/globals';
import { RegUser } from '../basic-reg/RegUser';

@Component({
  selector: 'app-family-member',
  templateUrl: './family-member.component.html',
  styleUrls: ['./family-member.component.scss'],
})
export class FamilyMemberComponent implements OnInit {

  members: RegUser[] = [];
  errorMessage = null;

  currentUser = new RegUser();
  requestPath = this.global.requestPath;

  constructor(public modalCtrl: ModalController
    , private global: Globals
    , private authService: AuthServiceService
    , private formBuilder: FormBuilder
    , private loader: LoaderService
    , private router: Router) {
  }

  ngOnInit() {

  }

  ionViewDidEnter() {
    this.fetchUser();
    this.getMembers();
  }

  fetchUser = function () {
    this.currentUser = this.authService.getLoggedInUser();
  }

  async addMember() {
    const modal = await this.modalCtrl.create({
      component: AddFamilyMemberComponent,
      componentProps: {
        "type": 1,
        "memberId" : 0
      }
    });

    modal.onDidDismiss().then(responseData => {
      if (!this.global.isEmpty(responseData)) {
        var resData = responseData.data;
        if (!this.global.isEmpty(resData)) {
          this.getMembers();
        }
      }
    });

    return await modal.present();
  }

  async deleteMember(id) {
    const modal = await this.modalCtrl.create({
      component: ConfirmationBoxComponent,
      componentProps: {
        "title": "Confirmation",
        "message": "Are you sure to delete this member?"
      }
    });

    modal.onDidDismiss().then(responseData => {
      if (!this.global.isEmpty(responseData)) {
        var resData = responseData.data;
        if (!this.global.isEmpty(resData)) {
          this.deleteMemberById(id);
        }
      }
    });

    return await modal.present();
  }

  async editMember(id) {
    const modal = await this.modalCtrl.create({
      component: AddFamilyMemberComponent,
      componentProps: {
        "type": 2,
        "memberId" : id
      }
    });

    modal.onDidDismiss().then(responseData => {
      if (!this.global.isEmpty(responseData)) {
        var resData = responseData.data;
        if (!this.global.isEmpty(resData)) {
          this.getMembers();
        }
      }
    });

    return await modal.present();
  }

  deleteMemberById(id) {
    // Delete Member
    this.loader.show("Please wait....");
    this.authService.postEncrypt('user?' + this.requestPath + '=deleteUserMemberById&memberId=' + id, null)
      .subscribe(res => {
        this.loader.hide();
        // console.log(res);

        if (res.success) {
          this.getMembers();
        } else {
          this.errorMessage = res.message;
        }
      }, error => {
        this.loader.hide();
        // console.log(error);
        this.errorMessage = "Error occured at server";
      });
  }

  getMembers = function () {
    this.errorMessage = "";
    this.members = [];
    this.loader.show("Loading data...");
    this.authService.get('user?' + this.requestPath + '=getUserMember')
      .subscribe((res) => {
        this.loader.hide();
        // console.log(res);
        if (res.success) {
          res.data = this.global.decrypt(res.data);
          if (res.data && res.data.length > 0) {
            for (var i = 0; i < res.data.length; i++) {
              var emp = res.data[i];

              if (emp.lastName == null) {
                emp.lastName = "";
              }
              emp.name = emp.firstName + " " + emp.lastName;

              this.members.push(emp);
            }
          }
        } else {
          this.errorMessage = res.message;
        }
      }, error => {
        this.loader.hide();
        // console.log("error in getting employees");
        this.errorMessage = "Error occured while fetching the employees.";
      })
  }

}
