<footer class="nk-footer bg-theme has-ovm">
  <hr class="hr hr-white-5 my-0">
  <section class="bg-transparent tc-light ov-v">
    <div class="container">
      <!-- Block @s -->
      <div class="nk-block block-footer">
        <!-- .row -->
        <div class="footer-bottom">
          <div class="row justify-content-center">
            <div class="col-lg-7">
              <div class="copyright-text copyright-text-s3">
                <!-- <a href="#" class="wgs-logo animated fadeInUp" data-animate="fadeInUp" data-delay=".1"
                  style="visibility: visible; animation-delay: 0.1s;">
                  <img src="assets/images/hiddin-logo.png" alt="logo">
                </a> -->
                <p><span class="d-block">Copyright © 2015.</span> All trademarks and copyrights belong to their respective owners.
                </p>
              </div>
            </div>
            <div class="col-lg-5 text-lg-right">
              <ul class="footer-links">
                <!-- <li><a href="https://hiddin.net/webmail" target="_blank">Hiddin-Mail</a></li> -->
                <a href="https://hiddin.net/webmail/" target="_blank" class="btn btn-md btn-grad btn-grad-alt no-change">
                  <span>Hiddin-Mail</span>
                </a>
                <li><a href="javascript:" [routerLink]="['/privacy-policy']">Privacy Policy</a></li>
                <li><a href="javascript:" [routerLink]="['/terms-and-conditions']">Terms &amp; Conditions</a></li>
              </ul>
            </div>
          </div>
        </div><!-- .row -->
      </div><!-- .block @e -->
    </div>
  </section>
  <div class="nk-ovm shape-t h-75"></div>
</footer>