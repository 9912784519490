import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from '../globals';
import { environment } from '../../environments/environment';
import { RegTokenServiceService } from './reg-token-service.service';
import { Router } from '@angular/router';
import { RegUser } from '../pages/basic-reg/RegUser';
import { throwError } from 'rxjs';
import { EncryptionUtil } from '../util/EncryptionUtil';
import { AppConfig } from './AppConfig';
import { AppPropService } from './app-prop.service';


@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {

  appConfig: AppConfig;

  constructor(private globals: Globals, private http: HttpClient, private router: Router,
    private regTokenService: RegTokenServiceService, private appPropService: AppPropService) {
    this.loadAppPropConfig();
  }

  public loadAppPropConfig() {
    setTimeout(() => {
      if (this.appConfig == undefined) {
        this.appConfig = this.appPropService.appConfig;
        this.loadAppPropConfig();
      }
    }, 2000);
  }

  public getAuthHeaders = () => ({
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.getToken()
    })
  });

  public getHeaders = () => ({
    headers: new HttpHeaders({
      Accept: 'application/json',
    })
  });

  public getNoSecure = function (path: string) {
    return this.http.get(environment.base + path, this.getHeaders());
  }

  public getNoSecurePayment = function (path: string) {
    return this.http.get(environment.paymentURL + path, this.getHeaders());
  }

  public get = function (path: string) {
    if (!this.authorized()) {
      this.unauthorized();
    }
    return this.http.get(environment.base + path, this.getAuthHeaders());
  }

  public postNoSecure = function (path: string, data: any) {
    return this.http.post(environment.base + path, data, this.getHeaders());
  }

  public post = function (path: string, data: any) {
    if (!this.authorized()) {
      this.unauthorized();
    }
    return this.http.post(environment.base + path, data, this.getAuthHeaders());
  }

  public postWithToken = function (path: string, data: any, token: string) {
    if (this.globals.isEmpty(token)) {
      this.unauthorized();
    }
    return this.http.post(environment.base + path, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JSON.parse(token)
      })
    });
  }

  public loginOld = function (email, phoneNumber, password) {
    let loginData = {
      email: email,
      phoneNumber: phoneNumber,
      password: window.btoa(password)
    };
    var path = 'oauth/token?requestPath=appLogin';
    return this.postDefaultEncrypt(path, loginData);
  }

  public loginTemp = function (deviceId, signupTime) {
    var path = 'oauth/token?requestPath=tempLogin&deviceId=' + deviceId + "&signupTime=" + signupTime;
    return this.getNoSecure(path);
  }

  public login = function (adjustedFlagTime, encounterNo) {
    var path = 'oauth/token?requestPath=login&adjustedFlagTime=' + adjustedFlagTime + "&encounterNo=" + encounterNo;
    return this.getNoSecure(path);
  }

  public logout = function () {
    this.globals.removeLocalData(this.globals.HIDDIN_TOKEN);
    this.globals.removeLocalData(this.globals.HIDDIN_USER_DETAILS);
    this.globals.removeLocalData(this.globals.USER_IMAGE_URL);
    this.globals.removeLocalData(this.globals.BOGUS_KEY);
    this.globals.removeLocalData(this.globals.SUBSCRIPTION);
  }

  public authorized = function () {

    if (this.getToken()) {
      return true;
    }
    return false;
  }

  public getToken = function () {

    var tokenString = this.globals.getLocalData(this.globals.HIDDIN_TOKEN);

    if (this.globals.isEmpty(tokenString)) {
      return null;
    }

    return JSON.parse(tokenString);
  }

  public isLoggedIn = function () {
    if (this.globals.isEmpty(this.getToken())) {
      return false;
    }
    return true;
  }

  public getLoggedInUser = function (): RegUser {
    var user = this.globals.getLocalData(this.globals.HIDDIN_USER_DETAILS);

    if (this.globals.isEmpty(user)) {
      return null;
    } else {
      var loggedInUser = JSON.parse(user);
      loggedInUser.imageUrl = this.getProfilePicture();
      return loggedInUser;
    }

  }

  public getSubscription() {
    var isExpired = true;
    try {
      isExpired = JSON.parse(this.globals.getLocalData(this.globals.SUBSCRIPTION));
    } catch (error) {
      this.router.navigate(['/error', '401']);
    }
    return isExpired;
  }

  public getProfilePicture() {
    if (this.isLoggedIn()) {
      return this.globals.getLocalData(this.globals.USER_IMAGE_URL);
    }
    return null;
  }

  public getNoSecureCore = function (path: string) {
    return this.http.get(environment.hiddinURL + path, this.getHeaders());
  }

  public getSecureCore = function (path: string) {
    return this.http.get(environment.hiddinURL + path, this.getAuthHeaders());
  }

  public postNoSecureCore = function (path: string, data) {
    return this.http.post(environment.hiddinURL + path, data, this.getHeaders());
  }

  public unauthorized = function () {
    this.logout();
    this.router.navigate(['/login']);
  }

  public error404 = function () {
    this.router.navigate(['/error', '404']);
  }

  public error500 = function () {
    this.router.navigate(['/error', '500']);
  }

  public postEncrypt = function (path: string, data: any) {

    if (!this.authorized()) {
      this.unauthorized();
    }

    if (!environment.isEncryptionEnable) {
      return this.http.post(environment.base + path, data, this.getAuthHeaders());
    }

    var dataStr = JSON.stringify(data);
    // console.log("Data to send: " + dataStr);

    var encryptedKey = this.globals.getCombineAesKey();
    // console.log("encryptedKey: " + encryptedKey);

    if (this.globals.isEmpty(encryptedKey)) {
      //console.log("Invalid AES Key");
      throwError("Invalid AES Key");
    }

    var encryptedData;
    if (!this.globals.isEmpty(data)) {
      encryptedData = EncryptionUtil.encrypt(encryptedKey, dataStr);
      // console.log("encryptedData: " + encryptedData);
    }

    // Append encrypted parameter; encrypted=true
    if (path.indexOf("?") >= 0) {
      path = path + "&encrypted=true&defaultEncrypted=false";
    } else {
      path = path + "?encrypted=true&defaultEncrypted=false";
    }

    return this.http.post(environment.base + path, encryptedData, this.getAuthHeaders());
  }

  public postEncryptCore = function (path: string, data: any) {

    if (!this.authorized()) {
      this.unauthorized();
    }

    if (!environment.isEncryptionEnable) {
      return this.http.post(environment.hiddinURL + path, data, this.getAuthHeaders());
    }

    var dataStr = JSON.stringify(data);
    // console.log("Data to send: " + dataStr);

    var encryptedKey = this.globals.getCombineAesKey();
    // console.log("encryptedKey: " + encryptedKey);

    if (this.globals.isEmpty(encryptedKey)) {
      //console.log("Invalid AES Key");
      throwError("Invalid AES Key");
    }

    var encryptedData;
    if (!this.globals.isEmpty(data)) {
      encryptedData = EncryptionUtil.encrypt(encryptedKey, dataStr);
      // console.log("encryptedData: " + encryptedData);
    }

    // Append encrypted parameter; encrypted=true
    if (path.indexOf("?") >= 0) {
      path = path + "&encrypted=true&defaultEncrypted=false";
    } else {
      path = path + "?encrypted=true&defaultEncrypted=false";
    }

    return this.http.post(environment.hiddinURL + path, encryptedData, this.getAuthHeaders());
  }

  public postDefaultEncrypt = function (path: string, data: any) {

    if (!environment.isEncryptionEnable) {
      return this.postNoSecure(path, data, this.getHeaders());
    }

    var dataStr = JSON.stringify(data);
    // console.log("Data to send: " + dataStr);

    var encryptedData;
    if (!this.globals.isEmpty(data)) {
      encryptedData = EncryptionUtil.encryptDefault(this.appConfig.pop, dataStr);
      // console.log("encryptedData: " + encryptedData);
    };

    // Append encrypted parameter; encrypted=true
    if (path.indexOf("?") >= 0) {
      path = path + "&encrypted=true&defaultEncrypted=true";
    } else {
      path = path + "?encrypted=true&defaultEncrypted=true";
    }

    return this.postNoSecure(path, encryptedData, this.getHeaders());
  }

  public postDefaultEncryptCore = function (path: string, data: any) {

    if (!environment.isEncryptionEnable) {
      return this.http.post(environment.hiddinURL + path, data, this.getHeaders());
    }

    var dataStr = JSON.stringify(data);
    // console.log("Data to send: " + dataStr);

    var encryptedData;
    if (!this.globals.isEmpty(data)) {
      encryptedData = EncryptionUtil.encryptDefault(this.appConfig.pop, dataStr);
      // console.log("encryptedData: " + encryptedData);
    }

    // Append encrypted parameter; encrypted=true
    if (path.indexOf("?") >= 0) {
      path = path + "&encrypted=true&defaultEncrypted=true";
    } else {
      path = path + "?encrypted=true&defaultEncrypted=true";
    }

    return this.http.post(environment.hiddinURL + path, encryptedData, this.getHeaders());
  }

  public postEcryptWithToken = function (path: string, data: any, token: string) {
    if (this.globals.isEmpty(token)) {
      this.unauthorized();
    }

    if (!environment.isEncryptionEnable) {
      return this.http.post(environment.base + path, data, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + JSON.parse(token)
        })
      });
    }

    var dataStr = JSON.stringify(data);
    // console.log("Data to send: " + dataStr);

    var encryptedData;
    if (!this.globals.isEmpty(data)) {
      encryptedData = EncryptionUtil.encryptDefault(this.appConfig.pop, dataStr);
      // console.log("encryptedData: " + encryptedData);
    }

    // Append encrypted parameter; encrypted=true
    if (path.indexOf("?") >= 0) {
      path = path + "&encrypted=true&defaultEncrypted=true";
    } else {
      path = path + "?encrypted=true&defaultEncrypted=true";
    }

    return this.http.post(environment.base + path, encryptedData, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JSON.parse(token)
      })
    });
  }
}
