import { Injectable } from '@angular/core';
import { AppConfig } from './AppConfig';

@Injectable({
  providedIn: 'root'
})
export class AppPropService {
  public appConfig: AppConfig;

  constructor() {
    this.loadAppConfig();
    // this.waitForLoadingProp();
  }

  // Basic operations
  loadAppConfig = () => {
    this.appConfig = new AppConfig();
    this.appConfig.pop = "MyS0c1a8l3R3ward";
    // this.http.get(environment.base + 'ns/common/user?' + this.global.requestPath + '=pop', this.getHeaders())
    // .subscribe({
    //   next: (response: any) => {
    //     if (response.success) {
    //       this.appConfig = JSON.parse(EncryptionUtil.decryptDefault(JSON.parse(EncryptionUtil.decryptDefault("#@" + response.status + "@#", response.message)), response.data));
    //       console.log("App Config: ", this.appConfig);
    //     } else {
    //       console.log("Error occured while loading app configs.");
    //     }
    //   },
    //   error: (error) => { console.log(error); }
    // });
  }
}
