<app-header-left-menu [user]="user"></app-header-left-menu>
<ion-content id="main-content" class="full-width-page">
  <div class="bg-theme-alt">
    <div class="bg-grad-special">
      <div class="bg-grad-special-alt">
        <div class="nk-ovm shape-u shape-contain shape-left-top"></div>
        <app-header [user]="user"></app-header>
        <div class="subContent">
          <div class="main-content container">
            <div class="tabs_wrap">
              <ul>
                <li class="active">Profile</li>
                <li [routerLink]="['/profile-enrich/basic-detail']">Bio Details</li>
                <li [routerLink]="['/profile-enrich/upload-documents']">Documents</li>
                <li [routerLink]="['/profile-enrich/security-question']">Questions</li>
                <li [routerLink]="['/profile-enrich/collect-directory']">Affiliations</li>
                <li [routerLink]="['/profile-enrich/education-detail']">Education</li>
                <li [routerLink]="['/profile-enrich/occupation-detail']">Occupation</li>
              </ul>
            </div>
            <div class="profile">
              <button
                *ngIf="user.roleLevel == 1 && (user.parentId == undefined || user.parentId == null || user.parentId == 0) && user.isDocumentUploaded"
                class="btn btn-primary btn-sm rghtAlign" type="button" (click)="gotToManageRelatives()">Manage
                Relatives</button>

              <form [formGroup]="signUpForm" (ngSubmit)="saveProfile()">
                <div class="flex-form">
                  <div class="image">
                    <div class="field-item">
                      <div class="field-wrap">
                        <div *ngIf="profileImageSrc">
                          <img [src]="profileImageSrc" />
                        </div>
                        <div *ngIf="!profileImageSrc">
                          <img src="assets/images/team/profile.png" />
                        </div>
                        <label><input type="file" (change)="fileBrowseHandler($event.target.files)"
                            accept=".png, .jpg, .jpeg"><em class="fa fa-edit tc-primary"></em></label>
                      </div>
                    </div>
                    <div class="field-item">
                      <div class="field-wrap">
                        <h6 class="title title-xs">Profile Type: <span class="greenBold">{{userForm.type}}</span>
                        </h6>
                      </div>
                      <div class="field-wrap" *ngIf="isSubscriptionRequired">
                        <h6 class="title title-xs">Subscription expired in <span
                            class="rednBold">{{remainingDays}}</span> days
                        </h6>
                      </div>
                      <div class="field-wrap mt-4">
                        <h6 class="title title-xs">Time <span class="dateTime">{{currentTime}}</span>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div class="width-50">
                    <div class="field-item">
                      <div class="field-wrap">
                        <h6 class="title title-xs">First Name<span class="red">*</span></h6>
                        <!-- <input type="text" class="input-bordered" placeholder="First Name" [value]="user.firstName" [disabled]="!ifEdited"> -->
                        <input type="text" formControlName="firstName" class="input-bordered"
                          [(ngModel)]="userForm.firstName"
                          [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
                        <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                          <div *ngIf="f.firstName.errors.required">First Name is required
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="field-item">
                      <div class="field-wrap">
                        <h6 class="title title-xs">Last Name<span class="red">*</span></h6>
                        <input type="text" formControlName="lastName" class="input-bordered"
                          [(ngModel)]="userForm.lastName" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }"
                          placeholder="Last Name" />
                        <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                          <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                        </div>
                      </div>
                    </div>
                    <div class="field-item " *ngIf="userForm.hiddinEmail">
                      <div class="field-wrap">
                        <h6 class="title title-xs">Hiddin Email
                          <!-- <i class="fa fa-envelope newFeildItem"
                          aria-hidden="true">&nbsp;</i> -->
                          <span class="red">*</span>
                        </h6>
                        <h5 class="emailTxt">{{userForm.hiddinEmail}}</h5>

                      </div>
                    </div>
                    <div class="field-item">
                      <div class="field-wrap">
                        <h6 class="title title-xs">Phone Number</h6>
                        <input type="tel" class="input-bordered" placeholder="Phone Number" [value]="user.phoneNumber"
                          [disabled]="true">
                      </div>
                    </div>
                    <div class="field-item">
                      <div class="field-wrap">
                        <h6 class="title title-xs">Date of Birth (MM/DD/YYYY)<span class="red">*</span></h6>
                        <input type="text" class="input-bordered" formControlName="birthDate"
                          placeholder="Enter Date Of Birth" (ngModelChange)="onDateChange($event)"
                          [(ngModel)]="user.birthDate" [ngClass]="{ 'is-invalid': submitted && f.birthDate.errors }" />
                        <div *ngIf="submitted && f.birthDate.errors" class="text-danger">
                          <div *ngIf="f.birthDate.errors.required">Date of birth is required</div>
                        </div>
                        <div *ngIf="dateValidation" class="invalid-feedback">
                          <div>Date of birth should be before current date</div>
                        </div>
                        <div *ngIf="dateFormatError" class="invalid-feedback">
                          Date of birth should be in <b>mm/dd/yyyy (Ex: 01/01/1990)</b> format
                        </div>
                      </div>
                    </div>
                    <div class="field-item">
                      <div class="field-wrap">
                        <h6 class="title title-xs">Email</h6>
                        <input type="text" class="input-bordered" placeholder="Your Email" [value]="user.email"
                          [disabled]="true">
                      </div>
                    </div>
                    <div class="field-item">
                      <div class="field-wrap">
                        <h6 class="title title-xs">TimeZone<span class="red">*</span></h6>
                        <input *ngIf="!ifEdited" type="text" class="input-bordered" [value]="user.timeZone"
                          [disabled]="true">
                        <select *ngIf="ifEdited" name="timezone" class="input-bordered" formControlName="timeZone"
                          [(ngModel)]="user.timeZone" [ngClass]="{ 'is-invalid': submitted && f.timeZone.errors }">
                          <option *ngFor="let timezone of timeZones" [value]="timezone.value">{{timezone.label}}
                          </option>
                        </select>
                        <div *ngIf="ifEdited && submitted && f.timeZone.errors" class="invalid-feedback">
                          <div *ngIf="f.timeZone.errors.required">TimeZone is required</div>
                        </div>
                      </div>
                    </div>
                    <div class="field-item" *ngIf="ifEdited">
                      <div class="field-wrap">
                        <h6 class="title title-xs">Password (Only if you want to change)</h6>
                        <input type="password" formControlName="userPassword" class="input-bordered"
                          [(ngModel)]="userForm.userPassword"
                          [ngClass]="{ 'is-invalid': submitted && f.userPassword.errors }" placeholder="Enter Password"
                          id="pwd" onPaste="return false" placeholder="Password" />
                        <i class="fa" [ngClass]="passwordVisible ? 'fa-eye-slash' : 'fa-eye'"
                          (click)="togglePassword('pwd')"></i>
                        <div *ngIf="submitted && passwordError" class="invalid-feedback">
                          <div>{{passwordError}}</div>
                        </div>
                      </div>
                    </div>
                    <div class="field-item" *ngIf="ifEdited">
                      <div class="field-wrap">
                        <h6 class="title title-xs">Confirm Password (Only if you want to change)</h6>
                        <input type="password" formControlName="confirmPassword" class="input-bordered"
                          [(ngModel)]="userForm.confirmPassword"
                          [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" id="confirmPassword"
                          onPaste="return false" placeholder="Repeat Password" />
                        <i class="fa" [ngClass]="confirmPasswordVisible ? 'fa-eye-slash' : 'fa-eye'"
                          (click)="togglePassword('confirmPassword')"></i>
                        <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                          <div *ngIf="f.confirmPassword.errors.mustMatch">Passwords must match</div>
                        </div>
                        <div *ngIf="submitted && confirmpasswordError" class="invalid-feedback">
                          <div>{{confirmpasswordError}} </div>
                        </div>
                      </div>
                    </div>
                    <div class="btn-container">
                      <button [disabled]="ifEdited" class="btn btn-primary btn-block btn-md" type="button"
                        (click)="editProfile()">Edit Profile</button>
                      <button [disabled]="!ifEdited" class="btn btn-primary btn-block btn-md" type="submit">Save
                        Profile</button>
                      <!-- <button class="btn btn-primary btn-block btn-md" type="button"
                        *ngIf="(user.roleLevel == 0 || user.roleLevel == 1 || user.roleLevel == 2) && !isRegCompleted"
                        (click)="presentModal()">Complete
                        Registration</button> -->
                      <!-- <button class="btn btn-primary btn-block btn-md" type="button" (click)="profileEnrich()">Enrich Profile</button> -->
                      <button *ngIf="userForm.roleLevel == 1 && userForm.isDocumentUploaded == true"
                        class="btn btn-primary btn-block btn-md" type="button"
                        (click)="openUpgradeToBusinessModal()">Upgrade to Business</button>
                    </div>
                    <div *ngIf="successMessage && successMessage != ''"
                      class="alert alert-success title title-xs mt-2 mb-2">
                      {{successMessage}}
                    </div>
                    <div *ngIf="errorMessage && errorMessage != ''" class="alert alert-danger title title-xs mt-2 mb-2">
                      {{errorMessage}}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
      </div>
    </div>
  </div>
</ion-content>