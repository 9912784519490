<ion-content overflow-scroll="true" class="content-main">
  <div id="login-popup">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <!-- <button class="modal-close" aria-label="Close" (click)="dismiss()"><em class="ti ti-close"></em></button> -->
        <div class="ath-container m-0">
          <div class="ath-body">
            <h5 class="ath-heading title">Notification <small class="tc-default">{{errorMsg}}</small></h5>
            <div class="btn-container animated fadeInDown" data-animate="fadeInDown" data-delay=".85" style="visibility: visible; animation-delay: 0.85s;">
              <div class="notification-content">
                <img [src]="imageSrc">
                <div class="btn btn-primary btn-md" (click)="dismiss()" >Ok</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>
