<app-header-left-menu [user]="user"></app-header-left-menu>
<ion-content id="main-content" class="full-width-page">
  <div class="bg-theme-alt">
    <div class="bg-grad-special">
      <div class="bg-grad-special-alt">
        <div class="nk-ovm shape-u shape-contain shape-left-top"></div>
        <app-header [user]="user"></app-header>
        <div class="subContent">
          <div class="main-content container mt-4">
            <!-- pending UI start -->
            <div class="pending-container pending-container2 container mb-5">
              <div *ngIf="errorMessage && errorMessage.length > 0">
                <p class="red">{{errorMessage}}</p>
              </div>
              <div class="pendingAction mt-3 mb-2">
                <h5 class="pending-heading">
                  <a (click)="gotoDashboard()"><ion-icon name="arrow-back-outline"></ion-icon></a>
                  Pending Action
                </h5>
                <ul class="list-group list-group-horizontal mt-4 margin-bottom-20">
                  <li class="list-group-item active-pill" (click)="allRequest();"
                    [ngClass]="allRequestVar ? ' active-pill' : 'pill'">All</li>
                  <li class="list-group-item  " (click)="pendingRequest();"
                    [ngClass]="allPendingVar ? ' active-pill' : 'pill'">Pending</li>
                  <li class="list-group-item " (click)="requested();" [ngClass]="requestVar ? ' active-pill' : 'pill'">
                    Requested</li>
                </ul>
              </div>
              <div *ngIf="allRequestVar || allPendingVar">
                <div class="search-card" *ngFor="let requestedContact of pendingRequests">
                  <div class="row width100">
                    <div class=" col-sm-12 col-md-2 col-lg-1 col-xl-1 cardImg"
                      (click)="openProfile(null, requestedContact.contactUser, false, false)">
                      <img class="card-img" src="assets/images/team/profile.png">
                    </div>
                    <div class=" col-sm-12 col-md-10 col-lg-11 col-xl-11">
                      <h5 class="team-name title title-sm paddingTop0">{{requestedContact.contactUser.firstName}}
                        {{requestedContact.contactUser.lastName}}
                        <span *ngIf="requestedContact.personalizedMsg" class="msg-icon"><ion-icon
                            (click)="messagespopup(requestedContact.personalizedMsg)"
                            name="chatbox-ellipses-outline"></ion-icon></span>
                        <span class="workspn">Pending</span>
                      </h5>
                      <div class="row padding-left-7 m-0">
                        <div class="col-sm-12 col-md-7 col-lg-7 ">
                          <div class="row">
                            <div class=" col-md-6 col-sm-12">
                              <a class="grey">
                                <i class="fa fa-envelope" aria-hidden="true"></i>{{requestedContact.contactUser.email}}
                              </a>
                            </div>
                            <div class=" col-md-6 col-sm-12">
                              <a class="grey">
                                <i class="fa fa-phone"
                                  aria-hidden="true"></i>{{requestedContact.contactUser.phoneDialCode}}
                                {{requestedContact.contactUser.phoneNumber | slice:0:6}}**** </a>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12 col-md-5 col-lg-5">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-6 txt-right mb-2">
                              <button type="button" class="basicbtn declinebtn"
                                (click)="rejectContact(requestedContact)">Decline &nbsp; <i
                                  class="fa fa-times-circle"></i></button>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-6 txt-right mb-2">
                              <button type="button" class="basicbtn approvebtn"
                                (click)="openSettingModal(requestedContact, requestedContact.contactUser)">Approve
                                &nbsp; <i class="fa fa-check-circle"></i></button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div *ngIf="allRequestVar || requestVar">
                <div class="search-card" *ngFor="let requestedContact of requestedContacts">
                  <div class="row width100">
                    <div class=" col-sm-12 col-md-2 col-lg-1 col-xl-1 cardImg"
                      (click)="openProfile(null, requestedContact.contactUser, false, false)">
                      <img class="card-img" src="assets/images/team/profile.png">
                    </div>
                    <div class=" col-sm-12 col-md-10 col-lg-11 col-xl-11">
                      <h5 class="team-name title title-sm paddingTop0">{{requestedContact.contactUser.firstName}}
                        {{requestedContact.contactUser.lastName}}
                        <span *ngIf="requestedContact.personalizedMsg" class="msg-icon"><ion-icon
                            (click)="messagespopup(requestedContact.personalizedMsg)"
                            name="chatbox-ellipses-outline"></ion-icon></span>
                        <span class="frndspn">Requested</span>
                      </h5>
                      <div class="row padding-left-7 m-0">
                        <div class="col-sm-12 col-md-7 ">
                          <div class="row">
                            <div class=" col-md-6 col-sm-12">
                              <a class="grey">
                                <i class="fa fa-envelope" aria-hidden="true"></i>{{requestedContact.contactUser.email}}
                              </a>
                            </div>
                            <div class=" col-md-6 col-sm-12">
                              <a class="grey">
                                <i class="fa fa-phone"
                                  aria-hidden="true"></i>{{requestedContact.contactUser.phoneDialCode}}
                                {{requestedContact.contactUser.phoneNumber | slice:0:6}}**** </a>
                            </div>
                          </div>
                        </div>

                        <div class="col-sm-12 col-md-5">
                          <div class="row">
                            <div class="col-12 txt-right">
                              <button type="button" class="basicbtn declinebtn"
                                (click)="cancelContact(requestedContact)">Cancel &nbsp; <i
                                  class="fa fa-times-circle"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Defult loader -->
              <div class="zero-result mb-5" *ngIf="loadingRequestedData || loadingPendingData">
                <div class="zero-result-container">
                  <img src="../../../../assets/images/loader.gif" class="no-result-img mb-2">
                  <p class="mb-2">Loading data, please wait...</p>
                  <div class="search-user">
                  </div>
                </div>
              </div>
              <div class="zero-result mb-5" *ngIf="noDataFound && !loadingRequestedData && !loadingPendingData">
                <div class="zero-result-container">
                  <img src="../../../../assets/images/no-result.png" class="no-result-img mb-2">
                  <h5>Your list is empty</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
      </div>
    </div>
  </div>
  <div class="my-contact"></div>
</ion-content>