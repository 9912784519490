<app-header [user]="user"></app-header>
<ion-content>
  <div class="limiter">
    <div class="container-login100" style="background-image: url('assets/login/bg-01.jpg');">
      <div class="wrap-login100 p-l-110 p-r-110 p-t-62 p-b-33">
        <span class="login100-form-title">
          Signup
        </span>
        <div *ngIf="phoneNoFormEnabled">
          <form [formGroup]="mobileNoForm" (ngSubmit)="mobileNoSubmit()"
            class="login100-form validate-form flex-sb flex-w">
            <div class="p-t-31 p-b-9">
              <span class="txt1">
                Mobile No.
              </span>
            </div>
            <div class="wrap-input100 validate-input">
              <input type="tel" formControlName="phoneNumber" class="input100" [(ngModel)]="userForm.phoneNumber"
                [ngClass]="{ 'is-invalid': phoneNoSubmitted && phn.phoneNumber.errors }" />
              <div *ngIf="phoneNoSubmitted && phn.phoneNumber.errors" class="invalid-feedback">
                <div *ngIf="phn.phoneNumber.errors.required">Phone Number is required</div>
              </div>
              <span class="focus-input100"></span>
            </div>

            <div *ngIf="phoneNoErrorMessage && phoneNoErrorMessage != ''" class="invalid-feedback input100 mt-2 mb-2">
              {{phoneNoErrorMessage}}
            </div>

            <div class="container-login100-form-btn m-t-17">
              <button class="login100-form-btn" type="submit">
                Continue
              </button>
            </div>
          </form>
        </div>
        <div *ngIf="otpFormEnabled">
          <form [formGroup]="otpForm" (ngSubmit)="otpSubmit()" class="login100-form validate-form flex-sb flex-w">
            <div class="p-t-31 p-b-9">
              <span class="txt1">
                Verification Code
              </span>
            </div>
            <div class="wrap-input100 validate-input">
              <input type="text" formControlName="mobileOtp" class="input100" [(ngModel)]="userForm.mobileOtp"
                [ngClass]="{ 'is-invalid': otpSubmitted && otp.mobileOtp.errors }" />
              <div *ngIf="otpSubmitted && otp.mobileOtp.errors" class="invalid-feedback">
                <div *ngIf="otp.mobileOtp.errors.required">Verification Code is required</div>
              </div>
              <span class="focus-input100"></span>
            </div>

            <div *ngIf="otpErrorMessage && otpErrorMessage != ''" class="invalid-feedback input100 mt-2 mb-2">
              {{otpErrorMessage}}
            </div>

            <div class="container-login100-form-btn m-t-17">
              <button class="login100-form-btn" type="submit">
                Verify
              </button>
            </div>
          </form>
        </div>
        <div *ngIf="detailFormEnabled">
          <form [formGroup]="signUpForm" (ngSubmit)="signup()" class="login100-form validate-form flex-sb flex-w">
            <div class="p-t-31 p-b-9">
              <span class="txt1">
                First Name
              </span>
            </div>
            <div class="wrap-input100 validate-input">
              <input type="text" formControlName="firstName" class="input100" [(ngModel)]="userForm.firstName"
                [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
              <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                <div *ngIf="f.firstName.errors.required">First Name is required</div>
                <div *ngIf="f.firstName.errors?.maxlength">First Name can be max 100 characters long</div>
              </div>
              <span class="focus-input100"></span>
            </div>
            <div class="p-t-31 p-b-9">
              <span class="txt1">
                Last Name
              </span>
            </div>
            <div class="wrap-input100 validate-input">
              <input type="text" formControlName="lastName" class="input100" [(ngModel)]="userForm.lastName"
                [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
              <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                <div *ngIf="f.lastName.errors?.maxlength">Last Name can be max 100 characters long</div>
              </div>
              <span class="focus-input100"></span>
            </div>
            <div class="p-t-31 p-b-9">
              <span class="txt1">
                Email
              </span>
            </div>
            <div class="wrap-input100 validate-input">
              <input type="email" formControlName="emailAddress" class="input100" [(ngModel)]="userForm.emailAddress"
                [ngClass]="{ 'is-invalid': (submitted || f.emailAddress.touched) && f.emailAddress.errors }" />
              <div *ngIf="(submitted || f.emailAddress.touched) && f.emailAddress.errors" class="invalid-feedback">
                <div *ngIf="f.emailAddress.errors.required">Email is required</div>
                <div *ngIf="f.emailAddress.errors.email">Email must be a valid email
                  address</div>
                <div *ngIf="f.emailAddress.errors.pattern">Email must be a valid
                  email address</div>
              </div>
              <span class="focus-input100"></span>
            </div>
            <div class="p-t-31 p-b-9">
              <span class="txt1">
                Date of Birth
              </span>
            </div>
            <div class="wrap-input100 validate-input">
              <input type="date" class="input100" formControlName="birthDate" [(ngModel)]="dob"
                (ngModelChange)="onDateChange($event)" [ngClass]="{ 'is-invalid': submitted && f.birthDate.errors }" />
              <div *ngIf="submitted && f.birthDate.errors" class="error">
                <div *ngIf="f.birthDate.errors.required">Date of birth is required</div>
              </div>
              <div *ngIf="dateValidation" class="error">
                Date of birth should be before current date
              </div>
              <span class="focus-input100"></span>
            </div>
            <div class="p-t-31 p-b-9">
              <span class="txt1">
                Password
              </span>
            </div>
            <div class="wrap-input100 validate-input">
              <input type="password" formControlName="userPassword" class="input100" [(ngModel)]="userForm.userPassword"
                [ngClass]="{ 'is-invalid': submitted && f.userPassword.errors }" id="pwd" onPaste="return false" />
              <div *ngIf="submitted && f.userPassword.errors" class="invalid-feedback">
                <div *ngIf="f.userPassword.errors.required">Password is required
                </div>
                <div *ngIf="f.userPassword.errors.minlength">Password must be at
                  least 6
                  characters</div>
              </div>
              <span class="focus-input100"></span>
            </div>
            <div class="p-t-31 p-b-9">
              <span class="txt1">
                Confirm Password
              </span>
            </div>
            <div class="wrap-input100 validate-input">
              <input type="password" formControlName="confirmPassword" class="input100"
                [(ngModel)]="userForm.confirmPassword"
                [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" id="confirmPassword"
                onPaste="return false" />
              <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                <div *ngIf="f.confirmPassword.errors.required">Confirm Password is
                  required
                </div>
              </div>
              <div *ngIf="!matchPassword" class="invalid-feedback">Password should be matched
              </div>
              <span class="focus-input100"></span>
            </div>

            <div *ngIf="errorMessage && errorMessage != ''" class="invalid-feedback input100 mt-2 mb-2">
              {{errorMessage}}
            </div>

            <div class="container-login100-form-btn m-t-17">
              <button class="login100-form-btn" type="submit">
                Signup
              </button>
            </div>
          </form>
        </div>
        <div *ngIf="signupSuccess">
          <p class="success-msg">You have signed up successfully.</p>
          <div class="container-login100-form-btn m-t-17">
            <button class="login100-form-btn" (click)="goToLogin()">
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="dropDownSelect1"></div>

  <app-footer></app-footer>
</ion-content>