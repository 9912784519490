import { Component, OnInit } from '@angular/core';
import { AuthServiceService } from '@services/auth-service.service';
import { Globals } from 'src/app/globals';
import { environment } from 'src/environments/environment';
import { RegUser } from '../basic-reg/RegUser';

@Component({
  selector: 'app-our-products',
  templateUrl: './our-products.component.html',
  styleUrls: ['./our-products.component.scss']
})
export class OurProductsComponent implements OnInit {

  user: RegUser = new RegUser();
  LoggedIn: boolean;

  constructor(public authService: AuthServiceService, public global: Globals) { }

  ngOnInit(): void {
    this.user = this.authService.getLoggedInUser();
  }

  ionViewWillEnter() {
    this.user = this.authService.getLoggedInUser();
    this.LoggedIn = this.authService.isLoggedIn();
    if (!this.global.isEmpty(this.user)) {
      this.user.imageUrl = this.authService.getProfilePicture();
    }
  }

  goToHiddinSimulation() {
    var url = environment.appBaseUrl + "web/hiddin-core?page=simulator&type=demo";
    window.open(url, "_blank");
  }

}
