import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  selectedSpinner: any = "bubbles";
  cssToApplied: 'custom-loader-class';
  loaderToShow: any;
  isLoading = false;
  loaderMaxTime = 20000;

  constructor(private loadingController: LoadingController) { }

  async show(info) {
    this.isLoading = true;
    this.loaderToShow = this.loadingController.create({
      message: info,
      duration: this.loaderMaxTime,
      spinner: this.selectedSpinner,
      cssClass: this.cssToApplied
    }).then((res) => {
      res.present().then(() => {
        //console.log('Loader presented');
        if (!this.isLoading) {
          res.dismiss().then(() => {
            //console.log('Abort presenting');
          });
        }
      });
    });
  }

  async hide() {
    this.isLoading = false;
    return await this.loadingController.dismiss().then(() => {
      //console.log('dismissed');
    });
  }

}
