<ion-content overflow-scroll="true" class="content-main">
    <div id="login-popup">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <button class="modal-close" aria-label="Close" (click)="dismiss()"><em class="ti ti-close"></em></button>
                <div class="ath-container m-0">
                    <div class="ath-body">
                        <div class="btn-container animated fadeInDown" data-animate="fadeInDown" data-delay=".85" style="visibility: visible; animation-delay: 0.85s;">
                            <div class="notification-content">
                                <h5 class="ath-heading title">Verify Your Email <small class="tc-default"></small></h5>
                                <img src="assets/images/email-icon.png">
                                <p>We have sent you a link on your reistered email <b>{{emailAdd}}</b>.</p>
                                <p>Kindly check your <b>inbox</b> or <b>spam</b> folder and click on the link. You will be able to setup your password on the same.</p>
                                <p>Once password will be set, a verification code has been sent to your registered phone number.</p>
                                <div class="btn btn-primary btn-md mt-3" (click)="dismiss()">Ok</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ion-content>