<app-header-left-menu [user]="user"></app-header-left-menu>
<ion-content id="main-content">
  <div class="bg-theme-alt">
    <div class="bg-grad-special">
      <div class="bg-grad-special-alt">
        <div class="page-container">
          <div class="nk-ovm shape-u shape-contain shape-left-top"></div>
          <app-header [user]="user"></app-header>
          <div id="login-popup">
            <div class="ath-body">
              <div class="left-image-section"></div>
              <div class="right-section">
                <h5 class="ath-heading title">Registration<small class="tc-default">Create New Hiddin Account</small>
                </h5>
                <form [formGroup]="signUpForm" (ngSubmit)="signup()">
                  <div class="field-item">
                    <div class="field-wrap">
                      <input type="text" formControlName="firstName" class="input-bordered"
                        [(ngModel)]="userForm.firstName" [ngClass]="{ 'is-invalid': (submitted || f.firstName.touched) && f.firstName.errors }"
                        placeholder="Enter First Name" autocomplete="disabled" />
                      <div *ngIf="(submitted || f.firstName.touched) && f.firstName.errors" class="invalid-feedback">
                        <div *ngIf="f.firstName.errors.required">First Name is required</div>
                        <div *ngIf="f.firstName.errors?.maxlength">First Name can be max 100 characters long</div>
                      </div>
                    </div>
                  </div>
                  <div class="field-item">
                    <div class="field-wrap">
                      <input type="text" formControlName="lastName" class="input-bordered"
                        [(ngModel)]="userForm.lastName" [ngClass]="{ 'is-invalid': (submitted || f.lastName.touched) && f.lastName.errors }"
                        placeholder="Enter Last Name" autocomplete="disabled" />
                      <div *ngIf="(submitted || f.lastName.touched) && f.lastName.errors" class="invalid-feedback">
                        <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                        <div *ngIf="f.lastName.errors?.maxlength">Last Name can be max 100 characters long</div>
                      </div>
                    </div>
                  </div>
                  <div class="field-item"> 
                    <div class="field-wrap">
                      <ngx-intl-tel-input type="text" formControlName="phoneNumber"
                        [cssClass]="'phoneNumber'"
                        [preferredCountries]="preferredCountries"
                        [enableAutoCountrySelect]="false"
                        [enablePlaceholder]="true"
                        [searchCountryFlag]="true"
                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                        [selectFirstCountry]="false"
                        [selectedCountryISO]="CountryISO.UnitedStates"
                        [maxLength]="15"
                        [phoneValidation]="true"
                        [separateDialCode]="separateDialCode"
                        [numberFormat]="PhoneNumberFormat.International"                  
                        [(ngModel)]="userForm.phoneNumberAll" autocomplete="disabled"
                        [ngClass]="{ 'is-invalid': submitted && f.phoneNumber.errors}" required />
                      <div *ngIf="(submitted || f.phoneNumber.touched) && f.phoneNumber.errors" class="invalid-feedback">
                        <div *ngIf="f.phoneNumber.errors.required">Phone number is required</div>
                      </div>
                      <div *ngIf="(submitted || f.phoneNumber.touched) && f.phoneNumber.errors" class="invalid-feedback">
                        <div *ngIf="!f.phoneNumber.errors.validatePhoneNumber.valid">Invalid phone number</div>
                      </div>
                    </div>
                  </div>
                  <div class="field-item">
                    <div class="field-wrap">
                      <input type="email" formControlName="emailAddress" class="input-bordered"
                        oninput="this.value = this.value.toLowerCase()" [(ngModel)]="userForm.emailAddress"
                        [ngClass]="{ 'is-invalid': (submitted || f.emailAddress.touched) && f.emailAddress.errors }"
                        placeholder="Enter Email" autocomplete="disabled" />
                      <div *ngIf="(submitted || f.emailAddress.touched) && f.emailAddress.errors"
                        class="invalid-feedback">
                        <div *ngIf="f.emailAddress.errors.required">Email is required</div>
                        <div *ngIf="f.emailAddress.errors.email">Email must be a valid email
                          address</div>
                        <div *ngIf="f.emailAddress.errors.pattern">Email must be a valid
                          email address</div>
                      </div>
                    </div>
                  </div>
                  <div class="field-item">
                    <div class="field-wrap">
                      <input type="email" formControlName="confirmEmailAddress" class="input-bordered"
                        oninput="this.value = this.value.toLowerCase()" [(ngModel)]="userForm.confirmEmailAddress"
                        [ngClass]="{ 'is-invalid': (submitted || f.confirmEmailAddress.touched) && f.confirmEmailAddress.errors }"
                        onPaste="return false" placeholder="Re-Enter Email" autocomplete="disabled" />
                      <div *ngIf="(submitted || f.confirmEmailAddress.touched) && f.confirmEmailAddress.errors"
                        class="invalid-feedback">
                        <div *ngIf="f.confirmEmailAddress.errors.required">Email is required</div>
                        <div *ngIf="f.confirmEmailAddress.errors.email">Email must be a valid email address</div>
                        <div *ngIf="f.confirmEmailAddress.errors.pattern">Email must be a valid email address</div>
                        <div *ngIf="f.confirmEmailAddress.errors.mustMatch">Email must match</div>
                      </div>
                    </div>
                  </div>
                  <div class="field-item">
                    <div class="field-wrap">
                      <input type="text" class="input-bordered" formControlName="birthDate" [(ngModel)]="dob"
                        placeholder="Enter Date Of Birth" (ngModelChange)="onDateChange($event)"
                        [ngClass]="{ 'is-invalid': submitted && f.birthDate.errors }"
                        placeholder="Date of Birth (MM/dd/yyyy)" />
                    </div>
                    <div *ngIf="(submitted || f.birthDate.touched) && f.birthDate.errors" class="error">
                      <div *ngIf="f.birthDate.errors.required">Date of birth is required</div>
                    </div>
                    <div *ngIf="dateValidation" class="error">
                      Date of birth should be before current date
                    </div>
                    <div *ngIf="dateFormatError" class="error">
                      Date of birth should be in <b>mm/dd/yyyy (Ex: 01/01/1990)</b> format
                    </div>
                  </div>
                  <!-- 
                  <div class="field-item">
                    <div class="field-wrap">
                      <input type="password" formControlName="userPassword" class="input-bordered"
                        [(ngModel)]="userForm.userPassword"
                        [ngClass]="{ 'is-invalid': submitted && f.userPassword.errors }" placeholder="Enter Password"
                        id="pwd" onPaste="return false" placeholder="Enter Password" />
                      <i class="fa" [ngClass]="passwordVisible ? 'fa-eye-slash' : 'fa-eye'"
                        (click)="togglePassword('pwd')"></i>
                      <div *ngIf="submitted && f.userPassword.errors" class="invalid-feedback">
                        <div *ngIf="f.userPassword.errors.required">Password is required
                        </div>
                        <div *ngIf="f.userPassword.errors.minlength">Password must be at
                          least 6
                          characters</div>
                      </div>
                    </div>
                  </div>
                  <div class="field-item">
                    <div class="field-wrap">
                      <input type="password" formControlName="confirmPassword" class="input-bordered"
                        [(ngModel)]="userForm.confirmPassword"
                        [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" id="confirmPassword"
                        onPaste="return false" placeholder="Enter Confirm Password" />
                      <i class="fa" [ngClass]="confirmPasswordVisible ? 'fa-eye-slash' : 'fa-eye'"
                        (click)="togglePassword('confirmPassword')"></i>
                      <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                        <div *ngIf="f.confirmPassword.errors.required">Confirm Password is
                          required
                        </div>
                        <div *ngIf="f.confirmPassword.errors.mustMatch">Passwords must match
                        </div>
                      </div>
                    </div>
                  </div> -->

                  <div *ngIf="errorMessage && errorMessage != ''" class="alert alert-danger title title-xs mt-2 mb-2">
                    {{errorMessage}}
                  </div>
                  <button type="reset" (click)="onReset()" class="btn btn-danger btn-md "
                    style="z-index: 1;">Reset</button>
                  <button type="submit" class="btn btn-primary btn-md float-right" style="z-index: 1;">Sign
                    Up</button>
                </form>
                <div class="ath-note text-center">
                  Already have an account? <a [routerLink]="['/login']">
                    Login</a>
                </div>
              </div>
            </div>
          </div>
          <app-footer></app-footer>
        </div>
      </div>
    </div>
  </div>
</ion-content>