<app-header-left-menu [user]="user"></app-header-left-menu>
<ion-content id="main-content" class="full-width-page">
  <div class="bg-theme-alt">
    <div class="bg-grad-special">
      <div class="bg-grad-special-alt">
        <div class="nk-ovm shape-u shape-contain shape-left-top"></div>
        <app-header [user]="user"></app-header>
        <div class="subContent">
          <div class="main-content container">
            <h5 class="ath-heading title">PLATFORM DYNAMICS<small class="tc-default">Dynamics of the HIDDIN
                Marketplace</small></h5>
            <h3 class="title title-md">
              Dynamics of the HIDDIN Marketplace
            </h3>
            <p>Buyer Enrolls</p>
            <p><b>Market Multiplier Effect Buyer</b> Each new buyer that joins the <b>HIDDIN</b> Marketplace platform
              is automatically a pre-qualified potential customer for every enrolled seller creating a multiplier
              effect based on the number of potential buyer seller relationships. (1 buyer times the # of sellers =
              total new customer equivalent potential) The value of the marketplace grows by this multiplier. Every
              seller potentially benefits from each new buyer that joins. The act of the buyer joining creates value
              and the marketplace structure creates the multiplier effect impacting that valuation. In pipe line
              businesses a new customer of one entity has no real effect on any other entity.
            </p>
            <p>Enrollment Logistics Buyer The traditional pipe line business model in situations other than cash and
              carry venues require each buyer to individually complete some sort of enrollment process with each
              seller before being able to conduct business with any one of them. Each individual enrollment would
              normally include aspects of identity and contact information creating numerous potential cyber security
              breach points. When a buyer enrolls in the <b>HIDDIN</b> Marketplace they have effectively completed
              everything required to immediately conduct secure and private communications and transactions with any
              seller now enrolled and any seller that enrolls in the future. For example when you seek medical
              assistance of any kind you are asked to fill out a medical intake form every time you go that gathers
              the same information each time. Medical establishments enrolled in the <b>HIDDIN</b> Marketplace have
              you provide this information one time and then it is pushed to every other enrolled medical
              establishment you ever go to in the future.</p>
            <p>Marketing Content Buyers enrolling in the <b>HIDDIN</b> marketplace agree to contribute their marketing
              data to our de-identified profile data base and they permit a de-identified record of their transactions
              to be recorded in our transaction register data base. The <b>HIDDIN</b> Marketplace is designed to use
              de-identified profile information as a substitute for the Identity and contact information that pipe
              line businesses typically use in their marketing efforts. Profile information is far more descriptive
              and better suited for analytical purposes than identity and contact information that tend to create
              cyber security exposures in pipe line marketing efforts.</p>
            <p>When buyers add content to this marketing resource they enhance it’s value to every enrolled seller /
              researcher that would ever want to access it for marketing and research purposes. When buyers conduct
              transactions through the platform the de-identified transaction history collected adds even more content
              and value for enrolled sellers and researchers. When sellers seek this content to conduct marketing
              research and develop advertising campaigns they establish queries that they can use over and over again
              in the future as they access this data. These same queries once established can be modified for future
              uses and act as a tool library for the marketplace to use to develop similar queries for any number of
              different industries using the market research capabilities of our platform. The tool library also
              becomes content that improves in value the more it is used and refined. (See Query Data below)</p>
            <h3 class="title title-md">
              Competition from enrolled sellers drives un-enrolled sellers to join.
            </h3>
            <p>Market Multiplier Effect Seller Each new Seller that joins the <b>HIDDIN</b> Marketplace platform is
              automatically a pre-qualified potential supplier for every enrolled buyer creating a multiplier effect
              based on the number of potential seller buyer relationships. (1 seller times the # of buyers less
              existing customer total = the total new seller equivalent potential) The value of the marketplace grows
              by this multiplier and the number of new customers this seller brings to the market. Every buyer
              potentially benefits from each new seller that joins by adding variety and competition. In pipe line
              businesses a new seller in a different industry has no real network effect on any other entity.</p>
            <p><b>Enrollment Logistics Seller</b> When a seller enrolls they have completed everything they need to do
              to conduct communications and transactions securely and privately with any enrolled buyer and any future
              buyers that enroll. Sellers deploying the cost to market savings derived from the marketing research and
              advertising delivery advantages of the <b>HIDDIN</b> Marketplace as well as the direct payment
              processing capabilities are expected to share these savings with the buyers in the form of better
              pricing and terms, compensate the <b>HIDDIN</b> Marketplace for providing these services, contribute to
              the charitable endowments of their buyers and add to their own profitability. The cost to market savings
              at any point in time will vary from entity to entity based on the industry and the rate that these
              sellers move away from their traditional marketing and advertising spending to the advantages of the
              <b>HIDDIN</b> Marketplace. Here again the more new entrants in terms of both buyers and sellers adds to
              the value of the marketplace for everyone using it. More options and choices mean greater cost savings
              that can be shared. (See the Cost to Market Savings)
            </p>
            <p>When pricing and terms change for the better for our enrolled buyers the competition will take notice
              and these un-enrolled sellers will need to take action. The magnitude of cost to market savings that
              each industry should experience constitutes such a compelling competitive disadvantage to the
              un-enrolled sellers that their only choice to compete would be to join the <b>HIDDIN</b> Marketplace
              before they loose anymore market share. (See Quantifying the Market Effects Sellers below)</p>
            <h3 class="title title-md">
              Un-enrolled sellers join and bring all their un-enrolled customers.
            </h3>
            <p>Each un-enrolled seller that enrolls in the <b>HIDDIN</b> Marketplace immediately enrolls their
              existing un-enrolled customers. The best way for a seller to hold on to their share of the market in the
              face of the competitive forces driving prices down by the <b>HIDDIN</b> Marketplace is to enroll
              themselves and sign up all their current customers before their customers enroll with the competition.
              Everyone wins as the seller retains market share, attains a more profitable position and their customers
              enjoy the price breaks and better terms as the result of sharing the cost to market savings the new
              marketplace makes possible.</p>
            <p>Each un-enrolled seller that enrolls in the <b>HIDDIN</b> Marketplace immediately enrolls their
              existing un-enrolled customers. The best way for a seller to hold on to their share of the market in the
              face of the competitive forces driving prices down by the <b>HIDDIN</b> Marketplace is to enroll
              themselves and sign up all their current customers before their customers enroll with the competition.
              Everyone wins as the seller retains market share, attains a more profitable position and their customers
              enjoy the price breaks and better terms as the result of sharing the cost to market savings the new
              marketplace makes possible.</p>
            <h3 class="title title-md">
              Un-enrolled buyers become another source of enrollment feeding the relationship in # I above.
            </h3>
            <p>The un-enrolled customers of recently enrolled sellers are being encouraged to enroll by multiple
              sellers from different industries to take advantage of the security and privacy that the <b>HIDDIN</b>
              communication and transaction platform provides. These sellers are motivated by a desire to maintain
              their market share in the face of the competition from the <b>HIDDIN</b> Marketplace. The enrollment
              campaign has every enrolled seller from all industries approaching their customer base to present them
              with what I have entitled as "The Reasoning" and “The Argument” as previously explained.</p>
            <h3 class="title title-md">
              Monetizing the HIDDIN Marketplace Platform
            </h3>
            <p>Buyers and sellers monetize the platform in several different ways:</p>
            <p><b>A. </b>&nbsp;&nbsp; Buyers and sellers pay <b>HIDDIN</b> a monthly fee for enhanced communication
              services and enhanced marketplace access or choose the free basic secure and private service levels for
              communications and marketplace access.</p>
            <p><b>B.</b> &nbsp;&nbsp; Sellers pay <b>HIDDIN</b> Ad techs to establish their marketing research
              queries, process them and setup the delivery of the resulting advertising campaigns and reports. (See
              Marketing Research and Ad Setup and Delivery below)</p>
            <p><b>C.</b> &nbsp;&nbsp; Each transaction passing through the platform generates a commission in the form
              of a percentage of the sales price based on the total cost to market savings for this seller's
              particular industry. (See Cost to Market Savings &amp; Potential Cost Savings Analysis below)</p>
            <p><b>D.</b> &nbsp;&nbsp; Charitable Endowment funds collected by the marketplace are assessed a 1.5%
              processing fee becoming a revenue stream for the charitable division of the <b>HIDDIN</b> marketplace.
              (See Charitable Endowment below) </p>
            <p><b>E.</b> &nbsp;&nbsp; The intelligence community pays <b>HIDDIN</b> research techs to establish
              triggers,research queries, process them, and setup the delivery of the resulting actionable intelligence
              from the government early warning systems they maintain.</p>
            <p><b>F.</b> &nbsp;&nbsp; The research community pays <b>HIDDIN</b> research techs to access
              <b>HIDDIN</b>’s cross industry research capabilities based on the use of the platform's profile data as
              the common search criterion of the transaction and communication histories in these multi industry
              investigations. (See Cross Industry Research Capabilities below)</p>
            <p><b>G.</b> &nbsp;&nbsp; Buyers and sellers upon enrollment establish net-checking accounts with
              financial institutions enrolled with our platform and agree to the terms of this payment processing
              system that include our fee structure. The seller’s bank gets .035%, the buyer’s bank gets .035% and
              <b>HIDDIN</b> gets .03% with a maximum charge of $100.00 and a minimum charge of 10 cents. Traditional
              payment processing systems that use credit cards, debit cards or checks run the risk of re-identifying
              the transactions this marketplace has labored to de-identify and make secure and private and some add
              costs of 2.5% to 3.5% that this process replaces. (See Potential Cost Savings Analysis Payment
              Processing below)</p>
            <p><b>H .</b>&nbsp;&nbsp; The <b>HIDDIN</b> Communication Console or HCC is the focal point of all
              communications for each enrollee and the starting point for initiating most transactions. Banner ads and
              H-mail ad listing opportunities create another form of advertising revenue for the marketplace.</p>
            <p><b>I.</b> &nbsp;&nbsp; The provisioning of digital mail as an ad on to the HCC in direct competition
              with snail mail postage fees, automatic bill payment systems, and our own version of direct mail
              advertising with access to our platforms marketing research capabilities are other revenue potentials.
            </p>
            <h3 class="title title-md">
              Marketing Research and Ad Setup and Delivery
            </h3>
            <p>The secure communication capabilities of the <b>HIDDIN</b> Marketplace platform make running marketing
              research queries on the profile and transaction history data extremely cost effective and secure for
              enrolled buyers and sellers. Charging sellers for the marketing research queries they conduct creates a
              revenue stream for the <b>HIDDIN</b> Marketplace based on the value of the content that buyers
              contribute to their profile data and transaction histories.</p>
            <p>When the buyer decides to conduct business the resulting transaction is recorded by the <b>HIDDIN</b>
              Marketplace acting as the bookkeeper for everyone involved. The <b>HIDDIN</b> Marketplace charges a
              commission as a percentage of the gross sales price of each transaction based on the marketing expense
              savings afforded each industry. Marketing expenses as a percent of gross sales for different industries
              typically range from a low of 4% to highs nearing 40% with 11% considered an average marketing cost.
              Industries with marketing expenses closer to 4% would be charged a commission closer to the 2% and those
              at the 40% would be around the 20% commission rate. We expect the businesses in these industries will
              over time shift their marketing expenditures away from their traditional marketing sources to our
              services as they come to experience us as a more cost effective alternative.</p>
            <h3 class="title title-md">
              Query Data
            </h3>
            <p>When sellers/researchers seek this content to conduct marketing research and develop advertising
              campaigns they establish queries that they can use over and over again in the future as they access this
              data. These same queries once established can be modified for future uses and act as a tool library for
              the marketplace to use to develop similar queries for any number of different industries using the
              market research capabilities of our platform. The tool library becomes content that improves in value
              the more it is used and refined</p>
            <h3 class="title title-md">
              Cost to Market Savings
            </h3>
            <p>Marketing Expenses Businesses invest in marketing research and advertising campaigns that target their
              clients. Then these clients repay them for those investments when they buy their goods and services.
              Companies that eventually recoup these investments stay in business and those that do not expire. Every
              business must cover their costs to market to survive which means they need to get their customers to pay
              these costs. Most marketing cost are incurred well in advance of the sales needed to pay for them
              creating a financing problem complication. (See Timing Considerations below) </p>
            <p>The <b>HIDDIN</b> Marketplace takes a different approach to solving the cost to market problems every
              business faces. Utilizing our technologies ability to de-identify the digital transmission process we
              can collect and share our buyer’s marketing information securely and privately with our enrolled
              sellers. Our buyer’s actively cooperate in the marketing research and advertising delivery processes the
              <b>HIDDIN</b> Marketplace makes possible eliminating many of the traditional marketing middle men and
              their expenses. The cost to market savings from these efforts translates into better terms and pricing
              for our buyers, a source of revenue for the marketplace, contributions to our charitable endowment
              initiatives, and a healthier bottom line for our sellers. The impact on any particular seller enrolled
              in the marketplace will depend on the specifics of their industry. (See potential cost savings analysis
              below) </p>
            <h3 class="title title-md">
              Potential Cost Saving Analysis
            </h3>
            <div class="content-detail">
              <div class="row">
                <div class="col-md-4">
                  <p><b>Payment Processing</b></p>
                </div>
                <div class="col-md-8">
                  <p>Debit and credit card processing costs for the sellers that make use of them are around 2.5% to
                    3.5% of their sales plus a flat fee of 30- 50 cents per transaction. Then merchant accounts are
                    hit with a range of monthly statement fees and fees for accepting reward cards and other odd
                    charges that the <b>HIDDIN</b> Marketplace removes.</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <p><b>Automatic Bill Pay</b></p>
                </div>
                <div class="col-md-8">
                  <p>Payment processing systems that present bills electronically and allow customers to generate
                    automatic drafts from established accounts to pay what they owe generally cost these sellers a
                    flat monthly fee around 50$ for the service and an ACH transfer fee of fifty five cents for each
                    customer that the <b>HIDDIN</b> Marketplace removes.</p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <p><b>Marketing Costs</b></p>
                </div>
                <div class="col-md-8">
                  <p>The cost of conducting marketing research and then the advertising campaigns as a percentage of
                    sales typically range from 4% to over 40%. The experience of any particular entity will depend
                    upon the industry norms they currently deal with and that company's management strategy with our
                    technology in terms of their rate of adoption. The entities that are first to adopt should see
                    dramatic increases in their market share as a result of the dynamics discussed above.</p>
                </div>
              </div>
            </div>
            <h3 class="title title-md">
              Timing Considerations
            </h3>
            <p>The traditional marketplace would require that most of the cost for research and advertising be paid up
              front or in scheduled payments committed to over time regardless of the results obtained. The
              <b>HIDDIN</b> Marketplace research costs are minimal and the commissions charged are based on actual
              sales and collected as funds move between the parties. The <b>HIDDIN</b> Marketplace diminishes the risk
              to the new comer and creates a more manageable cash flow both in terms of the timing and the overall
              cost.</p>
            <h3 class="title title-md">
              Cross Industry Research Capabilities
            </h3>
            <p>De-identifying and removing payment details from data makes it useless to cyber criminals. Absent
              Identity or some way to describe who is involved in a particular transmission makes the remaining data
              unworkable from a research or marketing perspective. The <b>HIDDIN</b> enrollment process gathers
              detailed profile information about each enrollee with the understanding that this information will be
              used in place of their identity. The descriptive profile information void of identity makes the
              transaction register data extremely useful for marketing and research purposes without revealing
              anyone’s identity information in these processes. The profile information gathered describes each
              participant with the kinds of details that marketing and research organization are always looking for
              and have never been able to attain consistently. Utilizing this profile information as the common
              denominator for the analysis of the transmission data coming from all the industries that will be able
              to participate in and make use of the <b>HIDDIN</b> marketplace services creates unique cross marketing
              research capabilities. What can be discovered from using this profile information to link the
              transmission data from multiple industries in the formulation of cross industry research queries? What
              will ten years of medical data and ten years of fast food data reveal when this profile information is
              used as the common search criteria across both these industries? </p>
            <p>The <b>HIDDIN</b> Marketplace charges a flat fee of $ 50.00 – 100.00 per research query and then an
              hourly rate for the personnel’s time to setup and conduct any specific query in the range of $150 – 200
              an hour. Running stock queries would not take much personnel time to conduct and drafting new ones would
              not require much more effort and expense. Research of this quality that is practically available on
              demand is unheard of anywhere else and at these prices makes it extremely affordable for the small
              business community. </p>
            <h3 class="title title-md">
              Quantifying the Market Effects Sellers
            </h3>
            <p>Example I 100% common customer base (This assumption is that 100 of the people in entity 2's base are
              the same people in entity 1's base and everyone in entity 2's base make up 200 of the people in entity
              3's base. The net effect is that of the 600 total only 300 unique individuals are involved. The other
              300 are the same people counted more than one time. This is highly unlikely but it lets us isolate a
              single network effect of the platform marketplace over the traditional pipe line alternative. </p>
            <h3 class="title title-md">
              Pure Market Effect
            </h3>
            <p>Industry &nbsp;&nbsp;&nbsp;A &nbsp;&nbsp;B &nbsp;&nbsp;C
              <br> Entity &nbsp;&nbsp;&nbsp;1 &nbsp;&nbsp;2 &nbsp;&nbsp;3
              <br> Base &nbsp;&nbsp;&nbsp;100 &nbsp;&nbsp;200 &nbsp;&nbsp;300&nbsp;&nbsp;Total 600
              <br> Potential # &nbsp;&nbsp;&nbsp;200 &nbsp;&nbsp;&nbsp;100 &nbsp;&nbsp;&nbsp;0
              <br> % Increase &nbsp;&nbsp;&nbsp; 200% &nbsp;&nbsp;50% &nbsp;&nbsp; 0
            </p>
            <p>The smallest entity by simply joining the marketplace acquires an immediate potential market increase
              of 200% and if we added company 4 in industry D with another 100 people in their customer base under the
              same 100% common customer base assumption then entity 4 would also enjoy a potential 200% increase
              without impacting any other entity's potential. The traditional marketplace one to one relationship
              isolates each entity to just the customers in their base and there is no network effect. The number of
              customers in any other entity does not impact the potential of any other entity even when you add
              another entity 4 from industry D.</p>
            <h3 class="title title-md">
              Overall Market Effect
            </h3>
            <p>Example II 25% common customer base (This is a more realistic assumption that has every entity sharing
              the same 25% of their respective customer bases.)</p>
            <p>Industry &nbsp;&nbsp;&nbsp;A &nbsp;&nbsp;B &nbsp;&nbsp;C
              <br> Entity &nbsp;&nbsp;&nbsp;1 &nbsp;&nbsp;2 &nbsp;&nbsp;3
              <br> Customer Base &nbsp;&nbsp;&nbsp;100 &nbsp;&nbsp;200 &nbsp;&nbsp;300&nbsp;&nbsp;Total 600
              <br> Potential # &nbsp;&nbsp;&nbsp;450 &nbsp;&nbsp;450 &nbsp;&nbsp;450 &nbsp;&nbsp;X.75
              <br> % Increase individuals &nbsp;&nbsp;&nbsp; 350% &nbsp;&nbsp;125% &nbsp;&nbsp; 50% &nbsp;&nbsp; 450
              unique
              <br> Net Effect &nbsp;&nbsp;&nbsp;150% &nbsp;&nbsp; 75% &nbsp;&nbsp; 50%
            </p>
            <p>The likely overall effect will help everyone yet the smallest entity is afforded the greatest potential
              which is never the case in the traditional one on one marketplace where no network effects are expected.
              The addition of company 4 of industry D adds another 75 unique individuals and they benefit everyone.
              The addition of a single buyer will likely benefit 75% of the current sellers with this assumption and
              the addition of another seller likely brings another 75% of that entity's customer base to benefit the
              rest of the sellers in the marketplace.</p>
            <p>Eventually the pace of sellers and buyers joining the marketplace will slow down when we approach the
              point where most buyers and sellers have already joined the marketplace. Then even at full enrollment of
              buyers and sellers the addition of a new industry or just a new business entity into the marketplace
              immediately avails that entity to all the potential customers that should want to do business with them.
              These new entrants will be able to access the <b>HIDDIN</b> Marketplace's research and advertising
              delivery capabilities and offer their potential customers’ terms and pricing superior to what would be
              available in the traditional pipe line one on one marketplace.</p>
            <p>Enrolled buyers will want to do business with enrolled sellers to reduce their cyber breach exposures
              and take advantage of the better terms and pricing from the cost to market savings the <b>HIDDIN</b>
              Marketplace offers. The more sellers that join the more opportunity for choice and savings for each
              buyer creating another feed back loop to spur activity. Sellers from every industry will be approaching
              their buyers with the "The Reasoning" and "The Argument"..</p>
            <h3 class="title title-md">
              "The Reasoning"
            </h3>
            <p>Think back to any purchase YOU have ever made, outside of a going out of business sale. The pricing YOU
              paid had to cover your seller’s costs to market that item. YOU pay your sellers marketing costs and as a
              percentage of the price YOU pay that could be anywhere from a low of 4% to more than 40% depending upon
              the particular industry. Our digital platform transforms buyers like YOU into the producers of raw
              marketing data that our sellers consume and then refine that raw data into the marketing intelligence
              they produce for YOU and our other buyers to then consume in the marketing campaigns our platform
              delivers resulting in the sales through our marketplace that monetizes our platform. The transaction
              data generated from these sales adds to the raw marketing data that our sellers consume. Then the
              decision reasoning and research surveys that YOU and our other buyers participate in, whether they buy
              or not, generates more raw marketing data for our sellers to refine for their future marketing
              campaigns. All of these interactions feed upon one another producing multiple feed back loops creating
              network effects which are essential for a platform business. What we have developed is a way to share
              these network effects with all the enterprises that join our marketplace so they can deploy their own
              digital platform marketing strategies to drive down costs further creating more ways to share with YOU
              the savings that result. YOU take an active role in the marketing process making it more efficient
              lowering what your seller spends and you end up paying. It pays to join.</p>
            <h3 class="title title-md">
              "The Argument"
            </h3>
            <p>The <b>HIDDIN</b> enrollment campaign has every enrolled seller from all industries approaching their
              customer base to present them with what I have entitled as “The Argument”. “The Argument” consists of
              the following questions:
            </p>
            <p><b>1.</b> &nbsp; Who do you want to do business with? The company that cares about the security of it's
              data and the security of their customer's information or the company that is a liability to itself and
              it's customers?
            </p>
            <p><b>2.</b> &nbsp; Who do you want to do business with? The company that seeks your cooperation in their
              marketing efforts to reduce their costs and then uses some of those cost to market savings to give you
              better terms and pricing and fund your selection of charitable endowments or the company that makes you
              pay for their ineffective marketing efforts of the goods and services you are going to buy anyway
              creating potential identity exposures for you in the process and does not have any cost to market
              savings to share with either you or the charitable endowments you want to support?
            </p>
            <p><b>3.</b> &nbsp; How many unsecured companies do you have to do business with before you can have a
              problem? It only takes one unprotected transaction to expose you to identity theft. You can do business
              with nine companies that protect you with this technology and it only takes one unprotected transaction
              to cause a problem with a hacker.
            </p>
            <p><b>4.</b> &nbsp; Where do you think all the cyber bad guys and identity thieves are going to go when
              they can no longer assault the customers of the companies that chose to deploy our secure platform to
              protect you? They are not going to retire. They will concentrate on the remaining companies that have
              not taken the precautions that our enrolled businesses have. This makes it more dangerous to do business
              with anyone that has not protected you with our technology
            </p>
            <p><b>5.</b> &nbsp; No one else has a solution to the quantum decryption event that could let the
              extremists damage if not destroy our way of life. If we do not do something before the Quantum threat
              gets real we will not have anything real worth arguing about.
            </p>
            <p>The sellers trying to hang on to their market share will come to believe that getting their customers
              to enroll with them is the best way to keep them as customers. The potential to have better terms and
              pricing from these sellers makes enrolling even more compelling. The security and privacy advantages of
              our digital technology over the current alternatives should be reason enough to compel them both to act.
              The prospects of joining a marketplace that promotes charitable endowments that address the kinds of
              societal problems our political class has been unable to address adds another reason for some to enroll.
            </p>
            <p>When these customers enroll they add to the financial value of the marketplace of the buyers and
              sellers already enrolled with the increase of their economic activity. They add to the volume of
              communication and transaction activity enhancing the security of everyone using the platform by adding
              to the sheer number of events and proving once again that there is safety in numbers and by increasing
              the volume of data the Marketplace becomes more valuable. They further encourage sellers to move away
              from the more expensive less effective forms of marketing and advertising in favor of the more cost
              effective <b>HIDDIN</b> Marketplace alternatives. 
            </p>
            <p>Marketplaces need to attract and retain buyers and sellers. Providing unique research capabilities or
              quantum and extremist proof security that could save the modern world might get some to join, but
              research and security are not sexy, nor compelling. Saving buyers money and making sellers more money
              while growing their market share is sexy and should drive the enrollment of both. Then the network
              effects and feedback loops designed into our digital platform marketplace will encourage their continued
              participation and motivate new buyers and sellers to join which in turn inspires more to join proving
              just how explosive the growth can be with digital platform businesses like Uber, Google and now
              <b>HIDDIN</b>.
            </p>
          </div>
        </div>
        <app-footer></app-footer>
      </div>
    </div>
  </div>
</ion-content>