<ion-content class="content-main">
  <div id="login-popup">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <button class="modal-close" aria-label="Close" (click)="dismiss('')"><em class="ti ti-close"></em></button>
        <div class="ath-container m-0">
          <div class="ath-body">
            <h5 class="ath-heading title">{{heading}}<small class="tc-default">{{subheading}}</small></h5>
            <form [formGroup]="signupForm">
              <div class="field-item">
                <div class="field-wrap">
                  <h6 class="title title-xs">First Name<span class="red">*</span></h6>
                  <input type="text" formControlName="firstName" class="input-bordered"
                    [(ngModel)]="signupInput.firstName" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }"
                    placeholder="Enter First Name" required>
                  <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                    <div *ngIf="f.firstName.errors.required">First Name is required</div>
                    <div *ngIf="f.firstName.errors?.maxlength">First Name can be max 50 characters long</div>
                  </div>
                </div>
              </div>
              <div class="field-item">
                <div class="field-wrap">
                  <h6 class="title title-xs">Last Name<span class="red">*</span></h6>
                  <input type="text" formControlName="lastName" class="input-bordered"
                    [(ngModel)]="signupInput.lastName" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }"
                    placeholder="Last Name" required>
                  <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                    <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                    <div *ngIf="f.lastName.errors?.maxlength">First Name can be max 50 characters long</div>
                  </div>
                </div>
              </div>
              <div class="field-item">
                <div class="field-wrap">
                  <h6 class="title title-xs">Email<span class="red">*</span></h6>
                  <input type="email" formControlName="emailAddress" class="input-bordered"
                    [(ngModel)]="signupInput.email"
                    [ngClass]="{ 'is-invalid': (submitted || f.emailAddress.touched) && f.emailAddress.errors }"
                    placeholder="Enter Email" />
                  <div *ngIf="(submitted || f.emailAddress.touched) && f.emailAddress.errors" class="invalid-feedback">
                    <div *ngIf="f.emailAddress.errors.required">Email is required</div>
                    <div *ngIf="f.emailAddress.errors.email">Email must be a valid email
                      address</div>
                    <div *ngIf="f.emailAddress.errors.pattern">Email must be a valid
                      email address</div>
                  </div>
                </div>
              </div>
              <div class="field-item">
                <div class="field-wrap">
                  <h6 class="title title-xs">Role<span class="red">*</span></h6>
                  <select class="input-bordered" formControlName="role" [(ngModel)]="signupInput.roleLevel"
                    [ngClass]="{ 'is-invalid': submitted && f.role.errors }">
                    <option value="null" selected disabled>Select Role</option>
                    <option *ngFor="let r of roles" [value]="r.value">{{r.name}}</option>
                  </select>
                  <div *ngIf="submitted && f.role.errors" class="invalid-feedback">
                    <div *ngIf="f.role.errors.required">Role is required
                    </div>
                  </div>
                </div>
              </div>
              <div class="field-item">
                <div class="field-wrap">
                  <h6 class="title title-xs">Relation<span class="red">*</span></h6>
                  <select class="input-bordered" formControlName="relation" [(ngModel)]="signupInput.relationshipId"
                    [ngClass]="{ 'is-invalid': submitted && f.relation.errors }">
                    <option value="null" selected disabled>Select Relation</option>
                    <option *ngFor="let rel of relationList" [value]="rel.customField1">{{rel.name}}</option>
                  </select>
                  <div *ngIf="submitted && f.relation.errors" class="invalid-feedback">
                    <div *ngIf="f.relation.errors.required">Relation is required
                    </div>
                  </div>
                </div>
              </div>
              <div class="field-item" *ngIf="type == 1">
                <div class="field-wrap">
                  <h6 class="title title-xs">Phone Number<span class="red">*</span></h6>
                  <ngx-intl-tel-input type="text" formControlName="phoneNumber" [cssClass]="'phoneNumber'"
                    [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="false"
                    [enablePlaceholder]="true" [searchCountryFlag]="true"
                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                    [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.UnitedStates" [maxLength]="15"
                    [phoneValidation]="true" [separateDialCode]="separateDialCode"
                    [numberFormat]="PhoneNumberFormat.International" [(ngModel)]="signupInput.phoneNumberAll"
                    autocomplete="disabled" (ngModelChange)="onCountryChange($event)"
                    [ngClass]="{ 'is-invalid': submitted && f.phoneNumber.errors }" required />
                  <div *ngIf="(submitted || f.phoneNumber.touched) && f.phoneNumber.errors" class="invalid-feedback">
                    <div *ngIf="f.phoneNumber.errors.required">Phone number is required
                    </div>
                  </div>
                  <div *ngIf="(submitted || f.phoneNumber.touched) && f.phoneNumber.errors" class="invalid-feedback">
                    <div *ngIf="!f.phoneNumber.errors.validatePhoneNumber?.valid">Invalid phone number</div>
                  </div>
                </div>
              </div>
              <div class="field-item" *ngIf="type == 2">
                <div class="field-wrap">
                  <h6 class="title title-xs">Phone Number<span class="red">*</span></h6>
                  <input type="text" id="phone" class="input-bordered" formControlName="phoneNumber"
                    [value]="mobileNoViewInEdit" [ngClass]="{ 'is-invalid': submitted && f.phoneNumber.errors }"
                    required />
                </div>
              </div>
              <div class="field-item">
                <div class="field-wrap">
                  <h6 class="title title-xs">Date of Birth<span class="red">*</span></h6>
                  <input type="date" class="input-bordered" formControlName="birthDate"
                    [(ngModel)]="signupInput.birthDate" placeholder="Enter Date Of Birth"
                    (ngModelChange)="onDateChange($event)" [ngClass]="{ 'is-invalid': submitted && f.birthDate.errors }"
                    placeholder="Date of Birth (dd/mm/yyyy)" />
                </div>
                <div *ngIf="submitted && f.birthDate.errors" class="invalid-feedback">
                  <div *ngIf="f.birthDate.errors.required">Date of birth is required</div>
                </div>
                <div *ngIf="dateValidation" class="invalid-feedback">
                  <div>Date of birth should be before current date</div>
                </div>
              </div>
              <div class="field-item">
                <div class="field-wrap">
                  <h6 class="title title-xs">Password<span class="red">*</span></h6>
                  <input type="password" formControlName="userPassword" class="input-bordered"
                    [(ngModel)]="signupInput.userPassword"
                    [ngClass]="{ 'is-invalid': submitted && f.userPassword.errors }" placeholder="Enter Password"
                    id="pwd" onPaste="return false" placeholder="Enter Password" />
                  <i class="fa" [ngClass]="passwordVisible ? 'fa-eye-slash' : 'fa-eye'"
                    (click)="togglePassword('pwd')"></i>
                  <div *ngIf="submitted && f.userPassword.errors" class="invalid-feedback">
                    <div *ngIf="f.userPassword.errors.required">Password is required
                    </div>
                    <div *ngIf="f.userPassword.errors.minlength">Password must be at
                      least 6
                      characters</div>
                  </div>
                </div>
              </div>
            </form>
            <div class="invalid-feedback mb-4" *ngIf="errorMessage && errorMessage.length > 0">
              {{errorMessage}}
            </div>
            <div class="btn-container mb-5">
              <div class="btn btn-danger btn-md pull-left" (click)="onReset()">Reset</div>
              <div class="btn btn-primary btn-md pull-right" (click)="saveMember()">{{btnText}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>