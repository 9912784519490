<app-header-left-menu [user]="user"></app-header-left-menu>
<ion-content id="main-content" class="full-width-page">
  <div class="bg-theme-alt">
    <div class="bg-grad-special">
      <div class="bg-grad-special-alt">
        <div class="nk-ovm shape-u shape-contain shape-left-top"></div>
        <app-header [user]="user"></app-header>
        <div class="subContent">
          <div class="main-content container">
            <div class="row">
              <div class="col-md-5 ml-3">
                <p>Shyam Ji</p>
                <div>
                  <div *ngFor="let d of user1Data" class="row">
                    <div class="container" *ngIf="d.senderId == user1Id">
                      <img src="assets/images/new/shyamji.jpg" alt="Avatar" style="width:100%;"
                        *ngIf="d.senderId == user1Id">
                      <p>{{d.msgData}}</p>
                      <span class="time-right">{{d.msgTimestamp}}</span>
                    </div>
                    <div class="container darker" *ngIf="d.senderId == user2Id">
                      <img src="assets/images/new/nature.jpg" alt="Avatar" class="right" style="width:100%;"
                        *ngIf="d.senderId == user2Id">
                      <p>{{d.msgData}}</p>
                      <span class="time-right">{{d.msgTimestamp}}</span>
                    </div>
                  </div>
                  <p class="red" *ngIf="user1Data == undefined || user1Data == null || user1Data.length == 0">Please
                    send the data first</p>
                </div>
                <hr />
                <div class="row">
                  <input type="text" name="user1" aria-required="true" [(ngModel)]="user1Text"
                    placeholder="Enter your text" class="input-bordered" />
                  <button class="btn btn-sm btn-success mt-2 mr-2" (click)="getAllUSerData()">Refresh</button>
                  <button class="btn btn-sm btn-primary mt-2" (click)="sendUser1Text()">Send</button>
                </div>
              </div>
              <div class="col-md-5 ml-4">
                <p>Ram Ji</p>
                <div>
                  <div *ngFor="let d of user2Data" class="row">
                    <div class="container" *ngIf="d.senderId == user2Id">
                      <img src="assets/images/new/shyamji.jpg" alt="Avatar" style="width:100%;"
                        *ngIf="d.senderId == user2Id">
                      <p>{{d.msgData}}</p>
                      <span class="time-right">{{d.msgTimestamp}}</span>
                    </div>
                    <div class="container darker" *ngIf="d.senderId == user1Id">
                      <img src="assets/images/new/nature.jpg" alt="Avatar" class="right" style="width:100%;"
                        *ngIf="d.senderId == user1Id">
                      <p>{{d.msgData}}</p>
                      <span class="time-right">{{d.msgTimestamp}}</span>
                    </div>
                  </div>
                  <p class="red" *ngIf="user2Data == undefined || user2Data == null || user2Data.length == 0">Please
                    send the data first</p>
                </div>
                <hr />
                <div class="row">
                  <input type="text" name="user2" aria-required="true" [(ngModel)]="user2Text"
                    placeholder="Enter your text" class="input-bordered" />
                  <button class="btn btn-sm btn-success mt-2 mr-2" (click)="getAllUSerData()">Refresh</button>
                  <button class="btn btn-sm btn-primary mt-2" (click)="sendUser2Text()">Send</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
      </div>
    </div>
  </div>
</ion-content>