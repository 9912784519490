<app-header-left-menu [user]="user"></app-header-left-menu>
<ion-content id="main-content" class="full-width-page">
  <div class="bg-theme-alt">
    <div class="bg-grad-special">
      <div class="bg-grad-special-alt">
        <div class="nk-ovm shape-u shape-contain shape-left-top"></div>
        <app-header [user]="user"></app-header>
        <div class="subContent">
          <div class="main-content container">
            <div class="row">
              <div class="col-6">
                <div class="text-center mt-3 mb-3">
                  <h5>Encrypt DB Data</h5>
                </div>
                <form [formGroup]="encForm" (ngSubmit)="encrypt()">
                  <div class="field-item">
                    <div class="field-wrap">
                      <h6 class="title title-xs">Plain Text<span class="red">*</span></h6>
                      <textarea rows="5" cols="100" formControlName="data" class="input-bordered"
                        [(ngModel)]="encData.data" placeholder="Enter Plain Text to Encrypt"
                        [ngClass]="{ 'is-invalid': encSubmitted && f_enc.data.errors }"></textarea>
                      <div *ngIf="encSubmitted && f_enc.data.errors" class="invalid-feedback">
                        <div *ngIf="f_enc.data.errors.required">Plain Text is required</div>
                        <div *ngIf="f_enc.data.errors?.minlength">Plain Text should be at least 2 characters in
                          length.</div>
                      </div>
                    </div>
                  </div>
                  <div class="field-item" *ngIf="encData.encyptedData && encData.encyptedData.length > 0">
                    <div class="field-wrap">
                      <h6 class="title title-xs">Encypted Data</h6>
                      <div class="invalid-feedback">
                        <div class="text-view">{{encData.encyptedData}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="btn-container">
                    <button class="btn btn-primary btn-block" [disabled]="encIsLoading">
                      <span class="spinner-border spinner-border-sm" *ngIf="encIsLoading"></span>&nbsp;
                      {{encButtonText}}
                    </button>
                  </div>
                  <!-- <div *ngIf="encSuccessMessage && encSuccessMessage != ''"
                    class="alert alert-success title title-xs mt-2 mb-2">
                    {{encSuccessMessage}}
                  </div> -->
                  <div *ngIf="encErrorMessage && encErrorMessage != ''"
                    class="alert alert-danger title title-xs mt-2 mb-2">
                    {{encErrorMessage}}
                  </div>
                </form>
              </div>
              <div class="col-6">
                <div class="text-center mt-3 mb-3">
                  <h5>Decrypt DB Data</h5>
                </div>
                <form [formGroup]="decForm" (ngSubmit)="decrypt()">
                  <div class="field-item">
                    <div class="field-wrap">
                      <h6 class="title title-xs">Ecnrypted Data<span class="red">*</span></h6>
                      <textarea rows="5" cols="100" formControlName="data" class="input-bordered"
                        [(ngModel)]="decData.data" placeholder="Enter Encrypted Data to Decrypt"
                        [ngClass]="{ 'is-invalid': decSubmitted && f_dec.data.errors }"></textarea>
                      <div *ngIf="decSubmitted && f_dec.data.errors" class="invalid-feedback">
                        <div *ngIf="f_dec.data.errors.required">Ecnrypted Data is required</div>
                        <div *ngIf="f_dec.data.errors?.minlength">Ecnrypted Data should be at least 2 characters in
                          length.</div>
                      </div>
                    </div>
                  </div>
                  <div class="field-item" *ngIf="decData.decryptedData && decData.decryptedData.length > 0">
                    <div class="field-wrap">
                      <h6 class="title title-xs">Decrypted Data</h6>
                      <div class="text-success">
                        <div class="text-view">{{decData.decryptedData}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="btn-container">
                    <button class="btn btn-primary btn-block" [disabled]="decIsLoading">
                      <span class="spinner-border spinner-border-sm" *ngIf="decIsLoading"></span>&nbsp;
                      {{decButtonText}}
                    </button>
                  </div>
                  <!-- <div *ngIf="decSuccessMessage && decSuccessMessage != ''"
                    class="alert alert-success title title-xs mt-2 mb-2">
                    {{decSuccessMessage}}
                  </div> -->
                  <div *ngIf="decErrorMessage && decErrorMessage != ''"
                    class="alert alert-danger title title-xs mt-2 mb-2">
                    {{decErrorMessage}}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
      </div>
    </div>
  </div>
</ion-content>