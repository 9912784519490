import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Globals } from 'src/app/globals';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MustMatch } from 'src/app/util/must-match.validator';
import { RegUser } from '../basic-reg/RegUser';
import { timer } from 'rxjs';
import { takeWhile, tap } from 'rxjs/operators';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { CommonUtil } from 'src/app/util/CommonUtil';

@Component({
  selector: 'app-forgot-pass',
  templateUrl: './forgot-pass.component.html',
  styleUrls: ['./forgot-pass.component.scss'],
})
export class ForgotPassComponent implements OnInit {

  separateDialCode = false;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.India];

  requestPath: any;

  heading = "Reset Password";
  subheading = "Recover Your Account";

  stepFirst: boolean = true;
  stepSecond: boolean = false;
  stepThird: boolean = false;
  stepFour: boolean = false;

  mobileNo: any;
  phoneNumberAll: any;
  fpForm: FormGroup;
  fpSubmitted: boolean;
  errorMessage: string;

  otp: any;
  otpForm: FormGroup;
  otpSubmitted: boolean;
  emailOtp: any;
  emailOtpForm: FormGroup;
  emailOtpSubmitted: boolean;
  activeResendLink: boolean = false;
  counter: number = 120;
  time: any;

  cpUser = new RegUser();
  cpForm: FormGroup;
  cpSubmitted: boolean;

  passwordVisible: boolean = false;
  confirmPasswordVisible: boolean = false;

  localToken: any;
  user: RegUser = new RegUser();
  LoggedIn: boolean;
  selectedMobilNo: any;
  email: any;

  formattedPhoneNo:any;
  formattedEmail:any;

  constructor(public global: Globals
    , public authService: AuthServiceService
    , public formBuilder: FormBuilder
    , public router: Router
    , private loader: LoaderService) {
    this.requestPath = this.global.requestPath;
  }

  ngOnInit() {
    this.fpForm = this.formBuilder.group({
      phoneNumber: ['', [Validators.required]]
    });

    this.otpForm = this.formBuilder.group({
      otp: ['', [Validators.required]]
    });

    this.emailOtpForm = this.formBuilder.group({
      emailOtp: ['', [Validators.required]]
    });

    this.cpForm = this.formBuilder.group({
      userPassword: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(6)]]
    }, {
      validator: MustMatch('userPassword', 'confirmPassword')
    });

    this.startCountdown();
  }

  ionViewDidEnter() {
    this.onReset();
    this.user = this.authService.getLoggedInUser();
    if (!this.global.isEmpty(this.user)) {
      this.user.imageUrl = this.authService.getProfilePicture();
    }
  }

  ionViewWillEnter() {
    this.LoggedIn = this.authService.isLoggedIn();
    if (!this.global.isEmpty(this.user)) {
      this.user.imageUrl = this.authService.getProfilePicture();
    }
  }

  onReset = function () {
    this.heading = "Reset password";
    this.formattedPhoneNo = '';
    this.formattedEmail = '';

    this.fpForm.reset();
    this.otpForm.reset();
    this.cpForm.reset();

    this.mobileNo = null;
    this.phoneNumberAll = null;
    this.email = null;
    this.otp = null;
    this.emailOtp = null;
    this.cpUser = new RegUser();

    this.fpSubmitted = false;
    this.otpSubmitted = false;
    this.cpSubmitted = false;
    this.emailOtpSubmitted = false;

    this.stepFirst = true;
    this.stepSecond = false;
    this.stepThird = false;
    this.stepFour = false;

    this.errorMessage = null;
    this.resetPhoneNumber();
  }

  resetPhoneNumber() {
    this.cpUser.phoneDialCode = "1";
    this.cpUser.phoneCountryShortName = "us";
    this.selectedMobilNo = null;
  }

  startCountdown() {

    timer(0, 1000)
      .pipe(
        takeWhile(() => this.counter > 0),
        tap(() => this.counter--)
      )
      .subscribe(() => {
        const minutes = Math.floor(this.counter / 60);
        const seconds = this.counter - minutes * 60;

        this.time = (minutes.toString()).padStart(2, '0') + ':' + (seconds.toString()).padStart(2, '0')

        if (this.counter < 1) {
          this.activeResendLink = true;
        }
      });
  }

  resendMobileOTP() {
    this.loader.show("Sending verification code...");
    this.mobileNo = this.phoneNumberAll?.e164Number.replace(this.phoneNumberAll.dialCode, "");
    this.authService.getNoSecure('ns/common/user?' + this.requestPath + '=sendEmailPhoneOtp&phoneNumber=' + this.mobileNo)
      .subscribe((res: any) => {
        this.loader.hide();
        // console.log(res);
        if (res.success) {
          // console.log("OTP has been resend");

          this.counter = 120;
          this.activeResendLink = false;
          this.startCountdown();
          console.log(res.description);
        } else {
          this.errorMessage = res.message;
          // console.log(this.errorMessage);
        }
      }, error => {
        this.loader.hide();
        this.errorMessage = "Error occured, please try again.";
        // console.log(this.errorMessage);
      });
  }

  resendEmailOTP() {
    this.loader.show("Sending verification code...");
    this.authService.getNoSecure('ns/common/user?' + this.requestPath + '=sendEmailPhoneOtp&email=' + this.email)
      .subscribe((res) => {
        this.loader.hide();
        // console.log(res);
        if (res.success) {
          // console.log("OTP has been resend");

          this.counter = 120;
          this.activeResendLink = false;
          this.startCountdown();
          console.log(res.description);
        } else {
          this.errorMessage = res.message;
          // console.log(this.errorMessage);
        }
      }, error => {
        this.loader.hide();
        this.errorMessage = "Error occured, please try again.";
        // console.log(this.errorMessage);
      });
  }

  public sendMobileOTP() {
    this.fpSubmitted = true;
    this.errorMessage = null;

    // stop here if form is invalid
    if (this.fpForm.invalid) {
      // console.log("Invalid: " + this.fpForm.value);
      return;
    }
    this.mobileNo = this.phoneNumberAll?.e164Number.replace(this.phoneNumberAll.dialCode, "");;
    this.loader.show("Sending verification code...");
    this.authService.getNoSecure('ns/common/user?' + this.requestPath + '=sendEmailPhoneOtp&phoneNumber=' + this.mobileNo)
      .subscribe((res: any) => {
        this.loader.hide();
        // console.log(res);
        if (res.success) {
          this.formattedPhoneNo = CommonUtil.formatPhone(this.mobileNo);
          this.heading = "Enter Mobile verification Code";
          this.subheading = "Check your registered mobile for Verification Code";
          this.stepFirst = false;
          this.stepSecond = true;

          this.counter = 120;
          this.activeResendLink = false;
          console.log(res.description);
        } else {
          this.errorMessage = res.message;
        }
      }, error => {
        this.loader.hide();
        // console.log(error);
        this.errorMessage = "Something went wrong, please try again.";
      });
  }

  validateMobileOTP = function () {

    this.otpSubmitted = true;
    this.errorMessage = null;

    if (this.otpForm.invalid) {
      // console.log("Invalid form");
      return;
    }
    
    this.mobileNo = this.phoneNumberAll?.e164Number.replace(this.phoneNumberAll.dialCode, "");;

    this.loader.show("Verifying...");
    this.authService.getNoSecure('ns/common/user?' + this.requestPath + '=verifyMobileOTP&mobileNumber=' + this.mobileNo + '&otp=' + this.otp.trim() + "&isRecoverAccount=true&sendEmail=false")
      .subscribe((res:any) => {
        this.loader.hide();
        // console.log(res);
        if (res.success) {
          res.data = this.global.decryptDefault(res.data);
          console.log(res.data);
          this.email = res.data.hiddinEmail != undefined ? res.data.hiddinEmail : res.data.email;

          this.sendEmailOTP();

          this.stepFirst = false;
          this.stepSecond = false;
          this.stepThird = true;
        } else {
          this.errorMessage = res.message;
        }
      }, error => {
        this.loader.hide();
        // console.log(error);
        this.errorMessage = "Something went wrong, please try again.";
      });

  }

  public sendEmailOTP() {

    if (this.global.isEmpty(this.email)) {
      this.errorMessage = "Email not found";
      return;
    }

    this.loader.show("Sending verification code...");
    this.authService.getNoSecure('ns/common/user?' + this.requestPath + '=sendEmailPhoneOtp&email=' + this.email)
      .subscribe(res => {
        this.loader.hide();
        // console.log(res);
        if (res.success) {
          this.heading = "Enter Email verification Code";
          this.subheading = "Check your registered email for Verification Code";
          this.formattedEmail = CommonUtil.formatEmail(this.email);

          this.counter = 120;
          this.activeResendLink = false;
          console.log(res.description);
        } else {
          this.errorMessage = res.message;
        }
      }, error => {
        this.loader.hide();
        // console.log(error);
        this.errorMessage = "Something went wrong, please try again.";
      });
  }

  validateEmailOTP = function () {

    this.emailOtpSubmitted = true;
    this.errorMessage = null;

    if (this.otpForm.invalid) {
      // console.log("Invalid form");
      return;
    }

    if (this.global.isEmpty(this.email)) {
      this.errorMessage = "Email not found";
      return;
    }

    this.loader.show("Verifying...");
    this.authService.getNoSecure('ns/common/user?' + this.requestPath + '=verifyEmailOTP&email=' + this.email + '&otp=' + this.emailOtp.trim() + '&tokenRequired=true')
      .subscribe((res) => {
        this.loader.hide();
        // console.log(res);
        if (res.success) {
          res.data = this.global.decryptDefault(res.data);
          this.localToken = JSON.stringify(res.data.access_token);

          this.heading = "Create New Password";
          this.subheading = "set your new Password";

          this.stepFirst = false;
          this.stepSecond = false;
          this.stepThird = false;
          this.stepFour = true;
        } else {
          this.errorMessage = res.message;
        }
      }, error => {
        this.loader.hide();
        // console.log(error);
        this.errorMessage = "Something went wrong, please try again.";
      });

  }

  createNewPassword = function () {

    this.cpSubmitted = true;
    this.errorMessage = null;

    if (this.cpForm.invalid) {
      // console.log("Invalid form");
      return;
    }

    this.cpUser.password = window.btoa(this.cpUser.userPassword);
    this.mobileNo = this.phoneNumberAll?.e164Number.replace(this.phoneNumberAll?.dialCode, "");;
 
    this.cpUser.phoneNumber = this.mobileNo;

    this.loader.show("Please wait...");
    this.authService.postEcryptWithToken('common/user?' + this.requestPath + '=createNewPassword', this.cpUser, this.localToken)
      .subscribe((res) => {
        this.loader.hide();
        // console.log(res);
        if (res.success) {
          this.heading = "Password Reset Successfully";
          this.subheading = "Your password has been set successfully."
          this.stepFirst = false;
          this.stepSecond = false;
          this.stepThird = false;
          this.stepFour = false;
        } else {
          this.errorMessage = res.message;
        }
      }, error => {
        this.loader.hide();
        // console.log(error);
        this.errorMessage = "Something went wrong, please try again.";
      });
  }

  // Phone number with country code field
  onCountryChange = function (event) {
    this.cpUser.phoneDialCode = this.phoneNumberAll?.dialCode;
    this.cpUser.phoneCountryShortName = this.phoneNumberAll?.countryCode;
  }


  togglePassword(id: string) {
    const password = document.querySelector('#' + id);
    const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
    password.setAttribute('type', type);

    if (id === 'resetPwd') {
      this.passwordVisible = !this.passwordVisible;
    }

    if (id === 'confirmResetPassword') {
      this.confirmPasswordVisible = !this.confirmPasswordVisible;
    }




  }

}
