import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { DashboardPage } from './pages/dashboard/dashboard.page';
import { KnowMoreComponent } from './pages/know-more/know-more.component';
import { ThemePageComponent } from './pages/theme-page/theme-page.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { CompleteRegistrationComponent } from './pages/complete-registration/complete-registration.component';
import { ForgotPassComponent } from './pages/forgot-pass/forgot-pass.component';
import { OtpComponent } from './pages/otp/otp.component';
import { LoginComponent } from './pages/login/login.component';
import { BasicRegComponent } from './pages/basic-reg/basic-reg.component';
import { HomeComponent } from './pages/home/home.component';
import { MoreInfoComponent } from './pages/more-info/more-info.component';
import { ErrorComponent } from './pages/error/error.component';
import { OurProductsComponent } from './pages/our-products/our-products.component';
import { ContactComponent } from './pages/contact/contact.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { SolutionsComponent } from './pages/solutions/solutions.component';
import { MarketplaceComponent } from './pages/marketplace/marketplace.component';
import { CommunicationComponent } from './pages/communication/communication.component';
import { TransactionComponent } from './pages/transaction/transaction.component';
import { SecurityComponent } from './pages/security/security.component';
import { CpaComponent } from './pages/cpa/cpa.component';
import { PlatformVsPipelineComponent } from './pages/platform-vs-pipeline/platform-vs-pipeline.component';
import { PlatformDynamicsComponent } from './pages/platform-dynamics/platform-dynamics.component';
import { MarketingEconomiesComponent } from './pages/marketing-economies/marketing-economies.component';
import { AuthGuardService } from '@services/auth-guard.service';
import { PaymentConfirmationComponent } from './pages/payment-confirmation/payment-confirmation.component';
import { RenewSubscriptionComponent } from './pages/renew-subscription/renew-subscription.component';
import { SubscriptionDoneComponent } from './pages/subscription-done/subscription-done.component';
import { FamilyMemberComponent } from './pages/family-member/family-member.component';
import { SecureCommunicationComponent } from './pages/secure-communication/secure-communication.component';
import { LoginGlobalComponent } from './pages/login-global/login-global.component';
import { RegGlobalComponent } from './pages/reg-global/reg-global.component';
import { SearchSectionComponent } from './pages/search-section/search-section.component';
import { SearchSectionNewComponent } from './pages/search-section-new/search-section-new.component';

import { CreatePasswordComponent } from './pages/create-password/create-password.component';
import { DirectoryDashbordComponent } from './pages/profile-enrichment/directory-dashbord/directory-dashbord.component';
import {DirectoryDashbordNewComponent} from './pages/profile-enrichment/directory-dashbord-new/directory-dashbord-new.component'
import { ProfileDetailsComponent } from './pages/profile-enrichment/profile-details/profile-details.component';
import { EncryptionDecryptionComponent } from './pages/encryption-decryption/encryption-decryption.component';
import { DirectoryDashboardComponent } from './pages/directory/directory-dashboard/directory-dashboard.component';
import { SearchDirectoryComponent } from './pages/directory/search-directory/search-directory.component';
import { PendingRequestNewComponent } from './pages/profile-enrichment/pending-request-new/pending-request-new.component';
import { ProfileDetailsNewComponent } from './pages/profile-enrichment/profile-details-new/profile-details-new.component';

const routes: Routes = [
  {
    path: '', redirectTo: 'home', pathMatch: 'full'
  },
  {
    path: 'home', component: HomeComponent
  },
  {
    path: 'dashboard', component: DashboardPage, canActivate: [AuthGuardService]
  },
  {
    path: 'login', component: LoginComponent
  },
  {
    path: 'otp', component: OtpComponent
  },
  {
    path: 'forgot-pass', component: ForgotPassComponent
  },
  {
    path: 'basic-reg', component: BasicRegComponent
  },
  {
    path: 'know-more', component: KnowMoreComponent
  },
  {
    path: 'more-info', component: MoreInfoComponent
  },
  {
    path: 'theme-page', component: ThemePageComponent
  },
  {
    path: 'profile', component: ProfileComponent, canActivate: [AuthGuardService]
  },
  {
    path: 'complete-reg', component: CompleteRegistrationComponent, canActivate: [AuthGuardService]
  },
  {
    path: 'our-products', component: OurProductsComponent
  },
  {
    path: 'contact', component: ContactComponent
  },
  {
    path: 'terms-and-conditions', component: TermsAndConditionsComponent
  },
  {
    path: 'privacy-policy', component: PrivacyPolicyComponent
  },
  {
    path: 'solutions', component: SolutionsComponent
  },
  {
    path: 'marketplace', component: MarketplaceComponent
  },
  {
    path: 'communication', component: CommunicationComponent
  },
  {
    path: 'transaction', component: TransactionComponent
  },
  {
    path: 'security', component: SecurityComponent
  },
  {
    path: 'cpa-associates', component: CpaComponent
  },
  {
    path: 'platform-vs-pipeline', component: PlatformVsPipelineComponent
  },
  {
    path: 'platform-dynamics', component: PlatformDynamicsComponent
  },
  {
    path: 'marketing-economies', component: MarketingEconomiesComponent
  },
  {
    path: 'payment-confirmation/:success/:transactionId',
    component: PaymentConfirmationComponent
  },
  {
    path: 'renew-subscription',
    component: RenewSubscriptionComponent
  },
  {
    path: 'subscription-done/:success/:transactionNo/:sessionId',
    component: SubscriptionDoneComponent
  },
  {
    path: 'family-member',
    component: FamilyMemberComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'secure-communication',
    component: SecureCommunicationComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'set-new-pass/:id/:verificationCode', component: CreatePasswordComponent
  },
  {
    path: 'login-global', component: LoginGlobalComponent
  },
  {
    path: 'reg-global', component: RegGlobalComponent
  },
  {
    path: 'search',
    component: SearchSectionComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'search-new',
    component: SearchSectionNewComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'directory-dashbord',
    component: DirectoryDashbordComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'directory-dashbord-new',
    component: DirectoryDashbordNewComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'pending-request-new',
    component: PendingRequestNewComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'profile-details',
    component: ProfileDetailsComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'profile-details-new',
    component: ProfileDetailsNewComponent
  },
  {
    path: 'encryption-decryption',
    component: EncryptionDecryptionComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'profile-enrich',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/profile-enrichment/profile-enrichment.module').then(m => m.ProfileEnrichmentModule)
  },
  {
    path: 'directory-dashboard-new',
    component: DirectoryDashboardComponent
  },
  {
    path: 'search-directory',
    component: SearchDirectoryComponent
  },
  {
    path: 'error/:errorCode', component: ErrorComponent
  },
  { path: '**', redirectTo: 'error/404', pathMatch: 'full' },
 
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, onSameUrlNavigation: 'reload' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
