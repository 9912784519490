<app-header-left-menu [user]="user"></app-header-left-menu>
<ion-content id="main-content">
  <div class="bg-theme-alt">
    <div class="bg-grad-special">
      <div class="bg-grad-special-alt">
        <div class="page-container">
          <div class="nk-ovm shape-u shape-contain shape-left-top"></div>
          <app-header [user]="user"></app-header>
          <div class="subContent">
            <div class="main-content container">
              <div class="right-section" *ngIf="isSubscriptionExpired">
                <h5 class="ath-heading title mb-4 text-center" data-animate="fadeInUp" data-delay=".3"
                  style="visibility: visible; animation-delay: 0.3s;">Renew Subscription<small class="tc-default">Make
                    payment you continue our services.</small></h5>
                <div class="mb-2">
                  &nbsp;
                </div>
                <div class="row">
                  <div class="feature feature-inline animated fadeInUp col-lg-6 mb-4" data-animate="fadeInUp"
                    data-delay=".4" style="visibility: visible; animation-delay: 0.4s;">
                    <div class="feature-icon feature-icon-s7">
                      <em class="icon ti ti-unlock"></em>
                    </div>
                    <div class="feature-text">
                      <h5 class="title title-sm">Hiddin IOT</h5>
                      <p>Hiddin Plateform for managing Hiddin Equipments and Devices.</p>
                    </div>
                  </div>
                  <div class="feature feature-inline animated fadeInUp col-lg-6 mb-4" data-animate="fadeInUp"
                    data-delay=".4" style="visibility: visible; animation-delay: 0.4s;">
                    <div class="feature-icon feature-icon-s7">
                      <em class="icon ti ti-lock"></em>
                    </div>
                    <div class="feature-text">
                      <h5 class="title title-sm">Hiddin IOT Simulation</h5>
                      <p>A place where we can see the actual IOT Equipments simulation.</p>
                    </div>
                  </div>
                  <div class="feature feature-inline animated fadeInUp col-lg-6 mb-4" data-animate="fadeInUp"
                    data-delay=".4" style="visibility: visible; animation-delay: 0.4s;">
                    <div class="feature-icon feature-icon-s7">
                      <em class="icon ti ti-email"></em>
                    </div>
                    <div class="feature-text">
                      <h5 class="title title-sm">Hiddin-Mail</h5>
                      <p>Hiddin Webmail server for sending secured mails.</p>
                    </div>
                  </div>
                  <div class="feature feature-inline animated fadeInUp col-lg-6 mb-4" data-animate="fadeInUp"
                    data-delay=".4" style="visibility: visible; animation-delay: 0.4s;">
                    <div class="feature-icon feature-icon-s7">
                      <em class="icon ti ti-shield"></em>
                    </div>
                    <div class="feature-text">
                      <h5 class="title title-sm">Hiddin Directory</h5>
                      <p>We can find our friends, colleague and more in whole world.</p>
                    </div>
                  </div>
                </div>
                <div class="text-center">
                  <button class="btn btn-md btn-grad" (click)="subscriptNow()"><span>Pay 100$</span></button>
                </div>
              </div>
              <div class="right-section" *ngIf="!isSubscriptionExpired">
                <h5 class="ath-heading title mb-4 text-center" data-animate="fadeInUp" data-delay=".3"
                  style="visibility: visible; animation-delay: 0.3s;">Currenct Subscription is not expired<small
                    class="tc-default">Current subscription will be expired in <span
                      class="greenBold">{{remainingDays}}</span>
                    days</small></h5>
                <div class="mb-2">
                  &nbsp;
                </div>
              </div>
            </div>
          </div>
          <app-footer></app-footer>
        </div>
      </div>
    </div>
  </div>
</ion-content>