<app-header-left-menu [user]="user"></app-header-left-menu>
<ion-content id="main-content" class="full-width-page">
    <div class="bg-theme-alt">
        <div class="bg-grad-special">
            <div class="bg-grad-special-alt">
                <div class="nk-ovm shape-u shape-contain shape-left-top"></div>
                <app-header [user]="user"></app-header>
                <div class="subContent">
                    <div class="main-content container">
                        <ion-row class="content_row">
                            <!--ion-col size-lg="3" size-md="12" size-sm="12" size-xs="12">
                                <div class="user-img">
                                    <div class="usr-img">
                                        <img src="assets/images/team/profile.png" />
                                    </div>
                                    <label><input type="file" (change)="fileBrowseHandler($event.target.files)"
                                            accept=".png, .jpg, .jpeg"><em class="fa fa-edit tc-primary"></em></label>

                                </div> -->
                                <!--ul class="nav tab-nav tab-nav-vr tab-nav-bdr mr-lg-3">
                                    <li><a [ngClass]="showId=='basicInfo' ? 'active':''" routerLinkActive="active" id="basicInfo" (click)="toggleShow('basicInfo')" routerLink="/profile-enrich/basic-detail"><em
                                                class="fas fa-caret-right"></em>Basic Details</a></li>
                                    <li>
                                        <a [ngClass]="showId=='securityQuestion' ?'active':''" routerLinkActive="active" id="securityQuestion" (click)="toggleShow('securityQuestion')" routerLink="/profile-enrich/security-question"><em
                                                class="fas fa-caret-right"></em>Security Question</a>
                                    </li>
                                    <li *ngIf="user && user.roleLevel != 3">
                                        <a [ngClass]="showId=='documents' ?'active':''" routerLinkActive="active" id="documents" (click)="toggleShow('documents')" routerLink="/profile-enrich/upload-documents"><em
                                                class="fas fa-caret-right"></em>Upload Documents</a>
                                    </li>
                                    <li><a [ngClass]="showId=='directory'?' active':''" routerLinkActive="active" id="directory" (click)="toggleShow('directory')" routerLink="/profile-enrich/collect-directory"><em
                                                class="fas fa-caret-right"></em>Affiliation for Directory</a></li>
                                    <li>
                                        <a [ngClass]="showId=='education'? 'active':''" routerLinkActive="active" id="education" (click)="toggleShow('education')" routerLink="/profile-enrich/education-detail"><em
                                                class="fas fa-caret-right"></em>Education</a>
                                    </li>
                                    <li>
                                        <a [ngClass]="showId=='occupation'? 'active':''" routerLinkActive="active" id="occupation" (click)="toggleShow('occupation')" routerLink="/profile-enrich/occupation-detail"><em
                                                class="fas fa-caret-right"></em>Occupation</a>
                                    </li-->
                                    <!-- <li>
                                        <a [ngClass]="showId=='organizations'? 'active':''" routerLinkActive="active"
                                            id="organizations" (click)="toggleShow('organizations')"
                                            routerLink="/add-user/organizations"><em
                                                class="fas fa-caret-right"></em>Formal Organizations</a>
                                    </li>
                                    <li>
                                        <a [ngClass]="showId=='associations'? 'active':''" routerLinkActive="active"
                                            id="associations" (click)="toggleShow('associations')"
                                            routerLink="/add-user/informal-associations"><em
                                                class="fas fa-caret-right"></em>Informal Associations </a>
                                    </li>
                                    <li>
                                        <a [ngClass]="showId=='informalGroups'? 'active':''" routerLinkActive="active"
                                            id="informalGroups" (click)="toggleShow('informalGroups')"
                                            routerLink="/add-user/informal-groups"><em
                                                class="fas fa-caret-right"></em>Informal Groups </a>
                                    </li>
                                    <li>
                                        <a [ngClass]="showId=='relationshipAssignments'? 'active':''"
                                            routerLinkActive="active" id="relationshipAssignments "
                                            (click)="toggleShow('relationshipAssignments')"
                                            routerLink="/add-user/relationship-assignments"><em
                                                class="fas fa-caret-right"></em>Relationship Assignments</a>
                                    </li> -->
                                    <!--li>
                                        <a></a>
                                    </li>
                                    <li>
                                        <a></a>
                                    </li>
                                    <li>
                                        <a></a>
                                    </li>
                                    <li>
                                        <a></a>
                                    </li>
                                </ul>
                            </ion-col-->
                            <ion-col size-lg="12" size-md="12" size-sm="12" size-xs="12">
                                <ion-router-outlet class="child-route"></ion-router-outlet>
                            </ion-col>
                        </ion-row>
                    </div>
                </div>
                <app-footer></app-footer>
            </div>
        </div>
    </div>
</ion-content>