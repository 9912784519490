import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthServiceService } from './auth-service.service';
import { RegTokenServiceService } from './reg-token-service.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class FileuploadService {

  constructor(private http: HttpClient, private authService: AuthServiceService, private router: Router,
    private regTokenService: RegTokenServiceService) { }

  public getAuthHeaders = () => ({
    headers: new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.getToken()
    })
  });

  upload(path, file: File) {

    const formData = new FormData();
    formData.append("file", file);

    return this.http.post<any>(environment.base + path, formData);
  }

  uploadSecure(path, file: File) {

    if (!this.authService.authorized()) {
      //throw "Unauthorized User";
      this.authService.unauthorized();
    }

    const formData = new FormData();
    formData.append("file", file);

    return this.http.post<any>(environment.base + path, formData, this.getAuthHeaders());
  }

  uploadWithProgress(path, file: File) {

    const formData = new FormData();
    formData.append("file", file);

    return this.http.post<any>(environment.base + path, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      catchError(this.handleError)
    );
  }

  uploadWithProgressSecure(path, file: File) {

    const formData = new FormData();
    formData.append("file", file);

    return this.http.post<any>(environment.base + path, formData, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.authService.getToken()
      }),
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => this.getEventMessage(event, formData)),
      catchError(this.handleError)
    );
  }

  private getEventMessage(event: HttpEvent<any>, formData) {

    switch (event.type) {
      case HttpEventType.UploadProgress:
        return this.fileUploadProgress(event);
        break;
      case HttpEventType.Response:
        return this.apiResponse(event);
        break;
      default:
        return `File surprising upload event: ${event.type}.`;
    }
  }

  private fileUploadProgress(event) {
    const percentDone = Math.round(100 * event.loaded / event.total);
    return { status: 'progress', message: percentDone };
  }

  private apiResponse(event) {
    return event.body;
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened. Please try again later.');
  }
}
