<ion-content overflow-scroll="true" class="content-main">
    <div id="login-popup">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <button class="modal-close" aria-label="Close" (click)="dismiss()"><em class="ti ti-close"></em></button>
                <div class="ath-container m-0">
                    <div class="ath-body">
                        <div class="btn-container animated fadeInDown" data-animate="fadeInDown" data-delay=".85" style="visibility: visible; animation-delay: 0.85s;">
                            <div class="notification-content">
                                <h5 class="ath-heading title">Success<small class="tc-default"></small></h5>
                                <img src="assets/images/success-icon.png">
                                <p>You have completed your first step in Enrolment. Please check your mail for the next step.</p>
                                <!-- <div class="btn btn-primary btn-md" (click)="dismiss()">Ok</div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ion-content>