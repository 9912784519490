import { Component, OnInit } from '@angular/core';

import { AuthServiceService } from 'src/app/services/auth-service.service';
import { RegUser } from '../basic-reg/RegUser';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-marketing-economies',
  templateUrl: './marketing-economies.component.html',
  styleUrls: ['./marketing-economies.component.scss']
})
export class MarketingEconomiesComponent implements OnInit {

  user: RegUser = new RegUser();
  LoggedIn: boolean;

  constructor(public authService: AuthServiceService, public global: Globals) { }

  ngOnInit(): void {
  }

  ionViewWillEnter() {    
    this.user = this.authService.getLoggedInUser();
    this.LoggedIn = this.authService.isLoggedIn();
    if (!this.global.isEmpty(this.user)) {
      this.user.imageUrl = this.authService.getProfilePicture();
    }
  }

}
