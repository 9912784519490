<app-header-left-menu [user]="user"></app-header-left-menu>
<ion-content id="main-content" class="full-width-page">
  <div class="bg-theme-alt">
    <div class="bg-grad-special">
      <div class="bg-grad-special-alt">
        <div class="nk-ovm shape-u shape-contain shape-left-top"></div>
        <app-header [user]="user"></app-header>
        <div class="subContent">
          <div class="main-content container">
            <h5 class="ath-heading title">MARKETING ECONOMIES<small class="tc-default">Lone Wolf Marketing versus The
                HIDDIN Marketplace</small></h5>
            <h3 class="title title-md">
              Marketing Economies of Scale
            </h3>
            <p>Lone Wolf Marketing versus The HIDDIN Marketplace</p>
            <p>The traditional Lone Wolf marketing where every business conducts their marketing by themselves generates
              few if any economies of scale. Anonymity from de-identification makes sharing data among any number of
              different businesses and industries safe and practical. See Diagram 1 below. RD = Research Data, TD =
              Transaction Data, PI = Profile Information, MI = Marketing Intelligence.</p>
            <div class="row">
              <div class="col-md-6">
                <p>Diagram 1 </p>
              </div>
              <div class="col-md-6">
                <p>Lone Wolf Approach</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <p>Business ID &amp; # of Customers</p>
              </div>
              <div class="col-md-6">
                <p>B1 100, B2 300, B3 500, B4 800 </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <p>RD</p>
              </div>
              <div class="col-md-3">
                <p>RD</p>
              </div>
              <div class="col-md-3">
                <p>RD</p>
              </div>
              <div class="col-md-3">
                <p>RD</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <p>100</p>
              </div>
              <div class="col-md-3">
                <p>300</p>
              </div>
              <div class="col-md-3">
                <p>500</p>
              </div>
              <div class="col-md-3">
                <p>800</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-1">
                <p>TD </p>
              </div>
              <div class="col-md-1">
                <p>B1</p>
              </div>
              <div class="col-md-1">
                <p>PI</p>
              </div>
              <div class="col-md-1">
                <p>TD</p>
              </div>
              <div class="col-md-1">
                <p>B2</p>
              </div>
              <div class="col-md-1">
                <p>PI</p>
              </div>
              <div class="col-md-1">
                <p>TD </p>
              </div>
              <div class="col-md-1">
                <p>B3 </p>
              </div>
              <div class="col-md-1">
                <p> PI </p>
              </div>
              <div class="col-md-1">
                <p>TD </p>
              </div>
              <div class="col-md-1">
                <p> B4 </p>
              </div>
              <div class="col-md-1">
                <p> PI</p>
              </div>

            </div>
            <div class="row">
              <div class="col-md-3">
                <p>MI</p>
              </div>
              <div class="col-md-3">
                <p>MI</p>
              </div>
              <div class="col-md-3">
                <p>MI</p>
              </div>
              <div class="col-md-3">
                <p>MI</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <p>Business ID &amp; # of Customers Total B1-B4 1,700*</p>
              </div>
              <div class="col-md-6">
                <p>* No common customers</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <p>Diagram 2</p>
              </div>
              <div class="col-md-9">
                <p><b>HIDDIN</b> Marketplace </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <p>RD</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <p> 1,700</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <p>TD B1, B2, B3, B4 PI </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <p>MI</p>
              </div>
            </div>
            <p>The Lone Wolf approach has each business trying to go it alone trying to amass enough quality data from
              their clients and by purchasing data from third parties to compile profile information, conduct meaningful
              research, and transaction data analysis, so they can work this information into some form of marketing
              intelligence that they can then use to conduct some form of marketing campaign. With all the advertising
              options available today I think most of the new and small to medium size businesses simply make educated
              guesses throwing some marketing mud against the wall to see what sticks or worked in the past to keep
              sales going from their customer base and adding new customers hopefully faster than attrition looses them.
              When a Lone Wolf adds a new customer only that one Wolf receives any benefit.</p>
            <p>Our Marketplace Approach has all the businesses sharing the total # of customers and their combined
              de-identified Profile, Transaction, and communication data allowing them to conduct queries and analysis
              on the entire group of customers to generate their marketing intelligence and the resulting campaigns.
              With the direct help of these customers providing this information on a voluntary de-identified basis
              the results of the marketing intelligence should be of the highest quality. The marketing campaigns
              based on this intelligence should be the most effective. We will charge for the research, the surveys
              and through our platforms de-identified channels of communication we will deliver their advertising
              messaging all on a cost plus basis just to make sure we do better than just breaking even. We intend to
              make our money when our sellers do, on a strict commission basis collected when they get paid via the
              de-identified transaction processes the <b>HIDDIN</b> Marketplace platform provides. Every communication
              and transaction is secure, private, adds valuable data to our marketplace and our goal is to cut what it
              costs our buyers and sellers to conduct their marketing by 50% so they can share the savings between
              them and the endowment initiatives they want to support.</p>
            <p>A new customer for any of these businesses is a new customer for the marketplace and a new potential
              customer for any of the other enrolled businesses illustrating the <b>HIDDIN</b> Marketplace multiplier
              effect unique to our digital platform marketplace. In the Lone Wolf example above 1 new customer
              impacted just that single business a multiplier of a factor of 1. In the above <b>HIDDIN</b> Marketplace
              example with 4 businesses the addition of a new customer to one business had a marketplace multiplier
              effect of a factor of 4. If we had 7,352 businesses enrolled the addition of 1 new customer would have a
              market multiplier effect of 7,352 potential new business customer relationships. When comparing the
              <b>HIDDIN</b> Marketplace to other digital platform businesses like Uber or Google a new customer for
              them still only has an impact by a factor of 1 because of their single industry single enterprise focus.
              Providing enterprises from all kinds of industries the chance to deploy their own digital platform
              marketing strategies with our platform of diverse communication and transaction processing capabilities
              is another way the <b>HIDDIN</b> Marketplace is unlike any other digital platform business.</p>
            <p>The addition of a new business to our marketplace when we have 1.2 million buyers enrolled would also
              have a potential multiplier effect of a factor of that same 1.2 million less any customers that business
              brought to the marketplace that were already enrolled. If this new business enrolled any new customers
              to our marketplace each one of them would have their own multiplier effect equal to the number of
              enrolled businesses that they were not already doing business with.</p>
            <p>Looking at B1 as the first enterprise to join our marketplace they start with a total of 100 customers
              and when enterprise B2 joins with 300 customers B1 has another 300 potential customers less any both
              enterprises have in common, which could produce a growth in B1’s market share by 3 times. When B3 joins
              B1 gets another potential market share boost of 5 times. Now it is unlikely that the customers of all
              these other enterprises are all going to need the goods or service B1 offers, but the research and
              advertising modalities the <b>HIDDIN</b> Marketplace will offer allows B1 to cost effectively find these
              customers and the <b>HIDDIN</b> Marketplace will give them a good reason to do business with B1 over any
              other un-enrolled businesses with the Realization and the Argument mentioned earlier.</p>
            <p>The sellers trying to hang on to their market share will come to believe that getting their customers
              to enroll with them is the best way to keep them as customers. The potential to have better terms and
              pricing from these sellers makes enrolling even more compelling. The security and privacy advantages of
              our digital technology over the current alternatives should be reason enough to compel them both to act.
              The prospects of joining a marketplace that promotes charitable endowments that address the kinds of
              societal problems our political class has been unable to address adds another reason for some to enroll.
            </p>
            <p>When these customers enroll they add to the financial value of the marketplace of the buyers and
              sellers already enrolled with the increase of their economic activity. They add to the volume of
              communication and transaction activity enhancing the security of everyone using the platform by adding
              to the sheer number of events and proving once again that there is safety in numbers and by increasing
              the volume of data the Marketplace becomes more valuable. They further encourage sellers to move away
              from the more expensive less effective forms of marketing and advertising in favor of the more cost
              effective <b>HIDDIN</b> Marketplace alternatives. </p>
            <p> Marketplaces need to attract and retain buyers and sellers. Providing unique research capabilities or
              quantum and extremist proof security that could save the modern world might get some to join, but
              research and security are not sexy, nor compelling. Saving buyers money and making sellers more money
              while growing their market share is sexy and should drive the enrollment of both. Then the network
              effects and feedback loops designed into our digital platform marketplace will encourage their continued
              participation and motivate new buyers and sellers to join which in turn inspires more to join proving
              just how explosive the growth can be with digital platform businesses like Uber, Google and now
              <b>HIDDIN</b>.</p>
            <p>We are giving away our patented security and privacy solution to both individuals and enterprises to
              get them to participate in the <b>HIDDIN</b> Marketplace where our buyers will anonymously contribute
              their raw marketing data for our sellers to consume and transform into marketing intelligence. That
              intelligence is then used to create effective marketing campaigns that our platform's secure digital
              communications deliver to our buyers anonymously. Our buyers consume this marketing intelligence
              yielding the sales flowing through the <b>HIDDIN</b> Marketplace that generate our commissions based on
              50% of the historical marketing expenditures for that industry. Every digital action creates another
              data point adding to the value of our buyer's marketing information improving the marketing intelligence
              our sellers use to provide our buyers with meaningful add campaigns encouraging them to purchase items
              at values that are offered no where else.</p>
            <p>Enrolled buyers will want to do business with enrolled sellers to reduce their cyber breach exposures
              and take advantage of the better terms and pricing from the cost to market savings the <b>HIDDIN</b>
              Marketplace offers. The more sellers that join the more opportunity for choice and savings for each
              buyer creating another feed back loop to spur activity. Sellers from every industry will be approaching
              their buyers with the "The Reasoning" and "The Argument" described previously.</p>
            <p>The <b>HIDDIN</b> Marketplace was designed to foster these synergistic network effects derived from the
              value of the marketing data that our consumers provide and our enterprises use to develop their digital
              marketing campaigns designed for our buyers. The <b>HIDDIN</b> Marketplace platform communicates these
              marketing campaigns to our consumers and manages the resulting transactions. Every transaction adds to
              the value of the marketing history we gather driving more interactions and inspiring another feed back
              loop. Encouraging interactions that produce valuable data that in turn encourages more interactions and
              more data creation is the intended dynamics of our digital platform businesses. Building a funding
              mechanism to energize the endowment approach to solving societal problems provides another reason for
              some to participate.</p>
            <p>These are the dynamics of a digital platform marketplace where many sellers interact with many buyers
              and with each other. The addition of a new consumer to this marketplace represents a new potential
              customer to every enrolled enterprise and every new transaction adds to the value of every enterprise's
              marketing information demonstrating another feed back loop inspiring further interactions among and
              between all the potential participants. Each new addition to the marketplace and every transaction adds
              more value to fuel further interactions perpetuating the marketplace to do more adding to the synergies
              digital platform businesses strive to promote.</p>
            <p>Traditional marketing efforts involve the activities of a single enterprise interacting with many
              buyers where the entry of an unrelated seller has no impact on the marketing research or access
              capabilities of any other enterprises and therefore no synergistic network effects are generated and
              success or failure is measured by the efforts and responses from each enterprise acting alone.</p>
            <p>Building a marketplace requires both buyers and sellers. The enterprises that would benefit the most
              from the potential cost to market savings, and the secure and private communications and transaction
              capabilities that we can offer to their customers are whom we shall target first with our Strategic
              Alliance Initiatives. When the first enterprise enrolls their natural customer retention motivations
              will drive them to enroll their current customers. The motivations of the next enterprise to enroll and
              all future enterprises is a direct result of the synergistic network effects associated with the digital
              platform businesses model that the <b>HIDDIN</b> Marketplace deploys.</p>
          </div>
        </div>
        <app-footer></app-footer>
      </div>
    </div>
  </div>
</ion-content>