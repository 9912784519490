import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-thanks-for-upgrade',
  templateUrl: './thanks-for-upgrade.component.html',
  styleUrls: ['./thanks-for-upgrade.component.scss'],
})
export class ThanksForUpgradeComponent implements OnInit {

  constructor(private modalCtrl: ModalController) { }

  ngOnInit() { }

  async dismiss() {
    this.modalCtrl.dismiss();
  }

}
