<app-header-left-menu [user]="user"></app-header-left-menu>
<ion-content id="main-content" class="full-width-page">
  <div class="bg-theme-alt">
    <div class="bg-grad-special">
      <div class="bg-grad-special-alt">
        <div class="nk-ovm shape-u shape-contain shape-left-top"></div>
        <app-header [user]="user"></app-header>
        <!-- <div class="subContent">
          <div class="main-content container">
            <h5 class="ath-heading title">About Hiddin <small class="tc-default">Anyone over forty grew up in an analog world.</small>
            </h5>
            <p>
              Anyone over forty grew up in an analog world. Their children grew up in this digital world. Our technology de-identifies this digital world making it far more secure and private for anyone using our services to communicate and conduct their transactions through our digital platform. De-identifying these digital communication and transaction processes makes it possible for the <b>HIDDIN</b> Marketplace platform to change how marketing is done and what it costs to match up our buyers and sellers to get their business done.
            </p>
            <p>
              This tech disrupts the communications industry, the transaction processing industry, the marketing industry and directly impacts any industry where any of these industries play a vital role in their operations. Any person or business that uses any of the current digital transmission technologies is our potential customer. The businesses providing them with these digital services will have to compete against the superior security and privacy of our technology and the cost effectiveness of our digital platform marketplace.
            </p>
            <p>
              Providing our sellers access to <b>HIDDIN's</b> digital platform to deploy their own digital marketing strategies gives the typical pipeline business the marketing advantages of the platform business model and that helps cut their marketing costs. Changing the marketing dynamics between our buyers and sellers adds even more to these marketing efficiencies. We project a fifty percent cost reduction overall in the current marketing spend for most industries with the efficiencies of our marketplace.
            </p>
            <p>
              Digital technologies improved our electronic communication, transaction, and storage capabilities replacing most of the analog technologies everyone once used. This digital transformation expanded our options to digitally interact and led to an explosion in the amount of these kinds of interactions. The dramatic increase in our ability to interact has led to the development of the digital platform business model or (DPBM), which is very different from the traditional Pipeline Business model or (PBM) and has led to a massive increase in our exposure to an evolving array of cyber security threats to our data and privacy.
            </p>
            <p>
              Encryption of the digital packet payload is the principal form of protection currently deployed to secure our data and preserve the privacy of our digital transmissions. The mechanics of how encryption works involves a discussion of public and private keys and how they are exchanged and utilized to protect the data and the identities of the transmitting parties hiding in the packet payloads being sent digitally. The simple explanation is that the encryption security strategy is to make the processing of these packet payloads so complex that it will exceed any practical attempt of the current decryption capabilities to unravel what has been hidden. The problem with this strategy is that mathematics, computers and software technologies are forever evolving and any combination of these three forces will eventually lower the complexity bar to levels that make our current encryption processes easy to decrypt leaving our data and identities exposed.
            </p>
            <p>
              Aggravating the decryption problem is the fact that storing encrypted data has become so inexpensive that anyone that can copy encrypted data can just wait until decryption technologies catch up to mine the data and identity information that will no longer be protected. When quantum computers come of age in the next five or ten years it is expected that they will lower the complexity bar to where most forms of encryption used commercially will be unable to protect our data and identities in any of the digital communication, transaction and storage mediums we use today. The potential problems quantum computers and the copy and wait schemes present have not gone undetected by the US government.
            </p>
            <p>
              The National Institute for Standards Technology initiated an effort in the fall of 2016 to get members of academia and the business community to work together in a contest to discover new forms of encryption that might survive the Quantum Decryption Threat. Once discovered the question becomes whether we will be able to adapt this new form of encryption throughout our digital infrastructure in time to avert the impending decryption disaster. Unfortunately any form of encryption replacement is only kicking some future decryption attempt down the road to the next technological evolution event.
            </p>
            <p>
              Our de-identifying technology alters the current digital technologies improving everyone's security and privacy by deploying a security strategy that is not dependent on the current level of technological complexity. We render the data useless by removing identity and payment information from what we transmit and store. This strategy does not work when the data itself is valuable. The formula for Coke or the recipe for Kentucky Fried Chicken would be examples of data that is valuable that our tech would not protect it. Fortunately our tech works for the majority of transmission and may offer the only commercially viable defense against the quantum decryption threat available today. Unlike encryption our solution is not simply another attempt to kick the problem down the road to the next technological evolution. It is a more lasting and far more adaptable solution to future technological advances.
            </p>
            <p>
              Security and privacy are important but unfortunately from a sales perspective the public does not see them as sexy, attractive nor compelling sale motivators until disaster hits, which in this situation would be far too late. It is what we can do with this technology that provides compelling motivations for both buyers and sellers to incorporate our tech into their digital existence.
            </p>
            <p>
              The three things you need to come to understand and accept about our technology are what it does, the scope of this undertaking and what we will do with it.
            </p>
            <p>
              <b>1)</b> What it does is provide superior security and privacy to the digital transmission process without relying on encryption by rendering most data useless.
            </p>
            <p>
              <b>2)</b> The scope will effect most of the digital communications, transaction and data storage capabilities that everyone uses today much like the impact of the analog to digital transition discussed earlier except you need to add all the new digital channels of communicating into the assessment to appreciate the scope completely.
            </p>
            <p>
              <b>3)</b> What we will do with it involves the creation of our digital platform marketplace that will enable us to change how marketing is done with new marketing dynamics between buyers and sellers. We will improve the marketing efficiency with this new cooperative relationship, cut traditional marketing expenditures by 50% enabling them both to share in the savings creating a price advantage to sellers enrolled with our platform over their un-enrolled competition. Providing pipeline businesses with access to our digital platform marketplace will allow them to deploy their own digital platform marketing strategies much like Uber, Google, and Amazons have done with their own digital platforms, but we will be providing much larger economies of scale far more rapidly.
            </p>
            <p>
              The buyer savings and seller price advantages sound like a compelling reasons for both parties to adopt our tech, but it creates a potential problem for these pipeline enterprises with their traditional limitation to scale when compare to a pure digital platform business. Our solution to their pipeline scale problem provided us with our venue to market our digital platform marketplace.
            </p>
            <p>
              The typical pipeline business that can produce 1,000 units a month would be in big trouble if we marketed them like a rock star with our digital platform marketplace delivering them orders for a 1,000 units a day. Who do you call when you want to address a quantitative problem like this? Not ghost busters! You need the advice of a good accountant and with the direct access to the marketing data our marketplace is going to provide our sellers turning to the professionals that design and maintain information systems is the most logical choice. The amazing thing is marketing has traditionally been an area that accountants and CPA firms have avoided and now in the era of big data and data analytics who else would you want to trust with this vital role?
            </p>
            <p>
              We have already patented the technology that will make most of this possible and we are building a working model to demonstrate how we would apply it to a typical home automation IOT application. We intend to have a working model up on a website by the end of the first quarter of 2018 that will act as a proof of concept and demonstrate our team’s ability to deliver the solutions to our security and privacy problems that this technology was designed to address.
            </p>
          </div>
        </div>           -->
        <section class="section bg-theme tc-light animated fadeInUp" data-animate="fadeInUp" data-delay="0.4"
        style="visibility: visible; animation-delay: 0.4s;">
          <div class="container">
            <div class="nk-block nk-block-features-s2">
                <div class="row align-items-center gutter-vr-30px">
                    <div class="col-md-6">
                      <img src="./assets/images/custom-hiddin/ecosystem.png" alt="ecosystem">
                    </div>
                    <div class="col-md-6">
                        <div class="nk-block-text">
                            <h6 class="title title-xs title-s1 tc-primary">Anyone over forty grew up in an analog world.</h6>
                            <p>
                              Anyone over forty grew up in an analog world. Their children grew up in this digital world. Our technology de-identifies this digital world making it far more secure and private for anyone using our services to communicate and conduct their transactions through our digital platform. De-identifying these digital communication and transaction processes makes it possible for the HIDDIN Marketplace platform to change how marketing is done and what it costs to match up our buyers and sellers to get their business done.
                            </p>
                            <p>
                              This tech disrupts the communications industry, the transaction processing industry, the marketing industry and directly impacts any industry where any of these industries play a vital role in their operations. Any person or business that uses any of the current digital transmission technologies is our potential customer. The businesses providing them with these digital services will have to compete against the superior security and privacy of our technology and the cost effectiveness of our digital platform marketplace.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </section>
        <section class="section bg-theme tc-light">
          <div class="container">
            <div class="nk-block nk-block-features-s2">
                <div class="row align-items-center gutter-vr-30px">
                    <div class="col-md-6">
                        <div class="nk-block-text">
                            <h6 class="title title-xs title-s1 tc-primary">Providing our sellers access to HIDDIN's digital platform</h6>
                            <p>
                              Providing our sellers access to HIDDIN's digital platform to deploy their own digital marketing strategies gives the typical pipeline business the marketing advantages of the platform business model and that helps cut their marketing costs. Changing the marketing dynamics between our buyers and sellers adds even more to these marketing efficiencies. We project a fifty percent cost reduction overall in the current marketing spend for most industries with the efficiencies of our marketplace.
                            </p>
                            <p>
                              Digital technologies improved our electronic communication, transaction, and storage capabilities replacing most of the analog technologies everyone once used. This digital transformation expanded our options to digitally interact and led to an explosion in the amount of these kinds of interactions. The dramatic increase in our ability to interact has led to the development of the digital platform business model or (DPBM), which is very different from the traditional Pipeline Business model or (PBM) and has led to a massive increase in our exposure to an evolving array of cyber security threats to our data and privacy.
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6">
                      <img src="./assets/images/custom-hiddin/services.png" alt="gfx">
                    </div>
                </div>
            </div>
          </div>
        </section>
        <section class="section bg-theme tc-light">
          <div class="container">
            <div class="nk-block nk-block-features-s2">
                <div class="row align-items-center gutter-vr-30px">
                  <div class="col-md-6">
                    <img src="./assets/images/custom-hiddin/secured-transaction.png" alt="Secured Transaction">
                  </div>
                    <div class="col-md-6">
                        <div class="nk-block-text">
                            <h6 class="title title-xs title-s1 tc-primary">Encryption of the digital packet payload is the principal</h6>
                            <p>
                              Encryption of the digital packet payload is the principal form of protection currently deployed to secure our data and preserve the privacy of our digital transmissions. The mechanics of how encryption works involves a discussion of public and private keys and how they are exchanged and utilized to protect the data and the identities of the transmitting parties hiding in the packet payloads being sent digitally. The simple explanation is that the encryption security strategy is to make the processing of these packet payloads so complex that it will exceed any practical attempt of the current decryption capabilities to unravel what has been hidden. The problem with this strategy is that mathematics, computers and software technologies are forever evolving and any combination of these three forces will eventually lower the complexity bar to levels that make our current encryption processes easy to decrypt leaving our data and identities exposed.
                            </p>
                            <p>
                              Aggravating the decryption problem is the fact that storing encrypted data has become so inexpensive that anyone that can copy encrypted data can just wait until decryption technologies catch up to mine the data and identity information that will no longer be protected. When quantum computers come of age in the next five or ten years it is expected that they will lower the complexity bar to where most forms of encryption used commercially will be unable to protect our data and identities in any of the digital communication, transaction and storage mediums we use today. The potential problems quantum computers and the copy and wait schemes present have not gone undetected by the US government.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </section>
        <section class="section bg-theme tc-light">
          <div class="container">
            <div class="nk-block nk-block-features-s2">
                <div class="row align-items-center gutter-vr-30px">
                    <div class="col-md-6">
                      <div class="nk-block-text">
                        <h6 class="title title-xs title-s1 tc-primary">The National Institute for Standards Technology</h6>
                        <p>
                          The National Institute for Standards Technology initiated an effort in the fall of 2016 to get members of academia and the business community to work together in a contest to discover new forms of encryption that might survive the Quantum Decryption Threat. Once discovered the question becomes whether we will be able to adapt this new form of encryption throughout our digital infrastructure in time to avert the impending decryption disaster. Unfortunately any form of encryption replacement is only kicking some future decryption attempt down the road to the next technological evolution event.
                        </p>
                        <p>
                          Our de-identifying technology alters the current digital technologies improving everyone's security and privacy by deploying a security strategy that is not dependent on the current level of technological complexity. We render the data useless by removing identity and payment information from what we transmit and store. This strategy does not work when the data itself is valuable. The formula for Coke or the recipe for Kentucky Fried Chicken would be examples of data that is valuable that our tech would not protect it. Fortunately our tech works for the majority of transmission and may offer the only commercially viable defense against the quantum decryption threat available today. Unlike encryption our solution is not simply another attempt to kick the problem down the road to the next technological evolution. It is a more lasting and far more adaptable solution to future technological advances.
                        </p>
                      </div>
                    </div>
                    <div class="col-md-6" style="display: flex;justify-content: center;">
                      <img src="./assets/images/custom-hiddin/layout.png" alt="gfx">
                    </div>
                </div>
            </div>
          </div>
        </section>
        <section class="section bg-theme tc-light">
          <div class="container">
            <div class="nk-block nk-block-features-s2">
                <div class="row align-items-center gutter-vr-30px">
                  <div class="col-md-6">
                    <img src="./assets/images/custom-hiddin/binary-data.png" alt="gfx">
                  </div>
                    <div class="col-md-6">
                      <div class="nk-block-text">
                        <h6 class="title title-xs title-s1 tc-primary">Security and privacy are important</h6>
                        <p>
                          Security and privacy are important but unfortunately from a sales perspective the public does not see them as sexy, attractive nor compelling sale motivators until disaster hits, which in this situation would be far too late. It is what we can do with this technology that provides compelling motivations for both buyers and sellers to incorporate our tech into their digital existence.
                        </p>
                        <p>
                          The three things you need to come to understand and accept about our technology are what it does, the scope of this undertaking and what we will do with it.
                        </p>
                        <p>
                          <strong>1. </strong>What it does is provide superior security and privacy to the digital transmission process without relying on encryption by rendering most data useless.
                        </p>
                        <p>
                          <strong>2. </strong>The scope will effect most of the digital communications, transaction and data storage capabilities that everyone uses today much like the impact of the analog to digital transition discussed earlier except you need to add all the new digital channels of communicating into the assessment to appreciate the scope completely.
                        </p>
                        <p>
                          <strong>3. </strong>What we will do with it involves the creation of our digital platform marketplace that will enable us to change how marketing is done with new marketing dynamics between buyers and sellers. We will improve the marketing efficiency with this new cooperative relationship, cut traditional marketing expenditures by 50% enabling them both to share in the savings creating a price advantage to sellers enrolled with our platform over their un-enrolled competition. Providing pipeline businesses with access to our digital platform marketplace will allow them to deploy their own digital platform marketing strategies much like Uber, Google, and Amazons have done with their own digital platforms, but we will be providing much larger economies of scale far more rapidly.
                        </p>
                      </div>
                    </div>
                </div>
            </div>
          </div>
        </section>
        <section class="section bg-theme tc-light">
          <div class="container">
            <div class="nk-block nk-block-features-s2">
                <div class="row align-items-center gutter-vr-30px">
                    <div class="col-md-6">
                      <div class="nk-block-text">
                        <h6 class="title title-xs title-s1 tc-primary">The buyer savings and seller price advantages</h6>
                        <p>
                          The buyer savings and seller price advantages sound like a compelling reasons for both parties to adopt our tech, but it creates a potential problem for these pipeline enterprises with their traditional limitation to scale when compare to a pure digital platform business. Our solution to their pipeline scale problem provided us with our venue to market our digital platform marketplace.
                        </p>
                        <p>
                          The typical pipeline business that can produce 1,000 units a month would be in big trouble if we marketed them like a rock star with our digital platform marketplace delivering them orders for a 1,000 units a day. Who do you call when you want to address a quantitative problem like this? Not ghost busters! You need the advice of a good accountant and with the direct access to the marketing data our marketplace is going to provide our sellers turning to the professionals that design and maintain information systems is the most logical choice. The amazing thing is marketing has traditionally been an area that accountants and CPA firms have avoided and now in the era of big data and data analytics who else would you want to trust with this vital role?
                        </p>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <img src="./assets/images/custom-hiddin/connect.png" alt="gfx">
                    </div>
                </div>
            </div>
          </div>
        </section>
          <section class="section bg-theme tc-light">
            <div class="container">
              <div class="nk-block nk-block-features-s2">
                  <div class="row align-items-center gutter-vr-30px">
                    <div class="col-md-6">
                      <img class="height450" src="./assets/images/custom-hiddin/shield.png" alt="gfx" >
                    </div>
                      <div class="col-md-6">
                        <div class="nk-block-text">
                          <h6 class="title title-xs title-s1 tc-primary">We have already patented the technology</h6>
                          <p>
                            We have already patented the technology that will make most of this possible and we are building a working model to demonstrate how we would apply it to a typical home automation IOT application. We intend to have a working model up on a website by the end of the first quarter of 2018 that will act as a proof of concept and demonstrate our team’s ability to deliver the solutions to our security and privacy problems that this technology was designed to address.
                          </p>
                        </div>
                      </div>
                  </div>
              </div>
            </div>
        </section>
        <app-footer></app-footer>
      </div>
    </div>
  </div>
</ion-content>
