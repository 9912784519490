<app-header-left-menu [user]="user" [content]="content"></app-header-left-menu>
<ion-content overflow-scroll="true" id="main-content" [scrollEvents]="true">
    <div class="nk-wrap">
        <header class="nk-header page-header is-transparent is-sticky is-shrink is-dark" id="header">
            <!-- Header @s -->
            <div class="header-main fixed-header" #header slot="fixed">
                <div class="header-container container">
                    <div class="header-wrap">
                        <ion-header class="ion-no-border">
                            <ion-toolbar class="header-main">
                                <ion-title slot="start">
                                    <a [routerLink]="['/home']">
                                        <img src="assets/images/hiddin-logo.png" />
                                    </a>
                                </ion-title>
                                <div class="header-navbar header-navbar-s3" slot="end">
                                    <nav class="header-menu justify-content-between" id="example-menu-04">
                                        <ul class="menu-desktop menu-s2 animated fadeInDown" data-animate="fadeInDown" data-delay=".75" style="visibility: visible; animation-delay: 0.75s; margin-bottom: 0;">
                                            <li class="menu-item"><a class="menu-link nav-link" [routerLink]="['/home']">Home</a></li>
                                            <li class="menu-item" *ngIf="LoggedIn"><a class="menu-link nav-link" [routerLink]="['/dashboard']">Dashboard</a></li>
                                            <li class="menu-item"><a class="menu-link nav-link" [routerLink]="['/know-more']">About Us</a>
                                            </li>
                                            <li class="menu-item"><a class="menu-link nav-link" routerLink="/our-products">Our Products</a>
                                            </li>
                                            <li class="menu-item"><a class="menu-link nav-link" routerLink="/contact">Contact</a></li>
                                        </ul>
                                        <ul class="menu-btns animated fadeInDown" data-animate="fadeInDown" data-delay=".85" style="visibility: visible; animation-delay: 0.85s;" *ngIf="!LoggedIn">
                                            <li>
                                                <button [routerLink]="['/basic-reg']" class="btn btn-rg btn-auto btn-outline btn-grad on-bg-theme-dark-alt"><span>Sign Up</span></button>
                                            </li>
                                            <li>
                                                <button [routerLink]="['/login']" class="btn btn-rg btn-auto btn-outline btn-grad on-bg-theme-dark-alt"><span>Login</span></button>
                                            </li>
                                        </ul>
                                        <div class="userDetails" *ngIf="LoggedIn">
                                            <span *ngIf="user" class="tc-light">| &nbsp;{{user.firstName}}</span>
                                            <span *ngIf="!user" class="tc-light">| &nbsp;</span>
                                            <div class="userImage">
                                                <img *ngIf="!user || !user.imageUrl" src="assets/images/team/profile.png" [routerLink]="['/profile']" />
                                                <i class="fa fa-star" *ngIf="isRegCompleted"></i>
                                                <a class="register-btn" href="/complete-reg" *ngIf="!isRegCompleted"><i class="fa fa-exclamation-circle" tooltip="Click Here to complete your registration" placement="bottom" theme="light"></i></a>
                                                <img *ngIf="user && user.imageUrl" src="{{user.imageUrl}}" [routerLink]="['/profile']" />
                                            </div>
                                            <button (click)="logout()" class="btn btn-rg btn-auto btn-outline btn-grad on-bg-theme-dark-alt tc-light">Log Out</button>
                                        </div>
                                    </nav>
                                    <div class="header-navbar-overlay"></div>
                                </div>

                                <ion-buttons slot="end" class="header-nav-toggle">
                                    <ion-menu-button></ion-menu-button>
                                </ion-buttons>
                            </ion-toolbar>
                        </ion-header>
                    </div>
                </div>
            </div>
            <!-- .header-main @e -->
            <!-- Banner @s -->
            <div class="header-banner bg-theme-alt">
                <div class="nk-banner bg-grad-special">
                    <div class="banner banner-fs banner-single bg-grad-special-alt tc-light has-ovm">
                        <canvas id="particles"></canvas>
                        <ngx-particles [id]="canvasId" [options]="particlesOptions" (particlesLoaded)="particlesLoaded($event)">
                        </ngx-particles>
                        <div class="banner-wrap mt-auto">
                            <div class="container">
                                <div class="row align-items-center justify-content-center justify-content-lg-between">
                                    <div class="col-lg-7 order-lg-last animated fadeInUp" data-animate="fadeInUp" data-delay="1.25" style="visibility: visible; animation-delay: 1.25s;">
                                        <div class="banner-gfx banner-gfx-re-s5">
                                            <img src="assets/images/header/gfx-f.png" alt="header">
                                        </div>
                                    </div>
                                    <!-- .col -->
                                    <div class="col-lg-5 text-center text-lg-left">
                                        <div class="banner-caption cpn tc-light">
                                            <div class="cpn-head">
                                                <h1 class="title title-xl-2 title-lg title-semibold animated fadeInUp" data-animate="fadeInUp" data-delay="1.35" style="visibility: visible; animation-delay: 1.35s;">Internet Of Things</h1>
                                            </div>
                                            <div class="cpn-text">
                                                <p class="lead-s2 animated fadeInUp" data-animate="fadeInUp" data-delay="1.45" style="visibility: visible; animation-delay: 1.45s;">
                                                    We hide your identity, your data, how you pay and the identities, data and payment details of those you communicate and transact business with allowing your world to stay safe, stay secure and stay private by staying HIDDIN.
                                                </p>
                                            </div>
                                            <div class="cpn-action">
                                                <ul class="cpn-links animated fadeInUp" data-animate="fadeInUp" data-delay="1.55" style="visibility: visible; animation-delay: 1.55s;">
                                                    <li><button [routerLink]="['/basic-reg']" class="btn btn-md btn-grad"><span>Join
                                Now</span></button></li>
                                                    <li><button (click)="scrollTo('about')" routerLinkActive="router-link-active" class="btn btn-md btn-grad"><span>Know
                                More</span></button></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- .col -->
                                </div>
                                <!-- .row -->
                            </div>
                        </div>
                        <div class="nk-block mt-auto pdb-r">
                            <div class="container">
                                <ul class="partner-list-s3 row align-items-center justify-content-center">
                                    <li class="col-12 col-md-2 text-center text-md-left">
                                        <h6 class="title title-xs tc-primary mb-md-0 mb-4 animated fadeInUp" data-animate="fadeInUp" data-delay="1.65" style="visibility: visible; animation-delay: 1.65s;">Our <br> Partners</h6>
                                    </li>
                                    <li class="col-4 col-md-2 animated fadeInUp" data-animate="fadeInUp" data-delay="1.7" style="visibility: visible; animation-delay: 1.7s;">
                                        <a href="https://www.ayomation.com" target="_blank">
                                            <img src="assets/images/partners/ayomation.png" alt="partner">
                                        </a>
                                    </li>
                                    <li class="col-4 col-md-2 animated fadeInUp" data-animate="fadeInUp" data-delay="1.75" style="visibility: visible; animation-delay: 1.75s;">
                                        <a href="https://www.nomadartx.com" target="_blank">
                                            <img src="assets/images/partners/nomad.png" alt="partner">
                                        </a>
                                    </li>
                                    <li class="col-4 col-md-2 animated fadeInUp" data-animate="fadeInUp" data-delay="1.8" style="visibility: visible; animation-delay: 1.8s;">
                                        <a href="https://www.rewardingsocials.com/" target="_blank">
                                            <img src="assets/images/partners/rewardingSocial.png" alt="partner">
                                        </a>
                                    </li>
                                    <li class="col-4 col-md-2 animated fadeInUp" data-animate="fadeInUp" data-delay="1.85" style="visibility: visible; animation-delay: 1.85s;">
                                        <a href="https://www.vtour.live" target="_blank">
                                            <img src="assets/images/partners/vtour.png" alt="partner">
                                        </a>
                                    </li>
                                    <li class="col-4 col-md-2 animated fadeInUp" data-animate="fadeInUp" data-delay="1.9" style="visibility: visible; animation-delay: 1.9s;">
                                        <a href="https://www.deliberations.live" target="_blank">
                                            <img src="assets/images/partners/deliberations.png" alt="partner">
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <!-- .block @e -->
                        <div class="nk-ovm shape-u shape-contain shape-left-top"></div>
                    </div>
                </div>
                <!-- .nk-banner -->
            </div>
            <!-- .header-banner @e -->
        </header>
        <main class="nk-pages bg-transparent">
            <!-- Start Section -->
            <section class="section section-intro bg-theme-alt tc-light pb-0 ov-h has-ovm">
                <div class="container">
                    <div class="nk-block nk-block-about">
                        <div class="row align-items-center justify-content-center justify-content-lg-between gutter-vr-30px">
                            <div class="col-lg-6 col-sm-9 pdb-m">
                                <div class="nk-block-text text-center text-lg-left">
                                    <h6 class="title title-xs title-xs-s3 tc-primary animated fadeInUp" data-animate="fadeInUp" data-delay=".1" style="visibility: visible; animation-delay: 0.1s;">What Hiddin does!</h6>
                                    <h2 class="title title-semibold animated fadeInUp" data-animate="fadeInUp" data-delay=".2" style="visibility: visible; animation-delay: 0.2s;">We Generate Trust <br /> and Secure Environment
                                    </h2>
                                    <p class="lead-s2 animated fadeInUp" data-animate="fadeInUp" data-delay=".3" style="visibility: visible; animation-delay: 0.3s;">HIDDIN Protects you and your associates as you send, store and create your information living in this digital world.
                                    </p>
                                    <div class="tc-light">
                                        <p class="animated fadeInUp title-s6" data-animate="fadeInUp" data-delay=".3" style="visibility: visible; animation-delay: 0.3s;">HIDDIN permanently hides your identity, how you pay and get paid and the identities and payment details of everyone you communicate and conduct your business.
                                        </p>
                                        <p class="animated fadeInUp title-s6" data-animate="fadeInUp" data-delay=".3" style="visibility: visible; animation-delay: 0.3s;">HIDDIN proves who you are to those you contact then proves who they are to you before any information of value is exchanged with our patent pending reciprocal authentication technology.
                                        </p>
                                        <p class="animated fadeInUp title-s6" data-animate="fadeInUp" data-delay=".3" style="visibility: visible; animation-delay: 0.3s;">HIDDIN reciprocal authentication for remote control applications makes sure you retain control at all times by making time your weapon.
                                        </p>
                                        <p class="animated fadeInUp title-s6" data-animate="fadeInUp" data-delay=".3" style="visibility: visible; animation-delay: 0.3s;">HIDDIN provides quantum resistant protections for everything you transmit deploying our patent pending version of deploying AES 256 symmetric encryption.
                                        </p>
                                        <button [routerLink]="['/more-info']" routerLinkActive="router-link-active" class="btn btn-md btn-grad"><span>Read More</span></button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-5 col-sm-9">
                                <div class="nk-block-video animated fadeInUp" data-animate="fadeInUp" data-delay=".6" style="visibility: visible; animation-delay: 0.6s;">
                                    <img src="assets/images/azure/hiddin-server-work.png" alt="image">
                                    <a href="javascript:;" (click)="isOverlayActive = !isOverlayActive" class="nk-block-video-play video-popup btn-play-wrap btn-play-wrap-s2">
                                        <div class="btn-play btn-play-sm btn-play-s2">
                                            <em class="btn-play-icon"></em>
                                        </div>
                                        <div class="btn-play-text">How it work</div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- .block @e -->
                </div>
                <!-- .conatiner  -->
                <div class="ui-mask-left ui-mask-s1"></div>
            </section>
            <!-- // -->
            <section class="section bg-theme-alt tc-light pb-0 ov-v has-ovm">
                <div class="container">
                    <div class="section-head section-head-s3 wide-auto-sm text-center">
                        <h6 class="title title-xs title-xs-s3 tc-primary animated fadeInUp" data-animate="fadeInUp" data-delay=".1" style="visibility: visible; animation-delay: 0.1s;">Why HIDDIN does it!</h6>
                        <h2 class="title animated fadeInUp" data-animate="fadeInUp" data-delay=".2" style="visibility: visible; animation-delay: 0.2s;">The problem now and at the door step.</h2>
                        <p class="animated fadeInUp" data-animate="fadeInUp" data-delay=".3" style="visibility: visible; animation-delay: 0.3s;">The encryption hiding who we are, how we pay, and how we get paid will fail to protect anything we have ever done when quantum technologies mature.</p>
                        <p class="animated fadeInUp" data-animate="fadeInUp" data-delay=".3" style="visibility: visible; animation-delay: 0.3s;">The encryption protecting how we pay and communicate now will fail to protect anything we have ever done or will do in the post quantum world that is coning.
                        </p>
                    </div>
                    <div class="nk-block">
                        <div class="row justify-content-center gutter-vr-30px">
                            <div class="col-lg-4 col-sm-8">
                                <div class="feature text-center animated fadeInUp" data-animate="fadeInUp" data-delay=".4" style="visibility: visible; animation-delay: 0.4s;">
                                    <div class="feature-icon feature-icon-s8">
                                        <img src="assets/images/gfx/gfx-sm-e.png" alt="feature">
                                    </div>
                                    <div class="feature-text">
                                        <h5 class="title title-md">World Wide Communications</h5>
                                        <p>We will extend de-identification to every major communication channel and offer these permanently secure services through out the world.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-8">
                                <div class="feature text-center animated fadeInUp" data-animate="fadeInUp" data-delay=".5" style="visibility: visible; animation-delay: 0.5s;">
                                    <div class="feature-icon feature-icon-s8">
                                        <img src="assets/images/gfx/gfx-sm-f.png" alt="feature">
                                    </div>
                                    <div class="feature-text">
                                        <h5 class="title title-md">Secure Transactions</h5>
                                        <p>We will extend de-identification to every major transaction processing channel and offer these permanently secure financial services through out the world.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-8">
                                <div class="feature text-center animated fadeInUp" data-animate="fadeInUp" data-delay=".6" style="visibility: visible; animation-delay: 0.6s;">
                                    <div class="feature-icon feature-icon-s8">
                                        <img src="assets/images/gfx/gfx-sm-g.png" alt="feature">
                                    </div>
                                    <div class="feature-text">
                                        <h5 class="title title-md">De-identified Data Cloud</h5>
                                        <p>The de-identified data created as the result of all these communications and transactions and stored in our cloud is practically useless to identity thieves and hackers. HIDDIN's ability to reunite our participants
                                            de-identified marketing data with this unique data set creates a valuable comprehensive research tool unlike anything that has ever existed.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="ui-mask-right ui-mask-s2"></div> -->
            </section>
            <!-- // -->
            <section class="section section-tokensale bg-grad-vr-alt-to-theme tc-light ov-v has-ovm" id="tokens">
                <div class="container">
                    <!-- Block @s -->
                    <div class="nk-block nk-block-token">
                        <div class="section-head section-head-s3 wide-auto-sm text-center">
                            <h6 class="title title-xs title-xs-s3 tc-primary animated fadeInUp" data-animate="fadeInUp" data-delay=".1" style="visibility: visible; animation-delay: 0.1s;">What is HIDDIN?</h6>
                            <h2 class="title animated fadeInUp" data-animate="fadeInUp" data-delay=".2" style="visibility: visible; animation-delay: 0.2s;">What We Are</h2>
                            <p class="animated fadeInUp" data-animate="fadeInUp" data-delay=".3" style="visibility: visible; animation-delay: 0.3s;">We hide your identity, your data, how you pay and the identities, data and payment details of those you communicate and transact business with allowing your world to stay safe, stay secure and stay private by staying HIDDIN.</p>
                        </div>
                        <div class="row align-items-center justify-content-between gutter-vr-30px">
                            <div class="row gutter-vr-30px">
                                <div class="col-sm-6 transform-scale">
                                    <div class="animated fadeInUp glass" data-animate="fadeInUp" data-delay=".4" style="visibility: visible; animation-delay: 0.4s;" [routerLink]="['/solutions']">
                                        <div class="image">
                                            <img src="assets/images/solutions.png" />
                                        </div>
                                        <div class="content-head">
                                            <h6 class="title title-xs-s2">Solutions</h6>
                                            <p>Building a better marketplace</p>
                                        </div>
                                    </div>
                                </div>
                                <!-- .col  -->
                                <div class="col-sm-6 transform-scale">
                                    <div class="animated fadeInUp glass" data-animate="fadeInUp" data-delay=".4" style="visibility: visible; animation-delay: 0.4s;" [routerLink]="['/platform-vs-pipeline']">
                                        <div class="image">
                                            <img src="assets/images/solutions.png" />
                                        </div>
                                        <div class="content-head">
                                            <h6 class="title title-xs-s2">Platform Versus Pipeline</h6>
                                            <p>The traditional pipeline business model involves combining raw material</p>
                                        </div>
                                    </div>
                                </div>
                                <!-- .col  -->
                                <div class="col-sm-6 transform-scale">
                                    <div class="animated fadeInUp glass" data-animate="fadeInUp" data-delay=".4" style="visibility: visible; animation-delay: 0.4s;" [routerLink]="['/cpa-associates']">
                                        <div class="image">
                                            <img src="assets/images/solutions.png" />
                                        </div>
                                        <div class="content-head">
                                            <h6 class="title title-xs-s2">CPA Associates</h6>
                                            <p>Today's encryption will not protect us from quantum computers</p>
                                        </div>
                                    </div>
                                </div>
                                <!-- .col  -->
                                <div class="col-sm-6 transform-scale">
                                    <div class="animated fadeInUp glass" data-animate="fadeInUp" data-delay=".4" style="visibility: visible; animation-delay: 0.4s;" [routerLink]="['/platform-dynamics']">
                                        <div class="image">
                                            <img src="assets/images/solutions.png" />
                                        </div>
                                        <div class="content-head">
                                            <h6 class="title title-xs-s2">Platform Dynamics</h6>
                                            <p>Dynamics of the HIDDIN Marketplace</p>
                                        </div>
                                    </div>
                                </div>
                                <!-- .col  -->
                                <div class="col-sm-6 transform-scale">
                                    <div class="animated fadeInUp glass" data-animate="fadeInUp" data-delay=".4" style="visibility: visible; animation-delay: 0.4s;" [routerLink]="['/our-products']">
                                        <div class="image">
                                            <img src="assets/images/solutions.png" />
                                        </div>
                                        <div class="content-head">
                                            <h6 class="title title-xs-s2">Our Products</h6>
                                            <p>"Data is eating the world" and "Data is the new currency"</p>
                                        </div>
                                    </div>
                                </div>
                                <!-- .col  -->
                                <div class="col-sm-6 transform-scale">
                                    <div class="animated fadeInUp glass" data-animate="fadeInUp" data-delay=".4" style="visibility: visible; animation-delay: 0.4s;" [routerLink]="['/marketing-economies']">
                                        <div class="image">
                                            <img src="assets/images/solutions.png" />
                                        </div>
                                        <div class="content-head">
                                            <h6 class="title title-xs-s2">Marketing Economies</h6>
                                            <p>Lone Wolf Marketing versus The HIDDIN Marketplace</p>
                                        </div>
                                    </div>
                                </div>
                                <!-- .col  -->
                            </div>
                            <!-- .row  -->
                        </div>
                    </div>
                </div>
                <div class="ui-mask-left ui-mask-s3"></div>
            </section>
            <!-- // -->
            <section class="section section-tokensale bg-grad-vr-alt-to-theme tc-light pb-0 ov-v has-ovm" id="about">
                <div class="container">
                    <!-- Block @s -->
                    <div class="nk-block nk-block-token">
                        <div class="section-head section-head-s3 wide-auto-sm text-center">
                            <h6 class="title title-xs title-xs-s3 tc-primary animated fadeInUp" data-animate="fadeInUp" data-delay=".1" style="visibility: visible; animation-delay: 0.1s;">Why HIDDIN does it!</h6>
                            <h2 class="title animated fadeInUp" data-animate="fadeInUp" data-delay=".2" style="visibility: visible; animation-delay: 0.2s;">The Problem</h2>
                            <p class="animated fadeInUp" data-animate="fadeInUp" data-delay=".3" style="visibility: visible; animation-delay: 0.3s;">The asymmetric encryption (AE) that protects your transactions, communications and the resulting stored data will fail you when quantum computers mature in the next five to ten years.</p>
                            <p class="animated fadeInUp" data-animate="fadeInUp" data-delay=".3" style="visibility: visible; animation-delay: 0.3s;">All the asymmetrically encrypted data from a lifetime of business and government interactions is at risk from this quantum decryption event. Any stores of your information relying on symmetric encryption and hashes at or below
                                128 characters are also vulnerable.</p>
                        </div>
                        <div class="row align-items-center justify-content-between gutter-vr-30px">
                            <div class="col-lg-12">
                                <div class="row gutter-vr-30px">
                                    <div class="col-sm-6">
                                        <div class="stage-info animated fadeInUp" data-animate="fadeInUp" data-delay=".4" style="visibility: visible; animation-delay: 0.4s;">
                                            <h6 class="title title-s6 title-xs-s2">What is the US Government Response?</h6>
                                            <p>The National Institute for Standards and Technology (NIST) is evaluating new forms of quantum resistant encryption to provide a temporary fix for this decryption problem. They could present several options for
                                                us to choose from by the first quarter of 2022. The last decryption event of this kind was in the 70's and it took almost 20 years to upgrade everything. We have a lot more data now, less time to get it
                                                done, and the costs of being too late are not acceptable.</p>
                                        </div>
                                    </div>
                                    <!-- .col  -->
                                    <div class="col-sm-6">
                                        <div class="stage-info animated fadeInUp" data-animate="fadeInUp" data-delay=".5" style="visibility: visible; animation-delay: 0.5s;">
                                            <h6 class="title title-s6 title-xs-s2">The Government's Encryption Strategy</h6>
                                            <p>The encryption strategy works by hiding everything sent and stored using complex math until decryption technologies catch up simplifying that math revealing everything once hid. All forms of encryption are temporary
                                                and that can mean 5 months, 5 years or 50 years it is like any math problem. It is unsolved until the moment it is solved.</p>
                                        </div>
                                    </div>
                                    <!-- .col  -->
                                    <div class="col-sm-6">
                                        <div class="stage-info animated fadeInUp" data-animate="fadeInUp" data-delay=".6" style="visibility: visible; animation-delay: 0.6s;">
                                            <h6 class="title title-s6 title-xs-s2">The HIDDIN NIST cost / timing differences</h6>
                                            <p>The actual costs in terms of time and resources to update our digital infrastructure with either HIDDIN or any of the NIST alternatives are not as yet known. What we do know is that HIDDIN holds the promise
                                                of benefits far greater than any implementation costs and we have no reason to expect benefits of a similar magnitude if any from implementation of the NIST alternatives.
                                            </p>
                                        </div>
                                    </div>
                                    <!-- .col  -->
                                    <div class="col-sm-6">
                                        <div class="stage-info animated fadeInUp" data-animate="fadeInUp" data-delay=".7" style="visibility: visible; animation-delay: 0.7s;">
                                            <h6 class="title title-s6 title-xs-s2">The HIDDIN Benefits</h6>
                                            <p>Removing identity and payment details from the data we send and store renders most of what is sent useless to would be identity thieves and hackers. It also kills the value for marketing and research purposes.
                                                Using our enrollment process to collect detailed marketing information from everyone into a de-identified data format allows our tech to reunite a persons marketing profile with their communications and
                                                transactions without including or exposing any of their identity or contact information. The resulting research capabilities are unlike anything that has ever existed. A near real time research tool able
                                                to conduct queries that encompasses all industries that preserve the anonymity of all participants.</p>
                                        </div>
                                    </div>
                                    <!-- .col  -->
                                    <div class="col-sm-6">
                                        <div class="stage-info animated fadeInUp" data-animate="fadeInUp" data-delay=".8" style="visibility: visible; animation-delay: 0.8s;">
                                            <h6 class="title title-s6 title-xs-s2">Authentication with Asymmetric Encryption</h6>
                                            <p>When Sue contacts Bob how does she know it is Bob she is contacting? When Bob gets contacted by Sue how does Bob know it is Sue that is contacting him? The asymmetric encryption we use today works with a private
                                                key that the person to receive contacts keeps to themselves and a public key they share with the world. Everyone just needs these two keys which is so much more efficient than the symmetric encryption explained
                                                earlier. Bob shares his public key so Sue can encrypt and send a message to BOB who is the only one with the private key needed to decrypt Sues message. If Sue hashed her message before encrypting it and
                                                included that hash value in the message she sent, Bob could open her message, use his private key to decrypt it, hash the message himself, and match it to the hash Sue sent so Bob can be confident that the
                                                message as sent has not been altered, but that does not prove that it was Sue that sent the message.</p>
                                        </div>
                                    </div>
                                    <!-- .col  -->
                                    <div class="col-sm-6">
                                        <div class="stage-info animated fadeInUp" data-animate="fadeInUp" data-delay=".9" style="visibility: visible; animation-delay: 0.9s;">
                                            <h6 class="title title-s6 title-xs-s2">Reciprocal Authentication with HIDDIN</h6>
                                            <p>HIDDIN provides a permanent fix for this problem now, with our reciprocal authentication technology. Sue and Bob enroll with our services and when Sue wants to contact Bob she signs into our service on one of
                                                her enrolled devices providing her password and credentials to prove who she is. The sign in process proves mathematically the identity of the enrolled device she is using to our server and our server proves
                                                mathematically the servers identity to Sues enrolled device. Sue is then presented with her communication console where she can check any of her messages, missed calls, missed video contacts, or access
                                                one of her directories where Bob is listed to select him to make contact.</p>
                                        </div>
                                    </div>
                                    <!-- .col  -->
                                </div>
                            </div>
                        </div>
                        <div class="row align-items-center justify-content-between gutter-vr-30px">
                            <div class="col-lg-6">
                                <div class="row gutter-vr-30px">
                                    <div class="col-sm-12">
                                        <div class="stage-info animated fadeInUp" data-animate="fadeInUp" data-delay=".8" style="visibility: visible; animation-delay: 0.8s;">
                                            <h6 class="title title-s6 title-xs-s2">Post Quantum Remote Control</h6>
                                            <p>The use of asymmetric encryption to remotely control drones, spacecraft, automated cars, and any of our critical infrastructure IOT devices involved in the control of our electric grid, aqueducts, gas & oil
                                                pipelines, manufacturing processing plants and such will be useless in a post quantum world. Given the power, WiFi and CPU limitations of most IOT devices currently deployed their ability to adapt to the
                                                temporary protections that the NIST alternatives might offer is questionable. The answer to that question will determine how many of these IOT devices in the field will need to be replaced for us to retain
                                                remote control over our digital infrastructure. </p>
                                        </div>
                                    </div>
                                    <!-- .col  -->
                                </div>
                                <!-- .row  -->
                            </div>
                            <div class="col-lg-5">
                                <div class="token-status token-status-s5 bg-grad no-bd round tc-light animated fadeInUp" data-animate="fadeInUp" data-delay="1" style="visibility: visible; animation-delay: 1s;">
                                    <div class="token-box token-box-s3">
                                        <h4 class="title title-sm">Registration Already Started</h4>
                                        <div class="countdown-s3 countdown-s4 countdown" data-date="2020/03/15"></div>
                                    </div>
                                    <div class="token-action">
                                        <a class="btn btn-md btn-grad btn-grad-alternet btn-round" href="javascript:" [routerLink]="['/basic-reg']">JOIN NOW</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="nk-block">
                        <div class="row gutter-vr-40px justify-content-center">
                            <div class="col-lg-6 col-mb-10">
                                <div class="single-chart text-center">
                                    <h3 class="title title-lg pdb-l animated fadeInUp" data-animate="fadeInUp" data-delay=".1" style="visibility: visible; animation-delay: 0.1s;">Key Security Features</h3>
                                    <div class="chart animated fadeInUp" data-animate="fadeInUp" data-delay=".2" style="visibility: visible; animation-delay: 0.2s;">
                                        <!-- <img src="assets/images/azure/chart-k-dark.png" alt="chart"> -->
                                        <img src="assets/images/azure/chart-1.png" alt="chart">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-mb-10">
                                <div class="single-chart text-center">
                                    <h3 class="title title-lg pdb-l animated fadeInUp" data-animate="fadeInUp" data-delay=".3" style="visibility: visible; animation-delay: 0.3s;">Sale Proceed Allocation</h3>
                                    <div class="chart animated fadeInUp" data-animate="fadeInUp" data-delay=".4" style="visibility: visible; animation-delay: 0.4s;">
                                        <!-- <img src="assets/images/azure/chart-l-dark.png" alt="chart"> -->
                                        <img src="assets/images/azure/chart-2.png" alt="chart">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- .block @e -->
                </div>
                <!-- .section-tokensale -->
                <div class="ui-mask-left ui-mask-s3"></div>
            </section>
            <section class="section bg-theme tc-light pb-0 ov-h" id="roadmap" style="min-height: 600px;">
                <div class="container">
                    <div class="section-head section-head-s3 wide-auto-sm text-center">
                        <h6 class="title title-xs title-xs-s3 tc-primary animated fadeInUp" data-animate="fadeInUp" data-delay=".1" style="visibility: visible; animation-delay: 0.1s;">Roadmap</h6>
                        <h2 class="title animated fadeInUp" data-animate="fadeInUp" data-delay=".2" style="visibility: visible; animation-delay: 0.2s;">The Timeline</h2>
                        <p class="animated fadeInUp" data-animate="fadeInUp" data-delay=".3" style="visibility: visible; animation-delay: 0.3s;">With help from our teams, contributors and investors these are the milestones we are looking forward to achieve.</p>
                    </div>
                    <!-- Block @s -->
                    <div class="nk-block">
                        <div class="row justify-content-center">
                            <div class="col">
                                <div class="roadmap-carousel-container-s2 text-center animated fadeInUp" data-animate="fadeInUp" data-delay=".4" style="visibility: visible; animation-delay: 0.4s;">
                                    <div class="has-carousel roadmap-carousel roadmap-carousel-s2 roadmap-carousel-nav-s2 owl-carousel owl-loaded owl-drag">
                                        <div class="owl-stage-outer" #widgetsContent>
                                            <div class="owl-stage" style="transform: translate3d(0px, 0px, 0px); transition: all 0s ease 0s; width: 1696px;">
                                                <div class="owl-item active" style="width: 182px; margin-right: 30px;">
                                                    <div class="roadmap roadmap-s6 roadmap-s6-3-2 roadmap-done">
                                                        <div class="roadmap-head roadmap-head-s6"><span class="roadmap-time roadmap-time-s6">March
                                2021</span></div>
                                                        <p>Progress to Date and Availability</p>
                                                    </div>
                                                </div>
                                                <div class="owl-item active" style="width: 182px; margin-right: 30px;">
                                                    <div class="roadmap roadmap-s6 roadmap-s6-2-1 roadmap-done">
                                                        <div class="roadmap-head roadmap-head-s6"><span class="roadmap-time roadmap-time-s6">April
                                2021</span></div>
                                                        <p>Doc Project</p>
                                                    </div>
                                                </div>
                                                <div class="owl-item active" style="width: 182px; margin-right: 30px;">
                                                    <div class="roadmap roadmap-s6 roadmap-s6-1-2">
                                                        <div class="roadmap-head roadmap-head-s6"><span class="roadmap-time roadmap-time-s6">October
                                2021</span></div>
                                                        <p>IOT Project</p>
                                                    </div>
                                                </div>
                                                <div class="owl-item active" style="width: 182px; margin-right: 30px;">
                                                    <div class="roadmap roadmap-s6 roadmap-s6-2-3">
                                                        <div class="roadmap-head roadmap-head-s6"><span class="roadmap-time roadmap-time-s6">January
                                2022</span></div>
                                                        <p>COVID Scheduling Project</p>
                                                    </div>
                                                </div>
                                                <div class="owl-item active" style="width: 182px; margin-right: 30px;">
                                                    <div class="roadmap roadmap-s6 roadmap-s6-3-1">
                                                        <div class="roadmap-head roadmap-head-s6"><span class="roadmap-time roadmap-time-s6">June
                                2022</span></div>
                                                        <p>Hiddin</p>
                                                    </div>
                                                </div>
                                                <div class="owl-item" style="width: 182px; margin-right: 30px;">
                                                    <div class="roadmap roadmap-s6 roadmap-s6-1-3">
                                                        <div class="roadmap-head roadmap-head-s6"><span class="roadmap-time roadmap-time-s6">December 2019</span></div>
                                                        <p>The firest product ready to goes live to all business</p>
                                                    </div>
                                                </div>
                                                <div class="owl-item" style="width: 182px; margin-right: 30px;">
                                                    <div class="roadmap roadmap-s6 roadmap-s6-3-2">
                                                        <div class="roadmap-head roadmap-head-s6"><span class="roadmap-time roadmap-time-s6">MarchQ2
                                2019</span></div>
                                                        <p>Start of the Hiddin Crypto Platform Development.</p>
                                                    </div>
                                                </div>
                                                <div class="owl-item" style="width: 182px; margin-right: 30px;">
                                                    <div class="roadmap roadmap-s6 roadmap-s6-2-1">
                                                        <div class="roadmap-head roadmap-head-s6"><span class="roadmap-time roadmap-time-s6">April
                                2019</span></div>
                                                        <p>Start of the Preparation for the Hiddin Token Sale</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="owl-nav">
                                            <button type="button" role="presentation" (click)="scrollLeft()" class="owl-prev"></button>
                                            <button type="button" role="presentation" (click)="scrollRight()" class="owl-next"></button>
                                        </div>
                                        <div class="owl-dots disabled"></div>
                                    </div>
                                </div>
                            </div>
                            <!-- .col -->
                        </div>
                        <!-- .row -->
                    </div>
                    <!-- .block @e -->
                </div>
            </section>
            <!-- // -->
            <section class="section bg-theme-dark-alt tc-light pb-0 ov-h has-ovm" id="our-products">
                <div class="container">
                    <div class="section-head section-head-s3 wide-auto-sm text-center">
                        <h6 class="title title-xs title-xs-s3 tc-primary animated fadeInUp" data-animate="fadeInUp" data-delay=".1" style="visibility: visible; animation-delay: 0.1s;">Solutions</h6>
                        <h2 class="title animated fadeInUp" data-animate="fadeInUp" data-delay=".2" style="visibility: visible; animation-delay: 0.2s;">Our Solutions</h2>
                        <p class="animated fadeInUp" data-animate="fadeInUp" data-delay=".3" style="visibility: visible; animation-delay: 0.3s;">Replacing identity and payment details with a one time used token permanently protects who we are, how we pay, and how we get paid immediately and in a quantum future.
                        </p>
                    </div>
                    <!-- Block @s -->
                    <div class="nk-block nk-block-text-wrap">
                        <div class="row align-items-center justify-content-center gutter-vr-40px">
                            <div>
                                <div class="nk-block-text pdb-l">
                                    <div class="nk-block">
                                        <div class="row justify-content-center gutter-vr-30px">
                                            <div class="col-lg-4 col-sm-8">
                                                <div class="feature text-center animated fadeInUp" data-animate="fadeInUp" data-delay=".4" style="visibility: visible; animation-delay: 0.4s;">
                                                    <div class="feature-icon feature-icon-s8">
                                                        <img src="assets/images/gfx/mail_server.png" alt="feature">
                                                    </div>
                                                    <div class="feature-text">
                                                        <h5 class="title title-md">Mail Server</h5>
                                                        <p>We offer own Mail Server for managing the E-Mails in the process</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-sm-8">
                                                <div class="feature text-center animated fadeInUp" data-animate="fadeInUp" data-delay=".5" style="visibility: visible; animation-delay: 0.5s;">
                                                    <div class="feature-icon feature-icon-s8">
                                                        <img src="assets/images/gfx/encrption.png" alt="feature">
                                                    </div>
                                                    <div class="feature-text">
                                                        <h5 class="title title-md">End-To-End Encryption</h5>
                                                        <p>We offer End-To-End Encryption of the customer data</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-sm-8">
                                                <div class="feature text-center animated fadeInUp" data-animate="fadeInUp" data-delay=".6" style="visibility: visible; animation-delay: 0.6s;">
                                                    <div class="feature-icon feature-icon-s8">
                                                        <img src="assets/images/gfx/directory_service.png" alt="feature">
                                                    </div>
                                                    <div class="feature-text">
                                                        <h5 class="title title-md">Directory Services</h5>
                                                        <p>We allow user to find the person that related in any way</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-center">
                                        <ul class="btn-grp justify-content-center">
                                            <li class="animated fadeInUp" data-animate="fadeInUp" data-delay=".75" style="visibility: visible; animation-delay: 0.75s;"><a href="#" class="btn btn-grad btn-md">Get
                          the app now</a></li>
                                            <li class="animated fadeInUp" data-animate="fadeInUp" data-delay=".85" style="visibility: visible; animation-delay: 0.85s;">
                                                <ul class="btn-grp gutter-15px tc-secondary">
                                                    <li><a href="#"><em class="fab fa-apple"></em></a></li>
                                                    <li><a href="#"><em class="fab fa-android"></em></a></li>
                                                    <li><a href="#"><em class="fab fa-windows"></em></a></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- .row -->
                    </div>
                    <!-- .block @e -->
                </div>
                <div class="ui-mask-right ui-mask-s7"></div>
            </section>
            <!-- // -->
            <section class="section bg-theme-alt tc-light pb-0 ov-h has-ovm" id="team">
                <div class="container">
                    <div class="section-head section-head-s3 wide-auto-sm text-center">
                        <h6 class="title title-xs title-xs-s3 tc-primary animated fadeInUp" data-animate="fadeInUp" data-delay=".1" style="visibility: visible; animation-delay: 0.1s;">Our Team</h6>
                        <h2 class="title animated fadeInUp" data-animate="fadeInUp" data-delay=".2" style="visibility: visible; animation-delay: 0.2s;">The Engineering Team</h2>
                        <p class="animated fadeInUp" data-animate="fadeInUp" data-delay=".3" style="visibility: visible; animation-delay: 0.3s;">James O'Brien CEO, principal inventor, former CPA, designed, and has managed EMR software for a Tampa Medical Clinic since 2002. Yogendra Singh managing his crew of software engineers earned his place as our technical cofounder
                            by building the first proof of concept of US Patent 8799022 and they are building and testing our current software projects including an overhaul of our EMR software..</p>
                    </div>
                    <!-- Section Head @s -->
                    <!-- Block @s -->
                    <div class="nk-block nk-block-team-list team-list">
                        <div class="row justify-content-center">
                            <div class="col-lg-3 col-mb-6">
                                <div class="team team-s5 animated fadeInUp" data-animate="fadeInUp" data-delay=".4" style="visibility: visible; animation-delay: 0.4s;">
                                    <div class="team-photo team-photo-s1">
                                        <img src="assets/images/team/anurag-mishra.jpg" alt="team" class="no-bdrs">
                                    </div>
                                    <h5 class="team-name title title-md">Anurag <br>Mishra</h5>
                                    <span class="team-position">Full Stack Expert</span>
                                    <ul class="team-social team-social-vr team-social-s2">
                                        <li><a href="#"><em class="fab fa-facebook-f"></em></a></li>
                                        <li><a href="#"><em class="fab fa-twitter"></em></a></li>
                                        <li><a href="#"><em class="fab fa-linkedin-in"></em></a></li>
                                    </ul>
                                </div>
                            </div>
                            <!-- .col -->
                            <div class="col-lg-3 col-mb-6">
                                <div class="team team-s5 animated fadeInUp" data-animate="fadeInUp" data-delay=".4" style="visibility: visible; animation-delay: 0.4s;">
                                    <div class="team-photo team-photo-s1">
                                        <img src="assets/images/team/parul_agrawal.jpeg" alt="team" class="no-bdrs">
                                    </div>
                                    <h5 class="team-name title title-md">Parul <br>Agrawal</h5>
                                    <span class="team-position">UI Developer</span>
                                    <ul class="team-social team-social-vr team-social-s2">
                                        <li><a href="#"><em class="fab fa-facebook-f"></em></a></li>
                                        <li><a href="#"><em class="fab fa-twitter"></em></a></li>
                                        <li><a href="#"><em class="fab fa-linkedin-in"></em></a></li>
                                    </ul>
                                </div>
                            </div>
                            <!-- .col -->
                            <div class="col-lg-3 col-mb-6">
                                <div class="team team-s5 animated fadeInUp" data-animate="fadeInUp" data-delay=".5" style="visibility: visible; animation-delay: 0.5s;">
                                    <div class="team-photo team-photo-s1">
                                        <img src="assets/images/team/umang_sachan.jpg" alt="team" class="no-bdrs">
                                    </div>
                                    <h5 class="team-name title title-md">Umang <br>Sachan</h5>
                                    <span class="team-position">PT Manager</span>
                                    <ul class="team-social team-social-vr team-social-s2">
                                        <li><a href="#"><em class="fab fa-facebook-f"></em></a></li>
                                        <li><a href="#"><em class="fab fa-twitter"></em></a></li>
                                        <li><a href="#"><em class="fab fa-linkedin-in"></em></a></li>
                                    </ul>
                                </div>
                            </div>
                            <!-- .col -->
                            <div class="col-lg-3 col-mb-6">
                                <div class="team team-s5 animated fadeInUp" data-animate="fadeInUp" data-delay=".6" style="visibility: visible; animation-delay: 0.6s;">
                                    <div class="team-photo team-photo-s1">
                                        <img src="assets/images/team/mukesh_kumar.jpg" alt="team" class="no-bdrs">
                                    </div>
                                    <h5 class="team-name title title-md">Mukesh <br>Kumar</h5>
                                    <span class="team-position">Full Stack Expert</span>
                                    <ul class="team-social team-social-vr team-social-s2">
                                        <li><a href="#"><em class="fab fa-facebook-f"></em></a></li>
                                        <li><a href="#"><em class="fab fa-twitter"></em></a></li>
                                        <li><a href="#"><em class="fab fa-linkedin-in"></em></a></li>
                                    </ul>
                                </div>
                            </div>
                            <!-- .col -->
                            <div class="col-lg-3 col-mb-6">
                                <div class="team team-s5 animated fadeInUp" data-animate="fadeInUp" data-delay=".6" style="visibility: visible; animation-delay: 0.6s;">
                                    <div class="team-photo team-photo-s1">
                                        <img src="assets/images/team/virendra.jpeg" alt="team" class="no-bdrs">
                                    </div>
                                    <h5 class="team-name title title-md">Virendra <br>Singh</h5>
                                    <span class="team-position">Comm Stack Expert</span>
                                    <ul class="team-social team-social-vr team-social-s2">
                                        <li><a href="#"><em class="fab fa-facebook-f"></em></a></li>
                                        <li><a href="#"><em class="fab fa-twitter"></em></a></li>
                                        <li><a href="#"><em class="fab fa-linkedin-in"></em></a></li>
                                    </ul>
                                </div>
                            </div>
                            <!-- .col -->
                            <div class="col-lg-3 col-mb-6">
                                <div class="team team-s5 animated fadeInUp" data-animate="fadeInUp" data-delay=".6" style="visibility: visible; animation-delay: 0.6s;">
                                    <div class="team-photo team-photo-s1">
                                        <img src="assets/images/team/nitin_arora.jpg" alt="team" class="no-bdrs">
                                    </div>
                                    <h5 class="team-name title title-md">Nitin <br>Arora</h5>
                                    <span class="team-position">QA - Automation</span>
                                    <ul class="team-social team-social-vr team-social-s2">
                                        <li><a href="#"><em class="fab fa-facebook-f"></em></a></li>
                                        <li><a href="#"><em class="fab fa-twitter"></em></a></li>
                                        <li><a href="#"><em class="fab fa-linkedin-in"></em></a></li>
                                    </ul>
                                </div>
                            </div>
                            <!-- .col -->
                            <div class="col-lg-3 col-mb-6">
                                <div class="team team-s5 animated fadeInUp" data-animate="fadeInUp" data-delay=".6" style="visibility: visible; animation-delay: 0.6s;">
                                    <div class="team-photo team-photo-s1">
                                        <img src="assets/images/team/anurag-singh.jpg" alt="team" class="no-bdrs">
                                    </div>
                                    <h5 class="team-name title title-md">Anurag <br>Singh</h5>
                                    <span class="team-position">Data Analytics</span>
                                    <ul class="team-social team-social-vr team-social-s2">
                                        <li><a href="#"><em class="fab fa-facebook-f"></em></a></li>
                                        <li><a href="#"><em class="fab fa-twitter"></em></a></li>
                                        <li><a href="#"><em class="fab fa-linkedin-in"></em></a></li>
                                    </ul>
                                </div>
                            </div>
                            <!-- .col -->
                            <div class="col-lg-3 col-mb-6">
                                <div class="team team-s5 animated fadeInUp" data-animate="fadeInUp" data-delay=".6" style="visibility: visible; animation-delay: 0.6s;">
                                    <div class="team-photo team-photo-s1">
                                        <img src="assets/images/team/kaustab.jpg" alt="team" class="no-bdrs">
                                    </div>
                                    <h5 class="team-name title title-md">Kaustab <br>Kumar</h5>
                                    <span class="team-position">Full Stack Developer</span>
                                    <ul class="team-social team-social-vr team-social-s2">
                                        <li><a href="#"><em class="fab fa-facebook-f"></em></a></li>
                                        <li><a href="#"><em class="fab fa-twitter"></em></a></li>
                                        <li><a href="#"><em class="fab fa-linkedin-in"></em></a></li>
                                    </ul>
                                </div>
                            </div>
                            <!-- .col -->
                            <div class="col-lg-3 col-mb-6">
                                <div class="team team-s5 animated fadeInUp" data-animate="fadeInUp" data-delay=".6" style="visibility: visible; animation-delay: 0.6s;">
                                    <div class="team-photo team-photo-s1">
                                        <img src="assets/images/team/shivani-chauhan.jpeg" alt="team" class="no-bdrs">
                                    </div>
                                    <h5 class="team-name title title-md">Shivani <br>Chauhan</h5>
                                    <span class="team-position">HR Executive</span>
                                    <ul class="team-social team-social-vr team-social-s2">
                                        <li><a href="#"><em class="fab fa-facebook-f"></em></a></li>
                                        <li><a href="#"><em class="fab fa-twitter"></em></a></li>
                                        <li><a href="#"><em class="fab fa-linkedin-in"></em></a></li>
                                    </ul>
                                </div>
                            </div>
                            <!-- .col -->
                            <div class="col-lg-3 col-mb-6">
                                <div class="team team-s5 animated fadeInUp" data-animate="fadeInUp" data-delay=".6" style="visibility: visible; animation-delay: 0.6s;">
                                    <div class="team-photo team-photo-s1">
                                        <img src="assets/images/team/atul-kumar.jpg" alt="team" class="no-bdrs">
                                    </div>
                                    <h5 class="team-name title title-md">Atul <br>Kumar</h5>
                                    <span class="team-position">Sales & Marketing Executive</span>
                                    <ul class="team-social team-social-vr team-social-s2">
                                        <li><a href="#"><em class="fab fa-facebook-f"></em></a></li>
                                        <li><a href="#"><em class="fab fa-twitter"></em></a></li>
                                        <li><a href="#"><em class="fab fa-linkedin-in"></em></a></li>
                                    </ul>
                                </div>
                            </div>
                            <!-- .col -->
                            <div class="col-lg-3 col-mb-6">
                                <div class="team team-s5 animated fadeInUp" data-animate="fadeInUp" data-delay=".7" style="visibility: visible; animation-delay: 0.7s;">
                                    <div class="team-photo team-photo-s1">
                                        <img src="assets/images/team/deeksha-tripathi.jpg" alt="team" class="no-bdrs">
                                    </div>
                                    <h5 class="team-name title title-md">Deeksha <br>Tripathi</h5>
                                    <span class="team-position">Quality Analyst</span>
                                    <ul class="team-social team-social-vr team-social-s2">
                                        <li><a href="#"><em class="fab fa-facebook-f"></em></a></li>
                                        <li><a href="#"><em class="fab fa-twitter"></em></a></li>
                                        <li><a href="#"><em class="fab fa-linkedin-in"></em></a></li>
                                    </ul>
                                </div>
                            </div>
                            <!-- .col -->
                            <div class="col-lg-3 col-mb-6">
                                <div class="team team-s5 animated fadeInUp" data-animate="fadeInUp" data-delay=".7" style="visibility: visible; animation-delay: 0.7s;">
                                    <div class="team-photo team-photo-s1">
                                        <img src="assets/images/team/shivam.jpg" alt="team" class="no-bdrs">
                                    </div>
                                    <h5 class="team-name title title-md">Shivam <br>Tiwari</h5>
                                    <span class="team-position">Software Developer</span>
                                    <ul class="team-social team-social-vr team-social-s2">
                                        <li><a href="#"><em class="fab fa-facebook-f"></em></a></li>
                                        <li><a href="#"><em class="fab fa-twitter"></em></a></li>
                                        <li><a href="#"><em class="fab fa-linkedin-in"></em></a></li>
                                    </ul>
                                </div>
                            </div>
                            <!-- .col -->
                            <div class="col-lg-3 col-mb-6">
                                <div class="team team-s5 animated fadeInUp" data-animate="fadeInUp" data-delay=".7" style="visibility: visible; animation-delay: 0.7s;">
                                    <div class="team-photo team-photo-s1">
                                        <img src="assets/images/team/alka_singh.jpg" alt="team" class="no-bdrs">
                                    </div>
                                    <h5 class="team-name title title-md">Alka <br>Singh</h5>
                                    <span class="team-position">Web Developer</span>
                                    <ul class="team-social team-social-vr team-social-s2">
                                        <li><a href="#"><em class="fab fa-facebook-f"></em></a></li>
                                        <li><a href="#"><em class="fab fa-twitter"></em></a></li>
                                        <li><a href="#"><em class="fab fa-linkedin-in"></em></a></li>
                                    </ul>
                                </div>
                            </div>
                            <!-- .col -->
                        </div>
                        <!-- .row -->
                    </div>
                    <div class="nk-block nk-block-team-list">
                        <div class="section-head section-head-sm text-center">
                            <h2 class="title-lg-2 title-semibold animated fadeInUp" data-animate="fadeInUp" data-delay=".8" style="visibility: visible; animation-delay: 0.8s;">The Advisory board</h2>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-lg-3 col-mb-6">
                                <div class="team team-s5 animated fadeInUp" data-animate="fadeInUp" data-delay=".9" style="visibility: visible; animation-delay: 0.9s;">
                                    <div class="team-photo team-photo-s1">
                                        <img src="assets/images/team/profile.png" alt="team" class="no-bdrs">
                                    </div>
                                    <h5 class="team-name title title-md">Jim <br>Junior 1</h5>
                                    <span class="team-position">Title</span>
                                    <ul class="team-social team-social-vr team-social-s2">
                                        <li><a href="#"><em class="fab fa-facebook-f"></em></a></li>
                                        <li><a href="#"><em class="fab fa-twitter"></em></a></li>
                                        <li><a href="#"><em class="fab fa-linkedin-in"></em></a></li>
                                    </ul>
                                </div>
                            </div>
                            <!-- .col -->
                            <div class="col-lg-3 col-mb-6">
                                <div class="team team-s5 animated fadeInUp" data-animate="fadeInUp" data-delay=".9" style="visibility: visible; animation-delay: 0.9s;">
                                    <div class="team-photo team-photo-s1">
                                        <img src="assets/images/team/profile.png" alt="team" class="no-bdrs">
                                    </div>
                                    <h5 class="team-name title title-md">Jim <br>Junior 2</h5>
                                    <span class="team-position">Title</span>
                                    <ul class="team-social team-social-vr team-social-s2">
                                        <li><a href="#"><em class="fab fa-facebook-f"></em></a></li>
                                        <li><a href="#"><em class="fab fa-twitter"></em></a></li>
                                        <li><a href="#"><em class="fab fa-linkedin-in"></em></a></li>
                                    </ul>
                                </div>
                            </div>
                            <!-- .col -->
                            <div class="col-lg-3 col-mb-6">
                                <div class="team team-s5 animated fadeInUp" data-animate="fadeInUp" data-delay="1" style="visibility: visible; animation-delay: 1s;">
                                    <div class="team-photo team-photo-s1">
                                        <img src="assets/images/team/madhu_singh.jpg" alt="team" class="no-bdrs">
                                    </div>
                                    <h5 class="team-name title title-md">Madhu <br>Singh</h5>
                                    <span class="team-position">Mentor</span>
                                    <ul class="team-social team-social-vr team-social-s2">
                                        <li><a href="#"><em class="fab fa-facebook-f"></em></a></li>
                                        <li><a href="#"><em class="fab fa-twitter"></em></a></li>
                                        <li><a href="#"><em class="fab fa-linkedin-in"></em></a></li>
                                    </ul>
                                </div>
                            </div>
                            <!-- .col -->
                        </div>
                        <!-- .row -->
                    </div>
                    <div class="nk-block nk-block-team-list">
                        <div class="section-head section-head-sm text-center">
                            <h2 class="title-lg-2 title-semibold animated fadeInUp" data-animate="fadeInUp" data-delay=".8" style="visibility: visible; animation-delay: 0.8s;">The Advisory board</h2>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-lg-3 col-mb-6">
                                <div class="team team-s5 animated fadeInUp" data-animate="fadeInUp" data-delay="1" style="visibility: visible; animation-delay: 1s;">
                                    <div class="team-photo team-photo-s1">
                                        <img src="assets/images/team/Jim.jpg" alt="team" class="no-bdrs">
                                    </div>
                                    <h5 class="team-name title title-md">James <br>O'Brien</h5>
                                    <span class="team-position">Founder</span>
                                    <ul class="team-social team-social-vr team-social-s2">
                                        <li><a href="#"><em class="fab fa-facebook-f"></em></a></li>
                                        <li><a href="#"><em class="fab fa-twitter"></em></a></li>
                                        <li><a href="#"><em class="fab fa-linkedin-in"></em></a></li>
                                    </ul>
                                </div>
                            </div>
                            <!-- .col -->
                            <div class="col-lg-3 col-mb-6">
                                <div class="team team-s5 animated fadeInUp" data-animate="fadeInUp" data-delay="1.1" style="visibility: visible; animation-delay: 1.1s;">
                                    <div class="team-photo team-photo-s1">
                                        <img src="assets/images/team/Gisele_Riscile.jpg" alt="team" class="no-bdrs">
                                    </div>
                                    <h5 class="team-name title title-md">Gisele <br>Riscile </h5>
                                    <span class="team-position">Co-Founder</span>
                                    <ul class="team-social team-social-vr team-social-s2">
                                        <li><a href="#"><em class="fab fa-facebook-f"></em></a></li>
                                        <li><a href="#"><em class="fab fa-twitter"></em></a></li>
                                        <li><a href="#"><em class="fab fa-linkedin-in"></em></a></li>
                                    </ul>
                                </div>
                            </div>
                            <!-- .col -->
                            <div class="col-lg-3 col-mb-6">
                                <div class="team team-s5 animated fadeInUp" data-animate="fadeInUp" data-delay=".9" style="visibility: visible; animation-delay: 0.9s;">
                                    <div class="team-photo team-photo-s1">
                                        <img src="assets/images/team/yogendra_singh.jpg" alt="team" class="no-bdrs">
                                    </div>
                                    <h5 class="team-name title title-md">Yogendra <br>Singh</h5>
                                    <span class="team-position">Co-Founder - Technical</span>
                                    <ul class="team-social team-social-vr team-social-s2">
                                        <li><a href="#"><em class="fab fa-facebook-f"></em></a></li>
                                        <li><a href="#"><em class="fab fa-twitter"></em></a></li>
                                        <li><a href="#"><em class="fab fa-linkedin-in"></em></a></li>
                                    </ul>
                                </div>
                            </div>
                            <!-- .col -->
                        </div>
                        <!-- .row -->
                    </div>
                    <!-- .block @e -->
                </div>
                <div class="nk-ovm shape-r"></div>
                <div class="ui-mask-right ui-mask-s4"></div>
            </section>
            <!-- // -->
            <section class="section bg-theme-alt tc-light pb-0 ov-v has-ovm" id="faq">
                <div class="container">
                    <div class="section-head section-head-s3 wide-auto-sm text-center">
                        <h6 class="title title-xs title-xs-s3 tc-primary animated fadeInUp" data-animate="fadeInUp" data-delay=".1" style="visibility: visible; animation-delay: 0.1s;">Faqs</h6>
                        <h2 class="title animated fadeInUp" data-animate="fadeInUp" data-delay=".2" style="visibility: visible; animation-delay: 0.2s;">Frequently Asked Questions</h2>
                        <p class="animated fadeInUp" data-animate="fadeInUp" data-delay=".3" style="visibility: visible; animation-delay: 0.3s;">Below we’ve provided a bit of information about Hiddin, Internet Security and few others. If you have any other questions, please get in touch using the contact form below.</p>
                    </div>
                    <div class="nk-block">
                        <div class="row justify-content-center">
                            <div class="col-xl-8 col-lg-10">
                                <ul class="nav tab-nav tab-nav-s2 tab-nav-center mgb-r animated fadeInUp" data-animate="fadeInUp" data-delay=".4" style="visibility: visible; animation-delay: 0.4s;">
                                    <li><a [class.active]="activeTabName === 'general'" (click)="activeTab('general')">General</a></li>
                                    <li><a [class.active]="activeTabName === 'security'" (click)="activeTab('security')">Internet
                      Security</a></li>
                                    <li><a [class.active]="activeTabName === 'token'" (click)="activeTab('token')">Token</a></li>
                                    <li><a [class.active]="activeTabName === 'client'" (click)="activeTab('client')">Client</a></li>
                                    <li><a [class.active]="activeTabName === 'legal'" (click)="activeTab('legal')">Legal</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-lg-10">
                                <div class="tab-content animated fadeInUp" data-animate="fadeInUp" data-delay=".5" style="visibility: visible; animation-delay: 0.5s;">
                                    <div class="tab-pane fade show" [class.active]="activeTabName === 'general'" *ngIf="activeTabName === 'general'">
                                        <div class="row gutter-vr-50px">
                                            <div class="col-md-12">
                                                <div class="nk-block-text">
                                                    <h5 class="title title-sm">The HIDDIN Strategy</h5>
                                                    <p>
                                                        HIDDIN deploys a different security strategy. We remove your identity and payment details from the data we send and store never putting it at risk by issuing a one time used token instead. HIDDIN offers the only permanent security solution for identity
                                                        and payment details to this decryption problem. Our first patent did not protect any of the other data sent and stored like the encryption it is replacing, but our patent pending tech will.
                                                        <br /><br /> Our patent pending technology addresses the needs for both reciprocal authentication and deploying NIST level quantum resistant symmetric encryption protections to all the data HIDDIN
                                                        sends and stores. Our symmetric keys start at 256 characters well above the NIST initial quantum resistant level and our processes can be easily adapted to grow the keys we use 128 characters each
                                                        time to stay ahead of any post quantum advances in technology and re-encrypting all our past copies of stored data will still be a viable option.
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="nk-block-text">
                                                    <h5 class="title title-sm">How HIDDIN is different from NIST? </h5>
                                                    <p>The difference is HIDDIN permanently secures identity and payment details and NIST can not. HIDDIN provides for reciprocal authentication of the parties involved in our transmission processes and NIST
                                                        does not. HIDDIN generates above NIST level quantum resistant encryption protections that can quickly adapt to today's digital infrastructure and respond to future advances in decryption technologies.
                                                        The NIST alternatives ability to adapt to our current digital infrastructure or respond to future advances in decryption technology is not known at this time.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade show" [class.active]="activeTabName === 'security'" *ngIf="activeTabName === 'security'">
                                        <div class="row gutter-vr-50px">
                                            <div class="col-md-6">
                                                <div class="nk-block-text">
                                                    <h5 class="title title-sm">Which of us ever undertakes laborious?</h5>
                                                    <p>Once Hiddin period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.</p>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="nk-block-text">
                                                    <h5 class="title title-sm">Who do not know how to pursue rationally?</h5>
                                                    <p>Once Hiddin period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.</p>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="nk-block-text">
                                                    <h5 class="title title-sm">Their separate existence is a myth?</h5>
                                                    <p>Once Hiddin period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.</p>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="nk-block-text">
                                                    <h5 class="title title-sm">Pityful a rethoric question ran over her cheek?</h5>
                                                    <p>Once Hiddin period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade show" [class.active]="activeTabName === 'token'" *ngIf="activeTabName === 'token'">
                                        <div class="row gutter-vr-50px">
                                            <div class="col-md-6">
                                                <div class="nk-block-text">
                                                    <h5 class="title title-sm">When she reached the first hills?</h5>
                                                    <p>Once Hiddin period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.</p>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="nk-block-text">
                                                    <h5 class="title title-sm">Big Oxmox advised her not to do?</h5>
                                                    <p>Once Hiddin period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.</p>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="nk-block-text">
                                                    <h5 class="title title-sm">Which roasted parts of sentences fly into your mouth?</h5>
                                                    <p>Once Hiddin period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.</p>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="nk-block-text">
                                                    <h5 class="title title-sm">Vokalia and Consonantia, there live?</h5>
                                                    <p>Once Hiddin period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade show" [class.active]="activeTabName === 'client'" *ngIf="activeTabName === 'client'">
                                        <div class="row gutter-vr-50px">
                                            <div class="col-md-6">
                                                <div class="nk-block-text">
                                                    <h5 class="title title-sm">When she reached the first hills?</h5>
                                                    <p>Once Hiddin period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.</p>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="nk-block-text">
                                                    <h5 class="title title-sm">Big Oxmox advised her not to do?</h5>
                                                    <p>Once Hiddin period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.</p>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="nk-block-text">
                                                    <h5 class="title title-sm">Which roasted parts of sentences fly into your mouth?</h5>
                                                    <p>Once Hiddin period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.</p>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="nk-block-text">
                                                    <h5 class="title title-sm">Vokalia and Consonantia, there live?</h5>
                                                    <p>Once Hiddin period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade show" [class.active]="activeTabName === 'legal'" *ngIf="activeTabName === 'legal'">
                                        <div class="row gutter-vr-50px">
                                            <div class="col-md-6">
                                                <div class="nk-block-text">
                                                    <h5 class="title title-sm">When she reached the first hills?</h5>
                                                    <p>Once Hiddin period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.</p>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="nk-block-text">
                                                    <h5 class="title title-sm">Big Oxmox advised her not to do?</h5>
                                                    <p>Once Hiddin period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.</p>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="nk-block-text">
                                                    <h5 class="title title-sm">Which roasted parts of sentences fly into your mouth?</h5>
                                                    <p>Once Hiddin period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.</p>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="nk-block-text">
                                                    <h5 class="title title-sm">Vokalia and Consonantia, there live?</h5>
                                                    <p>Once Hiddin period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="ui-mask-left ui-mask-s5"></div>
            </section>
            <!-- // -->
            <section class="section section-contact bg-theme-alt tc-light ov-h has-ovm" id="contact">
                <div class="container">
                    <div class="section-head section-head-s3 wide-auto-sm text-center">
                        <h6 class="title title-xs title-xs-s3 tc-primary animated fadeInUp" data-animate="fadeInUp" data-delay=".1" style="visibility: visible; animation-delay: 0.1s;">Contact</h6>
                        <h2 class="title animated fadeInUp" data-animate="fadeInUp" data-delay=".2" style="visibility: visible; animation-delay: 0.2s;">Get In Touch</h2>
                    </div>
                    <!-- Block @s -->
                    <div class="nk-block block-contact">
                        <div class="row justify-content-center gutter-vr-30px">
                            <div class="col-lg-4">
                                <div class="pdt-s">
                                    <div class="d-flex flex-column h-100">
                                        <p class="tc-white animated fadeInUp" data-animate="fadeInUp" data-delay=".3" style="visibility: visible; animation-delay: 0.3s;">Any question? Reach out to us and we’ll get back to you shortly.</p>
                                        <ul class="contact-list contact-list-s3">
                                            <li class="animated fadeInUp" data-animate="fadeInUp" data-delay=".4" style="visibility: visible; animation-delay: 0.4s;">
                                                <em class="contact-icon contact-icon-s3 fas fa-phone"></em>
                                                <div class="contact-text">
                                                    <span>+1(813) 831-6002</span>
                                                </div>
                                            </li>
                                            <li class="animated fadeInUp" data-animate="fadeInUp" data-delay=".5" style="visibility: visible; animation-delay: 0.5s;">
                                                <em class="contact-icon contact-icon-s3 fas fa-envelope"></em>
                                                <div class="contact-text">
                                                    <span>support&#64;hiddin.com</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <!-- .col -->
                            <div class="col-lg-6">
                                <div class="">
                                    <form id="contact-form" class="nk-form-submit" [formGroup]="getInTouchForm">
                                        <div class="field-item animated fadeInUp" data-animate="fadeInUp" data-delay=".7" style="visibility: visible; animation-delay: 0.7s;">
                                            <!-- <input name="contact-name" type="text" class="input-line required" aria-required="true"> -->
                                            <input type="text" name="contact-name" formControlName="name" class="input-line required" aria-required="true" [(ngModel)]="getInTouch.name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" placeholder="Your Name" />
                                            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                                <div *ngIf="f.name.errors.required">Name is required</div>
                                                <div *ngIf="f.name.errors?.maxlength">Name can be max 100 characters long</div>
                                            </div>
                                        </div>
                                        <div class="field-item animated fadeInUp" data-animate="fadeInUp" data-delay=".8" style="visibility: visible; animation-delay: 0.8s;">
                                            <!-- <input name="contact-email" type="email" class="input-line required email" aria-required="true"> -->
                                            <input type="text" name="contact-name" formControlName="email" class="input-line required" aria-required="true" [(ngModel)]="getInTouch.email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" placeholder="Your Email" />
                                            <div *ngIf="(submitted || f.email.touched) && f.email.errors" class="invalid-feedback">
                                                <div *ngIf="f.email.errors.required">Email is required</div>
                                                <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                                                <div *ngIf="f.email.errors.pattern">Email must be a valid email address</div>
                                            </div>
                                        </div>
                                        <div class="field-item animated fadeInUp" data-animate="fadeInUp" data-delay=".9" style="visibility: visible; animation-delay: 0.9s;">
                                            <textarea type="text" name="contact-message" class="input-line required" aria-required="true" formControlName="message" [(ngModel)]="getInTouch.message" [ngClass]="{ 'is-invalid': submitted && f.message.errors }" placeholder="Your Message"></textarea>
                                            <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                                                <div *ngIf="f.message.errors.required">Message is required</div>
                                                <div *ngIf="f.message.errors?.maxlength">Message can be max 500 characters long</div>
                                            </div>
                                        </div>
                                        <input type="text" class="d-none" name="form-anti-honeypot" value="">

                                        <div *ngIf="errorMessage && errorMessage != ''" class="alert alert-danger title title-xs mt-2 mb-2">
                                            {{errorMessage}}
                                        </div>

                                        <div *ngIf="successMessage && successMessage != ''" class="alert alert-success title title-xs mt-2 mb-2">
                                            {{successMessage}}
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-4 animated fadeInUp" data-animate="fadeInUp" data-delay="1" style="visibility: visible; animation-delay: 1s;">
                                                <button type="submit" class="btn btn-md btn-grad" (click)="submitGetInTouch()">Submit</button>
                                            </div>
                                            <div class="col-sm-8">
                                                <div class="form-results"></div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <!-- .col -->
                        </div>
                        <!-- .row -->
                    </div>
                    <!-- .block @e -->
                </div>
                <div class="ui-mask-right ui-mask-s6"></div>
            </section>
            <!-- // -->
            <section class="section section-contact bg-theme-dark tc-light overflow-fix" id="products">
                <div class="container">
                    <!-- Section Head @s -->
                    <div class="section-head text-center wide-auto-sm">
                        <h2 class="title animated fadeInUp" data-animate="fadeInUp" data-delay=".2" style="visibility: visible; animation-delay: 0.2s;">Our Products</h2>
                    </div>
                    <!-- .section-head @e -->
                    <!-- Block @s -->
                    <div class="nk-block block-partners">
                        <ul class="partner-list partner-list-s2 flex-wrap mgb-m30">
                            <li class="partner-logo-s3 animated fadeInUp" data-animate="fadeInUp" data-delay=".2" style="visibility: visible; animation-delay: 0.2s;">
                                <a href="#" target="_blank"><img src="assets/images/partners/hiddin-logo.png" alt="Hiddin Logo"></a>
                            </li>
                            <li class="partner-logo-s3 animated fadeInUp" data-animate="fadeInUp" data-delay=".25" style="visibility: visible; animation-delay: 0.25s;">
                                <a href="http://www.vteam.live" target="_blank"><img src="assets/images/partners/vteam.png" alt="V-Tour Logo"></a>
                            </li>
                            <li class="partner-logo-s3 animated fadeInUp" data-animate="fadeInUp" data-delay=".2" style="visibility: visible; animation-delay: 0.2s;">
                                <a href="https://www.mymddoctor.com" target="_blank"><img src="assets/images/partners/mymd.png" alt="MMD Doctor Logo"></a>
                            </li>
                        </ul>

                        <p style="text-align: center;margin-top: 80px;text-align: justify;">
                            Anyone over forty grew up in an analog world. Their children grew up in this digital world. Our technology de-identifies this digital world making it far more secure and private for anyone using our services to communicate and conduct their transactions
                            through our digital platform. De-identifying these digital communication and transaction processes makes it possible for the HIDDIN Marketplace platform to change how marketing is done and what it costs to match up our buyers
                            and sellers to get their business done.
                        </p>
                        <div class="prdscncntr">
                            <a routerLink="/our-products" class="btn btn-md btn-grad btn-grad-alt no-change">
                                <span>Know More</span>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </main>
        <footer class="nk-footer bg-theme-dark has-ovm">
            <section class="section section-m bg-transparent tc-light ov-v" style="background: #0d42b2 !important">
                <div class="container">
                    <!-- Block @s -->
                    <div class="nk-block block-footer">
                        <div class="row justify-content-between gutter-vr-30px">
                            <div class="col-lg-4 col-sm-6">
                                <div class="wgs wgs-menu">
                                    <a href="#" class="wgs-logo animated fadeInUp" data-animate="fadeInUp" data-delay=".1" style="visibility: visible; animation-delay: 0.1s;">
                                        <!-- <img src="assets/images/hiddin-logo.png" alt="logo"> -->
                                    </a>
                                    <ul class="social">
                                        <li class="animated fadeInUp" data-animate="fadeInUp" data-delay=".25" style="visibility: visible; animation-delay: 0.25s;" tooltip="Facebook" placement="bottom" theme="light">
                                            <a href="https://m.facebook.com/Hiddin-111114470802188/?__tn__=C-R" target="_blank">
                                                <em class="social-icon fab fa-facebook-f"></em>
                                            </a>
                                        </li>
                                        <li class="animated fadeInUp" data-animate="fadeInUp" data-delay=".3" style="visibility: visible; animation-delay: 0.3s;" tooltip="Twitter" placement="bottom" theme="light">
                                            <a href="#" target="_blank">
                                                <em class="social-icon fab fa-twitter"></em>
                                            </a>
                                        </li>
                                        <li class="animated fadeInUp" data-animate="fadeInUp" data-delay=".35" style="visibility: visible; animation-delay: 0.35s;" tooltip="LinkedIn" placement="bottom" theme="light">
                                            <a href="https://www.linkedin.com/company/hiddin-net" target="_blank">
                                                <em class="social-icon fab fa-linkedin-in"></em>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <!-- .col -->
                            <div class="col-lg-4 col-sm-6">
                                <div class="wgs wgs-subscribe-form wgs-subscribe-form-s1">
                                    <h6 class="wgs-title wgs-title-s2 ttc tc-white animated fadeInUp" data-animate="fadeInUp" data-delay=".55" style="visibility: visible; animation-delay: 0.55s;">Subscribe to our newsletter
                                    </h6>
                                    <div class="wgs-body">
                                        <form class="nk-form-submit" [formGroup]="subscribeForm">
                                            <div class="field-inline field-inline-s4 animated fadeInUp" data-animate="fadeInUp" data-delay=".65" style="visibility: visible; animation-delay: 0.65s;">
                                                <div class="field-wrap">
                                                    <input type="text" name="contact-name" formControlName="email" class="input-solid round required email bg-theme-alt" aria-required="true" [(ngModel)]="subscibeEmail" [ngClass]="{ 'is-invalid': sub_submitted && subscribeForm.controls.email.errors }" placeholder="Enter your email"
                                                        style="background: #15257b !important" />
                                                    <div *ngIf="(sub_submitted || subscribeForm.controls.email.touched) && subscribeForm.controls.email.errors" class="invalid-feedback">
                                                        <div *ngIf="subscribeForm.controls.email.errors.required">Email is required</div>
                                                        <div *ngIf="subscribeForm.controls.email.errors.email">Email must be a valid email address
                                                        </div>
                                                        <div *ngIf="subscribeForm.controls.email.errors.pattern">Email must be a valid email address
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="submit-wrap">
                                                    <button class="btn btn-round btn-md btn-auto btn-primary" (click)="submitSubscribe()">Subscribe</button>
                                                </div>
                                            </div>
                                            <div class="form-results"></div>
                                        </form>
                                    </div>
                                    <div *ngIf="sub_errorMessage && sub_errorMessage != ''" class="alert alert-danger title title-xs mt-2 mb-2">
                                        {{sub_errorMessage}}
                                    </div>

                                    <div *ngIf="sub_successMessage && sub_successMessage != ''" class="alert alert-success title title-xs mt-2 mb-2">
                                        {{sub_successMessage}}
                                    </div>
                                </div>
                            </div>
                            <!-- .col -->
                            <div class="col-lg-4">
                                <div class="wgs wgs-menu">
                                    <div class="wgs-body ml-lg-n3">
                                        <!-- <ul class="wgs-links wgs-links-s4 wgs-links-3clumn animated fadeInUp" data-animate="fadeInUp"
                      data-delay=".75" style="visibility: visible; animation-delay: 0.75s;">
                      <li><a href="#" [routerLink]="['/know-more']">What is Hiddin</a></li>
                      <li><a href="#" [routerLink]="['/basic-reg']">Join Us</a></li>
                      <li><a href="#" [routerLink]="['/contact']">Contact</a></li>
                    </ul> -->
                                        <ul class="wgs-links wgs-links-3clumn animated fadeInUp" style="visibility: visible;display: flex;flex-direction: column;">
                                            <li><a [routerLink]="['/know-more']">What is Hiddin</a></li>
                                            <li><a [routerLink]="['/basic-reg']">Join Us</a></li>
                                            <li><a [routerLink]="['/contact']">Contact</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <!-- .col -->
                        </div>
                        <!-- .row -->
                    </div>
                    <!-- .block @e -->
                </div>
            </section>
            <div class="nk-ovm shape-t h-75"></div>
        </footer>
        <app-footer></app-footer>
    </div>
</ion-content>

<div id="overlay" *ngIf="isOverlayActive">
    <div class="overlay-card-container">
        <div class="overlay-card">
            <div class="close-modal">
                <i class="fa fa-times-circle fa-lg" aria-hidden="true" (click)="isOverlayActive = !isOverlayActive"></i>
            </div>
            <iframe src="./assets/video/iot-working.webm" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
    </div>
</div>