<app-header-left-menu [user]="user"></app-header-left-menu>
<ion-content id="main-content" class="full-width-page">
    <div class="bg-theme-alt">
        <div class="bg-grad-special">
            <div class="bg-grad-special-alt">
                <div class="nk-ovm shape-u shape-contain shape-left-top"></div>
                <app-header [user]="user"></app-header>
                <div class="subContent">
                    <div class="main-content container">
                        <h3 class="title title-md title-gradient">Search Directory</h3>
                        <span class="redinfo">*Only initial or full word will be searched</span>
                        <form [formGroup]="userSearchForm" (ngSubmit)="searchUserDetails(1)">
                            <div formArrayName="searchRow">
                                <ion-row
                                    *ngFor="let itmRow of userSearchForm.controls.searchRow['controls']; let i=index"
                                    [formGroupName]="i">
                                    <ion-col size-lg="3" size-md="12" size-sm="12" size-xs="12">
                                        <div class="field-item">
                                            <div class="field-wrap">
                                                <!-- <h6 class="title title-xs">Property</h6> -->
                                                <select class="input-bordered" formControlName="searchField"
                                                    placeholder="Please seclect Property">
                                                    <option value="" selected disabled>Select Property</option>
                                                    <option value="firstName">First Name</option>
                                                    <option value="lastName">Last Name</option>
                                                    <option value="email">Email</option>
                                                    <option value="phoneNumber">Phone Number</option>
                                                </select>
                                            </div>
                                        </div>
                                    </ion-col>
                                    <ion-col size-lg="8" size-md="12" size-sm="12" size-xs="12">
                                        <div class="field-item">
                                            <div class="field-wrap">
                                                <input type="text" class="input-bordered" formControlName="searchTerm"
                                                    placeholder="Enter Data here">
                                            </div>
                                        </div>
                                    </ion-col>
                                    <ion-col size-lg="1" size-md="12" size-sm="12" size-xs="12">
                                        <div class="option-btn">
                                            <button type="button"
                                                class="btn btn-rg btn-auto btn-outline btn-grad on-bg-theme-dark-alt btn-option btn-outlines"
                                                [ngClass]="isSelectedItem('and',i)?'active-button':''"
                                                (click)="addSearchDetails('and',i)">And</button>
                                            <!--button type="button" class="btn btn-rg btn-auto btn-outline btn-grad on-bg-theme-dark-alt btn-option btn-outlines" [ngClass]="isSelectedItem('or',i)?'active-button':''" (click)="addSearchDetails('or',i)">Or</button-->
                                        </div>
                                    </ion-col>
                                </ion-row>

                                <div *ngIf="searchTermErrors" class="errorMessage"><span>{{searchTermErrors}}</span>
                                </div>

                                <button class="btn btn-rg btn-auto btn-outline btn-grad on-bg-theme-dark-alt pull-right"
                                    type="submit">Search</button>
                                <button class="btn btn-primary btn-md pull-right mr-2" type="button"
                                    (click)='resetRows()'>Reset</button>
                                <button class="btn btn-primary btn-md pull-right mr-2" type="button"
                                    (click)="goTo('/directory-dashbord')">Back</button>
                            </div>
                        </form>
                        <div>
                            <div *ngIf="contactAdded" class="successMessage"><span>Request has been sent
                                    successfully. Details are available on <a href=""
                                        [routerLink]="['/directory-dashbord']">Dashborad</a>.</span>
                            </div>
                        </div>
                        <div class="cards-section margin-top" *ngIf="dataDetailSection">
                            <h4 class="header-gradient" *ngIf="searchPerformed == true">Hiddin Users with matching
                                criteria - <span class="normalText">{{searchDisplayCriteria}}</span></h4>
                            <ion-row *ngIf="searchResults.length > 0">
                                <ion-col
                                    *ngFor="let searchResult of searchResults | paginate: { itemsPerPage: 12, currentPage: p };"
                                    size-lg="3" size-md="6" size-sm="12" size-xs="12">
                                    <ion-card>
                                        <div class="team">
                                            <div class="team-photo team-photo-sm">
                                                <img src="assets/images/team/profile.png" alt="team">
                                                <a href="#team-popup-10" class="content-popup"></a>
                                                <ul class="team-social">
                                                    <li><a href="#"><em class="fab fa-linkedin-in"></em></a></li>
                                                    <li><a href="#"><em class="fab fa-facebook-f"></em></a></li>
                                                </ul>
                                            </div>
                                            <h5 class="team-name title title-sm">{{searchResult.user.firstName}}
                                                {{searchResult.user.lastName}}</h5>
                                            <span class="team-position">{{searchResult.user.email}}</span>
                                            <br />
                                            <span class="team-position">+{{searchResult.user.phoneDialCode}}
                                                {{searchResult.user.phoneNumber}}</span>
                                            <br />
                                            <button *ngIf="!contactIds?.includes(searchResult.user.id)"
                                                class="btn btn-rg btn-auto btn-outline btn-grad on-bg-theme-dark-alt btn-options btn-outlines"
                                                (click)="showContactCategoryOptions(searchResult.user.id)"><i
                                                    class="fa fa-plus"></i></button>
                                            <button
                                                class="btn btn-rg btn-auto btn-outline btn-grad on-bg-theme-dark-alt btn-options btn-outlines"
                                                (click)="openProfile(searchResult.user, false)"><i
                                                    class="fa fa-eye"></i></button>
                                        </div>
                                    </ion-card>
                                </ion-col>
                                <ion-col size-lg="12" size-md="12" size-sm="12" size-xs="12">
                                    <pagination-controls (pageChange)="pagechange($event)"
                                        [hidden]="searchResults.length == 0" style=" text-align: center;">
                                    </pagination-controls>
                                </ion-col>
                            </ion-row>
                            <h6 *ngIf="searchResults.length == 0 && searchPerformed == true">- No user found. Please
                                refine your search term and search again.</h6>
                            <h6 *ngIf="searchPerformed == true && searchResults.length > 8"><button
                                    class="btn btn-primary btn-md pull-right mr-2" type="button"
                                    (click)="closeResults">Back</button></h6>
                        </div>
                    </div>
                </div>
                <app-footer></app-footer>
            </div>
        </div>
    </div>
</ion-content>