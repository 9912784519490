<app-header-left-menu></app-header-left-menu>
<ion-content id="main-content" class="full-width-page">
  <div class="bg-theme-alt">
    <div class="bg-grad-special">
      <div class="bg-grad-special-alt">
        <div class="nk-ovm shape-u shape-contain shape-left-top"></div>
        <app-header></app-header>
        <div class="subContent">
          <div class="main-content container">
            <div class="type-section">
              <h3 class="title title-md">Card with Image</h3>
              <div class="card-container">
                <div class="card">
                  <div class="card-image"><img src="assets/images/partners/project1.jpg"></div>
                  <div class="card-detail bg-theme">
                    <p><a href="#" target="_blank">Card Theme</a></p>
                    <p>Card Description</p>
                  </div>
                </div>
                <div class="card">
                  <div class="card-image"><img src="assets/images/partners/project1.jpg"></div>
                  <div class="card-detail bg-light-alt">
                    <p><a href="#" target="_blank" class="tc-info">Card Light</a></p>
                    <p class="tc-info">Card Description</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="type-section">
              <h3 class="title title-md">Card without Image</h3>
              <div class="card-container">
                <div class="card">
                  <div class="card-detail-2 bg-theme">
                    <p><a href="#" target="_blank">Card Theme</a></p>
                    <p>Card Description</p>
                  </div>
                </div>
                <div class="card">
                  <div class="card-detail-2 bg-light">
                    <p><a href="#" target="_blank" class="tc-info">Card Light</a></p>
                    <p class="tc-info">Card Description</p>
                  </div>
                </div>
                <div class="card">
                  <div class="card-detail-2 bg-light-alt">
                    <p><a href="#" target="_blank" class="tc-info">Card Alt Light</a></p>
                    <p class="tc-info">Card Description</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="type-section">
              <h3 class="title title-md">Button Styles</h3>
              <p>For proper styling, use one of the required contextual classes
                <code>(e.g., .btn-grad or .btn-primary)</code></p>
              <ul class="btn-grp gutter-20px gutter-vr-20px">
                <li><a href="#" class="btn btn-grad">Default Button</a></li>
                <li><a href="#" class="btn btn-primary">Primary</a></li>
                <li><a href="#" class="btn btn-secondary">Secondary</a></li>
                <li><a href="#" class="btn btn-info">Info</a></li>
                <li><a href="#" class="btn btn-warning">Warning</a></li>
                <li><a href="#" class="btn btn-success">Success</a></li>
                <li><a href="#" class="btn btn-danger">Danger</a></li>
                <li><a href="#" class="btn btn-light">Light</a></li>
                <li><a href="#" class="btn btn-dark">Dark</a></li>
                <li><a href="#" class="btn btn-facebook"><em
                      class="icon fab fa-facebook-f"></em><span>Facebook</span></a></li>
                <li><a href="#" class="btn btn-google"><em class="icon fab fa-google"></em><span>Google</span></a></li>
              </ul>
              <h3 class="title title-md pdt-l">Button Outline</h3>
              <p>For proper styling, use <code>.btn-outline</code> with base <code>.btn</code> class</p>
              <ul class="btn-grp gutter-20px gutter-vr-20px">
                <li><a href="#" class="btn btn-outline btn-grad">Default Button</a></li>
                <li><a href="#" class="btn btn-outline btn-primary">Primary</a></li>
                <li><a href="#" class="btn btn-outline btn-secondary">Secondary</a></li>
                <li><a href="#" class="btn btn-outline btn-info">Info</a></li>
                <li><a href="#" class="btn btn-outline btn-warning">Warning</a></li>
                <li><a href="#" class="btn btn-outline btn-success">Success</a></li>
                <li><a href="#" class="btn btn-outline btn-danger">Danger</a></li>
                <li><a href="#" class="btn btn-outline btn-light">Light</a></li>
                <li><a href="#" class="btn btn-outline btn-dark">Dark</a></li>
                <li><a href="#" class="btn btn-outline btn-facebook"><em
                      class="icon fab fa-facebook-f"></em><span>Facebook</span></a></li>
                <li><a href="#" class="btn btn-outline btn-google"><em
                      class="icon fab fa-google"></em><span>Google</span></a></li>
              </ul>
              <h3 class="title title-md pdt-l">Button Gradient Outline</h3>
              <p>Use these classes to match gradient button with background
                <code>.on-bg-light, .on-bg-light-alt, .on-bg-theme, .on-bg-theme-alt, .on-bg-theme-dark, .on-bg-theme-dark-alt </code>
              </p>
              <div class="row no-gutters">
                <div class="col-sm-6 col-lg-4 text-center p-4 p-lg-5 bg-light"><a href="#"
                    class="btn btn-outline btn-grad on-bg-light">Light BG</a></div>
                <div class="col-sm-6 col-lg-4 text-center bdt p-4 p-lg-5"><a href="#"
                    class="btn btn-outline btn-grad">White Bg</a></div>
                <div class="col-sm-6 col-lg-4 text-center p-4 p-lg-5 bg-light-alt"><a href="#"
                    class="btn btn-outline btn-grad on-bg-light-alt">Light Alt BG</a></div>
                <div class="col-sm-6 col-lg-4 text-center p-4 p-lg-5 bg-theme"><a href="#"
                    class="btn btn-outline btn-grad on-bg-theme">Theme BG</a></div>
                <div class="col-sm-6 col-lg-4 text-center p-4 p-lg-5 bg-theme-dark"><a href="#"
                    class="btn btn-outline btn-grad on-bg-theme-dark">Theme Dark BG</a></div>
                <div class="col-sm-6 col-lg-4 text-center p-4 p-lg-5 bg-theme-alt"><a href="#"
                    class="btn btn-outline btn-grad on-bg-theme-alt">Theme Alt BG</a></div>
              </div>
              <h3 class="title title-md pdt-l">Button With Badge</h3>
              <p>Just add badge in side of a button.</p>
              <ul class="btn-grp gutter-20px gutter-vr-20px">
                <li><a href="#" class="btn btn-grad">Default Button<span
                      class="badge badge-xs badge-warning">xs</span></a></li>
                <li><a href="#" class="btn btn-info">Info<span class="badge badge-xs badge-light">txt</span></a></li>
                <li><a href="#" class="btn btn-outline btn-primary">Primary<span
                      class="badge badge-xs badge-info">txt</span></a></li>
                <li><a href="#" class="btn btn-outline btn-secondary">Secondary<span
                      class="badge badge-xs badge-danger">100</span></a></li>
              </ul>
              <h3 class="title title-md pdt-l">Button Size</h3>
              <p>Add an extra class <code>.btn-sm &amp; .btn-md</code> for difrent sized button</p>
              <ul class="btn-grp align-items-end gutter-20px gutter-vr-20px">
                <li><a href="#" class="btn btn-grad btn-auto">Button</a></li>
                <li><a href="#" class="btn btn-grad btn-auto btn-md">Medium</a></li>
                <li><a href="#" class="btn btn-grad btn-auto btn-sm">Small</a></li>
              </ul>
              <h3 class="title title-md pdt-l">Button Outline Size</h3>
              <ul class="btn-grp align-items-end gutter-20px gutter-vr-20px">
                <li><a href="#" class="btn btn-outline btn-grad btn-auto">Button</a></li>
                <li><a href="#" class="btn btn-outline btn-grad btn-auto btn-md">Medium</a></li>
                <li><a href="#" class="btn btn-outline btn-grad btn-auto btn-sm">Small</a></li>
              </ul>
            </div>

            <div class="type-section">
              <div class="row">
                <div class="col-lg-5 mgb-l">
                  <h3 class="title title-md">Heading Styles</h3>
                  <h1 class="tc-dark">Heading 1</h1>
                  <h2 class="tc-dark">Heading 2</h2>
                  <h3 class="tc-dark">Heading 3</h3>
                  <h4 class="tc-dark">Heading 4</h4>
                  <h5 class="tc-dark">Heading 5</h5>
                  <h6 class="tc-dark">Heading 6</h6>
                </div>
                <div class="col-lg-7 mgb-l">
                  <h3 class="title title-md">Paragraphs</h3>
                  <p class="lead">ICO Crypto is #1 best selling ICO Landing page, which helps you lunch and introduce
                    your ICO Website. We designed it very carefully to make it more attractive, useful and fit any kind
                    of ICO/Crypto website.</p>
                  <p>ICO Crypto sectetur adipiscing elit, sed do eiusmod tempor incididunt uteop labore et dolore magna
                    aliqua. Ut enim ad minim veniam, nostrud exercit ullamco laboris nisi commodo consequat.</p>
                  <p><small>We create cryptocurrency dipiscing elit, sed do eiusmod tempor incididunt uteop labore et
                      dolore magna aliqua. Ut enim ad minim veniam, nostrud exercit ullamco laboris.</small></p>
                </div>
                <div class="col-lg-5 mgb-r">
                  <h3 class="title title-md">Text Case</h3>
                  <div class="d-flex mb-2">
                    <p class="tt-c mr-auto mb-0">Capitalize Text</p><code
                      class="mr-3">.tt-c</code><span>Capitalize</span>
                  </div>
                  <div class="d-flex mb-2">
                    <p class="tt-u mr-auto mb-0">UpperCase Text</p><code class="mr-3">.tt-u</code><span>Upper
                      Case</span>
                  </div>
                  <div class="d-flex mb-2">
                    <p class="tt-c mr-auto mb-0">LowerCase Text</p><code class="mr-3">.tt-l</code><span>Lower
                      Case</span>
                  </div>
                  <div class="d-flex">
                    <p class="tt-c mr-auto mb-0">Normal Text</p><code class="mr-3">.tt-n</code><span>Normal Case</span>
                  </div>
                </div>
                <div class="col-lg-7">
                  <h3 class="title title-md">Text Color</h3>
                  <div class="d-flex mb-2">
                    <p class="tc-primary mr-auto mb-0">Primary Color</p><code class="mr-3">.tc-primary</code>
                  </div>
                  <div class="d-flex mb-2">
                    <p class="tc-secondary mr-auto mb-0">Secondary Color</p><code class="mr-3">.tc-secondary</code>
                  </div>
                  <div class="d-flex mb-2">
                    <p class="tc-info mr-auto mb-0">Info Color</p><code class="mr-3">.tc-info</code>
                  </div>
                  <div class="d-flex mb-2">
                    <p class="tc-warning mr-auto mb-0">Warning Color</p><code class="mr-3">.tc-warning</code>
                  </div>
                  <div class="d-flex mb-2">
                    <p class="tc-success mr-auto mb-0">Success Color</p><code class="mr-3">.tc-success</code>
                  </div>
                  <div class="d-flex">
                    <p class="tc-danger mr-auto mb-0">Danger Color</p><code class="mr-3">.tc-danger</code>
                  </div>
                </div>
              </div>
            </div>

            <div class="type-section">
              <h3 class="title title-md">4 Columns Grid</h3>
              <div class="row">
                <div class="col-lg-3 mb-4">
                  <p>Tempore, cum soluta nobis est eligendi optio cumque nihil id quod maxime placeat facere possimus,
                    omnis voluptas assumenda est omnis. </p>
                </div>
                <div class="col-lg-3 mb-4">
                  <p>Tempore, cum soluta nobis est eligendi optio cumque nihil id quod maxime placeat facere possimus,
                    omnis voluptas assumenda est omnis. </p>
                </div>
                <div class="col-lg-3 mb-4">
                  <p>Tempore, cum soluta nobis est eligendi optio cumque nihil id quod maxime placeat facere possimus,
                    omnis voluptas assumenda est omnis. </p>
                </div>
                <div class="col-lg-3 mb-4">
                  <p>Tempore, cum soluta nobis est eligendi optio cumque nihil id quod maxime placeat facere possimus,
                    omnis voluptas assumenda est omnis. </p>
                </div>
              </div>
              <h3 class="title title-md">3 Columns Grid</h3>
              <div class="row">
                <div class="col-lg-4 mb-4">
                  <p>Tempore, cum soluta nobis est eligendi optio cumque nihil id quod maxime placeat facere possimus,
                    omnis voluptas assumenda. </p>
                </div>
                <div class="col-lg-4 mb-4">
                  <p>Tempore, cum soluta nobis est eligendi optio cumque nihil id quod maxime placeat facere possimus,
                    omnis voluptas assumenda. </p>
                </div>
                <div class="col-lg-4 mb-4">
                  <p>Tempore, cum soluta nobis est eligendi optio cumque nihil id quod maxime placeat facere possimus,
                    omnis voluptas assumenda. </p>
                </div>
              </div>
              <h3 class="title title-md">2 Columns Grid</h3>
              <div class="row">
                <div class="col-lg-6 mb-4">
                  <p>The automation development provides rem aperiam, et quasi architecto beatae vitae dicta sunt
                    explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit molestiae
                    illum qui.</p>
                </div>
                <div class="col-lg-6 mb-4">
                  <p>The automation development provides rem aperiam, et quasi architecto beatae vitae dicta sunt
                    explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit molestiae
                    illum qui.</p>
                </div>
              </div>
            </div>

            <div class="type-section">
              <h3 class="title title-md">Form Element Bordered</h3>
              <form action="#" class="form-validate" novalidate="novalidate">
                <div class="field-item">
                  <label class="field-label">Input Element</label>
                  <div class="field-wrap">
                    <input name="inputname1" type="text" class="input-bordered" id="input-text-2"
                      required="" aria-required="true">
                  </div>
                  <p>Use <code> .input-bordered </code> class with input element to make it bordered.</p>
                </div>
                <div class="field-item">
                  <label class="field-label">Select Element</label>
                  <div class="field-wrap">
                    <select class="input-bordered">
                      <option value="" data-select2-id="3">Please select</option>
                      <option value="option_a">Option A</option>
                      <option value="option_b">Option B</option>
                      <option value="option_c">Option C</option>
                      <option value="option_d">Option D</option>
                    </select>
                  </div>
                  <p> Use <code>.select</code> to active select2 jQuery plugin for custom select field style. and use
                    <code>data-select2-theme="bordered"</code> with select element for borderd style.</p>
                </div>
                <div class="field-item"><label class="field-label">Textarea Element</label>
                  <div class="field-wrap"><textarea name="textarea2" class="input-bordered input-textarea" required=""
                      aria-required="true"></textarea></div>
                  <p>Use <code> .input-textarea </code> as an extra class with your desired input class for textarea.
                  </p>
                </div>
                <ul class="d-flex flex-wrap gutter-30px">
                  <li class="mb-3">
                    <div class="field-wrap"><input class="input-checkbox" id="cbi-1" name="cbi[]" type="checkbox"
                        required="" aria-required="true"><label for="cbi-1">CheckBox Item</label></div>
                  </li>
                  <li class="mb-3">
                    <div class="field-wrap"><input class="input-checkbox" id="cbi-2" name="cbi[]" type="checkbox"
                        required="" aria-required="true"><label for="cbi-2">CheckBox Item</label></div>
                  </li>
                  <li class="mb-3">
                    <div class="field-wrap"><input class="input-checkbox" id="cbi-3" name="cbi[]" type="checkbox"
                        required="" aria-required="true"><label for="cbi-3">CheckBox Item</label></div>
                  </li>
                </ul>
                <p class="mb-4">Use <code> .input-checkbox </code> class with input type checkbox element to make it
                  look like above.</p>
                <ul class="d-flex flex-wrap gutter-30px">
                  <li class="mb-3">
                    <div class="field-wrap"><input class="input-radio" id="rdi-1" name="rdi" type="radio" required=""
                        aria-required="true"><label for="rdi-1">Radio Item</label></div>
                  </li>
                  <li class="mb-3">
                    <div class="field-wrap"><input class="input-radio" id="rdi-2" name="rdi" type="radio" required=""
                        aria-required="true"><label for="rdi-2">Radio Item</label></div>
                  </li>
                  <li class="mb-3">
                    <div class="field-wrap"><input class="input-radio" id="rdi-3" name="rdi" type="radio" required=""
                        aria-required="true"><label for="rdi-3">Radio Item</label></div>
                  </li>
                </ul>
                <p class="mb-4">Use <code> .input-radio </code> class with input type radio element to make it look like
                  above.</p>
                <ul class="d-flex flex-wrap gutter-30px">
                  <li class="mb-3">
                    <div class="field-wrap"><input class="input-switch" id="switch-1" name="switch[]" type="checkbox"
                        required="" aria-required="true"><label for="switch-1">Switch Item</label></div>
                  </li>
                  <li class="mb-3">
                    <div class="field-wrap"><input class="input-switch" id="switch-2" name="switch[]" type="checkbox"
                        required="" aria-required="true"><label for="switch-2">Switch Item</label></div>
                  </li>
                  <li class="mb-3">
                    <div class="field-wrap"><input class="input-switch" id="switch-3" name="switch[]" type="checkbox"
                        required="" aria-required="true"><label for="switch-3">Switch Item</label></div>
                  </li>
                </ul>
                <p class="mb-4">Use <code> .input-switch </code> class with input type checkbox or radio element to make
                  it look like above.</p>
              </form>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
      </div>
    </div>
  </div>
</ion-content>