import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MenuController } from '@ionic/angular';
import { AuthServiceService } from '@services/auth-service.service';
import { LoaderService } from '@services/loader.service';
import { Globals } from 'src/app/globals';
import { RegUser } from '../basic-reg/RegUser';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  // Get In Touch Form
  getInTouchForm: FormGroup;
  submitted: boolean = false;
  errorMessage: string;
  successMessage = null;

  user: RegUser = new RegUser();
  LoggedIn: boolean;

  // emailPattern = "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$";
  emailPattern = "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?";
  requestPath: string;

  getInTouch = {
    name: '',
    email: '',
    message: ''
  }

  constructor(
    private authService: AuthServiceService,
    private global: Globals,
    private formBuilder: FormBuilder,
    private loader: LoaderService) {
    this.requestPath = this.global.requestPath;
  }

  ngOnInit() {
    this.getInTouchForm = this.formBuilder.group({
      name: ['', [Validators.required, this.noWhitespace, Validators.maxLength(100)]],
      message: ['', [this.noWhitespace, Validators.maxLength(500)]],
      email: ['', [Validators.required, this.noWhitespace, Validators.maxLength(50), Validators.pattern(this.emailPattern)]]
    });
  }

  ionViewWillEnter() {    
    this.user = this.authService.getLoggedInUser();
    this.LoggedIn = this.authService.isLoggedIn();
    if (!this.global.isEmpty(this.user)) {
      this.user.imageUrl = this.authService.getProfilePicture();
    }
  }

  public noWhitespace(control: FormControl) {
    let isWhitespace = (control.value || '').trim().length === 0;
    let isValid = !isWhitespace;
    return isValid ? null : { 'required': true }
  }

  // convenience getter for easy access to form fields
  get f() { return this.getInTouchForm.controls; }

  resetGetInTouch() {
    this.getInTouchForm.reset();
    this.getInTouch = {
      name: '',
      email: '',
      message: ''
    }
    this.submitted = false;
    this.errorMessage = null;
  }

  submitGetInTouch() {
    this.submitted = true;
    this.errorMessage = null;
    this.successMessage = null;

    // stop here if form is invalid
    if (this.getInTouchForm.invalid) {
      // console.log("Invalid: " + this.getInTouchForm.value);
      return;
    }

    this.loader.show("Please wait...");
    this.authService.postDefaultEncrypt('ns/user?' + this.requestPath + '=saveQuery', this.getInTouch)
      .subscribe(res => {
        this.loader.hide();
        // console.log(res);
        if (res.success) {
          this.successMessage = "Your feedback is submitted successfully. Our customer care executive will connect to you shortly.";
          this.resetGetInTouch();
        } else {
          this.errorMessage = res.message;
        }
      }, error => {
        this.loader.hide();
        // console.log(error);
        this.errorMessage = "Something went wrong, please try again.";
      });
  }

}
