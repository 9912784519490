<ion-content overflow-scroll="true" class="content-main">
  <div id="login-popup">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <!-- <button class="modal-close" aria-label="Close" (click)="dismiss()"><em class="ti ti-close"></em></button> -->
        <div class="ath-container m-0">
          <div class="ath-body">
            <h5 class="ath-heading title">Thank You</h5>
            <div class="text-center">
              <img src="assets/images/check-circle.gif" width="100px" height="100px">
            </div>
            <div class="notification-content">
              <div class="info">
                You have successfully upgraded to Business. You can now authorized to use Business services provided
                into our system.
              </div>
              <div class="btn btn-primary btn-md mt-2" (click)="dismiss()">Ok</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>