// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  isEncryptionEnable: true,
  isSubscriptionRequired: false,
  isWeb: true,
  isDebugAlertOn: false,
  
  // imagePath: "http://localhost:8080/hiddinservices/",
  // base: "http://localhost:8080/hiddinservices/",
  // appBaseUrl: "http://localhost:8100/",
  // hiddinURL: "http://localhost:8080/",
  // domainName: "http://localhost:8100/",
  // socailService: "http://localhost:8080/hiddinsocialservices/",
  // attachableFileAccessPath: 'http://localhost:8100/assets/attachable/',
  // paymentURL: "http://localhost:8090/",

  // imagePath: "https://hiddin.net:8443/hiddinservices/",
  // base: "https://hiddin.net:8443/hiddinservices/",
  // appBaseUrl: "https://hiddin.net/app/hiddin/",
  // hiddinURL: "https://hiddin.net:8443/",
  // domainName: "https://hiddin.net/app/hiddin/",
  // socailService: "https://hiddin.net:8443/hiddinsocialservices/",
  // attachableFileAccessPath: 'https://hiddin.net/app/resource/attachable/'

  imagePath: "https://hiddin.net:8443/hiddinservices/",
  base: "https://hiddin.net:8443/hiddinservices/",
  appBaseUrl: "https://hiddin.net/",
  hiddinURL: "https://hiddin.net:8443/",
  domainName: "https://hiddin.net/",
  socailService: "https://hiddin.net:8443/hiddinsocialservices/",
  attachableFileAccessPath: 'https://hiddin.net/resource/attachable/',
  paymentURL: "https://hiddin.net:8443/payment-gateway/",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
