import { SecurityQuestions } from './SecurityQuestions';

export class RegUser {

    constructor() {
        this.securityQuestions = new SecurityQuestions();
    }
    id: number;
    type: string;
    username: string;
    password: string;
    firstName: string;
    middleName: string;
    lastName: string;
    hiddinEmail:string;
    email: string;
    confirmEmailAddress: string;
    emailAddress: string;
    userPassword: string;
    confirmPassword: string;
    phoneNumberAll: any;
    phoneNumber: String;
    company: string;
    zipCode: number;
    question1: string;
    answer1: string;
    question2: string;
    answer2: string;
    question3: string;
    answer3: string;
    question4: string;
    answer4: string;
    securityQuestions: SecurityQuestions;
    roleLevel: number;
    parentId: number;
    emailOtp: string;
    phoneOtp: string;
    mobileOtp: string;
    signupTime: string;
    birthDate: string;
    isDocumentUploaded: boolean;
    isSeqQueUploaded: boolean;
    imageUrl: string;
    relationshipId: number;
    phoneDialCode: string;
    phoneCountryShortName: string;
    verificationCode: string;
    city: string;
    state: string;
    country: string;
    aboutMe: string;
    timeZone: string;
}
