<app-header-left-menu [user]="user"></app-header-left-menu>
<ion-content id="main-content" class="full-width-page">
  <div class="bg-theme-alt">
    <div class="bg-grad-special">
      <div class="bg-grad-special-alt">
        <div class="nk-ovm shape-u shape-contain shape-left-top"></div>
        <app-header [user]="user"></app-header>
        <div class="subContent">
          <div class="main-content container">
            <h5 class="ath-heading title">HIDDIN INCORPORATED PRIVACY POLICY</h5>
            <h3 class="title title-md">
              Privacy Notice
            </h3>
            <p>This privacy notice discloses the privacy practices for https://hiddin.net. This privacy notice applies solely to information collected by this website. It will notify you of the following:</p>
            <ol>
              <li>
                What personally identifiable information is collected from you through the website, how it is used and with whom it may be shared.
              </li>
              <li>
                What choices are available to you regarding the use of your data.
              </li>
              <li>
                The security procedures in place to protect the misuse of your information.
              </li>
              <li>
                How you can correct any inaccuracies in the information.
              </li>
            </ol>
            <h3 class="title title-md">
              Information Collection, Use and Sharing
            </h3>
            <p>We are the sole owners of the information collected on this site. We only have access to/collect information that you voluntarily give us via email or other direct contact from you. We will not sell or rent this information to anyone. We will use your information to respond to you, regarding the reason you contacted us. We will not share your information with any third party outside of our organization, other than as directed by you.</p>
            <p>Unless you ask us not to, we may contact you via email in the future to tell you about specials, new products or services, or changes to this privacy policy.</p>
            <h3 class="title title-md">
              Your Access to and Control Over Information
            </h3>
            <p>You may opt out of any future contacts from us at any time. You can do the following at any time by contacting us via the email address or phone number given on our website:</p>
            <ul>
              <li>See what data we have about you, if any.</li>
              <li>Change/correct any data we have about you.</li>
              <li>Have us delete any data we have about you.</li>
              <li>Express any concern you have about our use of your data.</li>
            </ul>
            <h3 class="title title-md">
              Security
            </h3>
            <p>We take precautions to protect your information. When you submit sensitive information via the website, your information is protected both online and offline.</p>
            <p>While we use encryption to protect sensitive information transmitted online, we also protect your information offline. Only employees who need the information to perform a specific job (for example, billing or customer service) are granted access to personally identifiable information. The computers/servers in which we store personally identifiable information are kept in a secure environment.</p>
            <p><b>If you feel that we are not abiding by this privacy policy, you should contact us immediately via telephone at 813 842 4825 or via admin&#64;hiddin.com.</b></p>
            <h3 class="title title-md">
              Enrollment
            </h3>
            <p>In order to use this website, a user must first complete the enrollment process. During enrollment a user is required to give certain information such as name, address, smart phone number, and email address. This information is used to contact you about enrolling in the services on our site in which you have expressed interest.</p>
            <h3 class="title title-md">
              Cookies
            </h3>
            <p>We use "cookies" on this site. A cookie is a piece of data stored on a site visitor's hard drive to help us improve your access to our site and identify repeat visitors to our site. For instance, when we use a cookie to identify you, you would not have to log in a password more than once, thereby saving time while on our site. Cookies can also enable us to track and target the interests of our users to enhance the experience on our site. Usage of a cookie is in no way linked to any personally identifiable information on our site.</p>
            <h3 class="title title-md">
              Links
            </h3>
            <p>This website contains links to other sites. Please be aware that we are not responsible for the content or privacy practices of such other sites. We encourage our users to be aware when they leave our site and to read the privacy statements of any other site that collects personally identifiable information.</p>
            <h3 class="title title-md">
              Surveys
            </h3>
            <p>From time-to-time our site requests information via surveys. Participation in these surveys is completely voluntary and you may choose whether or not to participate and therefore disclose this information. Information requested may include contact information (such as name and shipping address), and demographic information (such as zip code, age level). Contact information may be used to notify participants of survey results. Survey information will be used for purposes of monitoring or improving the use and satisfaction of this site.</p>
            <p><b>Contact us with any questions or concern about our privacy policy at (813) 842-4825 or via e-mail at admin&#64;hiddin.com.</b></p>
          </div>
        </div>
        <app-footer></app-footer>
      </div>
    </div>
  </div>
</ion-content>
