import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthServiceService } from '@services/auth-service.service';
import { LoaderService } from '@services/loader.service';
import { Globals } from 'src/app/globals';
import { RegUser } from '../basic-reg/RegUser';

@Component({
  selector: 'app-encryption-decryption',
  templateUrl: './encryption-decryption.component.html',
  styleUrls: ['./encryption-decryption.component.scss'],
})
export class EncryptionDecryptionComponent implements OnInit {
  // Encryption Form
  encData = { "data": "", "encyptedData": "" };
  encForm: FormGroup;
  encSubmitted: boolean = false;
  encErrorMessage: string;
  encSuccessMessage: string;
  encButtonText: string = "Encrypt";
  encIsLoading: boolean;

  // Decryption Form
  decData = { "data": "", "decryptedData": "" };
  decForm: FormGroup;
  decSubmitted: boolean = false;
  decErrorMessage: string;
  decSuccessMessage: string;
  decButtonText: string = "Decrypt";
  decIsLoading: boolean;

  // APIs data
  requestPath: string;

  // Login user data
  user: RegUser = new RegUser();
  LoggedIn: boolean;
  profileImageSrc: any;

  constructor(public global: Globals
    , public authService: AuthServiceService
    , public formBuilder: FormBuilder
    , public router: Router
    , private loader: LoaderService) {
    this.requestPath = this.global.requestPath;
  }

  ngOnInit(): void {
    this.encForm = this.formBuilder.group({
      data: ['', [Validators.required, Validators.minLength(2)]]
    });

    this.decForm = this.formBuilder.group({
      data: ['', [Validators.required, Validators.minLength(2)]]
    });
  }

  ionViewDidEnter() {
    this.user = this.authService.getLoggedInUser();
    this.profileImageSrc = this.authService.getProfilePicture();
    this.user.imageUrl = this.profileImageSrc;

    this.resetFormsData();
  }

  resetFormsData = function () {
    // Reset Encryption Form
    this.encData = { "data": "", "encyptedData": "" };
    this.encSubmitted = false;
    this.encErrorMessage = null;
    this.encSuccessMessage = null;
    this.encButtonText = "Encrypt";
    this.encIsLoading = false;
    // Reset Decyryption Form
    this.decData = { "data": "", "decryptedData": "" };
    this.decSubmitted = false;
    this.decErrorMessage = null;
    this.decSuccessMessage = null;
    this.decButtonText = "Decrypt";
    this.decIsLoading = false;
  }

  // convenience getter for easy access to form fields
  get f_enc() { return this.encForm.controls; }
  get f_dec() { return this.decForm.controls; }

  encrypt() {
    this.encSubmitted = true;
    this.encErrorMessage = null;
    this.encSuccessMessage = null;

    // stop here if form is invalid
    if (this.encForm.invalid) {
      return;
    }

    this.encData.encyptedData = null;
    this.encIsLoading = true;
    this.encButtonText = "Encrypting data...";

    this.authService.postEncrypt('common/encryption?' + this.requestPath + '=encryptdb', this.encData)
      .subscribe((res: any) => {
        this.encIsLoading = false;
        if (res.success) {
          // Reinitialized the form state
          this.encSubmitted = false;
          // Success message
          this.encSuccessMessage = "Data has been encypted successfully.";
          this.encData.encyptedData = this.global.decrypt(res.data);
          this.encButtonText = "Again Encrypt";
        } else {
          this.encErrorMessage = res.message;
        }
      }, error => {
        this.encIsLoading = false;
        this.encErrorMessage = "Something went wrong, please try again.";
      });
  }

  decrypt() {
    this.decSubmitted = true;
    this.decErrorMessage = null;
    this.decSuccessMessage = null;

    // stop here if form is invalid
    if (this.decForm.invalid) {
      return;
    }

    this.decIsLoading = true;
    this.decButtonText = "Decrypting data...";
    this.decData.decryptedData = null;

    this.authService.postEncrypt('common/encryption?' + this.requestPath + '=decryptdb', this.decData)
      .subscribe((res: any) => {
        this.decIsLoading = false;
        if (res.success) {
          // Reinitialized the form state
          this.decSubmitted = false;
          // Success message
          this.decSuccessMessage = "Data has been decrypted successfully.";
          this.decData.decryptedData = this.global.decrypt(res.data);
          this.decButtonText = "Again Decrypt";
        } else {
          this.decErrorMessage = res.message;
        }
      }, error => {
        this.decIsLoading = false;
        this.decErrorMessage = "Something went wrong, please try again.";
      });
  }
}

