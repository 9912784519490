import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-reg-alert',
  templateUrl: './reg-alert.component.html',
  styleUrls: ['./reg-alert.component.scss']
})
export class RegAlertComponent implements OnInit {

  constructor(private modalCtrl: ModalController,
    private router: Router) { }

  ngOnInit(): void {
  }

  async dismiss() {
    this.modalCtrl.dismiss();
  }

  goToCompleteReg() {
    this.dismiss();
    this.router.navigate(['/complete-reg']);
  }
}
