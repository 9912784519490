export class CommonUtil {

    public static replaceAll(str, from, to) {
        if (this.isEmpty(str)) {
            return str;
        }
        return str.replace(new RegExp(from, 'g'), to);

    }

    public static removeSpecialChars(text: string) {
        return text.replace(/[^a-zA-Z0-9]/g, '');
    }

    public static isNullOrEmptyOrUndefined = function (value) {
        return !value;
    }

    public static isEmpty(value) {
        if (value == null || value == undefined || value.length == 0) {
            return true;
        }
        return false;
    }

    public static isEmptyTrim = function(value)  {
        if (value == null || value == undefined || value.trim().length == 0) {
            return true;
        }
        return false;
    }

    public static formatPhone = function(phoneNumber) {
        if(phoneNumber) {
            return CommonUtil.getX(phoneNumber.length - 4) + phoneNumber.substr(phoneNumber.length - 4);
        }
        return '';
    }

    public static formatEmail = function(email) {
        let atIndex = email.indexOf("@");
        if(email) {
            return email.substr(0, 1) + CommonUtil.getX(atIndex - 2).toLowerCase() + email.substr(atIndex-1, 1) + email.substr(atIndex, email.length);
        }
        return '';
    }

    public static getX = function(count) {
        let str = "";
        for (let i = 1; i <= count; i++) {
            str += "X";
        }
        return str;
    }
}