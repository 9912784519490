import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Globals } from 'src/app/globals';
import { Router } from '@angular/router';
import { LoaderService } from '../../services/loader.service';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { HttpClient } from '@angular/common/http';
import { RegUser } from '../basic-reg/RegUser';
import { timer } from 'rxjs';
import { takeWhile, tap } from 'rxjs/operators';
import { ModalController } from '@ionic/angular';
import { SuccessMessageComponent } from '@modal/success-message/success-message.component';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss'],
})
export class OtpComponent implements OnInit {

  otpForm: FormGroup;
  loading: boolean = false;
  activeResendLink: boolean = false;
  counter: number = 120;
  time: any;
  otpFormSubmitted: boolean = false;
  requestPath = this.global.requestPath;

  errorMessage: string = "";
  otp: string;

  existingUser;
  user: RegUser;
  topErrorMessage;

  constructor(private http: HttpClient, private global: Globals,
    private authService: AuthServiceService,
    private formBuilder: FormBuilder,
    public modalController: ModalController,
    private loader: LoaderService,
    private router: Router) {
  }

  ngOnInit() {

    this.startCountdown();

    this.otpForm = this.formBuilder.group({
      userOTP: ['', Validators.required]
    });
  }

  ionViewDidEnter() {
    this.resetForms();

    this.user = this.authService.getLoggedInUser();
    if (!this.global.isEmpty(this.user)) {
      this.user.imageUrl = this.authService.getProfilePicture();
    }

    this.existingUser = this.global.getLocalData(this.global.HIDDIN_USER_DETAILS);

    if (this.global.isEmpty(this.existingUser)) {
      // this.router.navigate(['/basic-reg']);
      this.topErrorMessage = "No user information found.";
    } else {
      this.existingUser = JSON.parse(this.existingUser);
    }
  }

  resetForms = function () {
    this.otp = null;
    this.otpForm.reset();
    this.otpFormSubmitted = false;
    this.topErrorMessage = null;
  }

  get f() { return this.otpForm.controls; }

  sumbitOTP() {
    this.otpFormSubmitted = true;
    this.loading = true;
    this.errorMessage = "";

    if (this.otpForm.invalid) {
      // console.log("Invalid: " + this.otpForm.value);
      return;
    }

    this.loader.show("Verifying...");
    this.authService.getNoSecure('ns/common/user?' + this.requestPath + '=verifyMobileOTP&mobileNumber=' + this.existingUser.phoneNumber + '&otp=' + this.otp.trim() + '&tokenRequired=false&sendEmail=true')
      .subscribe((res) => {
        this.loader.hide();
        // console.log(res);
        if (res.success) {
          res.data = this.global.decryptDefault(res.data);
          // this.global.saveLocalData(this.global.HIDDIN_USER_DETAILS, JSON.stringify(res.data));
          // this.router.navigate(['/login']);
          this.resetForms();
          this.openSuccessModal();
        } else {
          this.errorMessage = res.message;
          // console.log(this.errorMessage);
        }
      }, error => {
        this.loader.hide();
        this.errorMessage = "Error occured, please try again.";
        // console.log(this.errorMessage);
      });

  }

  startCountdown() {

    timer(0, 1000)
      .pipe(
        takeWhile(() => this.counter > 0),
        tap(() => this.counter--)
      )
      .subscribe(() => {
        const minutes = Math.floor(this.counter / 60);
        const seconds = this.counter - minutes * 60;

        this.time = (minutes.toString()).padStart(2, '0') + ':' + (seconds.toString()).padStart(2, '0')

        if (this.counter < 1) {
          this.activeResendLink = true;
        }
      });
  }

  resendMobileOTP() {
    this.loader.show("Sending verification code...");
    this.authService.getNoSecure('ns/common/user?' + this.requestPath + '=resendMobileOTP&mobileNumber=' + this.existingUser.phoneNumber + '&signupTime=' + this.existingUser.signupTime)
      .subscribe((res) => {
        this.loader.hide();
        // console.log(res);
        if (res.success) {
          // console.log("OTP has been resend");

          this.counter = 120;
          this.activeResendLink = false;
          this.startCountdown();
          console.log(res.description);
        } else {
          this.errorMessage = res.message;
          // console.log(this.errorMessage);
        }
      }, error => {
        this.loader.hide();
        this.errorMessage = "Error occured, please try again.";
        // console.log(this.errorMessage);
      });
  }

  async openSuccessModal() {
    const modal = await this.modalController.create({
      component: SuccessMessageComponent
    });

    modal.onDidDismiss().then(dataReturned => {
      this.router.navigate(['/home']);
    });

    return await modal.present();
  }
}
