import { Component, OnInit } from '@angular/core';

import { LeftMenuService } from '@services/left-menu.service';


@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss']
})
export class LeftMenuComponent implements OnInit {

  isVisible$ = this.leftMenuSvc.isVisible;

  constructor(private leftMenuSvc: LeftMenuService) { }

  ngOnInit(): void {    
  }

}
