import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { AuthServiceService } from '@services/auth-service.service';
import { LoaderService } from '@services/loader.service';
import { Globals } from 'src/app/globals';
import { RegUser } from 'src/app/pages/basic-reg/RegUser';
import * as moment from 'moment';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';

@Component({
  selector: 'app-add-family-member',
  templateUrl: './add-family-member.component.html',
  styleUrls: ['./add-family-member.component.scss'],
})
export class AddFamilyMemberComponent implements OnInit {

  @Input("type") type;
  @Input("memberId") memberId;

  separateDialCode = false;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.India];

  relationList = [];
  dateValidation: boolean;
  dob: any;

  isValidPoneNumber: Boolean;
  phoneMessage: String = '';
  internationalPhoneNo: any;
  mobileNoViewInEdit: any;

  requestPath = this.global.requestPath;

  signupForm: FormGroup;
  signupInput: RegUser = new RegUser();
  submitted: boolean = false;

  errorMessage: string;

  heading: string;
  subheading: string;
  btnText: string;

  selectedMobilNo: any;

  emailPattern = "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?";
  passwordVisible: boolean = false;

  roles = [{
    "name": "Viewer",
    "value": 4
  }, {
    "name": "Admin",
    "value": 5
  }]

  intlOptions = {
    initialCountry: 'us',
    autoHideDialCode: true,
    separateDialCode: false,
    enableAutoCountrySelect: true,
    autoPlaceholder: 'polite',
    nationalMode: true,
    customPlaceholder: function (selectedCountryPlaceholder) {
      return 'Example : ' + selectedCountryPlaceholder;
    }
  }

  constructor(public modalCtrl: ModalController
    , private global: Globals
    , private authService: AuthServiceService
    , private formBuilder: FormBuilder
    , private loader: LoaderService) { }

  ngOnInit(): void {
    this.getRelationList();

    this.signupForm = this.formBuilder.group({
      firstName: ['', [Validators.required, this.noWhitespace, Validators.maxLength(50)]],
      lastName: ['', [Validators.required, this.noWhitespace, Validators.maxLength(50)]],
      emailAddress: ['', [Validators.required, this.noWhitespace, Validators.maxLength(50), Validators.pattern(this.emailPattern)]],
      userPassword: ['', [Validators.required, Validators.minLength(6)]],
      relation: ['', Validators.required],
      phoneNumber: ['', [Validators.required]],
      birthDate: ['', [Validators.required]],
      role: ['', [Validators.required]]
    });
  }

  ionViewDidEnter() {
    this.passwordVisible = false;
    this.resetPhoneNumber();
    this.fetchUser();
    this.initUser();

    if (this.type == 1) {

      // Add Case
      this.heading = "Add Member";
      this.subheading = "Fill in the form below to add a member";
      this.btnText = "Add";

      this.enableEmailPhonenumber();

    } else if (this.type == 2) {

      // Edit Case
      this.heading = "Edit Member";
      this.subheading = "Update the data of the member";
      this.btnText = "Update";

      this.disableEmailPhonenumber();

      this.fetchUserData();

    } else {
      this.authService.unauthorized();
    }
  }

  resetPhoneNumber() {
    this.mobileNoViewInEdit = null;
    this.selectedMobilNo = null;
    this.signupInput.phoneNumberAll = null;
    this.signupInput.phoneDialCode = "1";
    this.signupInput.phoneCountryShortName = "us";
    this.isValidPoneNumber = false;
    this.phoneMessage = null;
  }

  fetchUser = function () {
    this.currentUser = this.authService.getLoggedInUser();
  }

  initUser = function () {
    this.signupInput = new RegUser();
    this.submitted = false;
    this.signupForm.reset();
  }

  fetchUserData = function () {
    this.errorMessage = "";

    this.loader.show("Loading data...");
    this.authService.get('user?' + this.requestPath + '=getUserMemberById&memberId=' + this.memberId)
      .subscribe((res) => {
        this.loader.hide();
        //console.log(res);
        if (res.success) {
          this.signupInput = this.global.decrypt(res.data);
          this.mobileNoViewInEdit = this.signupInput.phoneNumberAll?.e164Number;
        } else {
          this.errorMessage = res.message;
        }
      }, error => {
        this.loader.hide();
        //console.log(error);
        this.errorMessage = "Some error occured, please try again!!!";
      });
  }

  getRelationList = function () {

    this.relationList = [];

    this.loader.show("Loading data...");
    this.authService.getNoSecure('ns/refData?' + this.requestPath + '=findAllByParentId&parentId=' + 8)
      .subscribe((res) => {
        this.loader.hide();
        if (res.success) {
          //console.log(res);
          this.relationList = this.global.decryptDefault(res.data);
        }
      }, error => {
        this.loader.hide();
        //console.log(error);
      });
  }

  disableEmailPhonenumber() {
    this.signupForm.controls["emailAddress"].disable();
    this.signupForm.controls["phoneNumber"].disable();
  }

  enableEmailPhonenumber() {
    this.signupForm.controls["emailAddress"].enable();
    this.signupForm.controls["phoneNumber"].enable();
  }

  public noWhitespace(control: FormControl) {
    let isWhitespace = (control.value || '').trim().length === 0;
    let isValid = !isWhitespace;
    return isValid ? null : { 'required': true }
  }

  get f() { return this.signupForm.controls; }

  async dismiss(data) {
    this.modalCtrl.dismiss(data);
  }

  onDateChange(birthDate: any): void {

    if (this.global.isEmpty(birthDate)) {
      //console.log("Birth Date is Empty");
      this.signupForm.controls['birthDate'].setErrors({ 'incorrect': true });
      return;
    }

    this.dateValidation = moment(moment().format('YYYY-MM-DD')).isBefore(moment(birthDate).format('YYYY-MM-DD'));

    // this.signupInput.birthDate = birthDate;
  }

  saveMember = function () {
    this.errorMessage = "";

    this.submitted = true;

    if (this.signupForm.invalid) {
      return;
    }

    this.signupInput.parentId = this.currentUser.id;


    this.signupInput.phoneNumber = this.signupInput.phoneNumberAll?.e164Number.replace(this.signupInput.phoneNumberAll.dialCode, "");
    if (this.dateValidation) {
      return;
    }

    this.signupInput.password = window.btoa(this.signupInput.userPassword);

    var d = new Date();
    var dateFormatStr = d.getFullYear().toString() + "-" + ((d.getMonth() + 1).toString().length == 2 ? (d.getMonth() + 1).toString() : "0" + (d.getMonth() + 1).toString()) + "-" + (d.getDate().toString().length == 2 ? d.getDate().toString() : "0" + d.getDate().toString()) + " " + (d.getHours().toString().length == 2 ? d.getHours().toString() : "0" + d.getHours().toString()) + ":" + ((parseInt((d.getMinutes() / 5).toString()) * 5).toString().length == 2 ? (parseInt((d.getMinutes() / 5).toString()) * 5).toString() : "0" + (parseInt((d.getMinutes() / 5).toString()) * 5).toString()) + ":00";

    //console.log("Signup Date: " + dateFormatStr);
    this.signupInput.signupTime = dateFormatStr;

    this.loader.show("Please wait...");
    this.authService.postEncrypt('user?' + this.requestPath + '=saveUserMember', this.signupInput)
      .subscribe((res) => {
        this.loader.hide();
        if (res.success) {
          this.dismiss('ok');
        } else {
          this.errorMessage = res.message;
        }
      }, error => {
        this.loader.hide();
        //console.log('Oops! Error occured while adding member. Please try again later.')
        this.errorMessage = 'Error occured, please try again.';
      });
  }

  togglePassword(id: string) {

    const password = document.querySelector('#' + id);
    const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
    password.setAttribute('type', type);

    if (id === 'pwd') {
      this.passwordVisible = !this.passwordVisible;
    }

  }

  onReset = function () {
    this.submitted = false;
    this.signupForm.reset();
  }

  // Phone number with country code field
  onCountryChange = function (event) {
    this.signupInput.phoneDialCode = this.signupInput.phoneNumberAll?.dialCode;
    this.signupInput.phoneCountryShortName = this.signupInput.phoneNumberAll?.countryCode;
  }

}
