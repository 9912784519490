<app-header-left-menu [user]="currentUser"></app-header-left-menu>
<ion-content id="main-content" class="full-width-page">
  <div class="bg-theme-alt">
    <div class="bg-grad-special">
      <div class="bg-grad-special-alt">
        <div class="nk-ovm shape-u shape-contain shape-left-top"></div>
        <app-header [user]="currentUser"></app-header>
        <div class="subContent">
          <div class="main-content container">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <h6 class="ath-heading title">Relatives
                <small class="tc-default">Manage your relatives here</small>
              </h6>
              <div class="btn-container">
                <button class="btn btn-grad btn-md" (click)="addMember()">Add Member +</button>
              </div>
              <div class="table-responsive mt-2" *ngIf="members && members.length > 0">
                <table class="table table-bordered">
                  <tr>
                    <th class="table-head">#</th>
                    <th class="table-head">Name</th>
                    <th class="table-head">Email</th>
                    <th class="table-head">Phone Number</th>
                    <th class="table-head">Relation</th>
                    <th class="table-head">Joined Since</th>
                    <th class="table-head">Actions</th>
                  </tr>
                  <tr *ngFor="let employee of members; index as i">
                    <th class="table-des" scope="row">{{i + 1}}</th>
                    <td class="table-des">{{employee.name}}</td>
                    <td class="table-des">{{employee.email}}</td>
                    <td class="table-des">+{{employee.phoneDialCode}}&nbsp;{{employee.phoneNumber}}</td>
                    <td class="table-des">{{employee.relation}}</td>
                    <td class="table-des">{{employee.creationDate}}</td>
                    <td class="table-des">
                      <div class="btn-container">
                        <span class="mr-2" (click)="editMember(employee.id)" tooltip="Edit" placement="bottom"
                          theme="light"><i class="fa fa-edit" aria-hidden="true"></i></span>
                        <span class="mr-2" (click)="deleteMember(employee.id)" tooltip="Delete" placement="bottom"
                          theme="light"><i class="fa fa-trash red" aria-hidden="true"></i></span>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
              <div *ngIf="!members || members.length == 0" class="text-center">
                No member(s) found.
              </div>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
      </div>
    </div>
  </div>
</ion-content>