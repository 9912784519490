import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from './globals';
import { AuthServiceService } from '@services/auth-service.service';
import { RegTokenServiceService } from '@services/reg-token-service.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private route: ActivatedRoute,
    private router: Router,
    private global: Globals,
    private authService: AuthServiceService,
    private regTokenService: RegTokenServiceService
  ) {
    this.initializeApp();
    this.readQueryParam();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  readQueryParam = function () {
    this.route.queryParamMap.subscribe(queryParams => {
      var navigatedPage = queryParams.get("page"); // Page to be navigated
      var redirectUrl = queryParams.get("redirectUrl");

      // console.log("navigatedPage: " + navigatedPage);

      if (navigatedPage === 'payment-confirmation') {

        var deviceId = queryParams.get("deviceId");
        var encounterNo = queryParams.get("encounterNo");
        var adjustedFlagTime = queryParams.get("adjustedFlagTime");
        var deviceIdList = queryParams.get("deviceIdList");
        var sessionId = queryParams.get("sessionId");
        var transactionNo = queryParams.get("transactionNo");
        var success = queryParams.get("success");

        // console.log("------------ Payment Transaction Detail --------------");
        // console.log("deviceId: " + deviceId);
        // console.log("encounterNo: " + encounterNo);
        // console.log("adjustedFlagTime: " + adjustedFlagTime);
        // console.log("deviceIdList: " + deviceIdList);
        // console.log("sessionId: " + sessionId);
        // console.log("transactionNo: " + transactionNo);
        // console.log("success: " + success);

        this.regTokenService.setEncounterNo(encounterNo);
        this.regTokenService.setAdjustedFlagTime(adjustedFlagTime);
        this.regTokenService.setDeviceId(deviceId);
        this.regTokenService.setDeviceIdList(deviceIdList);
        this.regTokenService.setTransactionId(sessionId);
        this.regTokenService.setTransactionNo(transactionNo);

        if (this.global.isEmpty(deviceId) || this.global.isEmpty(encounterNo)
          || this.global.isEmpty(adjustedFlagTime) || this.global.isEmpty(deviceIdList)
          || this.global.isEmpty(sessionId) || this.global.isEmpty(transactionNo) || this.global.isEmpty(success)) {
          this.authService.unauthorized();
        }
        this.router.navigate(['/payment-confirmation', success, transactionNo]);

      } else if (navigatedPage === 'subscription-done') {

        var sessionId = queryParams.get("sessionId");
        var transactionNo = queryParams.get("transactionNo");
        var success = queryParams.get("success");

        if (this.global.isEmpty(transactionNo) || this.global.isEmpty(sessionId) || this.global.isEmpty(success)) {
          this.router.navigate(['/home']);
        } else {
          this.router.navigate(['/subscription-done', success, transactionNo, sessionId]);
        }

      }
      else if (!this.global.isEmpty(navigatedPage)) {
        if (!this.global.isEmpty(redirectUrl)) {
          this.router.navigate([navigatedPage], { queryParams: { "redirectUrl": redirectUrl } });
        } else {
          this.router.navigate(['/' + navigatedPage]);
        }
      }
    });
  }
}
