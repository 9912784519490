<app-header-left-menu></app-header-left-menu>
<ion-content id="main-content" class="full-width-page">
  <div class="bg-theme-alt">
    <div class="bg-grad-special">
      <div class="bg-grad-special-alt">
        <div class="nk-ovm shape-u shape-contain shape-left-top"></div>
        <app-header></app-header>
        <div class="subContent">
          <div class="main-content container">
            <!-- <div class="error-container" *ngIf="errorCode === '404'">
              <div class="error-image">
                <img src="assets/images/error-404.png">
              </div>
              <div class="error">
                <div>
                  <h2 class="error-head">404</h2>
                  <div class="error-msg">Oops! The page you are looking for doesn't exists.</div>
                </div>
                <button class="btn btn-grad btn-md" [routerLink]="['/home']">Go Home</button>
              </div>
            </div>
            <div class="error-container" *ngIf="errorCode === '401'">
              <div class="error-image">
                <img src="assets/images/error-401.png">
              </div>
              <div class="error">
                <div>
                  <h2 class="error-head">Unauthorized</h2>
                  <div class="error-msg">Oops! You are not authorized to access the page you requested.</div>
                </div>
                <button class="btn btn-grad btn-md" [routerLink]="['/home']">Go Home</button>
              </div>
            </div>
            <div class="error-container" *ngIf="errorCode === '500'">
              <div class="error-image">
                <img src="assets/images/error-404.png">
              </div>
              <div class="error">
                <div>
                  <h2 class="error-head">500</h2>
                  <div class="error-msg">The server encountered an internal error and we are not able to complete your request.</div>
                </div>
                <button class="btn btn-grad btn-md" [routerLink]="['/home']">Go Home</button>
              </div>
            </div> -->
            <div class="nk-wrap ov-h">
              <div class="container">
                <main class="nk-pages nk-pages-centered tc-dark px-0 text-center">
                  <div class="my-auto1">
                    <div class="row justify-content-center">
                      <div class="col-xl-6 col-md-7 col-sm-9">
                        <div class="position-relative" *ngIf="errorCode === '404'">
                          <h2 class="title-xxl-grad">404</h2>
                          <p class="">Oops! The page you are looking for doesn't exists.</p>
                          <a [routerLink]="['/home']" class="btn btn-grad btn-md btn-round">Go to Home</a>
                        </div>
                      </div>
                    </div>
                    <div class="row justify-content-center">
                      <div class="col-xl-6 col-md-7 col-sm-9">
                        <div class="position-relative" *ngIf="errorCode === '401'">
                          <h2 class="title-xxl-grad" style="font-size: 70px !important;">Unauthorized</h2>
                          <p class="">Oops! You are not authorized to access the page you requested.</p>
                          <a [routerLink]="['/home']" class="btn btn-grad btn-md btn-round">Go to Home</a>
                        </div>
                      </div>
                    </div>
                    <div class="row justify-content-center">
                      <div class="col-xl-6 col-md-7 col-sm-9">
                        <div class="position-relative" *ngIf="errorCode === '500'">
                          <h2 class="title-xxl-grad">500</h2>
                          <p class="">The server encountered an internal error and we are not able to complete your
                            request.</p>
                          <a [routerLink]="['/home']" class="btn btn-grad btn-md btn-round">Go to Home</a>
                        </div>
                      </div>

                    </div>
                  </div>

                </main>
              </div>
              <div class="nk-ovm shape-z4"></div>

            </div>
          </div>
        </div>
        <app-footer></app-footer>
      </div>
    </div>
  </div>
</ion-content>