<app-header-left-menu [user]="user"></app-header-left-menu>
<ion-content>
  <div class="bg-theme-alt">
    <div class="bg-grad-special">
      <div class="bg-grad-special-alt">
        <div class="page-container">
          <app-header [user]="user"></app-header>
          <div id="login-popup">
            <div class="ath-body">
              <div class="left-image-section"></div>
              <div class="right-section">
                <h5 class="ath-heading title text-lg-left text-center">Sign in <small class="tc-default">with your
                    Hiddin
                    Account</small></h5>
                <div class="right-align">
                  <p>Sign in with <a href="javascript: void(0)"><label *ngIf="isMobileLoginEnabled"
                        (click)="toggleLoginMethod()">Email</label><label *ngIf="!isMobileLoginEnabled"
                        (click)="toggleLoginMethod()">Mobile
                        Number</label></a></p>
                </div>
                <form [formGroup]="signUpForm" (ngSubmit)="login()">
                  <div class="field-item" *ngIf="!isMobileLoginEnabled">
                    <div class="field-wrap">
                      <input type="email" formControlName="emailAddress" class="input-bordered"
                        [(ngModel)]="userForm.emailAddress"
                        [ngClass]="{ 'is-invalid': (submitted || f.emailAddress.touched) && f.emailAddress.errors }"
                        placeholder="Your Email" />
                      <div *ngIf="(submitted || f.emailAddress.touched) && f.emailAddress.errors"
                        class="invalid-feedback">
                        <div *ngIf="f.emailAddress.errors.required">Email is required</div>
                        <div *ngIf="f.emailAddress.errors.email || f.emailAddress.errors.pattern">Email must be a valid
                          email address</div>
                      </div>
                    </div>
                  </div>
                  <div class="field-item" *ngIf="isMobileLoginEnabled">
                    <div class="field-wrap">
                      <ngx-intl-tel-input type="text" formControlName="phoneNumber" [cssClass]="'phoneNumber'"
                        [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="false"
                        [enablePlaceholder]="true" [searchCountryFlag]="true"
                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                        [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.UnitedStates" [maxLength]="15"
                        [phoneValidation]="true" [separateDialCode]="separateDialCode"
                        [numberFormat]="PhoneNumberFormat.International" [(ngModel)]="userForm.phoneNumberAll"
                        autocomplete="disabled" [ngClass]="{ 'is-invalid': submitted && f.phoneNumber.errors}"
                        required />
                      <div *ngIf="(submitted || f.phoneNumber.touched) && f.phoneNumber.errors"
                        class="invalid-feedback">
                        <div *ngIf="f.phoneNumber.errors.required">Phone number is required</div>
                      </div>
                      <div *ngIf="(submitted || f.phoneNumber.touched) && f.phoneNumber.errors"
                        class="invalid-feedback">
                        <div *ngIf="!f.phoneNumber.errors.validatePhoneNumber.valid">Invalid phone number</div>
                      </div>
                    </div>
                  </div>
                  <div class="field-item">
                    <div class="field-wrap">
                      <input type="password" formControlName="userPassword" class="input-bordered"
                        [(ngModel)]="userForm.userPassword"
                        [ngClass]="{ 'is-invalid': (submitted || f.userPassword.touched) && f.userPassword.errors }"
                        placeholder="Enter Password" id="loginPwd" onPaste="return false" placeholder="Password" />
                      <i class="fa" [ngClass]="passwordVisible ? 'fa-eye-slash' : 'fa-eye'"
                        (click)="togglePassword('loginPwd')"></i>
                      <div *ngIf="(submitted || f.userPassword.touched) && f.userPassword.errors"
                        class="invalid-feedback">
                        <div *ngIf="f.userPassword.errors.required">Password is required</div>
                        <div *ngIf="f.userPassword.errors.minlength">Password must be at least 6 characters</div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="errorMessage && errorMessage != ''" class="alert alert-danger title title-xs mt-2 mb-2">
                    {{errorMessage}}
                  </div>
                  <div class="field-item d-flex justify-content-between align-items-center">
                    <button class="btn btn-primary btn-block btn-md left" style="width: 100px !important;"
                      type="submit">Sign In</button>
                    <div class="forget-link fz-6">
                      <a [routerLink]="['/forgot-pass']">Forgot password?</a>
                    </div>
                  </div>
                </form>
                <div class="ath-note text-center">
                  Don’t have an account? <a [routerLink]="['/basic-reg']"> <strong>Sign up here</strong></a>
                </div>
              </div>
            </div>
          </div>
          <app-footer></app-footer>
        </div>
      </div>
    </div>
  </div>
</ion-content>