import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { AuthServiceService } from '@services/auth-service.service';
import { LoaderService } from '@services/loader.service';
import { Globals } from 'src/app/globals';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';

@Component({
  selector: 'app-collect-directory-form',
  templateUrl: './collect-directory-form.component.html',
  styleUrls: ['./collect-directory-form.component.scss'],
})
export class CollectDirectoryFormComponent implements OnInit {

  @Input('section') section: string;
  @Input('id') id: number;

  separateDialCode = false;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.India];

  submitted: boolean = false;
  errorMessage: string;

  relationList:any;
  userDirectoryForm: FormGroup;
  userData: any = {}

  requestPath:any;
  years = [];

  isValidPoneNumber: boolean;
  phoneMessage: string = '';
  internationalPhoneNo: string;
  selectedMobilNo: any;
  
  intlOptions = {
    initialCountry: 'us',
    autoHideDialCode: true,
    separateDialCode: false,
    enableAutoCountrySelect: true,
    autoPlaceholder: 'polite',
    nationalMode: true,
    customPlaceholder: function (selectedCountryPlaceholder) {
      return 'Example : ' + selectedCountryPlaceholder;
    }
  }

  constructor(private modalCtrl: ModalController
    , private fb: FormBuilder
    , private loader: LoaderService
    , private global: Globals
    , private authService: AuthServiceService) {
    this.requestPath = this.global.requestPath;
  }

  async dismiss(data: any) {
    this.modalCtrl.dismiss(data);
  }

  ngOnInit() {
    this.userDirectoryForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      phoneNumber: ['', [Validators.required, this.noWhitespace]],
      dob: ['', [Validators.required]],
    })
  }

  public noWhitespace(control: FormControl) {
    let isWhitespace = (control.value || '').trim().length === 0;
    let isValid = !isWhitespace;
    return isValid ? null : { 'required': true }
  }

  ionViewDidEnter() {
    this.submitted = false;
    this.errorMessage = null;
    this.resetPhoneNumber();
    this.populateYears();

    if (!this.global.isEmpty(this.id)) {
      this.fetchDetail(this.id);
    }
  }

  resetPhoneNumber() {
    this.selectedMobilNo = null;
    this.userData.phoneNumberAll = null;
    this.userData.phoneDialCode = "1";
    this.userData.phoneCountryShortName = "us";
    this.isValidPoneNumber = false;
    this.phoneMessage = null;
  }

  getRelationList = function () {
    this.relationList = [];
    this.loader.show("Loading data...");
    this.authService.getNoSecure('ns/refData?' + this.requestPath + '=findAllByParentId&parentId=' + 8)
      .subscribe((res: any) => {
        this.loader.hide();
        if (res.success) {
          this.relationList = this.global.decryptDefault(res.data);
        }
      }, error => {
        this.loader.hide();
      });
  }

  // getRelationList = function () {
  //   this.relationList = [];

  //   this.loader.show("Loading data...");
  //   this.authService.getNoSecure('ns/refData?' + this.requestPath + '=findAllByParentId&parentId=' + 8)
  //     .subscribe((res) => {
  //       this.loader.hide();
  //       if (res.success) {
  //         console.log(res);
  //         this.relationList = this.global.decryptDefault(res.data);

  //         if (!this.global.isEmpty(this.id)) {
  //           this.fetchMember(this.id);
  //         }
  //       }
  //     }, error => {
  //       this.loader.hide();
  //       console.log(error);
  //     });
  // }

  populateYears() {
    this.years = [];
    var currentYear = new Date().getFullYear();
    for (var i = new Date().getFullYear(); i >= 1950; i--) {
      this.years.push(i);
    }
  }

  get dirDetail() { return this.userDirectoryForm.controls; }

  fetchDetail = function (id) {
    this.errorMessage = null;
    this.userData = {};
    this.loader.show("Please wait...");
    this.authService.get('enrol/familymember?' + this.requestPath + '=get&memberId=' + id)
      .subscribe((res: any) => {
        this.loader.hide();
        //console.log(res);
        if (res.success) {
          var data = this.global.decrypt(res.data);
          if (!this.global.isEmpty(data)) {
            this.userData = data[0];
            // this.intlOptions.initialCountry = this.userData.phoneCountryShortName;
          }
        } else {
          //console.log(res.message);
          this.errorMessage = res.message;
        }
      }, (error: any) => {
        this.loader.hide();
        //console.log(error);
        this.errorMessage = "Something went wrong, please try after sometime.";
      });
  }

  saveDetails() {
    this.submitted = true;
    this.errorMessage = null;

    // stop here if form is invalid
    if (this.userDirectoryForm.invalid) {
      //console.log("Invalid: " + this.userDirectoryForm.value);
      return;
    }


    if (!this.isValidPoneNumber) {
      this.phoneMessage = "This is not a valid number";
      return;
    }
    this.userData.phoneNumber = this.userData.phoneNumberAll?.e164Number;
 
    this.loader.show("Please wait...");

    // this.userData.useType = 7; //DEFAULTED
    this.userData.relationshipId = 7;

    this.authService.postEncrypt('enrol/familymember?' + this.requestPath + '=save', this.userData)
      .subscribe((res) => {
        this.loader.hide();
        if (res.success) {
          this.dismiss('ok');
        } else {
          this.errorMessage = res.message;
        }
      }, error => {
        this.loader.hide();
        //console.log('Oops! Error occured while adding member. Please try again later.')
        this.errorMessage = 'Error occured, please try again.';
      });
  }

  fetchMember = function (id) {
    this.errorMessage = null;
    this.userData = {};
    this.loader.show("Please wait...");
    this.authService.get('enrol/familymember?' + this.requestPath + '=get&memberId=' + id)
      .subscribe((res) => {
        this.loader.hide();
        //console.log(res);
        if (res.success) {
          var memberList = this.global.decrypt(res.data);
          if (!this.global.isEmpty(memberList)) {
            this.userData = memberList[0];
          }
        } else {
          //console.log(res.message);
          this.errorMessage = res.message;
        }
      }, error => {
        this.loader.hide();
        //console.log(error);
        this.errorMessage = "Something went wrong, please try after sometime.";
      });
  }

  // Phone number with country code field
  hasError = function (event) {
    if (event) {
      this.isValidPoneNumber = true;
      this.phoneMessage = "This is a valid number";
    } else {
      this.isValidPoneNumber = false;
      this.phoneMessage = "Invalid number";
    }
  }
  getNumber = function (event) {
    this.isValidPoneNumber = true;
    this.selectedMobilNo = event;
  }
  telInputObject = function (event) {
    
  }
  onCountryChange = function (event) {
    this.userData.phoneDialCode = event.dialCode;
    this.userData.phoneCountryShortName = event.iso2;
  }

}
