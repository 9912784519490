import * as sjcl from 'sjcl';
declare var jQuery: any;

export class EncryptionUtil {

    private static options = { iter: 1000, ks: 128, ts: 64, iv: "5olvvndy2od23jThveFRyw==", salt: "parYv9E1LYM=" }

    static encryptMe = function (password, message) {
        //Encrypt
        var encryptedMessage = sjcl.encrypt(password, message, this.options);
        var parsedMessage = JSON.parse(encryptedMessage);
        var prop;
        for (prop in this.options) {
            delete parsedMessage[prop];
        }
        return parsedMessage.ct;
    }

    static decryptMe = function (password, encMessage) {
        //Decrypt
        var data = { "v": 1, "mode": "ccm", "adata": "", "cipher": "aes", "ct": encMessage };
        var frmtData = JSON.stringify(data);
        var parsedMessage = JSON.parse(frmtData);
        jQuery.extend(parsedMessage, this.options);
        var messageWithParameters = JSON.stringify(parsedMessage);
        return sjcl.decrypt(password, messageWithParameters);
    }

    static encrypt(passphrase, plaintext) {
        return this.encryptMe(passphrase, plaintext);
    }

    static decrypt(passphrase, encText) {
        return this.decryptMe(passphrase, encText);
    }

    static encryptDefault(default_key, plaintext) {
        return this.encryptMe(default_key, plaintext);;
    }

    static decryptDefault(default_key, encText) {
        return this.decryptMe(default_key, encText);
    }

}