import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RegTokenServiceService } from '@services/reg-token-service.service';
import { Globals } from 'src/app/globals';
import { AuthServiceService } from '@services/auth-service.service';
import { LoaderService } from '@services/loader.service';
import { RegUser } from '../basic-reg/RegUser';

@Component({
  selector: 'app-payment-confirmation',
  templateUrl: './payment-confirmation.component.html',
  styleUrls: ['./payment-confirmation.component.scss']
})
export class PaymentConfirmationComponent implements OnInit {

  user: RegUser;

  paymentSuccessful;

  encounterNo;
  adjFlagTime;
  deviceId;
  deviceIdList;
  transactionId;
  transactionNo;
  requestPath = this.global.requestPath;

  constructor(
    private route: ActivatedRoute,
    private global: Globals,
    private router: Router,
    private regTokenService: RegTokenServiceService,
    private authService: AuthServiceService,
    private loader: LoaderService) {

    this.route.params.subscribe(params => {

      this.paymentSuccessful = params['success'];
      this.transactionNo = params['transactionNo'];

      // console.log("paymentSuccessful: " + this.paymentSuccessful);
      // console.log("transactionId: " + this.transactionId);

      if (this.global.isEmpty(this.transactionNo) || this.global.isEmpty(this.paymentSuccessful)) {
        this.router.navigate(['/home']);
      }

      if (this.paymentSuccessful == "true") {
        this.encounterNo = this.regTokenService.getEncounterNo();
        this.adjFlagTime = this.regTokenService.getAdjustedFlagTime();
        this.deviceId = this.regTokenService.getDeviceId();
        this.deviceIdList = this.regTokenService.getDeviceIdList().split(",");
        this.transactionId = this.regTokenService.getTransactionId();

        // console.log("encounterNo: " + this.encounterNo);
        // console.log("adjFlagTime: " + this.adjFlagTime);
        // console.log("deviceId: " + this.deviceId);
        // console.log("deviceIdList: " + this.deviceIdList);
        // console.log("transactionId: " + this.transactionId);
        // console.log("transactionNo: " + this.transactionNo);

        if (this.global.isEmpty(this.encounterNo) || this.global.isEmpty(this.adjFlagTime)
          || this.global.isEmpty(this.deviceId) || this.global.isEmpty(this.deviceIdList)
          || this.global.isEmpty(this.transactionId) || this.global.isEmpty(this.transactionNo)) {
          this.router.navigate(['/home']);
        }

        this.markPayDone();
      }
    });
  }

  ngOnInit(): void {
  }

  ionViewDidEnter() {
    this.user = this.authService.getLoggedInUser();
  }

  markPayDone = function () {

    var message = this.regTokenService.getDefaultMessage();
    // Device ID is actual device id, and sender id is phone/laptop id
    message.senderId = this.deviceId;
    message.encounterNo = this.encounterNo;
    message.adjustedFlagTime = this.adjFlagTime;
    message.transactionNo = this.transactionNo;
    message.transactionId = this.transactionId;
    message.deviceIdList = this.deviceIdList;

    this.loader.show("Processing...");
    this.authService.postDefaultEncryptCore('enrollment/?' + this.requestPath + '=transactionSecurityLimitCheckup', message)
      .subscribe((res) => {
        this.loader.hide();
        // console.log(res);
        if (res.success) {
          // console.log("Payment is successfull.");
        } else {
          this.message = res.message;
          // console.log(res.message);
        }
      }, error => {
        this.loader.hide();
        this.message = 'Error occured, please try again.';
        // console.log('Error occured, please try again.');
      });
  }

}
