<app-header-left-menu [user]="user"></app-header-left-menu>
<ion-content id="main-content" class="full-width-page">
  <div class="bg-theme-alt">
    <div class="bg-grad-special">
      <div class="bg-grad-special-alt">
        <div class="nk-ovm shape-u shape-contain shape-left-top"></div>
        <app-header [user]="user"></app-header>
        <div class="subContent">
          <div class="main-content container">
            <!-- <h5 class="ath-heading title">Payment Status<small class="tc-default">Check your payment status here.</small></h5> -->
            <div class="payment-status" *ngIf="paymentSuccessful == 'true'">
              <div class="icon">
                <i class="fa fa-check-circle title tc-success"></i>
              </div>
              <div class="status">
                <div>
                  <h2 class="status-head">Your payment was successful</h2>
                  <p class="status-msg">Thanks for believing in our services.</p>
                  <p class="status-msg">Do visit the app and refresh the checkout page to see the current status.</p>
                  <p class="status-msg">Transaction ID: <b>{{transactionId}}</b></p>
                </div>
                <button class="btn btn-grad btn-md" [routerLink]="['/home']">Go Home</button>
              </div>
            </div>
            <div class="payment-status" *ngIf="paymentSuccessful == 'false'">
              <div class="icon">
                <i class="fa fa-times-circle title tc-red"></i>
              </div>
              <div class="status">
                <div>
                  <h2 class="status-head">Your payment failed</h2>
                  <p class="status-msg">Please try again.</p>
                  <p class="status-msg">Transaction ID: <b>{{transactionId}}</b></p>
                </div>
                <button class="btn btn-grad btn-md" [routerLink]="['/home']">Go Home</button>
              </div>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
      </div>
    </div>
  </div>
</ion-content>