import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { AuthServiceService } from '@services/auth-service.service';
import { LoaderService } from '@services/loader.service';
import { forkJoin } from 'rxjs';
import { Globals } from 'src/app/globals';
import { EncryptionUtil } from 'src/app/util/EncryptionUtil';
import { RegUser } from '../basic-reg/RegUser';

@Component({
  selector: 'app-secure-communication',
  templateUrl: './secure-communication.component.html',
  styleUrls: ['./secure-communication.component.scss'],
})
export class SecureCommunicationComponent implements OnInit {

  user: RegUser = new RegUser();
  requestPath: string;
  errorMessage;

  user1Text;
  user2Text;

  user1Data = [];
  user2Data = [];

  user1Id = 2;
  user2Id = 6;

  communicationId = "";
  aesKey = "";
  allCommIds = "";

  constructor(public global: Globals
    , public authService: AuthServiceService
    , public modalController: ModalController
    , public formBuilder: FormBuilder
    , public router: Router
    , private loader: LoaderService) {
    this.requestPath = this.global.requestPath;
  }

  ngOnInit() { }

  ionViewDidEnter() {
    this.user = this.authService.getLoggedInUser();

    this.getCommunicationKey();
    this.getAllUSerData();
  }

  sendUser1Text() {
    this.errorMessage = null;

    if (this.global.isEmpty(this.user1Text)) {
      return;
    }

    var data = {
      "senderId": this.user1Id,
      "recieverId": this.user2Id,
      "communicationId": this.communicationId,
      "msgData": EncryptionUtil.encrypt(this.aesKey, this.user1Text)
    };

    // this.loader.show("Please wait...");
    this.authService.postEncryptCore('transaction/communication?' + this.requestPath + '=sendData', data)
      .subscribe(res => {
        // this.loader.hide();

        // console.log(res);
        if (res.success) {
          // console.log("Message Sent by user " + this.user1Id + ": " + data);
          this.user1Text = "";
          this.getAllUSerData();
        } else {
          this.errorMessage = res.message;
        }
      }, error => {
        // this.loader.hide();
        // console.log(error);
        this.errorMessage = "Something went wrong, please try again.";
      });
  }

  sendUser2Text() {
    this.errorMessage = null;

    if (this.global.isEmpty(this.user2Text)) {
      return;
    }

    var data = {
      "senderId": this.user2Id,
      "recieverId": this.user1Id,
      "communicationId": this.communicationId,
      "msgData": EncryptionUtil.encrypt(this.aesKey, this.user2Text)
    };

    // this.loader.show("Please wait...");
    this.authService.postEncryptCore('transaction/communication?' + this.requestPath + '=sendData', data)
      .subscribe(res => {
        // this.loader.hide();

        // console.log(res);
        if (res.success) {
          // console.log("Message Sent by user " + this.user2Id + ": " + data)
          this.user2Text = "";
          this.getAllUSerData();
        } else {
          this.errorMessage = res.message;
        }
      }, error => {
        // this.loader.hide();
        // console.log(error);
        this.errorMessage = "Something went wrong, please try again.";
      });
  }
  getCommunicationKey = function () {
    this.errorMessage = "";
    // this.loader.show("Loading data...");
    this.authService.getSecureCore('transaction/communication?' + this.requestPath + '=getCurrentAESKey')
      .subscribe((res) => {
        // this.loader.hide();
        // console.log(res);
        if (res.success) {
          res.data = this.global.decrypt(res.data);
          this.communicationId = res.data.communicationId;
          this.aesKey = res.data.key;
          // console.log("Communication ID: " + this.communicationId);
        } else {
          this.errorMessage = res.message;
        }
      }, error => {
        // this.loader.hide();
        // console.log("Error in fetching Communication ID");
        this.errorMessage = "Error in fetching Communication ID";
      })
  }

  getAllUSerData() {
    this.errorMessage = "";
    this.user1Data = [];
    this.user2Data = [];
    const user1 = this.authService.getSecureCore('transaction/communication?' + this.requestPath + '=getMessages&senderId=' + this.user1Id);
    const user2 = this.authService.getSecureCore('transaction/communication?' + this.requestPath + '=getMessages&senderId=' + this.user2Id);

    forkJoin([user1, user2]).subscribe(([response1, response2]: any) => {
      if (response1.success == true) {
        this.user1Data = this.global.decrypt(response1.data);
      } else {
        this.errorMessage = response1.message;
        // console.log(this.errorMessage);
      }
      if (response2.success == true) {
        this.user2Data = this.global.decrypt(response2.data);
      } else {
        this.errorMessage = response2.message;
        // console.log(this.errorMessage);
      }
      if (this.user2Data && this.user2Data.length > 0) {
        this.getCommaSep(this.user2Data);
        this.getAllCommunicationIds();
      } else if (this.user1Data && this.user1Data.length > 0) {
        this.getCommaSep(this.user1Data);
        this.getAllCommunicationIds();
      }

    });
  }

  getAllCommunicationIds = function () {
    this.errorMessage = "";
    // this.loader.show("Loading data...");
    this.authService.getSecureCore('transaction/communication?' + this.requestPath + '=getAESKeysForCommunicationId&commId=' + this.allCommIds)
      .subscribe((res) => {
        // this.loader.hide();
        // console.log(res);
        if (res.success) {
          var idsMap = this.global.decrypt(res.data);
          // console.log("AES Kesy: " + JSON.stringify(idsMap));

          var commKeyVsAesKey  = idsMap["commIdVsKey"];

          // Decrypt User 1 Data
          for (var i = 0; i < this.user1Data.length; i++) {
            this.user1Data[i].msgData = EncryptionUtil.decrypt(commKeyVsAesKey[this.user1Data[i].communicationId], this.user1Data[i].msgData);
          }
          // Decrypt User 2 Data
          for (var i = 0; i < this.user2Data.length; i++) {
            this.user2Data[i].msgData = EncryptionUtil.decrypt(commKeyVsAesKey[this.user2Data[i].communicationId], this.user2Data[i].msgData);
          }
        } else {
          this.errorMessage = res.message;
        }
      }, error => {
        // this.loader.hide();
        // console.log("Error in fetching getAllCommunicationIds");
        this.errorMessage = "Error in getAllCommunicationIds";
      })
  }

  getCommaSep(data) {
    this.allCommIds = "";
    for (var i = 0; i < data.length; i++) {
      this.allCommIds = this.allCommIds + data[i].communicationId + ",";
    }
    if (data.length > 0) {
      this.allCommIds = this.allCommIds.substr(0, this.allCommIds.length - 1);
    }
  }

}
