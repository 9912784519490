<app-header-left-menu [user]="user"></app-header-left-menu>
<ion-content id="main-content">
  <div class="bg-theme-alt">
    <div class="bg-grad-special">
      <div class="bg-grad-special-alt">
        <div class="page-container">
          <div class="nk-ovm shape-u shape-contain shape-left-top"></div>
          <app-header [user]="user"></app-header>
          <div id="login-popup">
            <div class="ath-body">
              <div class="left-image-section">
                <!-- <img src="assets/images/hiddin-logo.png"> -->
              </div>
              <div class="right-section">
                <h5 class="ath-heading title">Mobile Verification Code <small class="tc-default">Check your registered
                    mobile for the Verification Code</small>
                </h5>
                <form [formGroup]="otpForm" (ngSubmit)="sumbitOTP()">
                  <div class="field-item">
                    <div class="field-wrap">
                      <h6 class="title title-xs">Verification Code<span class="red">*</span></h6>
                      <input class="input-bordered" type="text" required name="otp"
                        placeholder="Enter Verification Code" formControlName="userOTP" [(ngModel)]="otp"
                        [ngClass]="{ 'is-invalid': otpFormSubmitted && otpForm.controls.userOTP.errors }">
                      <div *ngIf="otpFormSubmitted && otpForm.controls.userOTP.errors" class="invalid-feedback">
                        <div *ngIf="otpForm.controls.userOTP.errors.required">Verification Code is required
                        </div>
                      </div>
                    </div>
                    <div class="timer">
                      <a href="javascript:" [class.disabled]="!activeResendLink"
                        (click)="resendMobileOTP()">Resend</a><span *ngIf="!activeResendLink"> {{time}}</span>
                    </div>
                  </div>
                  <div *ngIf="errorMessage && errorMessage.length > 0" class="alert alert-danger title title-xs mt-2">
                    {{errorMessage}}
                  </div>
                  <button type="submit" class="btn btn-primary btn-block btn-md mb-2 mt-2">Proceed</button>
                </form>
              </div>
            </div>
          </div>
          <app-footer></app-footer>
        </div>
      </div>
    </div>
  </div>
</ion-content>