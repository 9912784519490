import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from 'src/app/globals';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { LoaderService } from 'src/app/services/loader.service';
import * as moment from 'moment';
import { RegUser } from '../basic-reg/RegUser';
import { EncryptionUtil } from 'src/app/util/EncryptionUtil';

@Component({
  selector: 'app-reg-global',
  templateUrl: './reg-global.component.html',
  styleUrls: ['./reg-global.component.scss'],
})
export class RegGlobalComponent implements OnInit {

  userForm: RegUser = new RegUser();

  // emailPattern = "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$";
  emailPattern = "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?";
  requestPath: string;

  dateValidation: boolean;

  /* Phone number form */
  phoneNoSubmitted: boolean;
  mobileNoForm: FormGroup;
  phoneNoFormEnabled: boolean;
  phoneNoErrorMessage: string;
  encDefaultKey: string;

  /* OTP form */
  otpSubmitted: boolean;
  otpForm: FormGroup;
  otpFormEnabled: boolean;
  otpErrorMessage: string;
  defaultKey: string;

  /* OTP form */
  signUpForm: FormGroup;
  submitted: boolean = false;
  errorMessage: string;
  detailFormEnabled: boolean;
  matchPassword: boolean;

  /* Success Message */
  signupSuccess: boolean = false;

  dob;

  constructor(public global: Globals
    , public authService: AuthServiceService
    , public formBuilder: FormBuilder
    , public router: Router
    , private loader: LoaderService) {
    this.requestPath = this.global.requestPath;
  }

  ngOnInit() {

    this.mobileNoForm = this.formBuilder.group({
      phoneNumber: ['', [Validators.required]]
    });

    this.otpForm = this.formBuilder.group({
      mobileOtp: ['', [Validators.required]]
    });

    this.signUpForm = this.formBuilder.group({
      firstName: ['', [Validators.required, this.noWhitespace, Validators.maxLength(100)]],
      lastName: ['', [this.noWhitespace, Validators.maxLength(100)]],
      emailAddress: ['', [Validators.required, this.noWhitespace, Validators.maxLength(50), Validators.pattern(this.emailPattern)]],
      userPassword: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(6)]],
      birthDate: ['', [Validators.required]]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.signUpForm.controls; }
  get phn() { return this.mobileNoForm.controls; }
  get otp() { return this.otpForm.controls; }

  user: RegUser = new RegUser();
  LoggedIn: boolean;

  ionViewDidEnter() {
    this.initUser();
    this.onReset();
    this.user = this.authService.getLoggedInUser();
    // this.user.imageUrl = this.authService.getProfilePicture();
    if (!this.global.isEmpty(this.user)) {
      this.user.imageUrl = this.authService.getProfilePicture();
    }
  }

  ionViewWillEnter() {
    this.user = this.authService.getLoggedInUser();
    this.LoggedIn = this.authService.isLoggedIn();
    if (!this.global.isEmpty(this.user)) {
      this.user.imageUrl = this.authService.getProfilePicture();
    }
  }

  initUser = function () {
    this.signUpForm.reset();
    this.userForm = new RegUser();

    // Show Phone Number form
    this.phoneNoFormEnabled = true;
    this.otpFormEnabled = false;
    this.detailFormEnabled = false;
    this.matchPassword = true;
    this.signupSuccess = false;
  }

  mobileNoSubmit = function () {
    this.phoneNoSubmitted = true;
    this.phoneNoErrorMessage = null;

    // stop here if form is invalid
    if (this.mobileNoForm.invalid) {
      // console.log("Invalid: " + this.mobileNoForm.value);
      return;
    }

    // Hardcoded, need to remove
    this.userForm.phoneDialCode = "91";

    this.loader.show("Please wait...");
    this.authService.postDefaultEncrypt('ns/auth?' + this.requestPath + '=signup_mob', this.userForm)
      .subscribe(res => {
        this.loader.hide();
        // console.log(res);
        if (res.success) {
          //Decrypt Data
          res.data = this.global.decryptDefault(res.data);
          this.encDefaultKey = res.data.key;
          this.phoneNoFormEnabled = false;
          this.otpFormEnabled = true;
        } else {
          this.phoneNoErrorMessage = res.message;
        }
      }, error => {
        this.loader.hide();
        // console.log(error);
        this.phoneNoErrorMessage = "Something went wrong, please try again.";
      });
  }

  otpSubmit = function () {
    this.otpSubmitted = true;
    this.otpErrorMessage = null;

    // stop here if form is invalid
    if (this.otpForm.invalid) {
      // console.log("Invalid: " + this.otpForm.value);
      return;
    }

    // Decrypt default key
    try {
      this.defaultKey = EncryptionUtil.decrypt(this.userForm.mobileOtp, this.encDefaultKey);
    }
    catch (err) {
      this.otpErrorMessage = "Invalid verification code";
      // console.log("Error in decrypting default key:" + err);
    }
    this.userForm.mobileOtp = EncryptionUtil.encrypt(this.defaultKey, this.userForm.mobileOtp);

    this.loader.show("Please wait...");
    this.authService.postDefaultEncrypt('ns/auth?' + this.requestPath + '=signup_otp_verify', this.userForm)
      .subscribe(res => {
        this.loader.hide();
        // console.log(res);
        if (res.success) {
          this.otpFormEnabled = false;
          this.detailFormEnabled = true;
        } else {
          this.otpErrorMessage = res.message;
        }
      }, error => {
        this.loader.hide();
        // console.log(error);
        this.otpErrorMessage = "Something went wrong, please try again.";
      });
  }

  public noWhitespace(control: FormControl) {
    let isWhitespace = (control.value || '').toString().trim().length === 0;
    let isValid = !isWhitespace;
    return isValid ? null : { 'required': true }
  }

  public signup() {
    this.submitted = true;
    this.errorMessage = null;
    this.matchPassword = true;

    // stop here if form is invalid
    if (this.signUpForm.invalid) {
      // console.log("Invalid: " + this.signUpForm.value);
      return;
    }

    if (this.dateValidation) {
      return;
    }

    if (this.userForm.userPassword !== this.userForm.confirmPassword) {
      this.matchPassword = false;
      return;
    }

    this.userForm.password = window.btoa(this.userForm.userPassword);
    this.userForm.email = this.userForm.emailAddress;

    var d = new Date();
    var dateFormatStr = d.getFullYear().toString() + "-" + ((d.getMonth() + 1).toString().length == 2 ? (d.getMonth() + 1).toString() : "0" + (d.getMonth() + 1).toString()) + "-" + (d.getDate().toString().length == 2 ? d.getDate().toString() : "0" + d.getDate().toString()) + " " + (d.getHours().toString().length == 2 ? d.getHours().toString() : "0" + d.getHours().toString()) + ":" + ((parseInt((d.getMinutes() / 5).toString()) * 5).toString().length == 2 ? (parseInt((d.getMinutes() / 5).toString()) * 5).toString() : "0" + (parseInt((d.getMinutes() / 5).toString()) * 5).toString()) + ":00";

    // console.log("Signup Date: " + dateFormatStr);
    this.userForm.signupTime = EncryptionUtil.encrypt(this.defaultKey, dateFormatStr);

    // Encrypt data
    this.userForm.firstName = EncryptionUtil.encrypt(this.defaultKey, this.userForm.firstName);
    this.userForm.lastName = EncryptionUtil.encrypt(this.defaultKey, this.userForm.lastName);
    this.userForm.email = EncryptionUtil.encrypt(this.defaultKey, this.userForm.email);
    this.userForm.password = EncryptionUtil.encrypt(this.defaultKey, this.userForm.password);
    this.userForm.birthDate = EncryptionUtil.encrypt(this.defaultKey, this.userForm.birthDate);

    this.loader.show("Please wait...");
    this.authService.postDefaultEncrypt('ns/auth?' + this.requestPath + '=signup_details', this.userForm)
      .subscribe(res => {
        this.loader.hide();
        // console.log(res);
        if (res.success) {
          //Decrypt Data
          res.data = this.global.decryptDefault(res.data);
          this.global.saveLocalData(this.global.HIDDIN_USER_DETAILS, JSON.stringify(res.data));
          this.detailFormEnabled = false;
          this.signupSuccess = true;
        } else {
          this.errorMessage = res.message;
        }
      }, error => {
        this.loader.hide();
        // console.log(error);
        this.errorMessage = "Something went wrong, please try again.";
      });
  }

  goToLogin = function () {
    this.router.navigate(['/login']);
  }

  onReset = function () {
    this.submitted = false;
    this.signUpForm.reset();
    this.errorMessage = null;
    this.dateValidation = false;
  }

  onDateChange(birthDate: any): void {

    // birthDate = this.toModel(birthDate);

    if (this.global.isEmpty(birthDate)) {
      // console.log("Birth Date is Empty");
      this.signUpForm.controls['birthDate'].setErrors({ 'incorrect': true });
      return;
    }

    this.dateValidation = moment(moment().format('YYYY-MM-DD')).isBefore(moment(birthDate).format('YYYY-MM-DD'));

    this.userForm.birthDate = birthDate;
  }

  readonly DELIMITER = '-';

  toModel(date: NgbDateStruct | null): string | null {
    return date ? date.year + this.DELIMITER + date.month + this.DELIMITER + date.day : null;
  }

}

