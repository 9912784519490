import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from 'src/app/globals';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { LoaderService } from 'src/app/services/loader.service';
import { MustMatch } from 'src/app/util/must-match.validator';
import * as moment from 'moment';
import { RegUser } from './RegUser';
import { ModalController } from '@ionic/angular';
import { EmailVerificationInfoComponent } from '@modal/email-verification-info/email-verification-info.component';
import { TimeZoneConstant } from 'src/app/constants/TimeZone';
import { AppPropService } from '@services/app-prop.service';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';

@Component({
  selector: 'app-basic-reg',
  templateUrl: './basic-reg.component.html',
  styleUrls: ['./basic-reg.component.scss'],
})
export class BasicRegComponent implements OnInit {
 
  separateDialCode = false;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.India];

  intlOptions = {
    initialCountry: 'us',
    autoHideDialCode: true,
    separateDialCode: true,
    enableAutoCountrySelect: true,
    autoPlaceholder: 'polite',
    nationalMode: true,
    customPlaceholder: function (selectedCountryPlaceholder) {
      return 'Example : ' + selectedCountryPlaceholder;
    }
  }

  userForm: RegUser = new RegUser();

  signUpForm: FormGroup;
  submitted: boolean = false;
  errorMessage: string;

  emailAlreadyExist: boolean;
  emailMessage: string;

  passwordVisible: boolean = false;
  confirmPasswordVisible: boolean = false;

  phoneMessage: string = "";
  selectedMobilNo;
  isValidPoneNumber: boolean = false;
  internationalPhoneNo;

  // emailPattern = "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$";
  emailPattern = "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?";
  requestPath: string;

  dob: NgbDateStruct;
  dateValidation: boolean;
  dateFormatError: boolean;

  constructor(public global: Globals
    , public authService: AuthServiceService
    , public formBuilder: FormBuilder
    , public modalController: ModalController
    , public router: Router
    , private loader: LoaderService
    , private appPropService: AppPropService) {
    this.requestPath = this.global.requestPath;
  }

  ngOnInit() {
    this.signUpForm = this.formBuilder.group({
      firstName: ['', [Validators.required, this.noWhitespace, Validators.maxLength(100)]],
      lastName: ['', [this.noWhitespace, Validators.maxLength(100)]],
      emailAddress: ['', [Validators.required, this.noWhitespace, Validators.maxLength(50), Validators.pattern(this.emailPattern)]],
      confirmEmailAddress: ['', [Validators.required, this.noWhitespace, Validators.maxLength(50), Validators.pattern(this.emailPattern)]],
      // userPassword: ['', [Validators.required, Validators.minLength(6)]],
      // confirmPassword: ['', [Validators.required, Validators.minLength(6)]],
      phoneNumber: ['', [Validators.required, this.noWhitespace]],
      birthDate: ['', [Validators.required]]
    }, {
      // validator: MustMatch('userPassword', 'confirmPassword'),
      validator: MustMatch('emailAddress', 'confirmEmailAddress')
    });
  }

  user: RegUser = new RegUser();
  LoggedIn: boolean;

  ionViewDidEnter() {
    this.initUser();
    this.onReset();
    this.user = this.authService.getLoggedInUser();
    // this.user.imageUrl = this.authService.getProfilePicture();
    if (!this.global.isEmpty(this.user)) {
      this.user.imageUrl = this.authService.getProfilePicture();
    }
  }

  ionViewWillEnter() {
    this.user = this.authService.getLoggedInUser();
    this.LoggedIn = this.authService.isLoggedIn();
    if (!this.global.isEmpty(this.user)) {
      this.user.imageUrl = this.authService.getProfilePicture();
    }
  }

  initUser = function () {
    this.signUpForm.reset();
    this.userForm = new RegUser();
    this.userForm.type = "Individual";
  }

  // convenience getter for easy access to form fields
  get f() { return this.signUpForm.controls; }

  public noWhitespace(control: FormControl) {
    let isWhitespace = (control.value || '').toString().trim().length === 0;
    let isValid = !isWhitespace;
    return isValid ? null : { 'required': true }
  }

  public signup() {
    this.submitted = true;
    this.errorMessage = null;

    // stop here if form is invalid
    if (this.signUpForm.invalid) {
      // console.log("Invalid: " + this.signUpForm.value);
      return;
    }

    if (this.dateValidation) {
      return;
    }

    if (this.dateFormatError) {
      return;
    }
    console.log("this.userForm", this.userForm);
  
    this.internationalPhoneNo = this.userForm.phoneNumberAll.internationalNumber;
    this.userForm.phoneDialCode = this.userForm.phoneNumberAll?.dialCode;
    this.userForm.phoneCountryShortName = this.userForm.phoneNumberAll?.countryCode;
    this.userForm.phoneNumber = this.userForm.phoneNumberAll?.e164Number.replace(this.userForm.phoneNumberAll?.dialCode, "");
    this.userForm.email = this.userForm.emailAddress;
    this.userForm.timeZone = TimeZoneConstant.DEFAULT_TIMEZONE;

    var d = new Date();
    var dateFormatStr = d.getFullYear().toString() + "-" + ((d.getMonth() + 1).toString().length == 2 ? (d.getMonth() + 1).toString() : "0" + (d.getMonth() + 1).toString()) + "-" + (d.getDate().toString().length == 2 ? d.getDate().toString() : "0" + d.getDate().toString()) + " " + (d.getHours().toString().length == 2 ? d.getHours().toString() : "0" + d.getHours().toString()) + ":" + ((parseInt((d.getMinutes() / 5).toString()) * 5).toString().length == 2 ? (parseInt((d.getMinutes() / 5).toString()) * 5).toString() : "0" + (parseInt((d.getMinutes() / 5).toString()) * 5).toString()) + ":00";

    // console.log("Signup Date: " + dateFormatStr);
    this.userForm.signupTime = dateFormatStr;

    this.loader.show("Please wait...");
    this.authService.postDefaultEncrypt('ns/common/user?' + this.requestPath + '=doBasicSignup', this.userForm)
      .subscribe(res => {
        this.loader.hide();
        // console.log(res);
        if (res.success) {
          //Decrypt Data
          res.data = this.global.decryptDefault(res.data);
          this.onReset();
          this.initUser();
          this.openEmailVerificationModal(res.data.email);
        } else {
          this.errorMessage = res.message;
        }
      }, error => {
        this.loader.hide();
        // console.log(error);
        this.errorMessage = "Something went wrong, please try again.";
      });
  }

  onReset = function () {
    this.submitted = false;
    this.signUpForm.reset();
    this.errorMessage = null;
    this.dateValidation = false;
    this.dateFormatError = false;
    this.resetPhoneNumber();
  }

  resetPhoneNumber() {
    this.userForm.phoneNumberAll = null;
    this.userForm.phoneDialCode = "1";
    this.userForm.phoneCountryShortName = "us";
    this.isValidPoneNumber = false;
    this.phoneMessage = null;
  }

  onDateChange(birthDate: any): void {
    this.dateValidation = false;
    this.dateFormatError = false;

    if (this.global.isEmpty(birthDate)) {
      this.signUpForm.controls['birthDate'].setErrors({ 'incorrect': true });
      return;
    }
    this.dateValidation = moment(moment().format('YYYY-MM-DD')).isBefore(moment(birthDate).format('YYYY-MM-DD'));

    var date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/ ;
    if (!birthDate.match(date_regex)) {
      this.dateFormatError = true;
    }

    this.userForm.birthDate = birthDate;
  }

  readonly DELIMITER = '-';

  toModel(date: NgbDateStruct | null): string | null {
    return date ? date.year + this.DELIMITER + date.month + this.DELIMITER + date.day : null;
  }

  togglePassword(id: string) {
    const password = document.querySelector('#' + id);
    const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
    password.setAttribute('type', type);

    if (id === 'pwd') {
      this.passwordVisible = !this.passwordVisible;
    }

    if (id === 'confirmPassword') {
      this.confirmPasswordVisible = !this.confirmPasswordVisible;
    }

  }

  async openEmailVerificationModal(email) {
    const modal = await this.modalController.create({
      component: EmailVerificationInfoComponent,
      componentProps: {
        emailAdd: email
      }
    });

    modal.onDidDismiss().then(dataReturned => {
      this.router.navigate(['/home']);
    });

    return await modal.present();
  }

}
