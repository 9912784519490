import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { AuthServiceService } from '@services/auth-service.service';
import { LoaderService } from '@services/loader.service';
import { Globals } from 'src/app/globals';
import { TimeZoneUtils } from 'src/app/util/TimeZoneUtils';
import { RegUser } from '../../basic-reg/RegUser';

@Component({
  selector: 'app-profile-details-new',
  templateUrl: './profile-details-new.component.html',
  styleUrls: ['./profile-details-new.component.scss'],
})
export class ProfileDetailsNewComponent implements OnInit {
  myUser: any;
  user: any;
  userContact: any;
  loggedInUser: RegUser = new RegUser();

  editEnabled: boolean = false;
  addEnabled: boolean = false;
  isSearchResult: boolean = false;
  viewTimeSlot: boolean;
  errorMessage: string;
  requestPath: string;

  selectedTab: string = "basic";

  userPreferredTime = [];
  userPreferredChannel = [];
  myPreferredTime = [];
  myPreferredChannel = [];
  userStartTime;
  userEndTime;
  myStartTime;
  myEndTime;
  senderCategory;
  recieverCategory;

  constructor(public global: Globals,
    public router: Router,
    public authService: AuthServiceService,
    private loader: LoaderService,
    public modlCtrl: ModalController,
    public atrCtrl: AlertController) {
    this.requestPath = this.global.requestPath;
  }

  ngOnInit() {
    if (this.global.isEmpty(this.router.getCurrentNavigation().extras.state)) {
      this.router.navigate(['dashboard']);
    }
    this.editEnabled = this.router.getCurrentNavigation().extras.state.editEnabled;
    this.addEnabled = this.router.getCurrentNavigation().extras.state.addEnabled;
    this.myUser = this.router.getCurrentNavigation().extras.state.user;
    this.isSearchResult = this.router.getCurrentNavigation().extras.state.isSearchResult;
    this.userContact = this.router.getCurrentNavigation().extras.state.userContact;
    this.viewTimeSlot = this.router.getCurrentNavigation().extras.state.viewTimeSlot;
    this.user = this.userContact.contactUser;
  }

  ionViewDidEnter() {
    this.loggedInUser = this.authService.getLoggedInUser();
    this.loggedInUser.imageUrl = this.authService.getProfilePicture();
    this.preferances();
  }

  changeTab(tabName) {
    this.selectedTab = tabName;
  }

  preferances() {
    // Loggedin user is sender
    var userPreferredTime;
    var myPreferredTime;
    var userPreferredChannel;
    var myPreferredChannel;
    if (this.userContact.ownerId == this.myUser.id) {
      userPreferredChannel = this.userContact.recieverCommChanel?.split(",");
      userPreferredTime = this.userContact.recieverPrefTime?.split(",");
      this.userStartTime = this.userContact.recieverStartTime;
      this.userEndTime = this.userContact.recieverEndTime;
      this.recieverCategory = this.userContact.recieverCategory;

      myPreferredChannel = this.userContact.senderCommChanel?.split(",");
      myPreferredTime = this.userContact.senderPrefTime?.split(",");
      this.myStartTime = this.userContact.senderStartTime;
      this.myEndTime = this.userContact.senderEndTime;
      this.senderCategory = this.userContact.senderCategory;
    } else {
      // Loggedin user is reciever
      myPreferredChannel = this.userContact.recieverCommChanel?.split(",");
      myPreferredTime = this.userContact.recieverPrefTime?.split(",");
      this.myStartTime = this.userContact.recieverStartTime;
      this.myEndTime = this.userContact.recieverEndTime;
      this.senderCategory = this.userContact.recieverCategory;

      userPreferredChannel = this.userContact.senderCommChanel?.split(",");
      userPreferredTime = this.userContact.senderPrefTime?.split(",");
      this.userStartTime = this.userContact.senderStartTime;
      this.userEndTime = this.userContact.senderEndTime;
      this.recieverCategory = this.userContact.senderCategory;
    }

    if (!this.global.isEmpty(userPreferredTime) || !this.global.isEmpty(myPreferredTime)) {
      var convertedUserPrefTime;
      var convertedMyPreferredTime;
      if (!this.global.isEmpty(userPreferredTime) && userPreferredTime != "All") {
        convertedUserPrefTime = this.getConvertedTimeList(userPreferredTime);
      }
      if (!this.global.isEmpty(myPreferredTime && myPreferredTime != "All")) {
        convertedMyPreferredTime = this.getConvertedTimeList(myPreferredTime);
      }

      if (userPreferredTime == "All") {
        this.userPreferredTime.push("Anytime");
      } else {
        for (let prefTime of convertedUserPrefTime)
          this.userPreferredTime.push(prefTime);
      }
      if (myPreferredTime == "All") {
        this.myPreferredTime.push("Anytime");
      } else {
        for (let prefTime of convertedMyPreferredTime)
          this.myPreferredTime.push(prefTime);
      }
    }
    if (!this.global.isEmpty(userPreferredChannel) || !this.global.isEmpty(myPreferredChannel)) {
      for (var commType of this.communicationTypes) {
        if (userPreferredChannel?.includes(commType.id + "")) {
          this.userPreferredChannel.push(commType);
        }
      }
      for (var commType of this.communicationTypes) {
        if (myPreferredChannel?.includes(commType.id + "")) {
          this.myPreferredChannel.push(commType);
        }
      }
    }

    if (!this.global.isEmpty(this.myStartTime)) {
      this.myStartTime = this.changeTime24To12Format(TimeZoneUtils.convertUTCToCurrentTime(this.myStartTime, this.myUser.timeZone));
      this.myEndTime = this.changeTime24To12Format(TimeZoneUtils.convertUTCToCurrentTime(this.myEndTime, this.myUser.timeZone));
    }
    if (!this.global.isEmpty(this.userStartTime)) {
      this.userStartTime = this.changeTime24To12Format(TimeZoneUtils.convertUTCToCurrentTime(this.userStartTime, this.myUser.timeZone));
      this.userEndTime = this.changeTime24To12Format(TimeZoneUtils.convertUTCToCurrentTime(this.userEndTime, this.myUser.timeZone));
    }
    // console.log("userPreferredTime: " + JSON.stringify(this.userPreferredTime));
    // console.log("myPreferredTime: " + JSON.stringify(this.myPreferredTime));
    // console.log("userPreferredChannel: " + JSON.stringify(this.userPreferredChannel));
    // console.log("myPreferredChannel: " + JSON.stringify(this.myPreferredChannel));
    // console.log("userStartTime: " + this.userStartTime);
    // console.log("userEndTime: " + this.userEndTime);
    // console.log("myStartTime: " + this.myStartTime);
    // console.log("myStartTime: " + this.myEndTime);
  }

  convertUtcToUserTime(utcTime) {
    let splittedData = utcTime.split("-");
    if (splittedData.length != 2) {
      console.log("Invalid time range: " + utcTime);
      return utcTime;
    }
    return TimeZoneUtils.convertUTCToCurrentTime(splittedData[0], this.myUser.timeZone) + "-" + TimeZoneUtils.convertUTCToCurrentTime(splittedData[1], this.myUser.timeZone);
  }

  getConvertedTimeList(timeList: any) {
    let list: any[] = [];
    timeList.forEach((timeSlot: any) => {
      list.push(this.convertUtcToUserTime(timeSlot));
    });
    return list;
  }

  changeTime24To12Format(timeIn24Format) {
    let result = "";
    // Split slots
    var slotSplit = timeIn24Format.split("-");
    for (let splitSlot of slotSplit) {
      // Split Time
      var timeSplit = splitSlot.split(" ");
      // Split Hour and Minutes
      var hourMinSplit = timeSplit[0].split(":");
      if (parseInt(hourMinSplit[0]) > 12) {
        result += (parseInt(hourMinSplit[0]) - 12);
        result += ":";
        result += hourMinSplit[1];
        result += " PM";
      } else {
        result += splitSlot;
      }
      result += "-";
    }
    return result.substring(0, result.length - 1);
  }

  timeSlotsCol1 = [
    {
      id: 0,
      view: "00:00-01:00 AM",
      value: "0:00 AM-1:00 AM",
      startTime: "00:00",
      endTime: "01:00",
      checked: false
    },
    {
      id: 1,
      view: "01:00-02:00 AM",
      value: "1:00 AM-2:00 AM",
      startTime: "01:00",
      endTime: "02:00",
      checked: false
    },
    {
      id: 2,
      view: "02:00-03:00 AM",
      value: "2:00 AM-3:00 AM",
      startTime: "02:00",
      endTime: "03:00",
      checked: false
    },
    {
      id: 3,
      view: "03:00-04:00 AM",
      value: "3:00 AM-4:00 AM",
      startTime: "03:00",
      endTime: "04:00",
      checked: false
    },
    {
      id: 4,
      view: "04:00-05:00 AM",
      value: "4:00 AM-5:00 AM",
      startTime: "04:00",
      endTime: "05:00",
      checked: false
    },
    {
      id: 5,
      view: "05:00-06:00 AM",
      value: "5:00 AM-6:00 AM",
      startTime: "05:00",
      endTime: "06:00",
      checked: false
    },
    {
      id: 6,
      view: "06:00-07:00 AM",
      value: "6:00 AM-7:00 AM",
      startTime: "06:00",
      endTime: "07:00",
      checked: false
    },
    {
      id: 7,
      view: "07:00-08:00 AM",
      value: "7:00 AM-8:00 AM",
      startTime: "07:00",
      endTime: "08:00",
      checked: false
    },
    {
      id: 8,
      view: "08:00-09:00 AM",
      value: "8:00 AM-9:00 AM",
      startTime: "08:00",
      endTime: "09:00",
      checked: false
    },
    {
      id: 9,
      view: "09:00-10:00 AM",
      value: "9:00 AM-10:00 AM",
      startTime: "09:00",
      endTime: "10:00",
      checked: false
    },
    {
      id: 10,
      view: "10:00-11:00 AM",
      value: "10:00 AM-11:00 AM",
      startTime: "10:00",
      endTime: "11:00",
      checked: false
    },
    {
      id: 11,
      view: "11:00-12:00 AM",
      value: "11:00 AM-0:00 AM",
      startTime: "11:00",
      endTime: "12:00",
      checked: false
    }
  ];
  timeSlotsCol2 = [
    {
      id: 12,
      view: "00:00-01:00 PM",
      value: "12:00 PM-13:00 PM",
      startTime: "12:00",
      endTime: "13:00",
      checked: false
    },
    {
      id: 13,
      view: "01:00-02:00 PM",
      value: "13:00 PM-14:00 PM",
      startTime: "13:00",
      endTime: "14:00",
      checked: false
    },
    {
      id: 14,
      view: "02:00-03:00 PM",
      value: "14:00 PM-15:00 PM",
      startTime: "14:00",
      endTime: "15:00",
      checked: false
    },
    {
      id: 15,
      view: "03:00-04:00 PM",
      value: "15:00 PM-16:00 PM",
      startTime: "15:00",
      endTime: "16:00",
      checked: false
    },
    {
      id: 16,
      view: "04:00-05:00 PM",
      value: "16:00 PM-17:00 PM",
      startTime: "16:00",
      endTime: "17:00",
      checked: false
    },
    {
      id: 17,
      view: "05:00-06:00 PM",
      value: "17:00 PM-18:00 PM",
      startTime: "17:00",
      endTime: "18:00",
      checked: false
    },
    {
      id: 18,
      view: "06:00-07:00 PM",
      value: "18:00 PM-19:00 PM",
      startTime: "18:00",
      endTime: "19:00",
      checked: false
    },
    {
      id: 19,
      view: "07:00-08:00 PM",
      value: "19:00 PM-20:00 PM",
      startTime: "19:00",
      endTime: "20:00",
      checked: false
    },
    {
      id: 20,
      view: "08:00-09:00 PM",
      value: "20:00 PM-21:00 PM",
      startTime: "20:00",
      endTime: "21:00",
      checked: false
    },
    {
      id: 21,
      view: "09:00-10:00 PM",
      value: "21:00 PM-22:00 PM",
      startTime: "21:00",
      endTime: "22:00",
      checked: false
    },
    {
      id: 22,
      view: "10:00-11:00 PM",
      value: "22:00 PM-23:00 PM",
      startTime: "22:00",
      endTime: "23:00",
      checked: false
    },
    {
      id: 23,
      view: "11:00-12:00 PM",
      value: "23:00 PM-24:00 PM",
      startTime: "23:00",
      endTime: "24:00",
      checked: false
    }
  ];

  communicationTypes = [
    {
      id: 1,
      name: "Messenger",
      checked: false
    },
    {
      id: 2,
      name: "Audio Calling",
      checked: false
    },
    {
      id: 3,
      name: "Video Calling",
      checked: false
    },
    {
      id: 4,
      name: "Email",
      checked: false
    }]

}
