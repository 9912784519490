<app-header-left-menu [user]="user"></app-header-left-menu>
<ion-content id="main-content" class="full-width-page">
  <div class="bg-theme-alt">
    <div class="bg-grad-special">
      <div class="bg-grad-special-alt">
        <div class="nk-ovm shape-u shape-contain shape-left-top"></div>
        <app-header [user]="user"></app-header>
        <div class="subContent">
          <div class="main-content container">
            <h5 class="ath-heading title">A Better Security Strategy<small class="tc-default">Payment Processing Costs & Security Problems Impact Individuals and Enterprises.</small></h5>
            <h3 class="title title-md">
              The communication coordination problem
            </h3>
            <p>The HIDDIN Marketplace deploys a better security strategy than the encryption we currently use for the security of our digital transmissions. Today’s digital processes depend upon the complexity of the encryption used to secure and keep private our identities and data. Quantum computers will make obsolete all the encryption we currently use to protect our digital existence.</p>
            <p>The Problem with any form of encryption is that the protection strategy it relies upon is dependent upon it being more complex than the current capabilities of the decryption technologies available to unravel it. Advances in mathematics, computers, and software work in any combination to help decryption techniques to eventually unravel the level of complexity of any form of encryption. Quantum computers when they arrive in the next five to ten years are expected to be able to decrypt any of the encryption we currently use for the protection of our everyday digital existence. Hackers and identity thieves will exploit quantum decryption capabilities for their benefit and at our expense, but the real danger is from extremists like ISIS, Al-Qaida, Iran and North Korea that want to destroy our way of life. The generation of millions of bogus transactions could seriously disrupt if not destroy our confidence in the financial institutions that our way of life depends on. This tactic may offer these extremists their only real opportunity to damage us.</p>
            <p>We need new ways to protect our digital way of life that will survive quantum computers. Developing new forms of encryption that are even more complex than the next wave of quantum computers as the NIST is attempting to do only kicks the problem down the road to the next advancement in computers or software and or mathematical solutions. Then building these new found encryption schemes into our digital infrastructure will take some time to accomplish and time is not on our side. The protection strategy behind our patented tech does not depend on any level of complexity and we are already building it into our infrastructure starting with securing the IOT. We hope to have an Internet Of Things working model available to demo by the end of the third quarter of 2018.</p>
            <p>Our tech de-identifies the digital transmission and storage processes rendering the data itself useless to anyone that does not have access to our technology to re-identify it. Where encryption uses complexity to hide everything being transmitted or stored by masking it our tech hides your identity and the identities of those you communicate and transact your business with and the manner each of you pays or gets paid by removing this information from the data being stored or transmitted and replacing it with a onetime use token. The important difference is unmasking the complexity of encryption reveals everything and accessing the data protected by our technology reveals only the substance of the data not who was involved or how things were paid for.</p>
            <p>When the substance of the data being stored or transmitted is valuable and must be kept secret our technology does not provide the protection needed. The recipe for Classic Coke or the formula for Kentucky Fried Chicken or the details of troop movements are examples where the data itself is valuable and requires protection and our tech would not be useful. Fortunately in most transactions and communications it is the identities of the parties and how they pay and get paid that needs protection and our security approach is superior. We are quantum and extremist proof already and future advancements in math, computers and or software will not be enough to overcome our protection strategy.</p>
            <p>De-identifying the digital transmission process provides security and privacy to all forms of communications and transactions impacting everyone and every entity that will make use of them, yet it is the anonymity it creates that provides the greatest marketing potential and economies of scale for this technology. This patent protected tech disrupts the cyber security industry, the communications industry, the transaction processing industry, and the marketplace platform designed with it will disrupt the marketing industry. These changes will directly impact any industry where any of these other industries play a vital role in their operations. Any person or business that uses any of the current digital transmission technologies is our potential customer. The businesses providing them with these digital services now will not be able to compete against the superior security and privacy of our technology and the competitive cost advantages of our digital platform marketplace. They will either join us or loose significant market share to their competitors that join us and adapt our technology to meet the specific needs of their industry's customers.</p>
            <p>De-identification and the anonymity it creates makes sharing de-identified data possible between and among buyers and sellers creating valuable economies of scale from this cooperation that impact marketing research, analytic potentials and our buyers' ability to actively participate in the marketing process effecting the quality and the cost of those marketing efforts.</p>
            <p>Focusing on the needs of each industry’s customers is the best way to make these enterprises winners with their customers which will make the CPA's working with the HIDDIN Marketplace to provide these services look like winners to them. We need to present winning solutions for everyone's Quantum Conundrum and demonstrate the advantages that our kind of cooperative marketplace offers everyone.</p>
            <h3 class="title title-md">
              Security and Privacy Problems of The Cyber Security Industry.
            </h3>
            <p>Encrypted communication or transaction data that can be copied will fail to protect our security or privacy when decryption technologies catch up. When quantum computers come of age in the next five to ten years nothing we are currently encrypting will be safe if it has been copied with inexpensive USB and cloud storage capabilities.</p>
            <p><b>HIDDIN'S</b> de-identification renders the data transmitted and stored in our cloud useless to cyber punks even if they could copy it and eventually decrypt it. We use encryption for camouflage not security. Rendering the data useless enables us to offer our marketplace participants both buyers and sellers a viable first line of defense against the cyber threats the world faces. The rest of the cyber security industry assumes even a multi layer approach to perimeter defenses will fail and has concentrated on loss mitigation and breach detection strategies instead. De-identification and tokenization are some of the only viable first line of defense strategies still available and ours is the only one to offer both security and privacy without relying inappropriately on encryption.</p>
            <h3 class="title title-md">
              WWW Surfing Security Exposures
            </h3>
            <p>HIDDIN encourages enrolled individuals and small businesses to operate disconnected from the WWW other than the VPN connection to our services. Everyone enrolled with our services connected to HIDDIN through their VPN will be able to communicate and transmit data securely through us and they will be able to access our de-identified copy of their records cyber safely. When individuals and employees need to venture onto the WWW to reach out to customers, business contacts and other individuals that are not enrolled with our services our surfing app will isolate their computer equipment from the enterprises network and create a protected working environment for them. The data gathered or created in this work space including any e-mail or transmissions originating from un-enrolled sources will be scanned for malware before being stored and sent through to any equipment on your network or our own.</p>
            <p>HIDDIN communications hide the identity of the parties as the transmissions are happening. The HIDDIN authentication process reveals who you are and who everyone else is before these transmissions begin. That knowledge is the most important difference between HIDDIN and the nameless, faceless, no man’s land of the WWW. The WWW is like the Wild Wild West use to be where the only justice you can expect is the justice you can impose. HIDDIN knows who everyone is and can impose what ever justice the situation requires to keep the peace in the HIDDIN Marketplace for both transactions and communications. No spam, no dangerous file attachments, no illegal activity, do unto others as you would have others do unto you or the HIDDIN Marketplace will undo your privileges. HIDDIN is the new sheriff of our de-identified WWW.</p>
            <h3 class="title title-md">
              Security Issues Providing Customers Access To Enterprise Records.
            </h3>
            <p>Enterprises that provide digital access to their customers create an exposure from cyber criminals that threatens all of their customer’s information. Our capabilities provide either the sender or the receiver access to a de-identified version of all the records between them from our cloud. Our technology only exposes one party’s set of records at a time the rest of an enterprise’s customer information is never available nor subject to any exposure. Cyber criminals successfully attacking an enterprise that is not protected with our technology would be required to put forth the same amount of effort for each customer protected with our services. Again the goal is to make it more plausible to kick down doors in brick and motor locations than to mount a cyber attack strategy.</p>
            <h3 class="title title-md">
              Using Profile Information Instead of Identity Information.
            </h3>
            <p>Utilizing profile data as the search criterion rather than dangerously exposing customers identity information enhances the quality of the research initiative without endangering the subjects we are studying. Our competitors tend to commingle customer identity and contact data in the information they transmit and store creating potential cyber exposures in their processes. Our access and control of this secure de-identified communications platform allows us the opportunity to reach out on behalf of our enrolled enterprises to deliver advertising promotions to enrollees that could become new customers without revealing their identities to these enterprises in our processes.</p>
            <p>The level of security our technology makes possible for all our enrollees along with the marketing research and advertising delivery capabilities we provide creates a level playing field for businesses of all sizes. This is an environment where the little guy has the same access to cyber security counter measures and marketplace analytics that even the largest of enterprises would find difficult to replicate without our technology.</p>
            <h3 class="title title-md">
              HIDDIN Is the Security that Makes Possible the Omni Channel Experience that Customers Demand and Enterprises Need To Provide Cyber Safely.
            </h3>
            <p>Our competition is responsible for securing the transmissions of data from all the enterprise's legacy systems up to their cloud in order to work with this information. We secure all the actual channel transmissions for everyone involved in the original interactions and maintain a de-identified copy of all customer information in our cloud already. Our competition works for one enterprise at a time to help them service their individual enterprise customers. HIDDIN works for all our enrollees serving their needs to securely conduct the digital channel communications and transactions between them. Our services are not restricted to enterprise customer interactions. Any enrollee may use any of our secure digital channels to reach out to any other enrollee. Then the enrollee being contacted controls how they choose to participate when they receive a text, a call, an e-mail or file transmission from this specific enrollee.</p>
            <h3 class="title title-md">
              Investors Vantage Point
            </h3>
            <p>HIDDIN enters the information flow before the interaction even begins providing our unique de-identification security protections to enterprise customer interactions by altering the actual transmission process utilized by these digital communication and transaction channels. The process of de-identifying a channel involves positioning our clouded transmission platform between the enterprise and the customer. All the information flowing between them runs through our cloud and we manipulate it to tokenize the identities of everyone involved only to re-identify it when it arrives at the intended destination. HIDDIN provides a secure environment for the transmission of data by de-identifying it in transit. In this process we record a de-identified version of the information passing through each of these channels that we provide access to for both the enterprise and the customer. Our de-identified copy secures this data at rest in our cloud and makes it possible to perform data coordination activities and analytics safely with third party Apps.</p>
            <h3 class="title title-md">
              The HIDDIN Approach to the Omni Channel Coordination Problem
            </h3>
            <p>Protecting the data that is eating the world from the barbarians that seek to destroy the civilization we are building with this data. We need to get the world ready for the day when most of our encryptions protections fail us. One day everything is secure with a particular encryption scheme the next it is not. It may take months or even years for all of these systems to fail us and many will come in groupings. The process of retooling with more sophisticated encryption systems will take time to install and no one will be sure how long the new systems will last or how much they will cost to implement. The level of confusion and potential chaos that this type of financial uncertainty can create would not be the intended consequence of your average cyber criminal. Cyber criminals are out to make a buck to spend in the civilization we are building. The Al Qaeda, ISIS, Iran and North Korea bad guys want to destroy our Western civilization and this opportunity presents them with their only viable course of action to cause serious damage to our way of life.</p>
            <p>Our CEO has lost sleep over this since the fall of 2008 and now you can too or you can help HIDDIN solve this problem. Unfortunately if these bad guys did not know about this opportunity we just spilled the beans and now we are in a race against time. Establishing <b>HIDDIN'S</b> technologies to a level capable of quickly transitioning most of our communication and transaction activity away from failing encryption systems should be enough to bolster the confidence we need to avoid most of the panic and chaos problems this situation could create. Everyone does not have to move all their communication and transaction activity to our services. We simply need to grow our capabilities to a point where we could expand them rapidly to include most everyone in an emergency situation and then with a solution in place the bad guy’s efforts will be futile. If they do try something, they will fail and I am sure the western powers won’t fail to retaliate. Interested in how we plan to grow our capabilities in time by reaching out to the community of professional accountants?</p>
          </div>
        </div>
        <app-footer></app-footer>
      </div>
    </div>
  </div>
</ion-content>
