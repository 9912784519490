import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AuthServiceService } from '@services/auth-service.service';
import { LoaderService } from '@services/loader.service';
import { Globals } from 'src/app/globals';
import { MustMatch } from 'src/app/util/must-match.validator';
import { RegUser } from '../basic-reg/RegUser';
import * as moment from 'moment';
import * as _moment from 'moment-timezone';

import { CompleteRegConfirmationComponent } from '../../modal/complete-reg-confirmation/complete-reg-confirmation.component';
import { NotifiactionPopupComponent } from '../../modal/notifiaction-popup/notifiaction-popup.component';
import { FileuploadService } from '@services/fileupload.service';
import { UpgradeToBusinessComponent } from '@modal/upgrade-to-business/upgrade-to-business.component';
import { environment } from 'src/environments/environment';
import { TimeZoneConstant } from 'src/app/constants/TimeZone';
import { TimeZoneUtils } from 'src/app/util/TimeZoneUtils';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  ifEdited: boolean = false;
  userForm: RegUser = new RegUser();

  signUpForm: FormGroup;
  submitted: boolean = false;
  errorMessage: string;
  successMessage: string;

  passwordVisible: boolean = false;
  confirmPasswordVisible: boolean = false;

  requestPath: string;

  dob: NgbDateStruct;
  dateValidation: boolean;
  dateFormatError: boolean;

  passwordError: string;
  confirmpasswordError: string;

  user: RegUser = new RegUser();
  LoggedIn: boolean;
  profileImage: boolean = false;
  profileImageSrc: any;

  isRegCompleted: boolean;
  remainingDays: number;
  isSubscriptionRequired: boolean = false;

  DAY: number = 24 * 60 * 60 * 1000;
  timeZones = TimeZoneConstant.TIMEZONES;
  currentTime: string;
  timeInterval;

  constructor(public global: Globals
    , public authService: AuthServiceService
    , public modalController: ModalController
    , public formBuilder: FormBuilder
    , public router: Router
    , private loader: LoaderService
    , private fileuploadService: FileuploadService
    , private _changeRef: ChangeDetectorRef) {
    this.requestPath = this.global.requestPath;
  }

  ngOnInit(): void {
    this.signUpForm = this.formBuilder.group({
      firstName: ['', [Validators.required, this.noWhitespace, Validators.maxLength(50)]],
      lastName: ['', [this.noWhitespace, Validators.maxLength(50)]],
     
      userPassword: [''],
      confirmPassword: [''],
      timeZone: ['', [Validators.required]],
      birthDate: ['', [Validators.required]]
    }, {
      validator: MustMatch('userPassword', 'confirmPassword')
    });
  }

  ionViewDidEnter() {
    this.resetInputs();

    this.user = this.authService.getLoggedInUser();
   
    this.profileImageSrc = this.authService.getProfilePicture();
    this.user.imageUrl = this.profileImageSrc;

    this.userForm = this.user;
    // this.userForm.hiddinEmail = "demo123@test.com";
    this.LoggedIn = this.authService.isLoggedIn();
   

    // Set time
    this.timeInterval = setInterval(() => { this.setCurrentTime() }, 1000);

    if (environment.isSubscriptionRequired) {
      this.getCurrentSubscription();
      this.isSubscriptionRequired = true;
    }

    if (this.global.isEmpty(this.user)) {
      if (this.authService.isLoggedIn()) {
        this.getMyProfile();
      }
    } else {
      this.user.birthDate = moment(this.user.birthDate).format("MM/DD/YYYY");
      this.checkForCompleteReg();
    }

    // Test for TimeZone utils
    // var time = "21:00 PM";
    // var usZone = "US/Eastern";
    // var indiaZone = "Asia/Calcutta";
    // var utcTime = TimeZoneUtils.convertOnlyTimeToUTC(time, usZone);
    // console.log("US Time: " + time);
    // console.log("UTC Time: " + utcTime);
    // console.log("US Time converted from UTC: " + TimeZoneUtils.convertUTCToCurrentTime(utcTime, usZone));
    // console.log("India Time converted from UTC: " + TimeZoneUtils.convertUTCToCurrentTime(utcTime, indiaZone));

    // var dateTime = "05/05/2023 02:00 PM";
    // var utcDateTime = TimeZoneUtils.convertDateTimeToUTC(dateTime, usZone);
    // console.log("US Date Time: " + dateTime);
    // console.log("UTC Date Time: " + utcDateTime);
    // console.log("US Date Time converted from UTC: " + TimeZoneUtils.convertUTCToDateTime(utcDateTime, usZone));
    // console.log("India Date Time converted from UTC: " + TimeZoneUtils.convertUTCToDateTime(utcDateTime, indiaZone));
  }

  ionViewDidLeave() {
    clearInterval(this.timeInterval);
  }

  setCurrentTime() {
    if (!this.global.isEmpty(this.user)) {
      this.currentTime = _moment().tz(this.user.timeZone).format("MM/DD/YYYY hh:mm:ss a");
    }
  }

  profileEnrich = function () {
    this.router.navigate(['/profile-enrich/basic-detail']);
  }

  // convenience getter for easy access to form fields
  get f() { return this.signUpForm.controls; }

  public noWhitespace(control: FormControl) {
    let isWhitespace = (control.value || '').trim().length === 0;
    let isValid = !isWhitespace;
    return isValid ? null : { 'required': true }
  }

  editProfile() {
    this.ifEdited = true;
    this.successMessage = null;
    this.enableForm();
  }

  disableForm = function () {
    this.signUpForm.get("firstName").disable();
    this.signUpForm.get("lastName").disable();
    this.signUpForm.get("birthDate").disable();
    this.signUpForm.get("userPassword").disable();
    this.signUpForm.get("confirmPassword").disable();
    this.signUpForm.get("timeZone").disable();
  }

  enableForm = function () {
    this.signUpForm.get("firstName").enable();
    this.signUpForm.get("lastName").enable();
    this.signUpForm.get("birthDate").enable();
    this.signUpForm.get("userPassword").enable();
    this.signUpForm.get("confirmPassword").enable();
    this.signUpForm.get("timeZone").enable();
  }

  saveProfile() {
    this.submitted = true;
    this.errorMessage = null;
    this.successMessage = null;
    this.passwordError = null;
    this.confirmpasswordError = null;

    // stop here if form is invalid
    if (this.signUpForm.invalid) {
      // console.log("Invalid: " + this.signUpForm.value);
      return;
    }

    if (!this.global.isEmpty(this.userForm.userPassword)) {
      if (this.userForm.userPassword.length < 6 || this.userForm.userPassword.length > 20) {
        this.passwordError = "Password must be at between 6 to 20 characters in length";
        return;
      }
      if (this.userForm.confirmPassword.length < 6 || this.userForm.confirmPassword.length > 20) {
        this.confirmpasswordError = "Password must be at between 6 to 20 characters in length";
        return;
      }
      this.userForm.password = window.btoa(this.userForm.userPassword);
    }

    if (this.dateValidation) {
      return;
    }

    if (this.dateFormatError) {
      return;
    }

    this.loader.show("Please wait...");
    this.authService.postEncrypt('common/user?' + this.requestPath + '=updateBasicProfile', this.userForm)
      .subscribe((res: any) => {
        this.loader.hide();
        if (res.success) {
          // Reinitialized the form state
          this.submitted = false;

          // Success message
          this.successMessage = "Profile updated successfully.";

          // Disable form
          this.ifEdited = false;
          this.disableForm();

          res.data = this.global.decrypt(res.data);

          this.user = res.data;
          this.userForm = this.user;
          this.user.imageUrl = this.profileImageSrc;
          this.global.saveLocalData(this.global.HIDDIN_USER_DETAILS, JSON.stringify(res.data));
        } else {
          this.errorMessage = res.message;
        }
      }, error => {
        this.loader.hide();
        // console.log(error);
        this.errorMessage = "Something went wrong, please try again.";
      });
  }

  gotToManageRelatives = function () {
    this.router.navigate(["/family-member"]);
  }

  onDateChange(birthDate: any): void {
    this.dateValidation = false;
    this.dateFormatError = false;

    if (this.global.isEmpty(birthDate)) {
      // console.log("Birth Date is Empty");
      this.signUpForm.controls['birthDate'].setErrors({ 'incorrect': true });
      return;
    }

    this.dateValidation = moment(moment().format('YYYY-MM-DD')).isBefore(moment(birthDate).format('YYYY-MM-DD'));

    var date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/ ;
    if (!birthDate.match(date_regex)) {
      this.dateFormatError = true;
    }

    this.userForm.birthDate = birthDate;
  }

  getMyProfile = function () {
    this.loader.show("Fetching user details...");
    this.authService.get('user?' + this.requestPath + '=getUserDetail')
      .subscribe((res: any) => {
        this.loader.hide();
        // console.log(res);
        if (res.success) {
          // Update user data
          this.user = this.global.decrypt(res.data);;
          this.user.birthDate = moment(this.user.birthDate).format("MM/DD/YYYY");

          this.userForm = this.user;
          this.user.imageUrl = this.profileImageSrc;

          this.checkForCompleteReg();

          // Save data in local storage
          this.global.saveLocalData(this.global.HIDDIN_USER_DETAILS, JSON.stringify(this.user));

        } else {
          // console.log(res.message);
          this.errorMessage = res.message;
        }
      }, (error: any) => {
        this.loader.hide();
        // console.log(error);
        this.errorMessage = "Error occured";
      });
  }

  getCurrentSubscription = function () {
    this.authService.get('subscription?' + this.requestPath + '=getSubscriptionDetail')
      .subscribe((res: any) => {
        this.loader.hide();
        // console.log(res);
        if (res.success) {
          var userSubscription = this.global.decrypt(res.data);
          var remaining = userSubscription.expiryTime - userSubscription.startTime;
          this.remainingDays = Math.floor(remaining / this.DAY);

          if (this.remainingDays <= 0) {
            // Subscription expired
            this.global.saveLocalData(this.global.SUBSCRIPTION, JSON.stringify(true));
          }
        } else {
          // console.log(res.message);
          this.errorMessage = res.message;
        }
      }, (error: any) => {
        this.loader.hide();
        this.errorMessage = "Error occured";
      });
  }

  checkForCompleteReg() {
    if (this.user && (this.user.type == 'Individual' && !this.global.isEmpty(this.user.parentId)
      && this.user.parentId != 0 && !this.global.isEmpty(this.user.isSeqQueUploaded)
      && this.user.isSeqQueUploaded) || (!this.global.isEmpty(this.user.isDocumentUploaded) && this.user.isDocumentUploaded)) {
      this.isRegCompleted = true;
    } else {
      this.isRegCompleted = false;
    }
  }

  resetInputs() {
    this.ifEdited = false;
    this.errorMessage = null;
    this.successMessage = null;
    this.passwordError = null;
    this.confirmpasswordError = null;
    this.disableForm();
  }

  fileBrowseHandler(files) {

    var idxDot = files[0].name.lastIndexOf(".") + 1;
    var extFile = files[0].name.substr(idxDot, files[0].name.length).toLowerCase();
    if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
      this.profileImage = true;
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = (_event) => {
        this.profileImageSrc = reader.result;
      }
      this.updateProfilePicture(files[0]);
    }
    else {
      this.fileTypeNotification();
    }
  }

  updateProfilePicture = function (file) {
    this.successMessage = null;
    this.errorMessage = null;

    if (this.global.isEmpty(file)) {
      return;
    }
    this.loader.show("Updating profile picture...")
    this.fileuploadService.uploadSecure('image?' + this.requestPath + '=updateImage&type=profilepic', file)
      .subscribe((res) => {
        this.loader.hide();
        // console.log(res);
        if (res.success) {
          res.data = this.global.decrypt(res.data);
          if (!this.global.isEmpty(res.data)) {
            this.global.saveLocalData(this.global.USER_IMAGE_URL, JSON.parse(res.data).imageUrl);
            this.profileImageSrc = JSON.parse(res.data).imageUrl;
            this.user.imageUrl = this.profileImageSrc;
          }
          this.successMessage = "Profile picture has been updated successfully.";
        } else {
          this.errorMessage = res.message;
        }
      }, error => {
        this.loader.hide();
        // console.log(error);
        this.errorMessage = "Error occured, please try after some time.";
      });
  }

  async fileTypeNotification() {
    const modal = await this.modalController.create({
      component: NotifiactionPopupComponent,
      componentProps: {
        errorMsg: 'Only jpg/jpeg and png files are allowed!',
        imageSrc: 'assets/images/error.png'
      }
    });
    return await modal.present();
  }

  async presentModal() {
    const modal = await this.modalController.create({
      component: CompleteRegConfirmationComponent
    });
    return await modal.present();
  }

  async openUpgradeToBusinessModal() {
    const modal = await this.modalController.create({
      component: UpgradeToBusinessComponent
    });

    modal.onDidDismiss().then(dataReturned => {
      this.user = this.authService.getLoggedInUser();
      this.userForm = this.user;
      // this._changeRef.detectChanges();
    });

    return await modal.present();
  }

  togglePassword(id: string) {

    const password = document.querySelector('#' + id);
    const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
    password.setAttribute('type', type);

    if (id === 'pwd') {
      this.passwordVisible = !this.passwordVisible;
    }

    if (id === 'confirmPassword') {
      this.confirmPasswordVisible = !this.confirmPasswordVisible;
    }

  }

}
