<div class="leftMenu-Section" *ngIf="isVisible$ | async">
  <div class="leftMenu bg-theme">
    <ul>
      <li [routerLink]="['/home']">Home</li>
      <li [routerLink]="['/dashboard']">Dashboard</li>
    </ul>
  </div>
  <!-- <div class="ui-mask-left ui-mask-s1"></div> -->
</div>


