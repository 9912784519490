<!--Profile popup start -->
<ion-content class="content-main">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-color">
        <ion-col>
          <ion-row class="dsplflx">
            <ion-col size-lg="2" size-md="3" size-sm="3">
              <div class="img img-wrapper">
                <div class="field-item user-img">
                  <div *ngIf="profileImageSrc" class="user-img">
                    <img [src]="profileImageSrc" />
                  </div>
                  <div *ngIf="!profileImageSrc" class="user-img">
                    <img src="assets/images/team/profile.png" />
                  </div>
                </div>
              </div>
            </ion-col>
            <ion-col size-lg="10" size-md="9" size-sm="9">
              <div class="field-item user-details pd all-tag-pad">
                <div *ngIf="user?.firstName">
                  <h4 class="title" class="profile">
                    {{user?.firstName}} {{user?.lastName}}
                  </h4>
                </div>
                <ion-col>
                  <div class="city-name">
                    <div class="addrs" *ngIf="user?.city">
                      <h6 class="hdr">City</h6>
                      <p class="ftr">{{user?.city}}</p>
                    </div>
                    <div class="addrs" *ngIf="user?.state">
                      <h6 class="hdr">State</h6>
                      <p class="ftr">{{user?.state}}</p>
                    </div>
                    <div class="addrs" *ngIf="user?.country">
                      <h6 class="hdr">Country</h6>
                      <p class="ftr">{{user?.country}}</p>
                    </div>
                  </div>
                </ion-col>
                <ion-col>
                  <div *ngIf="user?.birthDate">
                    <h6 class="hdr">Birth Date</h6>
                    <p class="ftr">{{user?.birthDate}}</p>
                  </div>
                  <div *ngIf="user?.company">
                    <h6 class="hdr">Company</h6>
                    <p class="ftr">{{user?.company}}</p>
                  </div>
                </ion-col>
                <ion-col>
                  <div *ngIf="user?.aboutMe">
                    <h6 class="hdr">About Me</h6>
                    <p class="ftr">{{user?.aboutMe}}</p>
                  </div>
                </ion-col>
                <ion-col *ngIf="isAddButtonVisible">
                  <div>
                    <h6 class="hdr">Wants to add some personalize message</h6>
                    <p class="ftr"><textarea rows="2" class="form-control" [(ngModel)]="personalizedMsg" [ngModelOptions]="{standalone: true}" placeholder="Enter text"></textarea></p>
                  </div>
                </ion-col>
                <ion-col class="pop-btn-div1">
                  <div class="mnag">
                    <button (click)="dismiss('ok')"  class="basicbtn declinebtn" type="button">Cancel</button>
                    <button *ngIf="isAddButtonVisible" (click)="openSettingModal(user)"  class="basicbtn approvebtn ml-2" type="button">Add Contact</button>
                  </div>
                </ion-col>
              </div>
            </ion-col>
          </ion-row>
        </ion-col>
      </div>
    </div>
  </div>
</ion-content>
<!-- Profile Popup end -->