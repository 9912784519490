<app-header-left-menu [user]="user"></app-header-left-menu>
<ion-content id="main-content" class="full-width-page">
    <div class="bg-theme-alt">
        <div class="bg-grad-special">
            <div class="bg-grad-special-alt">
                <div class="nk-ovm shape-u shape-contain shape-left-top"></div>
                <app-header [user]="user"></app-header>
                <div class="subContent">
                    <div class="main-content container">
                        <h2 class="title-gradient">Welcome to Hiddin Directory</h2>
                        <p>Manage your communication directory</p>
                        <div class="mt-4">
                            <ion-row>
                                <ion-col size-lg="6" size-md="6" size-sm="12" size-xs="12">
                                    <div (click)="openProfile(null, user, true, false)" class="img-wrapper">
                                        <div class="field-item user-img">
                                            <div *ngIf="profileImageSrc" class="usr-img">
                                                <img [src]="profileImageSrc" />
                                            </div>
                                            <div *ngIf="!profileImageSrc" class="usr-img">
                                                <img src="assets/images/team/profile.png" />
                                            </div>
                                        </div>
                                        <div class="field-item user-details">
                                            <div>
                                                <h4 class="title">{{user.firstName}} {{user.lastName}}</h4>
                                            </div>
                                            <div><span *ngIf="user">{{user.email}}</span></div>
                                            <div><span *ngIf="user">{{user.phoneDialCode}} {{user.phoneNumber}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </ion-col>
                                <ion-col size-lg="6" size-md="6" size-sm="12" size-xs="12">
                                    <div class="right">
                                        <form>
                                            <div class="search-user right">
                                                <input type="text" [(ngModel)]="searchTerm"
                                                    [ngModelOptions]="{standalone: true}"
                                                    placeholder="Please search here!" />
                                                <button type="submit"
                                                    class="btn btn-rg btn-auto btn-outline btn-grad on-bg-theme-dark-alt btn-option btn-outlines"
                                                    (click)="searchContacts(1)"><i class="fa fa-search"></i></button>
                                                <button
                                                    class="btn btn-rg btn-auto btn-outline btn-grad on-bg-theme-dark-alt btn-option btn-outlines"
                                                    (click)="advanceSearch();"><i
                                                        class="fa fa-search-plus"></i></button>
                                            </div>
                                        </form>
                                    </div>
                                </ion-col>
                                <div *ngIf="searchTermErrors" class="errorMessage">
                                    <span><b>{{searchTermErrors}}</b></span>
                                </div>
                                <div *ngIf="contactAdded" class="successMessage"><span>Request has been sent successfully.</span>
                                </div>
                            </ion-row>
                        </div>
                        <div class="cards-section">
                            <h4 class="title-gradient" *ngIf="searchPerformed == false">Your Contact Details</h4>
                            <div *ngIf="searchPerformed == false && pendingRequests && pendingRequests.length > 0">
                                <h2 class="header-gradient">Pending with Me</h2>
                                <ion-row class="card-conatiner">
                                    <ion-col
                                        *ngFor="let requestedContact of pendingRequests | paginate: { itemsPerPage: 12, currentPage: p };"
                                        size-lg="3" size-md="6" size-sm="12" size-xs="12">
                                        <ion-card>
                                            <div class="team">
                                                <div class="team-photo team-photo-sm">
                                                    <img src="assets/images/team/profile.png" alt="team">
                                                    <a href="#team-popup-10" class="content-popup"></a>
                                                    <ul class="team-social">
                                                        <li><a href="#"><em class="fab fa-linkedin-in"></em></a></li>
                                                        <li><a href="#"><em class="fab fa-facebook-f"></em></a></li>
                                                    </ul>
                                                </div>
                                                <h5 class="team-name title title-sm">
                                                    {{requestedContact.contactUser.firstName}}
                                                    {{requestedContact.contactUser.lastName}}</h5>
                                                <span
                                                    class="team-position">{{requestedContact.contactUser.email}}</span>
                                                <br />
                                                <span
                                                    class="team-position">{{requestedContact.contactUser.phoneDialCode}}
                                                    {{requestedContact.contactUser.phoneNumber}}</span>
                                                <br />
                                                <button
                                                    class="btn btn-rg btn-auto btn-outline btn-grad on-bg-theme-dark-alt btn-option btn-outlines"
                                                    (click)="openProfile(requestedContact, requestedContact.contactUser, false, false)"><i
                                                        class="fa fa-eye"></i></button>
                                                <button
                                                    class="btn btn-rg btn-auto btn-outline btn-grad on-bg-theme-dark-alt btn-option btn-outlines"
                                                    (click)="showApproveCategoryOptions(requestedContact)"><i
                                                        class="fa fa-check"></i></button>
                                                <button
                                                    class="btn btn-rg btn-auto btn-outline btn-grad on-bg-theme-dark-alt btn-option btn-outlines"
                                                    (click)="rejectContact(requestedContact)"><i
                                                        class="fa fa-ban"></i></button>
                                            </div>
                                        </ion-card>
                                    </ion-col>
                                    <ion-col size-lg="12" size-md="12" size-sm="12" size-xs="12">
                                        <pagination-controls (pageChange)="pagechange($event)"
                                            [hidden]="requestedContacts.length == 0" style=" text-align: center;">
                                        </pagination-controls>
                                    </ion-col>
                                </ion-row>
                            </div>
                            <div *ngIf="searchPerformed == false && requestedContacts && requestedContacts.length > 0">
                                <h2 class="header-gradient">Requested</h2>
                                <ion-row class="card-conatiner">
                                    <ion-col
                                        *ngFor="let requestedContact of requestedContacts | paginate: { itemsPerPage: 12, currentPage: p };"
                                        size-lg="3" size-md="6" size-sm="12" size-xs="12">
                                        <ion-card>
                                            <div class="team">
                                                <div class="team-photo team-photo-sm">
                                                    <img src="assets/images/team/profile.png" alt="team">
                                                    <a href="#team-popup-10" class="content-popup"></a>
                                                    <ul class="team-social">
                                                        <li><a href="#"><em class="fab fa-linkedin-in"></em></a></li>
                                                        <li><a href="#"><em class="fab fa-facebook-f"></em></a></li>
                                                    </ul>
                                                </div>
                                                <h5 class="team-name title title-sm">
                                                    {{requestedContact.contactUser.firstName}}
                                                    {{requestedContact.contactUser.lastName}}</h5>
                                                <span
                                                    class="team-position">{{requestedContact.contactUser.email}}</span>
                                                <br />
                                                <span
                                                    class="team-position">{{requestedContact.contactUser.phoneDialCode}}
                                                    {{requestedContact.contactUser.phoneNumber}}</span>
                                                <br />
                                                <button
                                                    class="btn btn-rg btn-auto btn-outline btn-grad on-bg-theme-dark-alt btn-option btn-outlines"
                                                    (click)="openProfile(null, requestedContact.contactUser, false, false)"><i
                                                        class="fa fa-eye"></i></button>
                                                <!-- <button
                                                class="btn btn-rg btn-auto btn-outline btn-grad on-bg-theme-dark-alt btn-option btn-outlines"
                                                (click)="showApproveCategoryOptions(requestedContact)"><i
                                                    class="fa fa-check"></i></button>
                                            <button
                                                class="btn btn-rg btn-auto btn-outline btn-grad on-bg-theme-dark-alt btn-option btn-outlines"
                                                (click)="rejectContact(requestedContact)"><i
                                                    class="fa fa-ban"></i></button> -->
                                            </div>
                                        </ion-card>
                                    </ion-col>
                                    <ion-col size-lg="12" size-md="12" size-sm="12" size-xs="12">
                                        <pagination-controls (pageChange)="pagechange($event)"
                                            [hidden]="requestedContacts.length == 0" style=" text-align: center;">
                                        </pagination-controls>
                                    </ion-col>
                                </ion-row>
                            </div>
                            <div *ngIf="searchPerformed == false && favoriteContacts && favoriteContacts.length > 0">
                                <h2 class="header-gradient">Favorites</h2>
                                <ion-row class="card-conatiner">
                                    <ion-col
                                        *ngFor="let favoriteContact of favoriteContacts | paginate: { itemsPerPage: 12, currentPage: p };"
                                        size-lg="3" size-md="6" size-sm="12" size-xs="12">
                                        <ion-card (click)="openProfile(null, favoriteContact.contactUser, false, false)">
                                            <div class="team">
                                                <div class="team-photo team-photo-sm">
                                                    <img src="assets/images/team/profile.png" alt="team">
                                                    <a href="#team-popup-10" class="content-popup"></a>
                                                    <ul class="team-social">
                                                        <li><a href="#"><em class="fab fa-linkedin-in"></em></a></li>
                                                        <li><a href="#"><em class="fab fa-facebook-f"></em></a></li>
                                                    </ul>
                                                </div>
                                                <h5 class="team-name title title-sm">
                                                    {{favoriteContact.contactUser.firstName}}
                                                    {{favoriteContact.contactUser.lastName}}</h5>
                                                <span class="team-position">{{favoriteContact.contactUser.email}}</span>
                                                <br />
                                                <span
                                                    class="team-position">{{favoriteContact.contactUser.phoneDialCode}}
                                                    {{favoriteContact.contactUser.phoneNumber}}</span>
                                            </div>
                                            <p class="time-section"
                                                *ngIf="favoriteContact?.startTime && favoriteContact?.endTime">
                                                <span>{{convertTime(favoriteContact.startTime)}}</span> -
                                                <span>{{convertTime(favoriteContact.endTime)}}</span>
                                            </p>
                                            <p class="time-section"
                                                *ngIf="!favoriteContact?.startTime || !favoriteContact?.endTime">
                                                <span>Preferred Time: N/A</span>
                                            </p>
                                        </ion-card>
                                    </ion-col>
                                    <ion-col size-lg="12" size-md="12" size-sm="12" size-xs="12">
                                        <pagination-controls (pageChange)="pagechange($event)"
                                            [hidden]="favoriteContacts.length == 0" style=" text-align: center;">
                                        </pagination-controls>
                                    </ion-col>
                                </ion-row>
                            </div>
                            <div *ngIf="searchPerformed == false && personalContacts && personalContacts.length > 0">
                                <h2 class="header-gradient">Family</h2>
                                <ion-row class="card-conatiner">
                                    <ion-col
                                        *ngFor="let personalContact of personalContacts | paginate: { itemsPerPage: 12, currentPage: p };"
                                        size-lg="3" size-md="6" size-sm="12" size-xs="12">
                                        <ion-card (click)="openProfile(null, personalContact.contactUser, false, true)">
                                            <div class="team">
                                                <div class="team-photo team-photo-sm">
                                                    <img src="assets/images/team/profile.png" alt="team">
                                                    <a href="#team-popup-10" class="content-popup"></a>
                                                    <ul class="team-social">
                                                        <li><a href="#"><em class="fab fa-linkedin-in"></em></a></li>
                                                        <li><a href="#"><em class="fab fa-facebook-f"></em></a></li>
                                                    </ul>
                                                </div>
                                                <h5 class="team-name title title-sm">
                                                    {{personalContact.contactUser.firstName}}
                                                    {{personalContact.contactUser.lastName}}</h5>
                                                <span class="team-position">{{personalContact.contactUser.email}}</span>
                                                <br />
                                                <span
                                                    class="team-position">{{personalContact.contactUser.phoneDialCode}}
                                                    {{personalContact.contactUser.phoneNumber}}</span>
                                            </div>
                                            <p class="time-section"
                                                *ngIf="personalContact?.startTime && personalContact?.endTime">
                                                <span>{{convertTime(personalContact.startTime)}}</span> -
                                                <span>{{convertTime(personalContact.endTime)}}</span>
                                            </p>
                                            <p class="time-section"
                                                *ngIf="!personalContact?.startTime || !personalContact?.endTime">
                                                <span>Preferred Time: N/A</span>
                                            </p>
                                        </ion-card>
                                    </ion-col>
                                    <ion-col size-lg="12" size-md="12" size-sm="12" size-xs="12">
                                        <pagination-controls (pageChange)="pagechange($event)"
                                            [hidden]="personalContacts.length == 0" style=" text-align: center;">
                                        </pagination-controls>
                                    </ion-col>
                                </ion-row>
                            </div>
                            <div *ngIf="searchPerformed == false && workContacts && workContacts.length > 0">
                                <h2 class="header-gradient">Work</h2>
                                <ion-row class="card-conatiner">
                                    <ion-col
                                        *ngFor="let workContact of workContacts | paginate: { itemsPerPage: 12, currentPage: p };"
                                        size-lg="3" size-md="6" size-sm="12" size-xs="12">
                                        <ion-card (click)="openProfile(null, workContact.user, false, false)">
                                            <div class="team">
                                                <div class="team-photo team-photo-sm">
                                                    <img src="assets/images/team/profile.png" alt="team">
                                                    <a href="#team-popup-10" class="content-popup"></a>
                                                    <ul class="team-social">
                                                        <li><a href="#"><em class="fab fa-linkedin-in"></em></a></li>
                                                        <li><a href="#"><em class="fab fa-facebook-f"></em></a></li>
                                                    </ul>
                                                </div>
                                                <h5 class="team-name title title-sm">
                                                    {{workContact.contactUser.firstName}}
                                                    {{workContact.contactUser.lastName}}</h5>
                                                <span class="team-position">{{workContact.contactUser.email}}</span>
                                                <br />
                                                <span class="team-position">{{workContact.contactUser.phoneDialCode}}
                                                    {{workContact.contactUser.phoneNumber}}</span>
                                            </div>
                                            <p class="time-section"
                                                *ngIf="workContact?.startTime && workContact?.endTime">
                                                <span>{{convertTime(workContact.startTime)}}</span> -
                                                <span>{{convertTime(workContact.endTime)}}</span>
                                            </p>
                                            <p class="time-section"
                                                *ngIf="!workContact?.startTime || !workContact?.endTime">
                                                <span>Preferred Time: N/A</span>
                                            </p>
                                        </ion-card>
                                    </ion-col>
                                    <ion-col size-lg="12" size-md="12" size-sm="12" size-xs="12">
                                        <pagination-controls (pageChange)="pagechange($event)"
                                            [hidden]="workContacts.length == 0" style=" text-align: center;">
                                        </pagination-controls>
                                    </ion-col>
                                </ion-row>
                            </div>
                            <div *ngIf="searchPerformed == false && generalContacts && generalContacts.length > 0">
                                <h2 class="header-gradient">Friends</h2>
                                <ion-row class="card-conatiner">
                                    <ion-col
                                        *ngFor="let generalContact of generalContacts | paginate: { itemsPerPage: 12, currentPage: p };"
                                        size-lg="3" size-md="6" size-sm="12" size-xs="12">
                                        <ion-card (click)="openProfile(null, generalContact.contactUser, false, false)">
                                            <div class="team">
                                                <div class="team-photo team-photo-sm">
                                                    <img src="assets/images/team/profile.png" alt="team">
                                                    <a href="#team-popup-10" class="content-popup"></a>
                                                    <ul class="team-social">
                                                        <li><a href="#"><em class="fab fa-linkedin-in"></em></a></li>
                                                        <li><a href="#"><em class="fab fa-facebook-f"></em></a></li>
                                                    </ul>
                                                </div>
                                                <h5 class="team-name title title-sm">
                                                    {{generalContact.contactUser.firstName}}
                                                    {{generalContact.contactUser.lastName}}</h5>
                                                <span class="team-position">{{generalContact.contactUser.email}}</span>
                                                <br />
                                                <span class="team-position">{{generalContact.contactUser.phoneDialCode}}
                                                    {{generalContact.contactUser.phoneNumber}}</span>
                                            </div>
                                            <p class="time-section"
                                                *ngIf="generalContact?.startTime && generalContact?.endTime">
                                                <span>{{convertTime(generalContact.startTime)}}</span> -
                                                <span>{{convertTime(generalContact.endTime)}}</span>
                                            </p>
                                            <p class="time-section"
                                                *ngIf="!generalContact?.startTime || !generalContact?.endTime">
                                                <span>Preferred Time: N/A</span>
                                            </p>
                                        </ion-card>
                                    </ion-col>
                                    <ion-col size-lg="12" size-md="12" size-sm="12" size-xs="12">
                                        <pagination-controls (pageChange)="pagechange($event)"
                                            [hidden]="generalContacts.length == 0" style=" text-align: center;">
                                        </pagination-controls>
                                    </ion-col>
                                </ion-row>
                            </div>
                            <h6
                                *ngIf="contactsLoaded && userContacts && userContacts.length == 0 && searchPerformed == false">
                                - Start adding contacts by searching other users.</h6>
                            <h4 *ngIf="searchPerformed == true" class="header-gradient">Hiddin Users with matching
                                criteria: <span class="green">{{searchTerm}}</span></h4>
                            <ion-row *ngIf="searchPerformed == true && searchResults && searchResults.length > 0"
                                class="card-conatiner">
                                <ion-col
                                    *ngFor="let searchResult of searchResults | paginate: { itemsPerPage: 12, currentPage: p };"
                                    size-lg="3" size-md="6" size-sm="12" size-xs="12">
                                    <ion-card>
                                        <div class="team">
                                            <div class="team-photo team-photo-sm">
                                                <img src="assets/images/team/profile.png" alt="team">
                                                <a href="#team-popup-10" class="content-popup"></a>
                                                <ul class="team-social">
                                                    <li><a href="#"><em class="fab fa-linkedin-in"></em></a></li>
                                                    <li><a href="#"><em class="fab fa-facebook-f"></em></a></li>
                                                </ul>
                                            </div>
                                            <h5 class="team-name title title-sm">{{searchResult.user.firstName}}
                                                {{searchResult.user.lastName}}</h5>
                                            <span class="team-position">{{searchResult.user.email}}</span>
                                            <br />
                                            <span class="team-position">{{searchResult.user.phoneDialCode}}
                                                {{searchResult.user.phoneNumber}}</span>
                                            <br />
                                            <button *ngIf="!contactIds.includes(searchResult.user.id)"
                                                class="btn btn-rg btn-auto btn-outline btn-grad on-bg-theme-dark-alt btn-option btn-outlines"
                                                (click)="showContactCategoryOptions(searchResult.user.id)"><i
                                                    class="fa fa-plus"></i></button>
                                            <button
                                                class="btn btn-rg btn-auto btn-outline btn-grad on-bg-theme-dark-alt btn-option btn-outlines"
                                                (click)="openProfile(null, searchResult.user, false, true)"><i
                                                    class="fa fa-eye"></i></button>
                                        </div>
                                    </ion-card>
                                </ion-col>
                                <ion-col size-lg="12" size-md="12" size-sm="12" size-xs="12">
                                    <pagination-controls (pageChange)="pagechange($event)"
                                        [hidden]="searchResults.length == 0" style=" text-align: center;">
                                    </pagination-controls>
                                </ion-col>
                            </ion-row>
                            <h6 *ngIf="searchResults && searchResults.length == 0 && searchPerformed == true">- No user
                                found. Please refine your search term and search again.</h6>
                            <h6 *ngIf="searchPerformed == true"><button
                                    class="btn btn-primary btn-md pull-right mt-4 mr-2" type="button"
                                    (click)="closeResults()">Close</button></h6>
                        </div>
                    </div>
                </div>
                <app-footer></app-footer>
            </div>
        </div>
    </div>
</ion-content>