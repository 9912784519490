import { Pipe, PipeTransform } from '@angular/core';
import { ContactCategory } from '../constants/ContactCategory';

@Pipe({
    name: 'matchesCategory'
})
export class MathcesCategoryPipe implements PipeTransform {
    transform(items: Array<any>, category: string): Array<any> {
        if (category == ContactCategory.ALL) {
            return items;
        } else if (category == ContactCategory.FAVOURITE) {
            return items.filter(item => item.isFavorite === true);
        }
        return items.filter(item => item.category === category);
    }
}