import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-success-message',
  templateUrl: './success-message.component.html',
  styleUrls: ['./success-message.component.scss'],
})
export class SuccessMessageComponent implements OnInit {

  @Input() imageSrc: string;
  @Input() errorMsg: string;

  constructor(private modalCtrl:ModalController) {}

  async dismiss() {
    this.modalCtrl.dismiss();
  }

  ngOnInit(): void {
  }

}
