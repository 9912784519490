import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LeftMenuService {

  isVisibleSubject = new BehaviorSubject<boolean>(true);
  
  get isVisible() {
    return this.isVisibleSubject.asObservable();
  }

  toggleMenu() {
    this.isVisibleSubject.next(!this.isVisibleSubject.value);
  }

}
