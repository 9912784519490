import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { AuthServiceService } from '@services/auth-service.service';
import { LoaderService } from '@services/loader.service';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent implements OnInit {
  @Input('section') section: string;
  @Input('id') id: number;

  requestPath;
  errorMessage;

  constructor(private modalCtrl: ModalController
    , private fb: FormBuilder
    , private loader: LoaderService
    , private global: Globals
    , private authService: AuthServiceService) {
    this.requestPath = this.global.requestPath;
  }

  ngOnInit(): void {
  }

  async dismiss(data) {
    this.modalCtrl.dismiss(data);
  }

  deleteData() {
    if (this.global.isEmpty(this.id)) {
      this.errorMessage = "No data found";
      return;
    }
    if (this.section === "Basic Detail") {
      this.deleteBasicInfo();
    } else if (this.section === "User Location") {
      this.deleteAddress();
    } else if (this.section === 'Family Member') {
      this.deleteFamilyMember();
    } else if (this.section === 'Education Detail') {
      this.deleteEducation();
    } else if (this.section === 'Occupation Detail') {
      this.deleteOccupation();
    }
  }

  deleteBasicInfo() {
    this.loader.show("Please wait...");
    this.authService.postEncrypt('enrol/basic-info?' + this.requestPath + '=delete&infoId=' + this.id, null)
      .subscribe((res) => {
        this.loader.hide();
        if (res.success) {
          this.dismiss('ok');
        } else {
          this.errorMessage = res.message;
        }
      }, error => {
        this.loader.hide();
        // console.log('Oops! Error occured while deleting basic info. Please try again later.')
        this.errorMessage = 'Error occured, please try again.';
      });
  }

  deleteAddress() {
    this.loader.show("Please wait...");
    this.authService.postEncrypt('enrol/address?' + this.requestPath + '=delete&addressId=' + this.id, null)
      .subscribe((res) => {
        this.loader.hide();
        if (res.success) {
          this.dismiss('ok');
        } else {
          this.errorMessage = res.message;
        }
      }, error => {
        this.loader.hide();
        // console.log('Oops! Error occured while deleting address. Please try again later.')
        this.errorMessage = 'Error occured, please try again.';
      });
  }

  deleteFamilyMember() {
    this.loader.show("Please wait...");
    this.authService.postEncrypt('enrol/familymember?' + this.requestPath + '=delete&memberId=' + this.id, null)
      .subscribe((res) => {
        this.loader.hide();
        if (res.success) {
          this.dismiss('ok');
        } else {
          this.errorMessage = res.message;
        }
      }, error => {
        this.loader.hide();
        // console.log('Oops! Error occured. Please try again later.')
        this.errorMessage = 'Error occured, please try again.';
      });
  }

  deleteEducation() {
    this.loader.show("Please wait...");
    this.authService.postEncrypt('enrol/education?' + this.requestPath + '=delete&educationId=' + this.id, null)
      .subscribe((res) => {
        this.loader.hide();
        if (res.success) {
          this.dismiss('ok');
        } else {
          this.errorMessage = res.message;
        }
      }, error => {
        this.loader.hide();
        // console.log('Oops! Error occured. Please try again later.')
        this.errorMessage = 'Error occured, please try again.';
      });
  }

  deleteOccupation() {
    this.loader.show("Please wait...");
    this.authService.postEncrypt('enrol/employment?' + this.requestPath + '=delete&employmentId=' + this.id, null)
      .subscribe((res) => {
        this.loader.hide();
        if (res.success) {
          this.dismiss('ok');
        } else {
          this.errorMessage = res.message;
        }
      }, error => {
        this.loader.hide();
        // console.log('Oops! Error occured. Please try again later.')
        this.errorMessage = 'Error occured, please try again.';
      });
  }
}
