<app-header-left-menu [user]="user"></app-header-left-menu>
<ion-content id="main-content" class="full-width-page">
  <div class="bg-theme-alt">
    <div class="bg-grad-special">
      <div class="bg-grad-special-alt">
        <div class="nk-ovm shape-u shape-contain shape-left-top"></div>
        <app-header [user]="user"></app-header>
        <div class="subContent">
          <div class="main-content container">
            <h5 class="ath-heading title">SOLUTIONS<small class="tc-default">Building A Better Marketplace</small></h5>
            <h3 class="title title-md">
              Building A Better Marketplace
            </h3>
            <p>Creating a digital platform marketplace with our tech allows our sellers to deploy customized digital platform marketing strategies uniquely tailored for their industries and provides our buyers with the anonymity they need to take ownership and control of their data and the marketing process. Together these forces combine to produce our targeted cost to market savings of 50% over the traditional marketing spend for most industries while providing a level of security, privacy and control unavailable with existing technologies..... [<a href="javascript:" [routerLink]="['/marketplace']">Read More</a>]</p>
            <h3 class="title title-md">
              A Better Communications Platform
            </h3>
            <p>The enhancements in communication security and privacy together with the intelligence of our proprietary access controls and channel coordination capabilities for both the individual and the enterprise will make everyone look smarter and not work harder. Providing customers with the kind of Omni Channel Experience they seek without endangering their security or privacy demonstrates how our enrolled enterprises will act smarter at operational costs well below their competition without working harder..... [<a href="javascript:" [routerLink]="['/communication']">Read More</a>]</p>
            <h3 class="title title-md">
              A Better Transaction Processing Platform
            </h3>
            <p>The de-identification of transaction processing makes HIDDIN more secure for both buyers and sellers, improves the quality of and access to the transaction data it processes, and eliminates the typical transaction processing costs..... [<a href="javascript:" [routerLink]="['/transaction']">Read More</a>]</p>
            <h3 class="title title-md">
              Deploying A Better Security Strategy
            </h3>
            <p>Encryption the principal means of protecting the security and privacy of our digital communications and transactions will fail when quantum computers mature in the next five to ten years. Where encryption hides our information the HIDDIN de-identification security strategy renders the data we transmit and store useless protecting us from the quantum computer threat. Deploying our tech over all communication channels and transaction settings and within every industry will take years to accomplish and offers the only known solution to this quantum decryption problem......[<a href="javascript:" [routerLink]="['/security']">Read More</a>]</p>
          </div>
        </div>
        <app-footer></app-footer>
      </div>
    </div>
  </div>
</ion-content>
