import { Injectable } from '@angular/core';
import { Globals } from '../globals';

@Injectable({
    providedIn: 'root'
})
export class RegTokenServiceService {

    constructor(private global: Globals) { }

    private deviceId = "";
    private processId = "";
    private checksum = "";
    private adjustedFlagTime = "";
    private encounterNo = "";
    private flagTime = "";
    private inventoryAdjustedFlagTime = "";
    private inventoryEncounterNo = "";
    private successMessageTitle = "";
    private messageType = "";
    private successMessageBody = "";
    private user = {};
    private mails = [];
    private picturePasswordUrl = "";
    private authorized = false;
    private deviceIdList;
    private amount;
    private currentUserId;
    private transactionNo;
    public transactionId;

    public getCurrentUserId = function () {
        return this.currentUserId;
    }

    public setCurrentUserId = function (currentUserId) {
        this.currentUserId = currentUserId;
    }


    public getDeviceIdKey = function () {
        return this.global.DEVICE_ID;
    }

    public getOldTokenKey = function () {
        return this.global.OLD_TOKENS;
    }

    public getNewTokenKey = function () {
        return this.global.NEW_TOKENS;
    }

    public setDeviceIdList = function (deviceIdList) {
        this.deviceIdList = deviceIdList;
    }

    public getDeviceIdList = function () {
        return this.deviceIdList;
    }

    public setAmount = function (amount) {
        this.amount = amount;
    }

    public getAmount = function () {
        return this.amount;
    }

    public setAuthorized = function (authorize) {
        this.authorized = authorize;
    }

    public isAuthorized = function () {
        return this.authorized;
    }

    public getTimeStamp = function () {
        return this.timestamp;
    }

    public setTimeStamp = function (timestamp) {
        this.timestamp = timestamp;
    }

    public getPicturePasswordUrl = function () {
        return this.picturePasswordUrl;
    }

    public setPicturePasswordUrl = function (picturePasswordUrl) {
        this.picturePasswordUrl = picturePasswordUrl;
    }

    public getSuccessMessageTitle = function () {
        return this.successMessageTitle;
    }

    public getSuccessMessageBody = function () {
        return this.successMessageBody;
    }

    public getMessageType = function () {
        return this.messageType;
    }

    public setMessageType = function (type) {
        this.messageType = type;
    }

    public setSuccessMessageTitle = function (successMessageTitle) {
        this.successMessageTitle = successMessageTitle;
    }

    public setSuccessMessageBody = function (successMessageBody) {
        this.successMessageBody = successMessageBody;
    }

    public isNullOrEmptyOrUndefined = function (value) {
        return !value;
    }

    public setChecksum = function (checksum) {
        this.checksum = checksum;
    }

    public setAdjustedFlagTime = function (adjustedFlagTime) {
        this.adjustedFlagTime = adjustedFlagTime;
    }

    public setFlagTime = function (flagTime) {
        this.flagTime = flagTime;
    }

    public setEncounterNumber = function (encounterNo) {
        this.encounterNo = encounterNo;
    }

    public setProcessId = function (processId) {
        this.processId = processId;
    }

    public setDeviceId = function (deviceId) {
        this.deviceId = deviceId;
    }

    public getDeviceId = function () {
        return this.deviceId;
    }

    public setInventoryAdjFlagTime = function (inventoryAdjustedFlagTime) {
        this.inventoryAdjustedFlagTime = inventoryAdjustedFlagTime;
    }

    public getInventoryAdjFlagTime = function () {
        return this.inventoryAdjustedFlagTime;
    }

    public setInventoryEncounterNo = function (inventoryEncounterNo) {
        this.inventoryEncounterNo = inventoryEncounterNo;
    }

    public getInventoryEncounterNo = function () {
        return this.inventoryEncounterNo;
    }

    public getProcessId = function () {
        return this.processId;
    }

    public getChecksum = function () {
        return this.checksum;
    }

    public getAdjustedFlagTime = function () {
        return this.adjustedFlagTime;
    }

    public getFlagTime = function () {
        return this.flagTime;
    }

    public getEncounterNo = function () {
        return this.encounterNo;
    }

    public setEncounterNo = function (no) {
        this.encounterNo = no;
    }

    public setUser = function (user) {
        this.user = user;
    }
    public getUser = function () {
        return this.user;
    }

    public setHMail = function (mails) {
        this.mails = mails;
    }
    public getHMails = function () {
        return this.mails;
    }

    public getTransactionNo = function () {
        return this.transactionNo;
    }

    public setTransactionNo = function (no) {
        this.transactionNo = no;
    }

    public getTransactionId = function () {
        return this.transactionId;
    }

    public setTransactionId = function (id) {
        this.transactionId = id;
    }

    public getDefaultToken = function () {
        return {
            "deviceId": "",
            "processId": "",
            "label": "",
            "token": "",
            "timeOffset": "",
            "timeCalc": "",
            "checksum": "",
            "stepUsed": "",
            "flagTime": "",
            "adjFlagTime": "",
            "matchTime": "",
            "valid": ""
        };
    }

    public getDefaultMessage = function () {
        return {
            "deviceId": "",
            "processId": "",
            "senderId": "",
            "label": "",
            "encounterNo": "",
            "serial1": "",
            "serial2": "",
            "timestamp": "",
            "checksum": "",
            "token": "",
            "timeOffset": "",
            "timeCalc": "",
            "tokenChecksum": "",
            "flagTime": "",
            "adjustedFlagTime": "",
            "matchTime": 0,
            "answer1": "",
            "answer2": "",
            "answer3": "",
            "answer4": "",
            "answer5": "",
            "oldDeviceTokens": [],
            "newDeviceTokens": [],
            "picturePasswordUrl": "",
            "otp": "",
            "hubId": "",
            "publicKey1": "",
            "publicKey2": "",
            "selectedManufacturer": "",
            "email": {
                "recipients": "",
                "subject": "",
                "body": ""
            },
            "globalDevice": [],
            "rdfLabel": ""
        };
    }
}
